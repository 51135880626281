import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'Services/AxiosService';
import { paramsToQueryParam, listToQueryParam } from "../../UrlUtils";
import { LINK_ALL, LINK_ADD, LINK_DELETE, LINK_REPORT } from 'store/UrlConstants';
import { showSnackbarMessage } from "store/slices/MessagesSystemSlice";
import { removeManyObjectList } from "./KnowledgeObjectSearchSlice";

const linkAdapter = createEntityAdapter({ selectId: entity => entity.idDossier });

export const addKnowledgeUniversLinked = createAsyncThunk("KnowledgeObjectLink/Add", async (requestBody, thunkAPI) => {
    try {
        const response = await axios.post(LINK_ADD, requestBody);

        thunkAPI.dispatch(
            showSnackbarMessage({
                dba: "objetsLangues2",
                messageId: "web_confirmation_enregistrement",
                severity: "success",
            })
        );

        return response.data;

    } catch (error) {
        thunkAPI.dispatch(
            showSnackbarMessage({
                message: error.response.data.messageText,
                severity: "error",
                autoHideDuration: 10000,
            })
        );

        return thunkAPI.rejectWithValue(error.response.data);
    }
})

export const getAllKnowledgeUniversLinked = createAsyncThunk("KnowledgeObjectLink/All", async (idDocument) => {
    const response = await axios.get(LINK_ALL, { params: { idDocument: idDocument } });
    return response.data;
})

export const {
    selectAll: selectAllUniversAssocies,
    selectById: selectUniverAssocieById,
} = linkAdapter.getSelectors(state => state.kmObjectLink.universAssociesList);

export const deleteKnowledgeUniversLinked = createAsyncThunk("KnowledgeObjectLink/Delete", async (idDocuments, thunkAPI) => {
    try {
        const response = await axios.delete(LINK_DELETE + listToQueryParam("idDocuments", idDocuments));
        thunkAPI.dispatch(removeManyObjectList(idDocuments));

        thunkAPI.dispatch(
            showSnackbarMessage({
                dba: "objetsLangues2",
                messageId: "web_confirmation_enregistrement",
                severity: "success",
            })
        );

        return response.data;

    } catch (error) {
        thunkAPI.dispatch(
            showSnackbarMessage({
                message: error.response.data.messageText,
                severity: "error",
                autoHideDuration: 10000,
            })
        );

        return thunkAPI.rejectWithValue(error.response.data);
    }
})

const initialState = {
    universAssociesList: linkAdapter.getInitialState(),
    openAssociationUniversDialog: false,
}

const KnowledgeObjectLinkSlice = createSlice({
    name: 'KnowledgeObjectLink',
    initialState: initialState,
    reducers: {
        handleOpenCloseAssociationUniversDialog(state, action) {
            state.openAssociationUniversDialog = !state.openAssociationUniversDialog;
        },
    },
    extraReducers: {
        [getAllKnowledgeUniversLinked.fulfilled]: (state, action) => {
            linkAdapter.setAll(state.universAssociesList, action.payload);
        },
    },
});

export const { handleOpenCloseAssociationUniversDialog } = KnowledgeObjectLinkSlice.actions;

export default KnowledgeObjectLinkSlice.reducer;