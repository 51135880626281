import React, {useEffect, useMemo, useState, Suspense, lazy} from 'react';
import { useSelector, useDispatch } from "react-redux";
import {Redirect, Route, Switch} from "react-router-dom";
import LocalStorageService from "../Services/LocalStorageService";
import {fetchLoggedUserData, setReloadLocale} from "store/slices/AuthenticationSlice";
import {fetchCompanyContactDetails} from "../store/slices/CompanyContactDetailsSlice";
import { fetchLanguages } from "store/slices/LanguagesSlice";
import {fetchAllMessagesLanguesByLangue, fetchAllObjetsLangues2ByLangue} from "store/slices/MessagesSystemSlice";
import {fetchAllPersoObjets} from "../store/slices/PersoSlice";
import {setMenuData} from 'store/slices/SecuritySlice';
import { fetchSites } from "store/slices/SitesSlice";
import {PublicClientApplication} from "@azure/msal-browser";
import {MsalProvider} from "@azure/msal-react";
import {getCookieValue, getHost} from "../Utils/CommonFunctions";
import CircularProgress from '@mui/material/CircularProgress';
import Box from "@mui/material/Box";
import {useLazyFetchMenuDataQuery} from "../store/slices/UsagerApi"
const PageLogin = lazy(() => import("../Components/Login"));
const PageLoading = lazy(() => import("../Components/Login/Loading"));
const PublicDocuments = lazy(() => import("Components/Modules/GestionDocumentaire/DocumentsActifs"));
const LoginLogic = lazy(() => import("./MainRoutesLoginLogic"));
const RedirectComponent = lazy(() => import("Components/Common/Redirect"));

export default function MainRoutes(props) {
  // Configuration Azure AD
  // ---------------------------------------------------------------------------------------------
  const configAzure = useSelector(state => state.authentication.azureADConfig);
  const msalConfig = useMemo(() => ({
    auth: {
      clientId: configAzure.clientId,
      authority: configAzure.url,
      redirectUri: getHost(window.location),
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  }), [configAzure.clientId, configAzure.url]);
  const msalInstance = useMemo(() => {
    return new PublicClientApplication(msalConfig);
  }, [msalConfig]);
  // ---------------------------------------------------------------------------------------------

  const [_fetchMenuData, menuDataQueryResult] = useLazyFetchMenuDataQuery();

  const loggedIn = useSelector((state) => state.authentication.status) === 'loggedIn';
  const reloadLocale = useSelector((state) => state.authentication.reloadLocale);
  const currUnauthLang = getCookieValue("unauthenticatedLanguage");
  const langLoadInProgress = useSelector((state) => state.languages.loadInProgress);
  const [initialMountDone, setInitialMountDone] = useState(false);

  const loadedItems = [];
  loadedItems.push(!langLoadInProgress)
  loadedItems.push(useSelector((state) => !state.authentication.loadInProgress))
  loadedItems.push(!menuDataQueryResult.isUninitialized && menuDataQueryResult.isSuccess)
  loadedItems.push(useSelector((state) => !state.perso.loadInProgress))
  loadedItems.push(useSelector((state) => !state.companyContactDetails.loadInProgress))
  loadedItems.push(useSelector((state) => !state.messagesSystem.loadingMessagesLangues))
  loadedItems.push(useSelector((state) => !state.messagesSystem.loadingObjetsLangues))

  const loadProgress = loadedItems.filter((a) => a).length
  const loadPercent = 100 * loadProgress / loadedItems.length
  const loaded = loadProgress === loadedItems.length && initialMountDone
  const dispatch = useDispatch();

  const localStorageService = LocalStorageService.getService();
  const refreshingToken = localStorageService.getRefreshingToken();

  function initialLoad() {
    dispatch(fetchLanguages());
    dispatch(fetchLoggedUserData());
    dispatch(fetchSites());
    dispatch(fetchCompanyContactDetails());
  }

  function initialLoadUnlogged() {
    dispatch(fetchSites({loggedOut: true}));
  }

  function initialLoadLocale() {
    _fetchMenuData();
    dispatch(fetchAllPersoObjets());
    dispatch(fetchAllMessagesLanguesByLangue());
    dispatch(fetchAllObjetsLangues2ByLangue());
  }

  function loadLocaleUnlogged() {
    dispatch(fetchAllObjetsLangues2ByLangue({loggedOut: true, lang: currUnauthLang}));
    dispatch(fetchAllPersoObjets({loggedOut: true, lang: currUnauthLang}));
  }

  useEffect(() => {
    localStorageService.setLoggedIn(loggedIn)
    if (loggedIn) {
      initialLoad();
    } else {
      initialLoadUnlogged();
    }
  }, [loggedIn]);

  useEffect(() => {
    if (loggedIn && reloadLocale) {
      dispatch(setReloadLocale(false));
      initialLoadLocale();
    } else if(!loggedIn && reloadLocale) {
      dispatch(setReloadLocale(false));
      loadLocaleUnlogged();
    }
  }, [loggedIn, reloadLocale]);

  useEffect(() => {
    if(refreshingToken === 'refreshDone') {
      localStorageService.setRefreshingToken('idle');
      initialLoad();
      initialLoadLocale();
    }
  }, [refreshingToken]);

  useEffect(() => {
    setInitialMountDone(true);
  }, []);

  useEffect(() => {
    if(loggedIn) {
      if(initialMountDone && menuDataQueryResult.isUninitialized && !menuDataQueryResult.isFetching) {
        _fetchMenuData();
      } else if(!menuDataQueryResult.isUninitialized && menuDataQueryResult.isSuccess) {
        dispatch(setMenuData(menuDataQueryResult.data));
      }
    }
  }, [initialMountDone, menuDataQueryResult, loggedIn]);

  return (
    <Suspense fallback={<Box sx={{ textAlign: 'center', margin: '200px' }}><CircularProgress /></Box>}>
      <Switch>
        <Route exact path="/">
          <Redirect to="/home" />
        </Route>
        <Route path="/login">
          <PageLogin />
        </Route>
        <Route path="/loading">
          <PageLoading value={loadPercent} />
        </Route>
        <Route exact strict path="/pub">
          <Redirect to="/pub/" />
        </Route>
        <Route path="/pub/:docId?">
          <PublicDocuments docPublic={true} />
        </Route>
        <Route path="/isovision/ouvrir">
          <RedirectComponent />
        </Route>
        <Route>
          <MsalProvider instance={msalInstance}>
            <LoginLogic loaded={loaded} loadPercent={loadPercent} {...props} />
          </MsalProvider>
        </Route>
      </Switch>
    </Suspense>
  )
}
