import {toLowerCamelCase} from "Utils/CommonFunctions"

export default function (signalement) {
  return {
    'codeNonConform': signalement ? signalement.codeNonConform : null,
    'source': signalement ? signalement.source : null,
    'codeSite': signalement ? signalement.codeSite : '',
    'dateNonConform': signalement ? signalement.dateNonConform : null,
    'heureNonConform': signalement ? signalement.heureNonConform : null,
    'identifiant2': signalement ? signalement.identifiant2 : null,
    'codeProcede': signalement ? signalement.codeProcede : null,
    'codeUnite': signalement ? signalement.codeUnite : null,
    'codeComposante': signalement ? signalement.codeComposante : null,
    'codePtEchantillon': signalement ? signalement.codePtEchantillon : null,
    'description': signalement ? signalement.description : null,
    'rapportePar': signalement ? signalement.rapportePar : null,
    'origine': signalement ? signalement.origine : '',
    'codeProduit': signalement ? signalement.codeProduit : '',
    'employe': signalement ? signalement.employe : null,
    'classeNC': signalement ? signalement.classeNC : '',
    'noSerie': signalement ? signalement.noSerie : null,
    'noLot': signalement ? signalement.noLot : null,
    'etape': signalement ? signalement.etape : '',
    'quartDeTravail': signalement ? signalement.quartDeTravail : '',
    'equipeTravail': signalement ? signalement.equipeTravail : '',
    'victimeExterne': signalement ? signalement.victimeExterne : null,
    'nomCieEmpBlesse': signalement ? signalement.nomCieEmpBlesse : null,
    'recommandationActions': signalement ? signalement.recommandationActions : '',
    'equipMesurNonConf': signalement ? signalement.equipMesurNonConf : '',
    'noBonTravail': signalement ? signalement.noBonTravail : null,
    'noDessin': signalement ? signalement.noDessin : null,
    'noPiece': signalement ? signalement.noPiece : null,
    'noBonCommande': signalement ? signalement.noBonCommande : null,
    'listeChoix1': signalement ? signalement.listeChoix1 : '',
    'texte1': signalement ? signalement.texte1 : null,
    'numerique1': signalement ? signalement.numerique1 : null,
    'date1': signalement ? signalement.date1 : null,
    'heure1': signalement ? signalement.heure1 : null,
    'typeNonConformite': signalement ? signalement.typeNonConformite : null,
    'codeDecision': signalement ? signalement.codeDecision : null,
    'gravite': signalement ? signalement.gravite : null,
    'dossierClosON': signalement ? signalement.dossierClosON : 'NON',
    'protectSynchroFields': signalement ? (signalement.protectSynchroFields || []).map(field => toLowerCamelCase(field)) : [],
    'actions': signalement ? signalement.actions : [],
    'causes': signalement ? signalement.causes : [],
    'volets': signalement ? signalement.volets : [],
    'voletIds': signalement ? signalement.voletIds : [],
    'indHyperlienExist': signalement ? signalement.indHyperlienExist : null,
    'nbHyperliens': signalement ? signalement.nbHyperliens : null,
    'indSynchroNC': signalement ? signalement.indSynchroNC : null,
    'indNCPoserQuestion': signalement ? signalement.indNCPoserQuestion : null,
  }
}