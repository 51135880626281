/**
 * @param {object} _obj
 * @returns 
 */
export default function GeneralInspectionSheet(_obj) {
  let obj = {
    autreIdentifiant: "",
    codeComposante: "",
    codeProcede: "",
    codePtEchantillon: "",
    codeSite: "",
    codeUnite: "",
    description: "",
    indAfficherDecoupage: 0,
    indCreationOpp: 0,
    indInactif: 0,
    inspecteurs: [],
    inspFicheInfoGenId: 0,
    inspTypeInpectionId: 0,
    nbHyperliens: 0,
    titre: "",
    volets: [],
  }

  obj.autreIdentifiant = (_obj && _obj.autreIdentifiant) ? _obj.autreIdentifiant : obj.autreIdentifiant
  obj.codeComposante = (_obj && _obj.codeComposante) ? _obj.codeComposante : obj.codeComposante
  obj.codeProcede = (_obj && _obj.codeProcede) ? _obj.codeProcede : obj.codeProcede
  obj.codePtEchantillon = (_obj && _obj.codePtEchantillon) ? _obj.codePtEchantillon : obj.codePtEchantillon
  obj.codeSite = (_obj && _obj.codeSite) ? _obj.codeSite : obj.codeSite
  obj.codeUnite = (_obj && _obj.codeUnite) ? _obj.codeUnite : obj.codeUnite
  obj.description = (_obj && _obj.description) ? _obj.description : obj.description
  obj.indAfficherDecoupage = (_obj && _obj.indAfficherDecoupage) ? _obj.indAfficherDecoupage : obj.indAfficherDecoupage
  obj.indCreationOpp = (_obj && _obj.indCreationOpp) ? _obj.indCreationOpp : obj.indCreationOpp
  obj.indInactif = (_obj && _obj.indInactif) ? _obj.indInactif : obj.indInactif
  obj.inspecteurs = (_obj && _obj.inspecteurs) ? _obj.inspecteurs : obj.inspecteurs
  obj.inspFicheInfoGenId = (_obj && _obj.inspFicheInfoGenId) ? _obj.inspFicheInfoGenId : obj.inspFicheInfoGenId
  obj.inspTypeInpectionId = (_obj && _obj.inspTypeInpectionId) ? _obj.inspTypeInpectionId : obj.inspTypeInpectionId
  obj.nbHyperliens = (_obj && _obj.nbHyperliens) ? _obj.nbHyperliens : obj.nbHyperliens
  obj.titre = (_obj && _obj.titre) ? _obj.titre : obj.titre
  obj.volets = (_obj && _obj.volets) ? _obj.volets : obj.volets

  return obj;
}
