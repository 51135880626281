import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import axios from 'Services/AxiosService';
import {
  GET_FICHIER_DOCUMENT,
  GET_FICHIER_DOCUMENT_INFO,
  GET_LOGO_ENTREPRISE,
} from 'store/UrlConstants';


const initialState = {
  documentInfo: {},
  doc: {},
  loadInProgress: false
};

export const getFichierDocumentInfo = createAsyncThunk(GET_FICHIER_DOCUMENT_INFO, async (idFicher) => {
  const response = await axios.get(GET_FICHIER_DOCUMENT_INFO + '/' + idFicher);
  return response.data;
});

// export const getFichierDocumentManage = createAsyncThunk("FichierDoc/getDocManage", async ({ idDossier, loggedOut }) => {
//   const response = await axios.get(
//       `${loggedOut ? UNAUTH_GET_FICHIER_DOCUMENT_DOC_MANAGE : GET_FICHIER_DOCUMENT_MANAGE}/${idDossier}`
//   );
//   return response.data;
// });

export const getFichierDoc = createAsyncThunk(GET_FICHIER_DOCUMENT, async (idFicher) => {
  const response = await axios.get(GET_FICHIER_DOCUMENT + '/' + idFicher);
  return response.data;
});

export const getLogoEntreprise = createAsyncThunk(GET_LOGO_ENTREPRISE, async () => {
  const response = await axios.get(GET_LOGO_ENTREPRISE);
  return response.data;
});

const fichierDocumentSlice = createSlice({
  name: 'fichierDocument',
  initialState,
  reducers: {},
  extraReducers: {
    [getFichierDocumentInfo.pending]: (state, action) => {
      state.loadInProgress = true;
    },
    [getFichierDocumentInfo.fulfilled]: (state, action) => {
      state.loadInProgress = false
      state.documentInfo = {...action.payload}
    },
    [getFichierDoc.pending]: (state, action) => {
      state.loadInProgress = true;
    },
    [getFichierDoc.fulfilled]: (state, action) => {
      state.loadInProgress = false
      state.doc = {...action.payload}
    },
    [getLogoEntreprise.pending]: (state, action) => {
      state.loadInProgress = true;
    },
    [getLogoEntreprise.fulfilled]: (state, action) => {
      state.loadInProgress = false
      state.logoEntreprise = {...action.payload}
    }
  }
});

export default fichierDocumentSlice.reducer;