import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "Services/AxiosService";
import { SEARCH_ACTIONS_PERF_REVIEW } from "store/UrlConstants";

export const searchActions = createAsyncThunk(
    "RevuePerformance/SearchActions",
    async (params, thunkAPI) => {
        const response = await axios.post(SEARCH_ACTIONS_PERF_REVIEW, params);
        return response.data;
    }
);

// All SearchActionsResult
const allSearchActionsResultAdapter = createEntityAdapter({
    selectId: (entity) => entity.codeAutoAction,
});

const employeeSearchActionsResultAdapter = createEntityAdapter({
    selectId: (entity) => entity.employeId,
});

const initialState = {
    actionsObjets: allSearchActionsResultAdapter.getInitialState(),
    employeeActionsObjets: employeeSearchActionsResultAdapter.getInitialState(),
};

const searchSlice = createSlice({
    name: "perfReviewSearch",
    initialState: {
        ...initialState,
        loadingInProgress: false,
    },
    reducers: {},
    extraReducers: {
        [searchActions.pending]: (state, action) => {
            state.loadingInProgress = true;
        },
        [searchActions.fulfilled]: (state, action) => {
            const { actionResultats, employesQuickInfo } = action.payload;
            allSearchActionsResultAdapter.setAll(
                state.actionsObjets,
                actionResultats
            );
            employeeSearchActionsResultAdapter.setAll(
                state.employeeActionsObjets,
                employesQuickInfo
            );
            state.loadingInProgress = false;
        },
    },
});

export const { selectAll: selectAllActions, selectById: selectActionsById } =
    allSearchActionsResultAdapter.getSelectors(
        (state) => state.perfReviewSearch.actionsObjets
    );

export const { selectAll: selectAllEmployeeActions, selectById: selectEmployeActionById } =
    employeeSearchActionsResultAdapter.getSelectors(
        (state) => state.perfReviewSearch.employeeActionsObjets
    );

export default searchSlice.reducer;
