import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
} from "@reduxjs/toolkit";
import originalTree from "./TestBoicTree";

export const fetchChildren = createAsyncThunk(
    "TestBoicTree/Children",
    async (parent) => {
        await new Promise((resolve) => setTimeout(resolve, 500));
        return {
            parent: parent,
            children: originalTree[parent] ? originalTree[parent] : [],
        };
    }
);

const initialState = {
    flatTree: {},
};
/////////////////////////////////

const testBoicTreeSlice = createSlice({
    name: "testBoicTree",
    initialState,
    reducers: {},
    extraReducers: {
        [fetchChildren.fulfilled]: (state, action) => {
            //      creates                         =   check if values exits
            state.flatTree[action.payload.parent] = state.flatTree[
                action.payload.parent
            ]
                ? state.flatTree[action.payload.parent]
                : {};

            //flatTree: { "0": { }}
            state.flatTree[action.payload.parent].children =
                action.payload.children.map((i) => i.id);
            //.children creates a new object???
            //flatTree: { "0": {children:[1,2,3...]}}
            action.payload.children.map((i) => {
                state.flatTree[i.id] = i;
            });

            //flatTree: { "0": {children:[1,2,3...]}, "1":}
        },
    },
});

export const treeSelector = (state) => state.testBoicTree.flatTree;
export const nodeSelector = (nodeId) =>
    createSelector(treeSelector, (data) => data[nodeId]);

export default testBoicTreeSlice.reducer;
