import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "Services/AxiosService";
import { LOAD_ACTION_EMPLOYES_LISTE_PERF_REVIEW } from "store/UrlConstants";
import { paramsToQueryParam } from "store/UrlUtils";

export const loadEmployes = createAsyncThunk(
    "RevuePerformance/LoadActionEmployesListe",
    async (params, thunkAPI) => {
        const response = await axios.get(
            LOAD_ACTION_EMPLOYES_LISTE_PERF_REVIEW + paramsToQueryParam(params)
        );
        return response.data;
    }
);

const allEmployesAdapter = createEntityAdapter({
    selectId: (entity) => entity.code,
});

const initialState = {
    actionsObjets: allEmployesAdapter.getInitialState(),
    loadInProgress: false,
};

const listEmployesSlice = createSlice({
    name: "perfReviewListEmployes",
    initialState,
    reducers: {},
    extraReducers: {
        [loadEmployes.pending]: (state, action) => {
            state.loadInProgress = true;
        },
        [loadEmployes.fulfilled]: (state, action) => {
            allEmployesAdapter.setAll(state.actionsObjets, action.payload);
            state.loadInProgress = false;
        },
    },
});

export const { selectAll: selectAllEmployes } = allEmployesAdapter.getSelectors(
    (state) => state.perfReviewListEmployes.actionsObjets
);

export default listEmployesSlice.reducer;
