import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'Services/AxiosService';
import {GS_SEARCH} from 'store/UrlConstants';

const initialState = {};

export const globalSearch = createAsyncThunk('GlobalSearch/Search', async (term) => {
  const response = await axios.get(`${GS_SEARCH}?term=${term}`);
  return response.data;
});

const globalSearchSlice = createSlice({
  name: 'globalSearch',
  initialState,
  reducers: {},
  extraReducers: {

  }
});

export default globalSearchSlice.reducer;