import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import axios from 'Services/AxiosService';
import {FETCH_ALL_DISPOS, FETCH_LANGUAGES, FETCH_LANGUAGES_DISPO} from 'store/UrlConstants';

const languagesAdapter = createEntityAdapter({});

const initialState = {
  languages: languagesAdapter.getInitialState(),
  loaded: false,
  loadInProgress: false,
  langTransData: {
    frenchCanada: {
      muiDataTableTextLabels: {
        body: {
          noMatch: "Désolé, aucune donnée correspondante à afficher.",
          toolTip: "Trier",
          columnHeaderTooltip: column => `Trier pour ${column.label}`
        },
        pagination: {
          next: "Page suivante",
          previous: "Page précédente",
          rowsPerPage: "Lignes par page:",
          displayRows: "sur",
        },
        toolbar: {
          search: "Recherchez",
          downloadCsv: "Télécharger CSV",
          print: "Imprimer",
          viewColumns: "Voir les colonnes",
          filterTable: "Filtres",
        },
        filter: {
          all: "Tous",
          title: "Filtres",
          reset: "Rétablir",
        },
        viewColumns: {
          title: "Afficher les colonnes",
          titleAria: "Afficher/masquer les colonnes du tableau",
        },
        selectedRows: {
          text: "ligne(s) sélectionnée(s)",
          delete: "Supprimer",
          deleteAria: "Supprimer la ligne sélectionnée"
        },
      },
    },

    englishCanada: {
      muiDataTableTextLabels: {
        body: {
          noMatch: "Sorry, no matching records found.",
          toolTip: "Sort",
          columnHeaderTooltip: column => `Sort for ${column.label}`
        },
        pagination: {
          next: "Next page",
          previous: "Previous page",
          rowsPerPage: "Lignes par page:",
          displayRows: "on",
        },
        toolbar: {
          search: "Search",
          downloadCsv: "Download CSV",
          print: "Print",
          viewColumns: "See columns",
          filterTable: "Filters",
        },
        filter: {
          all: "All",
          title: "Filters",
          reset: "Reset",
        },
        viewColumns: {
          title: "Show columns",
          titleAria: "Show/hide table columns",
        },
        selectedRows: {
          text: "selected line(s)",
          delete: "Delete",
          deleteAria: "Delete selected row"
        },
      },
    },

    espagnol: {
      muiDataTableTextLabels: {
        body: {
          noMatch: "Lo sentimos, no hay datos coincidentes para mostrar.",
          toolTip: "Ordenar",
          columnHeaderTooltip: column => `Ordenar por ${column.label}`
        },
        pagination: {
          next: "Página siguiente",
          previous: "Página anterior",
          rowsPerPage: "Filas por página:",
          displayRows: "on",
        },
        toolbar: {
          search: "Buscar",
          downloadCsv: "Descargar CSV",
          print: "Imprimir",
          viewColumns: "Ver columnas",
          filterTable: "Filtros",
        },
        filter: {
          all: "Todos",
          reset: "Reiniciar",
          title: "FILTROS",
        },
        viewColumns: {
          title: "Mostrar columnas",
          titleAria: "Mostrar/ocultar las columnas de la tabla",
        },
        selectedRows: {
          text: "fila(s) seleccionada(s)",
          delete: "Eliminar",
          deleteAria: "Eliminar la(s) línea(s) seleccionada(s)"
        },
      },
    },
  },
  dispos: []
};

export const fetchLanguages = createAsyncThunk('languages/fetchLanguages', async () => {
  const response = await axios.get(FETCH_LANGUAGES);
  return response.data;
});

export const fetchAllDispos = createAsyncThunk('languages/fetchAllDispos', async () => {
  const response = await axios.get(FETCH_ALL_DISPOS);
  return response.data;
});

const languagesSlice = createSlice({
  name: 'languages',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchLanguages.fulfilled]: (state, action) => {
      state.loaded = true;
      state.loadInProgress = false;
      languagesAdapter.setAll(state.languages, action.payload);
    },
    [fetchLanguages.pending]: (state, action) => {
      state.loadInProgress = true;
    },
    [fetchLanguages.rejected]: (state, action) => {
      state.loadInProgress = false;
    },
    [fetchAllDispos.pending]: (state, action) => {
      state.loadInProgress = true;
    },
    [fetchAllDispos.rejected]: (state, action) => {
      state.loadInProgress = false;
    },
    [fetchAllDispos.fulfilled]: (state, action) => {
      state.dispos = [...action.payload]
    }
  }
});

export const {
  selectAll: selectAllLanguages,
  selectById: selectLanguageById,
} = languagesAdapter.getSelectors((state) => state.languages.languages);

export default languagesSlice.reducer;