import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'Services/AxiosService';
import { ADD_EMAIL_TASK, ADD_EMAIL_TASKS } from 'store/UrlConstants';
import { showSnackbarMessage } from './MessagesSystemSlice';

export const addEmailTasks = createAsyncThunk("AddEmailTasks", async (emailTasks = {}, thunkAPI) => {
  // let formData = new FormData();
  // for (var key in emailTasks) {
  //   formData.append(key, emailTasks[key]);
  // }
  const response = await axios.post(ADD_EMAIL_TASKS, [emailTasks]);
  return response.data;
})
const buildFormData = (formData, data, parentKey) => {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;

    formData.append(parentKey, value);
  }
}
export const addEmailTask = createAsyncThunk("AddEmailTask", async (emailTask = {}, thunkAPI) => {
  try {
    let formData = new FormData();
    buildFormData(formData, emailTask);

    const response = await axios.post(ADD_EMAIL_TASK, formData, { headers: { 'Content-Type': 'multipart/form-data' }});
    
    thunkAPI.dispatch(
      showSnackbarMessage({
            dba: "objetsLangues2",
            messageId: "iw_email_confirm_env",
            severity: "success",
          })
    )
    
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 3000,
      })
  );
  return thunkAPI.rejectWithValue(error.response.data);
  }
})
  
const initialState = {
  tasks: [],
  message: {},
  taskProps: {
    subject: '',
    from: '',
    to: '',
    cc: '',
    ccc: '',
    msgBrut: '',
    msgHtml: '', 
    filesToAttach: '', 
    indDeleteAttach: 0, 
    indAppnameSubject: 0, 
    indHistorique: 0,
    msgUsager: '', 
    msgAvertissement: '', 
    destinataires: [], 
    typeMessage: 0, 
    subjectAppName: false
  } 
};

const nightWorkerSlice = createSlice({
  name: 'nightworker',
  initialState,
  reducers: {
    addTask(state, action) {
      state.tasks.push(action.payload)
    },
    resetTasks(state) {
      state.tasks = []
    },
  },
  extraReducers: {
    [addEmailTasks.fulfilled]: (state, action) => {
      state.tasks = action.payload
    },
  },
});

export const { addEmail, resetEmails } = nightWorkerSlice.actions;

export default nightWorkerSlice.reducer;