import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import axios from 'Services/AxiosService';
import {listToQueryParam, paramsToQueryParam} from 'store/UrlUtils';
import { FEL_RECEVOIR_MULTI_FORMATION } from 'store/UrlConstants';

const formationsMultiAdapter = createEntityAdapter({
  selectId: entity => entity.formationCode
});

const initialState = formationsMultiAdapter.getInitialState(
  { status: 'idle' }  
);

export const fetchFormationsMulti = createAsyncThunk('FormationEnLigne/Formations/SearchMulti', async ({sites, ville}) => { 
  const response = await axios.get(FEL_RECEVOIR_MULTI_FORMATION + listToQueryParam("siteCodes", sites) + (ville ? paramsToQueryParam({city: ville}).replace('?','&') : "") );
  return response.data;
});

export const {
  selectAll: selectAllFormationsMulti,
  selectById: selectFormationMultiById,
} = formationsMultiAdapter.getSelectors((state) => state.felFormationsMulti);

const felFormationsMultiSlice = createSlice({
  name: 'felFormationsMulti',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchFormationsMulti.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      formationsMultiAdapter.setAll(state, action.payload);
    },
    [fetchFormationsMulti.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchFormationsMulti.rejected]: (state, action) => {
      state.status = 'failed';
    },
  },
});

export default felFormationsMultiSlice.reducer;