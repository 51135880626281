// ========================================================================================
// IMPORTS
// ========================================================================================
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit"
import axios from "Services/AxiosService"
import moment from "moment"
import { paramsToQueryParam } from "../../UrlUtils"
import {
  GET_ALL_PROCEDES_ACTIVITES_BY_CODE_SITE,
  GET_ALL_AFFECTATION_EMPLOYE_BY_EMPLOYE,
  ADD_ONE_AFFECTATION_EMPLOYE,
  UPDATE_ONE_AFFECTATION_EMPLOYE,
  DELETE_MANY_AFFECTATIONS_EMPLOYE,
  GET_ALL_CATEGORIE_EMPLOYE,
  GET_ALL_EMPLOYE_CATEGORIE_BY_EMPLOYE,
  ADD_ONE_EMPLOYE_CATEGORIE,
  UPDATE_ONE_EMPLOYE_CATEGORIE,
  DELETE_MANY_EMPLOYES_CATEGORIES,
  GET_ALL_FORMATION_RECUE_BY_EMPLOYE,
  ADD_FORMATION_RECUE_BY_EMPLOYE,
  GET_ALL_EMPLOYE_EPI,
  ADD_EMPLOYE_EPI,
  UPDATE_EMPLOYE_EPI,
  DELETE_EMPLOYE_EPI,
  //GET_EMPLOYE_BY_NO,
  GET_EMPLOYE_BY_ID,
  GET_NEW_EMPLOYE,
  ADD_UPDATE_EMPLOYE,
  DELETE_EMPLOYE,
  GET_TAUX_HORAIRE,
  ADD_TAUX_HORAIRE,
  UPDATE_TAUX_HORAIREE,
  DELETE_TAUX_HORAIRE,
  UPDATE_IND_HORS_QC_TAUX_HORAIRE,
  GET_ALL_POSTE_OCCUPE,
  ADD_POSTE_OCCUPE,
  DELETE_POSTE_OCCUPE,
  GET_CONFIGURATION_MODULE_FC,
  FETCH_GET_MOYENS_SQDM2017,
  UPDATE_CONFIGURATION_MODULE_FC,
} from "store/UrlConstants"
import { showSnackbarMessage } from "store/slices/MessagesSystemSlice"

// ========================================================================================
// ADAPTERS / ASYNC ACTIONS / SELECTORS
// ========================================================================================
// ----------------------------------------------------------------------------------------
// AffectationsEmploye
// ----------------------------------------------------------------------------------------
const affectationEmployeAdapter = createEntityAdapter({
  selectId: (entity) =>
    `${entity.noEmploye}#${entity.codeProcede}#${moment(
      entity.dateEntreeVigueur
    ).format("YYYY-MM-DD")}`,
})

export const fetchAffectationsEmployes = createAsyncThunk(
  "FormationCompetences/AffectationEmploye/GetByEmploye",
  async (noEmploye) => {
    const result = await axios.get(GET_ALL_AFFECTATION_EMPLOYE_BY_EMPLOYE, {
      params: { noEmploye: noEmploye },
    })
    return result.data
  }
)

export const addOneAffectationEmploye = createAsyncThunk(
  "FormationCompetences/AffectationEmploye/AddOne",
  async (affectationEmploye, thunkAPI) => {
    try {
      const result = await axios.post(
        ADD_ONE_AFFECTATION_EMPLOYE,
        affectationEmploye
      )

      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      )
      return result.data
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      )
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const updateOneAffectationEmploye = createAsyncThunk(
  "FormationCompetences/AffectationEmploye/UpdateOne",
  async (affectationEmploye, thunkAPI) => {
    try {
      const result = await axios.put(
        UPDATE_ONE_AFFECTATION_EMPLOYE,
        affectationEmploye
      )

      thunkAPI.dispatch(upsertOneAffectationsEmployeState(result.data))

      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      )
      return result.data
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      )
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const deleteManyAffectationsEmploye = createAsyncThunk(
  "FormationCompetences/AffectationEmploye/DeleteMany",
  async (params, thunkAPI) => {
    const { requestBody, showMessage } = params

    try {
      const result = await axios.delete(DELETE_MANY_AFFECTATIONS_EMPLOYE, {
        data: requestBody,
      })

      if (showMessage) {
        thunkAPI.dispatch(
          showSnackbarMessage({
            dba: "objetsLangues2",
            messageId: "information_supprimee",
            severity: "success",
          })
        )
      }

      return result.data
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      )

      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const affectationEmployeSelector = (store) =>
  store.formationCompetences.affectationsEmployes

export const { selectAll: selectAffectationsEmployes } =
  affectationEmployeAdapter.getSelectors(affectationEmployeSelector)

export const selectAffectationsEmployesActifs = createSelector(
  selectAffectationsEmployes,
  (affectations) =>
    affectations.filter((affectation) => affectation.indInactif === 0)
)

export const selectLoadingAffectationsEmployes = createSelector(
  affectationEmployeSelector,
  (state) => state.loading
)

// ----------------------------------------------------------------------------------------
// CategorieEmploye
// ----------------------------------------------------------------------------------------
const categorieEmployeAdapter = createEntityAdapter()

export const fetchCategoriesEmployes = createAsyncThunk(
  "FormationCompetences/CategorieEmploye/Get",
  async () => {
    const response = await axios.get(GET_ALL_CATEGORIE_EMPLOYE)
    return response.data
  }
)

export const categoriesEmployesSelector = (store) =>
  store.formationCompetences.categoriesEmployes

export const {
  selectAll: selectAllCategoriesEmployes,
  selectById: selectCategorieEmployeById,
} = categorieEmployeAdapter.getSelectors(categoriesEmployesSelector)

export const selectCategoriesEmployesActifs = createSelector(
  selectAllCategoriesEmployes,
  (categories) => categories.filter((categorie) => categorie.indInactif === 0)
)

export const selectLoadingCategoriesEmployes = createSelector(
  categoriesEmployesSelector,
  (state) => state.loading
)

// ----------------------------------------------------------------------------------------
// ConfigurationsDuModule
// ----------------------------------------------------------------------------------------
const moyensSqdmAdapter = createEntityAdapter()

export const getConfigurationModule = createAsyncThunk(
  "FormationCompetences/Configuration/Get",
  async () => {
    const response = await axios.get(GET_CONFIGURATION_MODULE_FC)
    return response.data
  }
)

export const updateConfigurationModule = createAsyncThunk(
  "FormationCompetences/Configuration/Update",
  async (params, thunkAPI) => {
    try {
      const response = await axios.put(UPDATE_CONFIGURATION_MODULE_FC, params)
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      )
      return response.data
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      )
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const getMoyensSqdm = createAsyncThunk(
  "FormationCompetences/Configuration/Criteria/GetMoyensSqdm2017",
  async () => {
    const response = await axios.get(FETCH_GET_MOYENS_SQDM2017)
    return response.data
  }
)

export const selectAllFCConfigurations = (store) =>
  store.formationCompetences.configurationsModule

export const moyensSqdmSelector = (store) =>
  store.formationCompetences.moyensSqdm
export const { selectAll: selectAllMoyensSqdm } =
  moyensSqdmAdapter.getSelectors(moyensSqdmSelector)

// ----------------------------------------------------------------------------------------
// EmployeCategorie
// ----------------------------------------------------------------------------------------
const employeCategorieAdapter = createEntityAdapter({
  selectId: (entity) =>
    `${entity.noEmploye}#${moment(entity.dateDebut).format("YYYY-MM-DD")}`,
})

export const fetchEmployesCategories = createAsyncThunk(
  "FormationCompetences/EmployeCategorie/GetByEmploye",
  async (noEmploye) => {
    const response = await axios.get(GET_ALL_EMPLOYE_CATEGORIE_BY_EMPLOYE, {
      params: { noEmploye: noEmploye },
    })
    return response.data
  }
)

export const addOneEmployeCategorie = createAsyncThunk(
  "FormationCompetences/AddOneEmployeCategorie/Post",
  async (employeCategorie, thunkAPI) => {
    try {
      const response = await axios.post(
        ADD_ONE_EMPLOYE_CATEGORIE,
        employeCategorie
      )

      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      )
      return response.data
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      )
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const updateOneEmployeCategorie = createAsyncThunk(
  "FormationCompetences/EmployeCategorie/UpdateOne",
  async (employeCategorie, thunkAPI) => {
    try {
      const response = await axios.put(
        UPDATE_ONE_EMPLOYE_CATEGORIE,
        employeCategorie
      )

      thunkAPI.dispatch(upsertOneEmployeCategorieState(response.data))

      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      )
      return response.data
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      )
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const deleteManyEmployesCategories = createAsyncThunk(
  "FormationCompetences/EmployeCategorie/DeleteMany",
  async (params, thunkAPI) => {
    const { requestBody, showMessage } = params

    try {
      const response = await axios.delete(DELETE_MANY_EMPLOYES_CATEGORIES, {
        data: requestBody,
      })

      if (showMessage) {
        thunkAPI.dispatch(
          showSnackbarMessage({
            dba: "objetsLangues2",
            messageId: "information_supprimee",
            severity: "success",
          })
        )
      }

      return response.data
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      )

      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const employesCategoriesSelector = (store) =>
  store.formationCompetences.employesCategories

export const {
  selectAll: selectAllEmployesCategories,
  selectById: selectEmployeCategorieById,
} = employeCategorieAdapter.getSelectors(employesCategoriesSelector)

export const selectEmployesCategoriesActifs = createSelector(
  selectAllEmployesCategories,
  (categories) => categories.filter((categorie) => categorie.indInactif === 0)
)

export const selectLoadingEmployesCategories = createSelector(
  employesCategoriesSelector,
  (state) => state.loading
)

// ----------------------------------------------------------------------------------------
// FormationRecue
// ----------------------------------------------------------------------------------------
const formationRecueAdapteur = createEntityAdapter({
  selectId: (entity) => entity.formationRecueId,
})

export const fetchFormationsRecuesByEmploye = createAsyncThunk(
  "FormationCompetences/Formation/GetByEmploye",
  async (noEmploye) => {
    const response = await axios.get(GET_ALL_FORMATION_RECUE_BY_EMPLOYE, {
      params: { noEmploye: noEmploye },
    })
    return response.data
  }
)

export const formationRecueByEmployeSelector = (store) =>
  store.formationCompetences.formationsRecues

export const { selectAll: selectAllFormationsRecuesByEmploye } =
  formationRecueAdapteur.getSelectors(formationRecueByEmployeSelector)

export const selectLoadingFormationsRecuesByEmploye = createSelector(
  formationRecueByEmployeSelector,
  (state) => state.loading
)

export const addFormationRecuByEmploye = createAsyncThunk(
  "FormationCompetences/EmployeCategorie/AddOne",
  async (params, thunkAPI) => {
    try {
      const response = await axios.post(ADD_FORMATION_RECUE_BY_EMPLOYE, params)
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      )
      return response.data
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      )
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

// ----------------------------------------------------------------------------------------
// ProcedeActivite
// ----------------------------------------------------------------------------------------
const procedeActiviteAdapter = createEntityAdapter()

export const fetchProcedesActivitisBySite = createAsyncThunk(
  "FormationCompetences/ProcedeActivite/GetBySite",
  async (codeSite) => {
    const response = await axios.get(GET_ALL_PROCEDES_ACTIVITES_BY_CODE_SITE, {
      params: { codeSite: codeSite },
    })
    return response.data
  }
)

export const procedeActiviteBySiteSelector = (store) =>
  store.formationCompetences.procedesActiviteBySite

export const { selectAll: selectProcedesActivitesBySite } =
  procedeActiviteAdapter.getSelectors(procedeActiviteBySiteSelector)

export const selectProcedesActivitesActifsBySite = createSelector(
  selectProcedesActivitesBySite,
  (procedes) => procedes.filter((procede) => procede.indInactif === 0)
)

export const selectLoadingProcedesActivitesBySite = createSelector(
  procedeActiviteBySiteSelector,
  (state) => state.loading
)

// ----------------------------------------------------------------------------------------
// Employés et Contacts Externes
// ----------------------------------------------------------------------------------------
export const fetchEmployeById = createAsyncThunk(
  "FormationCompetences/LoadEmployeIentificationById/Get",
  async (idEmploye) => {
    const response = await axios.get(GET_EMPLOYE_BY_ID, { params: idEmploye });
    return response.data;
  }
);

export const getNewEmploye = createAsyncThunk(
  "FormationCompetences/NewEmployeIentification/Get",
  async (codeSite) => {
    const response = await axios.get(GET_NEW_EMPLOYE, { params: codeSite });
    return response.data;
  }
);

export const addUpdateEmploye = createAsyncThunk(
  "FormationCompetences/AddUpdateEmployeIentification/Put", 
  async ({employe, nomEcran}, thunkAPI) => {
    try {
      const result = await axios.put(ADD_UPDATE_EMPLOYE +
          paramsToQueryParam({nomEcran: nomEcran}), employe)
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      )
      return result.data
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      )
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const deleteEmploye = createAsyncThunk(
  "FormationCompetences/DeleteEmployeIentification/Delete",
  async (employeId, thunkAPI) => {
    try {
      const result = await axios.delete(DELETE_EMPLOYE, { params: { employeId: employeId } })
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "information_supprimee",
          severity: "success",
        })
      )
      return result.data
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      )
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

// ----------------------------------------------------------------------------------------
// Employés et Contacts Externes - Taux Horaire
// ----------------------------------------------------------------------------------------
export const fetchTauxHoraire = createAsyncThunk(
  "FormationCompetences/LoadTauxHoraire/Get",
  async (noEmploye) => {
    const result = await axios.get(
      GET_TAUX_HORAIRE + paramsToQueryParam({ noEmploye: noEmploye })
    )
    return result.data
  }
)

export const addTauxHoraire = createAsyncThunk(
  "FormationCompetences/AddTauxHoraire/Post",
  async (params, thunkAPI) => {
    try {
      const result = await axios.post( ADD_TAUX_HORAIRE, params )
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      )
      return result.data
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      )
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const updateTauxHoraire = createAsyncThunk(
  "FormationCompetences/UpdateTauxHoraire/Put",
  async (params, thunkAPI) => {
    try {
      const result = await axios.put(UPDATE_TAUX_HORAIREE, params)
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      )
      return result.data
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      )
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const deleteTauxHoraire = createAsyncThunk(
  "FormationCompetences/DeleteTauxHoraire/Delete",
  async (params, thunkAPI) => {
    try {
      const result = await axios.delete(DELETE_TAUX_HORAIRE, { data: params })
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "information_supprimee",
          severity: "success",
        })
      )
      return result.data
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      )
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const updateIndHorsQuebec = createAsyncThunk(
  "FormationCompetences/UpdateIndHorsQuebec/Put",
  async (params, thunkAPI) => {
    try {
      const result = await axios.put(UPDATE_IND_HORS_QC_TAUX_HORAIRE, params)
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      )
      return result.data
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      )
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

// ----------------------------------------------------------------------------------------
// Employés et Contacts Externes - Postes Occupes
// ----------------------------------------------------------------------------------------
const allEmployePosteOccupeAdapter = createEntityAdapter({
  selectId: (entity) => entity.posteOccupeId,
})

export const fetchAllEmployePosteOccupe = createAsyncThunk(
  "FormationCompetences/GetAllEmployePosteOccupe/Get",
  async (noEmploye) => {
    const result = await axios.get(
      GET_ALL_POSTE_OCCUPE + paramsToQueryParam({ noEmploye: noEmploye })
    )
    return result.data
  }
)

export const allEmployePosteOccupeSelector = (store) =>
  store.formationCompetences.allEmployePosteOccupe

export const { selectAll: selectAllEmployePosteOccupe } =
  allEmployePosteOccupeAdapter.getSelectors(allEmployePosteOccupeSelector)

export const selectLoadingAllEmployePosteOccupe = createSelector(
  allEmployePosteOccupeSelector,
  (state) => state.loading
)

export const addUpdateEmployePosteOccupe = createAsyncThunk(
  "FormationCompetences/AddUpdateEmployePosteOccupe/Put",
  async (params, thunkAPI) => {
    try {
      const result = await axios.put(ADD_POSTE_OCCUPE, params)
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      )
      return result.data
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      )
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const deleteEmployePosteOccupe = createAsyncThunk(
  "FormationCompetences/DeleteEmployePosteOccupe/Delete",
  async (params, thunkAPI) => {
    try {
      const result = await axios.delete(DELETE_POSTE_OCCUPE, { data: params })
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "information_supprimee",
          severity: "success",
        })
      )
      return result.data
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      )
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

// ----------------------------------------------------------------------------------------
// Employés et Contacts Externes - ÉPI
// ----------------------------------------------------------------------------------------
const allEmployeEpiAdapter = createEntityAdapter({
  selectId: (entity) => entity.equipementProtectionId,
})

export const fetchAllEmployeEpi = createAsyncThunk(
  "FormationCompetences/GetAllEmployeEpi/Get",
  async (noEmploye) => {
    const result = await axios.get(
      GET_ALL_EMPLOYE_EPI + paramsToQueryParam({ noEmploye: noEmploye })
    )
    return result.data
  }
)

export const allEmployeEpiSelector = (store) =>
  store.formationCompetences.allEmployeEpi

export const { selectAll: selectAllEmployeEpi } =
  allEmployeEpiAdapter.getSelectors(allEmployeEpiSelector)

export const selectLoadingAllEmployeEpi = createSelector(
  allEmployeEpiSelector,
  (state) => state.loading
)

export const addEmployeEpi = createAsyncThunk(
  "FormationCompetences/AddEmployeEpi/Put",
  async (params, thunkAPI) => {
    try {
      const result = await axios.put(ADD_EMPLOYE_EPI, params)
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      )
      return result.data
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      )
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const updateEmployeEpi = createAsyncThunk(
  "FormationCompetences/UpdateEmployeEpi/Put",
  async (params, thunkAPI) => {
    try {
      const result = await axios.put(UPDATE_EMPLOYE_EPI, params)
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      )
      return result.data
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      )
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const deleteEmployeEpi = createAsyncThunk(
  "FormationCompetences/DeleteEmployeEpi/Delete",
  async (params, thunkAPI) => {
    try {
      const result = await axios.delete(DELETE_EMPLOYE_EPI, { data: params })
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "information_supprimee",
          severity: "success",
        })
      )
      return result.data
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      )
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

// ========================================================================================
// MAIN SLICE
// ========================================================================================
const initialState = {
  affectationsEmployes: affectationEmployeAdapter.getInitialState({
    loading: false,
  }),
  categoriesEmployes: categorieEmployeAdapter.getInitialState({
    loading: false,
  }),
  configurationsModule: null,
  loadingConfigurationModule: false,
  moyensSqdm: moyensSqdmAdapter.getInitialState({
    loading: false,
  }),
  employesCategories: employeCategorieAdapter.getInitialState({
    loading: false,
  }),
  formationsRecues: formationRecueAdapteur.getInitialState({ loading: false }),
  procedesActiviteBySite: procedeActiviteAdapter.getInitialState({
    loading: false,
  }),
  allEmployePosteOccupe: allEmployePosteOccupeAdapter.getInitialState({
    loading: false,
  }),
  allEmployeEpi: allEmployeEpiAdapter.getInitialState({
    loading: false,
  }),
}

const formationCompetencesSlice = createSlice({
  name: "formationCompetences",
  initialState,
  reducers: {
    // ---------------------------------------------------------------------------------
    // AffectationsEmploye
    // ---------------------------------------------------------------------------------
    setAffectationsEmployesList(state, action) {
      affectationEmployeAdapter.setAll(
        state.affectationsEmployes,
        action.payload
      )
    },
    upsertOneAffectationsEmployeState(state, action) {
      affectationEmployeAdapter.upsertOne(
        state.affectationsEmployes,
        action.payload
      )
    },
    deleteManyAffectationEmployeState(state, action) {
      const affectationsForDelete = action.payload
      let idsForDelete = []

      affectationsForDelete.forEach((element) => {
        let id = `${element.noEmploye}#${element.codeProcede}#${element.dateEntreeVigueur
          ? moment(element.dateEntreeVigueur).format("YYYY-MM-DD")
          : "Invalid date"
          }`
        idsForDelete.push(id)
      })

      affectationEmployeAdapter.removeMany(
        state.affectationsEmployes,
        idsForDelete
      )
    },

    // ---------------------------------------------------------------------------------
    // ConfigurationsModule
    // ---------------------------------------------------------------------------------
    changeConfigurationsModule(state, action) {
      const { propName, value } = action.payload
      state.configurationsModule = {
        ...state.configurationsModule,
        [propName]: value,
      }
    },
    resetConfigurationModule(state) {
      state.configurationsModule = initialState.configurationsModule
    },

    // ---------------------------------------------------------------------------------
    // EmployeCategorie
    // ---------------------------------------------------------------------------------
    setEmployesCategoriesList(state, action) {
      employeCategorieAdapter.setAll(state.employesCategories, action.payload)
    },
    upsertOneEmployeCategorieState(state, action) {
      employeCategorieAdapter.upsertOne(
        state.employesCategories,
        action.payload
      )
    },
    deleteManyEmployesCategoriesState(state, action) {
      const categoriesForDelete = action.payload
      let idsForDelete = []

      categoriesForDelete.forEach((categorie) => {
        let id = `${categorie.noEmploye}#${categorie.dateDebut
          ? moment(categorie.dateDebut).format("YYYY-MM-DD")
          : "Invalid date"
          }`
        idsForDelete.push(id)
      })

      employeCategorieAdapter.removeMany(state.employesCategories, idsForDelete)
    },

    // ---------------------------------------------------------------------------------
    // FormationRecue
    // ---------------------------------------------------------------------------------
    setFormationsRecuesList(state, action) {
      formationRecueAdapteur.setAll(state.formationsRecues, action.payload)
    },
    upsertOneFormationRecue(state, action) {
      formationRecueAdapteur.upsertOne(state.formationsRecues, action.payload)
    },
    removeOneFormationRecue(state, action) {
      formationRecueAdapteur.removeOne(
        state.formationsRecues,
        action.payload.formationRecueId
      )
    },
  },
  extraReducers: {
    // ---------------------------------------------------------------------------------
    // AffectationsEmploye
    // ---------------------------------------------------------------------------------
    [fetchAffectationsEmployes.pending]: (state) => {
      state.affectationsEmployes.loading = true
    },
    [fetchAffectationsEmployes.rejected]: (state) => {
      state.affectationsEmployes.loading = false
    },
    [fetchAffectationsEmployes.fulfilled]: (state, action) => {
      affectationEmployeAdapter.setAll(
        state.affectationsEmployes,
        action.payload
      )
      state.affectationsEmployes.loading = false
    },

    // ---------------------------------------------------------------------------------
    // CategorieEmploye
    // ---------------------------------------------------------------------------------
    [fetchCategoriesEmployes.pending]: (state) => {
      state.categoriesEmployes.loading = true
    },
    [fetchCategoriesEmployes.rejected]: (state) => {
      state.categoriesEmployes.loading = false
    },
    [fetchCategoriesEmployes.fulfilled]: (state, action) => {
      categorieEmployeAdapter.setAll(state.categoriesEmployes, action.payload)
      state.categoriesEmployes.loading = false
    },

    // ---------------------------------------------------------------------------------
    // ConfigurationsModule
    // ---------------------------------------------------------------------------------
    [getConfigurationModule.pending]: (state, action) => {
      state.loadingConfigurationModule = true
    },
    [getConfigurationModule.rejected]: (state, action) => {
      state.loadingConfigurationModule = false
    },
    [getConfigurationModule.fulfilled]: (state, action) => {
      state.configurationsModule = action.payload
      state.loadingConfigurationModule = false
    },
    [getMoyensSqdm.pending]: (state) => {
      state.moyensSqdm.loading = true
    },
    [getMoyensSqdm.rejected]: (state) => {
      state.moyensSqdm.loading = false
    },
    [getMoyensSqdm.fulfilled]: (state, action) => {
      moyensSqdmAdapter.setAll(state.moyensSqdm, action.payload)
      state.moyensSqdm.loading = false
    },
    [updateConfigurationModule.pending]: (state, action) => {
      state.loadingConfigurationModule = true
    },
    [updateConfigurationModule.rejected]: (state, action) => {
      state.loadingConfigurationModule = false
    },
    [updateConfigurationModule.fulfilled]: (state, action) => {
      state.loadingConfigurationModule = false
    },

    // ---------------------------------------------------------------------------------
    // EmployeCategorie
    // ---------------------------------------------------------------------------------
    [fetchEmployesCategories.pending]: (state) => {
      state.employesCategories.loading = true
    },
    [fetchEmployesCategories.rejected]: (state) => {
      state.employesCategories.loading = false
    },
    [fetchEmployesCategories.fulfilled]: (state, action) => {
      employeCategorieAdapter.setAll(state.employesCategories, action.payload)
      state.employesCategories.loading = false
    },

    // ---------------------------------------------------------------------------------
    // FormationRecue
    // ---------------------------------------------------------------------------------
    [fetchFormationsRecuesByEmploye.pending]: (state) => {
      state.formationsRecues.loading = true
    },
    [fetchFormationsRecuesByEmploye.rejected]: (state) => {
      state.formationsRecues.loading = false
    },
    [fetchFormationsRecuesByEmploye.fulfilled]: (state, action) => {
      formationRecueAdapteur.setAll(state.formationsRecues, action.payload)
      state.formationsRecues.loading = false
    },

    // ---------------------------------------------------------------------------------
    // ProcedeActivite
    // ---------------------------------------------------------------------------------
    [fetchProcedesActivitisBySite.pending]: (state) => {
      state.procedesActiviteBySite.loading = true
    },
    [fetchProcedesActivitisBySite.rejected]: (state) => {
      state.procedesActiviteBySite.loading = false
    },
    [fetchProcedesActivitisBySite.fulfilled]: (state, action) => {
      procedeActiviteAdapter.setAll(
        state.procedesActiviteBySite,
        action.payload
      )
      state.procedesActiviteBySite.loading = false
    },

    // ---------------------------------------------------------------------------------
    // Employés et Contacts Externes
    // ---------------------------------------------------------------------------------
    [fetchAllEmployePosteOccupe.pending]: (state, action) => {
      state.allEmployePosteOccupe.loading = true
    },
    [fetchAllEmployePosteOccupe.rejected]: (state, action) => {
      state.allEmployePosteOccupe.loading = false
    },
    [fetchAllEmployePosteOccupe.fulfilled]: (state, action) => {
      allEmployePosteOccupeAdapter.setAll(
        state.allEmployePosteOccupe,
        action.payload
      )
      state.allEmployePosteOccupe.loading = false
    },
    [fetchAllEmployeEpi.pending]: (state, action) => {
      state.allEmployeEpi.loading = true
    },
    [fetchAllEmployeEpi.rejected]: (state, action) => {
      state.allEmployeEpi.loading = false
    },
    [fetchAllEmployeEpi.fulfilled]: (state, action) => {
      allEmployeEpiAdapter.setAll(state.allEmployeEpi, action.payload)
      state.allEmployeEpi.loading = false
    },
  },
})

// ========================================================================================

export const {
  setAffectationsEmployesList,
  upsertOneAffectationsEmployeState,
  deleteManyAffectationEmployeState,
  changeConfigurationsModule,
  resetConfigurationModule,
  setEmployesCategoriesList,
  upsertOneEmployeCategorieState,
  deleteManyEmployesCategoriesState,
  setFormationsRecuesList,
  upsertOneFormationRecue,
  removeOneFormationRecue,
} = formationCompetencesSlice.actions

export default formationCompetencesSlice.reducer
