import {
    createSlice,
    createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'Services/AxiosService';
import { RAPPORT_GET_COURRIEL } from 'store/UrlConstants';
import { paramsToQueryParam } from "store/UrlUtils";

const initialState = {
}

export const getCourriel = createAsyncThunk('Rapport/GetCourriel', async (params, thunkAPI) => {
    const response = await axios.get(RAPPORT_GET_COURRIEL + paramsToQueryParam(params));
    return response.data;
});

const RapportSlice = createSlice({
    name: 'rapport',
    initialState,
    reducers: {},
    extraReducers: {}
});

export default RapportSlice.reducer;
