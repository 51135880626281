import InspectionSearchCriteria from './InspectionSearch';

export default function InspectionSearchFichesCriteria(_searchCriteria) {
    let criteria = new InspectionSearchCriteria(_searchCriteria);
    let searchCriteria = {...criteria};
    
    searchCriteria.description = (_searchCriteria && _searchCriteria.description) ? _searchCriteria.description : null;
    searchCriteria.indInactif = (_searchCriteria && _searchCriteria.indInactif) ? _searchCriteria.indInactif : 0;

    return searchCriteria;
}