import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'Services/AxiosService';
import { listToQueryParam, paramsToQueryParam } from "store/UrlUtils";
import {
  FCR_GET_ATTESTATION_REPORT,
  FCR_GET_EVALUATION_PERFORMANCE_REPORT,
  FCR_GET_FEUILLE_PRESENCE_VEERGE_REPORT,
  FCR_GET_FORMATION_RAPPORT_OPTION_POSTES,
  FCR_GET_PLAN_FORMATION_DETAIL_REPORT,
  FCR_GET_PRESENCES_ACTIVITE_REPORT,
  FCR_GET_RAPPEL_ACTIVITES_REPORT,
  FCR_GET_RAPPEL_ACTIVITES_REPORT_CRITERIAS,
  FCR_LOAD_FORMATION_ATTESTATION_RESULTATS
} from 'store/UrlConstants';

import { showSnackbarMessage } from "store/slices/MessagesSystemSlice";

const initialState = {
  loadingInProgress: false,
  procedesliste: [],
  initialRappelActivitesReport: {},
  rapportOptionPostes: []
};

export const getPlanFormationDetailReport = createAsyncThunk('formationCompetencesRapports/GetPlanFormationDetailReport', async (params, thunkAPI) => {
  try{
    const { data } = await axios.get(FCR_GET_PLAN_FORMATION_DETAIL_REPORT + paramsToQueryParam(params));
    return data;
  } catch(e){
    console.log(e, 'E')
  }
});

export const getFeuillePresenceViergeReport = createAsyncThunk('formationCompetencesRapports/GetFeuillePresenceViergeReport', async (params, thunkAPI) => {
  try{
    const { data } = await axios.get(FCR_GET_FEUILLE_PRESENCE_VEERGE_REPORT + paramsToQueryParam(params));
    return data;
  } catch(e){
    console.log(e, 'E')
  }
});

export const getRappelActivitesReportCriterias = createAsyncThunk('formationCompetencesRapports/GetRappelActivitesReportCriterias', async (codeSites, thunkAPI) => {
  try{
    const { data } = await axios.get(FCR_GET_RAPPEL_ACTIVITES_REPORT_CRITERIAS + listToQueryParam("codeSites", codeSites));
    return data;
  } catch(error){
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const apercuRappelActivitesData = createAsyncThunk('formationCompetencesRapports/ApercuRappelActivitesData', async (rapportBody, thunkAPI) => {
  try{
    const { data } = await axios.put(FCR_GET_RAPPEL_ACTIVITES_REPORT, rapportBody);
    return data;
  } catch(error){
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const loadFormatioAttestationResultats = createAsyncThunk('formationCompetencesRapports/LoadFormationAttestationResultats', async (putBody, thunkAPI) => {
  try{
    const { data } = await axios.put(FCR_LOAD_FORMATION_ATTESTATION_RESULTATS, putBody);
    return data;
  } catch(error){
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getAttestationReport = createAsyncThunk('formationCompetencesRapports/GetAttestationReport', async (params, thunkAPI) => {
  try{
    const { data } = await axios.get(FCR_GET_ATTESTATION_REPORT + paramsToQueryParam(params));
    return data;
  } catch(error){
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    // return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getFormationRapportOptionPostes = createAsyncThunk('formationCompetencesRapports/GetFormationRapportOptionPostes', async (params, thunkAPI) => {
  try{
    const { data } = await axios.get(FCR_GET_FORMATION_RAPPORT_OPTION_POSTES + paramsToQueryParam(params));
    return data;
  } catch(error){
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    // return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getPresencesActiviteReport = createAsyncThunk('formationCompetencesRapports/GetPresencesActiviteReport', async (params, thunkAPI) => {
  try{
    const { data } = await axios.get(FCR_GET_PRESENCES_ACTIVITE_REPORT + paramsToQueryParam(params));
    return data;
  } catch(error){
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    // return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getEvaluationPerformanceReport = createAsyncThunk('formationCompetencesRapports/GetEvaluationPerformanceReport', async (params, thunkAPI) => {
  try{
    const { data } = await axios.get(FCR_GET_EVALUATION_PERFORMANCE_REPORT + paramsToQueryParam(params));
    return data;
  } catch(error){
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    // return thunkAPI.rejectWithValue(error.response.data);
  }
});

const FormationCompetencesRapportsSlice = createSlice({
  name: 'formationCompetencesRapports',
  initialState,
  reducers: {},
  extraReducers: {
    [getPlanFormationDetailReport.fulfilled]: (state, action) => {
      state.loadingInProgress = false
    },
    [getPlanFormationDetailReport.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [getPlanFormationDetailReport.rejected]: (state, action) => {
      state.loadingInProgress = false
    },
    [getRappelActivitesReportCriterias.fulfilled]: (state, action) => {
      state.loadingInProgress = false
      if(action.payload){
        state.initialRappelActivitesReport = {...action.payload}
      }
    },
    [getRappelActivitesReportCriterias.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [getRappelActivitesReportCriterias.rejected]: (state, action) => {
      state.loadingInProgress = false
    },
    [apercuRappelActivitesData.fulfilled]: (state, action) => {
      state.loadingInProgress = false
    },
    [apercuRappelActivitesData.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [apercuRappelActivitesData.rejected]: (state, action) => {
      state.loadingInProgress = false
    },
    [loadFormatioAttestationResultats.fulfilled]: (state, action) => {
      state.loadingInProgress = false
    },
    [loadFormatioAttestationResultats.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [loadFormatioAttestationResultats.rejected]: (state, action) => {
      state.loadingInProgress = false
    },
    [getAttestationReport.fulfilled]: (state, action) => {
      state.loadingInProgress = false
    },
    [getAttestationReport.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [getAttestationReport.rejected]: (state, action) => {
      state.loadingInProgress = false
    },
    [getFormationRapportOptionPostes.fulfilled]: (state, action) => {
      state.loadingInProgress = false
      state.rapportOptionPostes = action.payload
    },
    [getFormationRapportOptionPostes.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [getPresencesActiviteReport.fulfilled]: (state, action) => {
      state.loadingInProgress = false
    },
    [getPresencesActiviteReport.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [getPresencesActiviteReport.rejected]: (state, action) => {
      state.loadingInProgress = false
    },
    [getEvaluationPerformanceReport.fulfilled]: (state, action) => {
      state.loadingInProgress = false
    },
    [getEvaluationPerformanceReport.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [getEvaluationPerformanceReport.rejected]: (state, action) => {
      state.loadingInProgress = false
    },
  },
});

export const prepareSelector = state => state.formationCompetencesRapports;

export default FormationCompetencesRapportsSlice.reducer;
