import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import axios from "Services/AxiosService";
import {
  // GET_ALL_GED_LOAD_DOS_COTES,
  GET_ALL_UGED_BY_CLASSIFICATION,
  GET_ALL_UGED_LOAD_DOS_COTES, 
  // LOAD_DOS_COTE_PATH,
  UGED_LOAD_DOS_COTE_PATH,
} from "store/UrlConstants";
import { paramsToQueryParam } from "../../UrlUtils";
// import {fetchDosCotesPath} from "../GestionDocumentaire/GestDocumentaireSlice";

const allUGEDByClassificationAdapter = createEntityAdapter({
  selectId: (entity) => entity.cote,
});

export const fetchAllUGEDByClassification = createAsyncThunk(
  "UGED/AllByClassification/Get",
  async (codeAuto) => {
    const response = await axios.get(GET_ALL_UGED_BY_CLASSIFICATION, {
      params: { codeAuto: codeAuto },
    });
    return response.data;
  }
);

export const fetchAllUGEDLoadCotes = createAsyncThunk(
  "UGED/LoadDosCotes/Get",
  async (params) => {
    const response = await axios.get(
      GET_ALL_UGED_LOAD_DOS_COTES + paramsToQueryParam(params)
    );

    //retour premier rendu
    if (params.loadSites) {
      const arrayChildrenRoot = response.data.map((item) => item.dosCote);
      return {
        dosCote: 0,
        dossierFils: arrayChildrenRoot,
        responseData: response.data,
        loadSites: true
      };
    } else {
      return response.data;
    }
  }
);

export const fetchUGEDDosCotesPath = createAsyncThunk(
    "UGED/LoadDosCotesPath/Get",
    async (codeAuto) => {
      const response = await axios.get(UGED_LOAD_DOS_COTE_PATH, {
        params: { codeAuto: codeAuto },
      });
      return response.data;
    }
);

export const allUGEDByClassificationSelector = (store) =>
  store.unauthenticatedGestDocumentaire.allUGEDByClassification;

export const {
  selectAll: selectAllUGEDByClassification,
  selectById: selectAllUGEDByClassificationById,
} = allUGEDByClassificationAdapter.getSelectors(
  allUGEDByClassificationSelector
);

export const selectLoadingAllUGEDByClassification = createSelector(
  allUGEDByClassificationSelector,
  (state) => state.loading
);

const initialState = {
  allCotes: {},
  allUGEDByClassification: allUGEDByClassificationAdapter.getInitialState({
    loading: false,
  }),
};

const UnauthenticatedGestDocumentaireSlice = createSlice({
  name: "unauthenticatedGestDocumentaire",
  initialState,
  reducers: {
    upsertOneAllUGEDByClassification(state, action) {
      allUGEDByClassificationAdapter.upsertOne(
        state.allUGEDByClassification,
        action.payload
      );
    },
  },
  extraReducers: {
    [fetchAllUGEDLoadCotes.fulfilled]: (state, action) => {
      if (action.payload.loadSites) {
        state.allCotes[action.payload.dosCote] = {};
        state.allCotes[action.payload.dosCote].dossierFils =
            action.payload.dossierFils.map((i) => i);
        state.allCotes[action.payload.dosCote].dosCote = action.payload.dosCote;
        action.payload.responseData.forEach((dossier) => {
          state.allCotes[dossier.dosCote] = {...dossier};
          state.allCotes[dossier.dosCote].dossierFils = dossier.dossierFils.map((i) => i.dosCote);
          dossier.dossierFils.map(
              (item) => (state.allCotes[item.dosCote] = item)
          );
        });
      } else {
        state.allCotes[action.payload[0].dosCote].dossierFils =
            action.payload[0].dossierFils.map((i) => i.dosCote);
        action.payload[0].dossierFils.map(
            (item) => (state.allCotes[item.dosCote] = item)
        );
      }
    },
    [fetchAllUGEDByClassification.fulfilled]: (state, action) => {
      allUGEDByClassificationAdapter.setAll(
        state.allUGEDByClassification,
        action.payload
      );
    },
    [fetchUGEDDosCotesPath.fulfilled]: (state, action) => {
      action.payload.forEach(dossier => {
        state.allCotes[dossier.dosCote] = {...dossier};
        state.allCotes[dossier.dosCote].dossierFils = dossier.dossierFils.map((i) => i.dosCote);
        dossier.dossierFils.map(
            (item) => (state.allCotes[item.dosCote] = item)
        );
      });
    },
  },
});

export const { upsertOneAllUGEDByClassification } = UnauthenticatedGestDocumentaireSlice.actions;

export default UnauthenticatedGestDocumentaireSlice.reducer;

export const ugedTreeSelector = (state) =>
  state.unauthenticatedGestDocumentaire.allCotes;
export const ugedNodeSelector = (nodeId) =>
  createSelector(ugedTreeSelector, (data) => data[nodeId]);
