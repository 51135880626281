import React from "react";
import {useLocation} from "react-router-dom";

// Fonction qui prend un nom de parametre et un array de valeurs et retourne le tout en query string
export function listToQueryParam(paramName, values, queryString = true) {
  return (queryString ? '?' : '') + values.map(function (value) {
    return `${paramName}=` + encodeURIComponent(value);
  }).join('&');
}

// Fonction qui prend un objet de clés:valeurs et retourne un query string
// Supporte les arrays via listToQueryParam
export function paramsToQueryParam(params) {
  let queryString = [];
  for (let [key, value] of Object.entries(params)) {
    if (Array.isArray(value)) {
      queryString.push(listToQueryParam(key, value, false));
    } else {
      queryString.push(`${key}=` + encodeURIComponent(value));
    }
  }
  return '?' + queryString.join('&');
}

// Source: https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url?page=1&tab=scoredesc#tab-top
export function validURL(str) {
  let url;

  try {
    url = new URL(str);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}