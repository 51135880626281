import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import axios from "Services/AxiosService";
import { GET_UNIVERS_CRITERIA_SITES, GET_UNIVERS_CRITERIA_FAVORITES } from "store/UrlConstants";
import { paramsToQueryParam } from "store/UrlUtils";
import { initSearchParamsSites } from "./KnowledgeUniverseSearchSlice";

const universCriteriaSitesAdapter = createEntityAdapter({ selectId: (entity) => entity.code });
const universcriteriaFavoritesAdapter = createEntityAdapter({ selectId: (entity) => entity.titre });

export const getUniversCriteriaSites = createAsyncThunk("KmUniversCriteriaSites/Get", async (params, thunkAPI) => {
    const response = await axios.get(GET_UNIVERS_CRITERIA_SITES);
    thunkAPI.dispatch(initSearchParamsSites(response.data));
    return response.data;
});

export const {
    selectAll: selectAllUniversCriteriaSites,
    selectById: selectUniverCriteriaSiteById,
} = universCriteriaSitesAdapter.getSelectors((state) => state.KmUniversCriteria.universCriteriaSites);

export const selectAllUcCriteriaSitesActif = createSelector(
    selectAllUniversCriteriaSites,
    (sites) => sites.filter(site => !site.inactive)
)

export const getUniversCriteriaFavorites = createAsyncThunk('KmUniversCriteriaFavorites/Get', async (_params = null) => {
    const response = await axios.get(GET_UNIVERS_CRITERIA_FAVORITES, { params: _params });
    return response.data;
})

export const {
    selectAll: selectAllUniverseCriteriaFavorites,
    selectById: selectUniverseCriteriaFavoriteById,
} = universcriteriaFavoritesAdapter.getSelectors((state) => state.KmUniversCriteria.universCriteriaFavorites);


const initialState = {
    universCriteriaSites: universCriteriaSitesAdapter.getInitialState(),
    universCriteriaFavorites: universcriteriaFavoritesAdapter.getInitialState()
};

const KnowledgeUniversCriteriaSlice = createSlice({
    name: "KnowledgeUniversCriteria",
    initialState: initialState,
    reducers: {},
    extraReducers: {
        [getUniversCriteriaSites.fulfilled]: (state, action) => {
            universCriteriaSitesAdapter.setAll(state.universCriteriaSites, action.payload)
        },
        [getUniversCriteriaFavorites.fulfilled]: (state, action) => {
            universcriteriaFavoritesAdapter.setAll(state.universCriteriaFavorites, action.payload);
        },
    },
})

export const { } = KnowledgeUniversCriteriaSlice.actions;

export default KnowledgeUniversCriteriaSlice.reducer;