import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "Services/AxiosService";
import { listToQueryParam, paramsToQueryParam } from "store/UrlUtils";
import {
    LOAD_FREQ_CALENDRIER,
    LOAD_CALENDRIER,
    LOAD_PERSONNE_SELECT,
} from "store/UrlConstants";
import { showSnackbarMessage } from "./MessagesSystemSlice";

export const loadCalendrier = createAsyncThunk(
    LOAD_FREQ_CALENDRIER,
    async (params) => {
        const response = await axios.get(
            LOAD_FREQ_CALENDRIER + paramsToQueryParam(params)
        );
        return response.data;
    }
);

export const loadCalendrierRecurrence = createAsyncThunk(
    LOAD_CALENDRIER,
    async (params) => {
        const response = await axios.get(
            LOAD_CALENDRIER + paramsToQueryParam(params)
        );
        //console.log(response.data);
        return response.data;
    }
);
const allRecurrenceAdapter = createEntityAdapter({
    selectId: (entity) => entity.calendrierId,
});

export const loadPersonneSelect = createAsyncThunk(
    LOAD_PERSONNE_SELECT,
    async () => {
        const response = await axios.get(LOAD_PERSONNE_SELECT);
        return response.data;
    }
);

const initialState = {
    actionsObjets: allRecurrenceAdapter.getInitialState(),
    loadInProgress: false,
};

const calendrierFrequenceSlice = createSlice({
    name: "inspection",
    initialState,
    reducers: {},
    extraReducers: {
        [loadCalendrier.fulfilled]: (state, action) => {
            state.loadInProgress = false;
        },
        [loadCalendrier.pending]: (state, action) => {
            state.loadInProgress = true;
        },
        [loadCalendrier.rejected]: (state, action) => {
            state.loadInProgress = false;
        },
        [loadCalendrierRecurrence.fulfilled]: (state, action) => {
            allRecurrenceAdapter.setAll(state.actionsObjets, action.payload);
            state.loadInProgress = false;
        },
        [loadCalendrierRecurrence.pending]: (state, action) => {
            state.loadInProgress = true;
        },
        [loadCalendrierRecurrence.rejected]: (state, action) => {
            state.loadInProgress = false;
        },
        [loadPersonneSelect.fulfilled]: (state, action) => {
            state.loadInProgress = false;
        },
        [loadPersonneSelect.pending]: (state, action) => {
            state.loadInProgress = true;
        },
        [loadPersonneSelect.rejected]: (state, action) => {
            state.loadInProgress = false;
        },
    },
});

export const {
    setSearchDetail,
    setCriteres,
    resetSearchDetail,
    setSelectedBanqueQuestionLang,
} = calendrierFrequenceSlice.actions;

export const { selectAll: selectAllRecurrence } =
    allRecurrenceAdapter.getSelectors(
        (state) => state.calendrierFrequence.actionsObjets
    );

export default calendrierFrequenceSlice.reducer;
