import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import axios from "Services/AxiosService";
import { ANALYSE_DERISQUES_GET_CRITERES_MULTI_TYPE } from "store/UrlConstants";

export const loadActionsCriteresMultiType = createAsyncThunk(
    "AnalyseDeRisques/GetActionCriteresMultiType",
    async (params, thunkAPI) => {
        const response = await axios.get(ANALYSE_DERISQUES_GET_CRITERES_MULTI_TYPE);
        return response.data;
    }
);

const allCriteresMultiTypeAdapter = createEntityAdapter({
    selectId: (entity) => entity.ordre,
});

const initialState = {
    actionsObjets: allCriteresMultiTypeAdapter.getInitialState(),
    loading: false,
}

const getCritereMultiTypeSlice = createSlice({
    name: "riskAnalysisGetCriteresMultiType",
    initialState,
    reducers: {},
    extraReducers: {
        [loadActionsCriteresMultiType.pending]: (state, action) => {
            state.loading = true;
        },
        [loadActionsCriteresMultiType.fulfilled]: (state, action) => {
            allCriteresMultiTypeAdapter.setAll(
                state.actionsObjets,
                action.payload
            );
            state.loading = false;
        },
    },
});

export const {selectAll: selectAllARCriteresMultiType} = 
    allCriteresMultiTypeAdapter.getSelectors(
        (state) => state.riskAnalysisGetCriteresMultiType.actionsObjets
    );

export default getCritereMultiTypeSlice.reducer;
