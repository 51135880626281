import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
    createSelector,
} from "@reduxjs/toolkit";
import axios from "Services/AxiosService";
import { paramsToQueryParam, listToQueryParam } from "store/UrlUtils";
import {
    FETCH_EMPLOYE_IMAGE,
    FETCH_EMPLOYE_SUMMARY,
    EMP_CRITERIA_SEARCH,
    EMP_GET_TYPES,
    EMP_IMM_SUP,
    EMP_EMP_BY,
    EMP_ONE,
    EMP_ONE_BY_ID,
    EMP_NO,
    EMP_TYPES_SITE,
    EMP_CATEGORIE_CONTACT,
    EMP_STATUTS,
    EMP_QUART,
    EMP_EQUIPE,
    EMP_NEW,
    EMP_ADD,
    EMP_UPDATE,
    EMP_DELETE,
    EMP_GET_DUPLIQUER_INFO,
    EMP_DUPLIQUER,
    EMP_DUPLIQUER_SECURITE_EMAIL,
    EMP_DESACTIVER_REACTIVER,
    EMP_IMAGE_BY_ID,
    EMP_UPDATE_IMAGE,
    EMP_SEARCH,
    EMP_SKILL_SEARCH,
    EMP_SKILL_ADD,
    EMP_SKILL_UPDATE,
    EMP_SKILL_DELETE,
    EMP_COURRIELS,
    EMP_AUTRES_IDENTIFIANT_1,
    EMP_AUTRES_IDENTIFIANT_2,
    EMP_AUTRES_IDENTIFIANT_3,
    EMP_PERSONNES_SELECT,
    EMP_MANY_QUICK_INFO_BY_CODE,
    EMP_SUP_IMMEDIA_EMPL_SITE,
    EMP_AFFECTATIONS_CRIT_EMPL_SITE,
    EMP_CAT_EMPLOYE,
    EMP_POSTES_EMPL_SITE,
    EMP_MANY_QUICK_INFO_BY_ID,
} from "store/UrlConstants";
import { getHeightAndWidthFromDataUrl } from "../../Utils/CommonFunctions";
import { showSnackbarMessage } from "store/slices/MessagesSystemSlice";

export const fetchEmployeImage = createAsyncThunk("Employe/Image", async () => {
    const response = await axios.get(FETCH_EMPLOYE_IMAGE);
    return response.data;
});

export const fetchEmployeSummary = createAsyncThunk(
    "Employe/Summary",
    async () => {
        let response = await axios.get(FETCH_EMPLOYE_SUMMARY);
        return response.data;
    }
);

const employeTypesAdapter = createEntityAdapter();
const autresIdentifiant1Adapter = createEntityAdapter();
const autresIdentifiant2Adapter = createEntityAdapter();
const autresIdentifiant3Adapter = createEntityAdapter();

const employesCriteriaAdapter = createEntityAdapter({
    selectId: (employeCriteria) => employeCriteria.employeId,
});

const immediateSuperiorsAdapter = createEntityAdapter({
    selectId: (employeCriteria) => employeCriteria.employeCode,
});

const employedBysAdapter = createEntityAdapter({
    selectId: (employeCriteria) => employeCriteria.employeCode,
});

export const fetchEmployeByCode = createAsyncThunk(
    "Employe/GetOne",
    async (employeCode) => {
        const response = await axios.get(
            EMP_ONE + paramsToQueryParam({ noEmploye: employeCode })
        );
        return response.data;
    }
);

export const fetchEmployeById = createAsyncThunk(
    "Employe/GetOneById",
    async (employeId, thunkAPI) => {
        const response = await axios.get(EMP_ONE_BY_ID, { params: employeId });
        return response.data;
    }
);

export const fetchManyEmployeQuickInfoByCodes = createAsyncThunk(
    "Employe/GetManyQuickInfoByCodes",
    async (employeCodes, thunkAPI) => {
        const res = await axios.get(
            EMP_MANY_QUICK_INFO_BY_CODE +
            listToQueryParam("employeCodes", employeCodes)
        );
        return res.data;
    }
);

export const fetchManyEmployeQuickInfoByIds = createAsyncThunk("Employe/GetManyQuickInfoByIds", async (employeIds, thunkAPI) => {
    const res = await axios.get(EMP_MANY_QUICK_INFO_BY_ID + listToQueryParam("employeIds", employeIds));
    return res.data;
});

export const fetchEmployeCourriels = createAsyncThunk(
    EMP_COURRIELS,
    async () => {
        const response = await axios.get(EMP_COURRIELS);
        return response.data;
    }
);

export const fetchPersonnesSelect = createAsyncThunk(
    EMP_PERSONNES_SELECT,
    async () => {
        const response = await axios.get(EMP_PERSONNES_SELECT);
        return response.data;
    }
);

export const CriteriaSearch = createAsyncThunk(
    "Employe/CriteriaSearch",
    async (params) => {
        const response = await axios.post(EMP_CRITERIA_SEARCH, params);
        return response.data;
    }
);

export const {
    selectAll: selectAllEmployesCriteria,
    selectById: selectEmployeCriteriaById,
} = employesCriteriaAdapter.getSelectors(
    (state) => state.employe.employesCriteria
);

export const fetchEmployeTypes = createAsyncThunk(
    "Employe/GetEmployeTypes",
    async () => {
        const response = await axios.get(EMP_GET_TYPES);
        return response.data;
    }
);

export const {
    selectAll: selectAllEmployeTypes,
    selectById: selectTypeEmployeById,
} = employeTypesAdapter.getSelectors((state) => state.employe.employeTypes);

export const fetchImmediateSuperiors = createAsyncThunk(
    "Employe/ImmediateSuperiors",
    async (sites) => {
        const response = await axios.get(
            EMP_IMM_SUP + paramsToQueryParam({ sites: sites })
        );
        return response.data;
    }
);

export const {
    selectAll: selectAllImmediateSuperiors,
    selectById: selectImmediateSuperiorsById,
} = immediateSuperiorsAdapter.getSelectors(
    (state) => state.employe.immediateSuperiors
);

export const fetchEmployedBys = createAsyncThunk(
    "Employe/EmployedBys",
    async (sites) => {
        const response = await axios.get(
            EMP_EMP_BY + paramsToQueryParam({ sites: sites })
        );
        return response.data;
    }
);

export const {
    selectAll: selectAllEmployedBys,
    selectById: selectEmployedByById,
} = employedBysAdapter.getSelectors((state) => state.employe.employedBys);

export const fetchAutresIdentifiant1 = createAsyncThunk(
    "Employe/AutresIdentifiant1",
    async (sites) => {
        const response = await axios.get(
            EMP_AUTRES_IDENTIFIANT_1 + paramsToQueryParam({ sites: sites })
        );
        return response.data;
    }
);

export const {
    selectAll: selectAllAutresIdentifiant1,
    selectById: selectAutreIdentifiant1ById,
} = autresIdentifiant1Adapter.getSelectors(
    (state) => state.employe.autresIdentifiant1
);

export const fetchAutresIdentifiant2 = createAsyncThunk(
    "Employe/AutresIdentifiant2",
    async (sites) => {
        const response = await axios.get(
            EMP_AUTRES_IDENTIFIANT_2 + paramsToQueryParam({ sites: sites })
        );
        return response.data;
    }
);

export const {
    selectAll: selectAllAutresIdentifiant2,
    selectById: selectAutreIdentifiant2ById,
} = autresIdentifiant2Adapter.getSelectors(
    (state) => state.employe.autresIdentifiant2
);

export const fetchAutresIdentifiant3 = createAsyncThunk(
    "Employe/AutresIdentifiant3",
    async (sites) => {
        const response = await axios.get(
            EMP_AUTRES_IDENTIFIANT_3 + paramsToQueryParam({ sites: sites })
        );
        return response.data;
    }
);

export const {
    selectAll: selectAllAutresIdentifiant3,
    selectById: selectAutreIdentifiant3ById,
} = autresIdentifiant3Adapter.getSelectors(
    (state) => state.employe.autresIdentifiant3
);

export const deleteEmploye = createAsyncThunk(
    "Employe/Delete/Delete", async (employeId, thunkAPI) => {
        try {
            const resp = await axios.delete(EMP_DELETE, { params: { employeId: employeId } });
            return resp.data;
        } catch (error) {
            thunkAPI.dispatch(
                showSnackbarMessage({
                    message: error.response.data.messageText,
                    severity: "error",
                    autoHideDuration: 10000,
                })
            );
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getEmployeByCode = createAsyncThunk(
    "Employe/One/Get",
    async (employeCode) => {
        const response = await axios.get(
            EMP_ONE + paramsToQueryParam({ noEmploye: employeCode })
        );
        return response.data;
    }
);

export const getDupliquerInfo = createAsyncThunk(
    "Employe/GetDupliquerInfo/Get",
    async (employeId) => {
        const response = await axios.get(
            EMP_GET_DUPLIQUER_INFO + paramsToQueryParam({ employeId: employeId })
        );
        return response.data;
    }
);

export const dupliquerEmploye = createAsyncThunk(
    "Employe/Dupliquer/Post",
    async (params, thunkAPI) => {
      try {
        const response = await axios.post(EMP_DUPLIQUER, params);
        thunkAPI.dispatch(
          showSnackbarMessage({
            dba: "objetsLangues2",
            messageId: "web_confirmation_enregistrement",
            severity: "success",
          })
        )
        return response.data
      } catch (error) {
        thunkAPI.dispatch(
          showSnackbarMessage({
            message: error.response.data.messageText,
            severity: "error",
            autoHideDuration: 10000,
          })
        )
        return thunkAPI.rejectWithValue(error.response.data)
      }
    }
  )

export const dupliquerSecuriteEmail = createAsyncThunk(
    "Employe/DupliquerSecuriteEmail/Post",
    async (params, thunkAPI) => {
      try {
        const response = await axios.post(EMP_DUPLIQUER_SECURITE_EMAIL, params);
        thunkAPI.dispatch(
          showSnackbarMessage({
            dba: "objetsLangues2",
            messageId: "web_confirmation_enregistrement",
            severity: "success",
          })
        )
        return response.data
      } catch (error) {
        thunkAPI.dispatch(
          showSnackbarMessage({
            message: error.response.data.messageText,
            severity: "error",
            autoHideDuration: 10000,
          })
        )
        return thunkAPI.rejectWithValue(error.response.data)
      }
    }
  )

  export const getDesactiverReactiver = createAsyncThunk(
    "Employe/DesactiverReactiver/Get",
    async (params) => {
        const response = await axios.get(
            EMP_DESACTIVER_REACTIVER + paramsToQueryParam({ employeId: params.employeId, action: params.action, raison: params.raison })
        );
        return response.data;
    }
);

  export const getImageByIdEmploye = createAsyncThunk(
    "Employe/ImageByIdEmploye/Get",
    async (idEmploye) => {
      const response = await axios.get(`${EMP_IMAGE_BY_ID}/${idEmploye}`);
      return response.data;
    }
  );

  export const updateImageEmploye = createAsyncThunk(
    "Employe/UpdateImageEmploye/Post",
    async (params, thunkAPI) => {
        let formData = new FormData();
        formData.append("file", params.file);
        try {
            const response = await axios.post(
                EMP_UPDATE_IMAGE + paramsToQueryParam({ noEmploye: params.noEmploye }), 
                formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                }
            );
            thunkAPI.dispatch(
            showSnackbarMessage({
                dba: "objetsLangues2",
                messageId: "web_confirmation_enregistrement",
                severity: "success",
            })
            )
            return response.data
        } catch (error) {
            thunkAPI.dispatch(
            showSnackbarMessage({
                message: error.response.data.messageText,
                severity: "error",
                autoHideDuration: 10000,
            })
            )
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
  )

//Megha.........................................
const noEmployesAdapter = createEntityAdapter();

const typesSiteAdapter = createEntityAdapter({
    selectId: (employeCriteria) => employeCriteria.code,
});

const empContactAdapter = createEntityAdapter({
    selectId: (employeCriteria) => employeCriteria.code,
});

const employeStatutsAdapter = createEntityAdapter({
    selectId: (employeCriteria) => employeCriteria.code,
});

const employeQuartAdapter = createEntityAdapter({
    selectId: (employeCriteria) => employeCriteria.code,
});

const superieursImmediatEmplSiteAdapter = createEntityAdapter({
    selectId: (employeCriteria) => employeCriteria.code,
});
const affectationsCritereEmplSiteAdapter = createEntityAdapter({
    selectId: (employeCriteria) => employeCriteria.code,
});
const categorieEmployeAdapter = createEntityAdapter({
    selectId: (employeCriteria) => employeCriteria.code,
});
const postesEmplSitesAdapter = createEntityAdapter({
    selectId: (employeCriteria) => employeCriteria.code,
});

const employeEquipeAdapter = createEntityAdapter({
    selectId: (employeCriteria) => employeCriteria.code,
});

const employeSearchAdapter = createEntityAdapter({
    selectId: (employeCriteria) => employeCriteria.employeId,
});

const employeSkillAdapter = createEntityAdapter({
    selectId: (emp) => emp.cleCompetenceEmploye,
});

export const fetchNoEmployes = createAsyncThunk(
    "Employe/NoEmployes",
    async () => {
        const result = await axios.get(EMP_NO);
        return result.data;
    }
);

export const {
    selectAll: selectAllnoEmployes,
    selectById: selectnoEmployesById,
} = noEmployesAdapter.getSelectors((state) => state.employe.noEmployes);

export const fetchTypesSite = createAsyncThunk(
    "Employe/EmployeTypesSite",
    async (sites) => {
        const res = await axios.get(
            EMP_TYPES_SITE + paramsToQueryParam({ sites: sites })
        );
        return res.data;
    }
);

export const {
    selectAll: selectAlltypesSite,
    selectById: selectTypesSiteById,
} = typesSiteAdapter.getSelectors((state) => state.employe.typesSite);

export const fetchEmpCategorieContact = createAsyncThunk(
    "Employe/CategorieContact",
    async (sites) => {
        const result = await axios.get(
            EMP_CATEGORIE_CONTACT + paramsToQueryParam({ sites: sites })
        );
        return result.data;
    }
);

export const {
    selectAll: selectAllEmpContact,
    selectById: selectEmpContactById,
} = empContactAdapter.getSelectors((state) => state.employe.empContact);

export const fetchEmployeStatuts = createAsyncThunk(
    "Employe/Statuts",
    async (sites) => {
        const result = await axios.get(
            EMP_STATUTS + paramsToQueryParam({ sites: sites })
        );
        return result.data;
    }
);

export const {
    selectAll: selectAllEmployeStatuts,
    selectById: selectEmployeStatutsById,
} = employeStatutsAdapter.getSelectors((state) => state.employe.employeStatuts);

export const fetchEmployeQuart = createAsyncThunk(
    "Employe/QuartDeTravail",
    async (sites) => {
        const result = await axios.get(
            EMP_QUART + paramsToQueryParam({ sites: sites })
        );
        return result.data;
    }
);

export const {
    selectAll: selectAllEmployeQuart,
    selectById: selectEmployeQuartById,
} = employeQuartAdapter.getSelectors((state) => state.employe.employeQuart);

export const fetchSuperieursImmediatEmplSite = createAsyncThunk(
    "Employe/SuperieursImmediatEmplSite",
    async (sites) => {
        const result = await axios.get(
            EMP_SUP_IMMEDIA_EMPL_SITE + paramsToQueryParam({ sites: sites })
        );
        return result.data;
    }
);

export const {
    selectAll: selectAllSuperieursImmediatEmplSite,
    selectById: selectSuperieursImmediatEmplSiteById,
} = superieursImmediatEmplSiteAdapter.getSelectors(
    (state) => state.employe.superieursImmediatEmplSite
);

export const fetchAffectationsCritereEmplSite = createAsyncThunk(
    "Employe/AffectationsCritereEmplSite",
    async (sites) => {
        const result = await axios.get(
            EMP_AFFECTATIONS_CRIT_EMPL_SITE +
            paramsToQueryParam({ sites: sites })
        );
        return result.data;
    }
);

export const {
    selectAll: selectAllAffectationsCritereEmplSite,
    selectById: selectAffectationsCritereEmplSiteById,
} = affectationsCritereEmplSiteAdapter.getSelectors(
    (state) => state.employe.affectationsCritereEmplSite
);

export const fetchCategorieEmploye = createAsyncThunk(
    "Employe/CategorieEmploye",
    async (sites) => {
        const result = await axios.get(
            EMP_CAT_EMPLOYE + paramsToQueryParam({ sites: sites })
        );
        return result.data;
    }
);

export const {
    selectAll: selectAllCategorieEmploye,
    selectById: selectCategorieEmployeById,
} = categorieEmployeAdapter.getSelectors(
    (state) => state.employe.categorieEmploye
);

export const fetchPostesEmplSites = createAsyncThunk(
    "Employe/PostesEmplSites",
    async (sites) => {
        const result = await axios.get(
            EMP_POSTES_EMPL_SITE + paramsToQueryParam({ sites: sites })
        );
        return result.data;
    }
);

export const {
    selectAll: selectAllPostesEmplSites,
    selectById: selectPostesEmplSitesById,
} = postesEmplSitesAdapter.getSelectors(
    (state) => state.employe.postesEmplSites
);

export const fetchEmployeEquipe = createAsyncThunk(
    "Employe/EquipeDeTravail",
    async (sites) => {
        const result = await axios.get(
            EMP_EQUIPE + paramsToQueryParam({ sites: sites })
        );
        return result.data;
    }
);

export const {
    selectAll: selectAllEmployeEquipe,
    selectById: selectEmployeEquipeById,
} = employeEquipeAdapter.getSelectors((state) => state.employe.employeEquipe);

export const fetchEmployeNew = createAsyncThunk("Employe/New", async () => {
    const result = await axios.get(EMP_NEW);
    return result.data;
});

export const postEmployeAdd = createAsyncThunk("Employe/Add", async (data) => {
    if(data.imageFichier !== undefined){
        const responseImgSize = await getHeightAndWidthFromDataUrl(
            window.URL.createObjectURL(data.imageFichier)
        );
        data.ImageHauteur = responseImgSize.height
        data.ImageLargeur = responseImgSize.width
    }
    const response = await axios.post(EMP_ADD, data, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
});

export const postEmployeUpdate = createAsyncThunk(
    "Employe/Update",
    async (employe) => {
        let formData = new FormData();
        for (let key in employe) {
            formData.append(
                key,
                key === "imageFichier" ? employe[key][0] : employe[key]
            );
        }

        if (employe.imageFichier !== undefined) {
            const responseImgSize = await getHeightAndWidthFromDataUrl(
                window.URL.createObjectURL(employe.imageFichier[0])
            );
            formData.append("ImageHauteur", responseImgSize.height);
            formData.append("ImageLargeur", responseImgSize.width);
        }
        const response = await axios.post(EMP_UPDATE, employe, {
            headers: { "Content-Type": "multipart/form-data" },
        });
        return response.data;
    }
);

export const fetchEmployes = createAsyncThunk(
    "Employe/EmployeSearch",
    async (filterData) => {
        const result = await axios.post(EMP_SEARCH, { ...filterData });
        return result.data;
    }
);

export const { selectAll: selectAllEmployes, selectById: selectEmployeById } =
    employeSearchAdapter.getSelectors((state) => state.employe.employes);

export const selectAllEmployesActifs = createSelector(
    selectAllEmployes,
    (employes) => employes.filter((employe) => employe.inactif === 0)
);

export const fetchEmployeSkills = createAsyncThunk(
    "Employe/EmployeSkillSearch",
    async ({ noEmploye }) => {
        const result = await axios.get(
            EMP_SKILL_SEARCH + "?noEmploye=" + noEmploye
        );
        return result.data;
    }
);

export const addEmployeSkill = createAsyncThunk(
    "Employe/EmployeSkillAdd",
    async (competenceEmploye, thunkAPI) => {
        try {
            const result = await axios.post(EMP_SKILL_ADD, competenceEmploye);

            thunkAPI.dispatch(
                showSnackbarMessage({
                    dba: "objetsLangues2",
                    messageId: "web_confirmation_enregistrement",
                    severity: "success",
                })
            );

            return result.data;
        } catch (error) {
            thunkAPI.dispatch(
                showSnackbarMessage({
                    message: error.response.data.messageText,
                    severity: "error",
                    autoHideDuration: 10000,
                })
            );
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const updateEmployeSkill = createAsyncThunk(
    "Employe/EmployeSkillUpdate",
    async (competenceEmploye, thunkAPI) => {
        try {
            const result = await axios.put(EMP_SKILL_UPDATE, competenceEmploye);

            thunkAPI.dispatch(updateOneEmployeSkill(result.data));

            thunkAPI.dispatch(
                showSnackbarMessage({
                    dba: "objetsLangues2",
                    messageId: "web_confirmation_enregistrement",
                    severity: "success",
                })
            );

            return result.data;
        } catch (error) {
            thunkAPI.dispatch(
                showSnackbarMessage({
                    message: error.response.data.messageText,
                    severity: "error",
                    autoHideDuration: 10000,
                })
            );
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const deleteEmployeSkills = createAsyncThunk(
    "Employe/EmployeSkill/DeleteMany",
    async (clesCompetencesEmploye, thunkAPI) => {
        try {
            const response = await axios.delete(
                EMP_SKILL_DELETE +
                listToQueryParam(
                    "cleCompetenceEmployes",
                    clesCompetencesEmploye
                )
            );

            thunkAPI.dispatch(removeManyEmployeSkills(clesCompetencesEmploye));

            thunkAPI.dispatch(
                showSnackbarMessage({
                    dba: "objetsLangues2",
                    messageId: "information_supprimee",
                    severity: "success",
                })
            );

            return response.data;
        } catch (error) {
            thunkAPI.dispatch(
                showSnackbarMessage({
                    message: error.response.data.messageText,
                    severity: "error",
                    autoHideDuration: 10000,
                })
            );

            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const {
    selectAll: selectAllEmployeSkills,
    selectById: selectEmployeSkillById,
} = employeSkillAdapter.getSelectors((state) => state.employe.employeSkills);

export const initialEmpContExtSearchParams = {
    initiliazed: false,
    typeDossier: 4,
    sites: [],
    indInactif: 0,
};

const initialState = {
    employeImage: {},
    employeQuickInfo: [],
    employesCriteria: employesCriteriaAdapter.getInitialState(),
    employeTypes: employeTypesAdapter.getInitialState(),
    immediateSuperiors: immediateSuperiorsAdapter.getInitialState(),
    employedBys: employedBysAdapter.getInitialState(),
    autresIdentifiant1: autresIdentifiant1Adapter.getInitialState(),
    personnesSelect: [],
    autresIdentifiant2: autresIdentifiant2Adapter.getInitialState(),
    autresIdentifiant3: autresIdentifiant3Adapter.getInitialState(),
    empContExtSearchParams: initialEmpContExtSearchParams,
    loadingEmployeTypes: false,
    loadingImmediateSuperiors: false,
    loadingEmployedBys: false,
    loadingEmployesCriteria: false,
    loadingAutresIdentifiant1: false,
    loadingAutresIdentifiant2: false,
    loadingAutresIdentifiant3: false,
    dossierEmploye: 1,
    dossierSearch: 1,
    noEmployes: noEmployesAdapter.getInitialState(),
    typesSite: typesSiteAdapter.getInitialState(),
    empContact: empContactAdapter.getInitialState(),
    employeStatuts: employeStatutsAdapter.getInitialState(),
    employeQuart: employeQuartAdapter.getInitialState(),
    superieursImmediatEmplSite:
        superieursImmediatEmplSiteAdapter.getInitialState(),
    affectationsCritereEmplSite:
        affectationsCritereEmplSiteAdapter.getInitialState(),
    categorieEmploye: categorieEmployeAdapter.getInitialState(),
    postesEmplSites: postesEmplSitesAdapter.getInitialState(),
    employes: employeSearchAdapter.getInitialState(),
    employeSkills: employeSkillAdapter.getInitialState(),
    employeEquipe: employeEquipeAdapter.getInitialState(),
    loadingNoEmployes: false,
    loadingEmployesSkills: false,
    loadingTypesSite: false,
    loadingEmpContact: false,
    loadingEmployeStatuts: false,
    loadingEmployeQuart: false,

    loadingEmployeSuperieursImmediatEmplSite: false,
    loadingEmployeAffectationsCritereEmplSite: false,
    loadingEmployeCategorieEmploye: false,
    loadingEmployePostesEmplSites: false,

    loadingEmployeEquipe: false,
    loadingEmployeCourriels: false,
    loadingPersonnes: false,
    loadingSearchEmploye: false,
    employeNew: {},
    employeAdd: {},
    status: "",
    updating: false,
};

const employesSlice = createSlice({
    name: "employe",
    initialState,
    reducers: {
        resetStatus(state, action) {
            state.status = initialState.status;
        },
        setEmployeSkillList(state, action) {
            employeSkillAdapter.setAll(state.employeSkills, action.payload);
        },
        updateOneEmployeSkill(state, action) {
            employeSkillAdapter.upsertOne(state.employeSkills, action.payload);
        },
        removeManyEmployeSkills(state, action) {
            employeSkillAdapter.removeMany(state.employeSkills, action.payload);
        },
        setDossierEmploye(state, action) {
            state.dossierEmploye = action.payload;
        },
        setDossierSearch(state, action) {
            state.dossierSearch = action.payload;
        },
        resetEmployesCriterias(state, action) {
            employesCriteriaAdapter.setAll(
                state.employesCriteria,
                employesCriteriaAdapter.getInitialState()
            );
        },
        setEmpContExtSearchParams(state, action) {
            state.empContExtSearchParams = action.payload;
        },
        resetEmployesSearchResult(state) {
            employeSearchAdapter.removeAll(state.employe);
        }
    },
    extraReducers: {
        [fetchEmployeImage.fulfilled]: (state, action) => {
            state.employeImage = action.payload;
        },
        [fetchEmployeSummary.fulfilled]: (state, action) => {
            state.employeSummary = action.payload;
        },

        [CriteriaSearch.pending]: (state, action) => {
            state.loadingEmployesCriteria = true;
        },
        [CriteriaSearch.fulfilled]: (state, action) => {
            employesCriteriaAdapter.setAll(
                state.employesCriteria,
                action.payload
            );
            state.loadingEmployesCriteria = false;
        },
        [fetchEmployeTypes.pending]: (state, action) => {
            state.loadingEmployeTypes = true;
        },
        [fetchEmployeTypes.fulfilled]: (state, action) => {
            employeTypesAdapter.setAll(state.employeTypes, action.payload);
            state.loadingEmployeTypes = false;
        },
        [fetchImmediateSuperiors.pending]: (state, action) => {
            state.loadingImmediateSuperiors = true;
        },
        [fetchImmediateSuperiors.fulfilled]: (state, action) => {
            immediateSuperiorsAdapter.setAll(
                state.immediateSuperiors,
                action.payload
            );
            state.loadingImmediateSuperiors = false;
        },
        [fetchEmployedBys.pending]: (state, action) => {
            state.loadingEmployedBys = true;
        },
        [fetchEmployedBys.fulfilled]: (state, action) => {
            employedBysAdapter.setAll(state.employedBys, action.payload);
            state.loadingEmployedBys = false;
        },
        [fetchEmployedBys.rejected]: (state, action) => {
            state.loadingEmployedBys = false;
        },
        [fetchEmployeByCode.fulfilled]: (state, action) => {
            employedBysAdapter.setAll(state.employedBys, action.payload);
        },
        [fetchAutresIdentifiant1.pending]: (state, action) => {
            state.loadingAutresIdentifiant1 = true;
        },
        [fetchAutresIdentifiant1.fulfilled]: (state, action) => {
            autresIdentifiant1Adapter.setAll(
                state.autresIdentifiant1,
                action.payload
            );
            state.loadingAutresIdentifiant1 = false;
        },
        [fetchPersonnesSelect.pending]: (state, action) => {
            state.loadingPersonnes = true;
        },
        [fetchPersonnesSelect.fulfilled]: (state, action) => {
            state.personnesSelect = [...action.payload];
            state.loadingPersonnes = false;
        },
        [fetchAutresIdentifiant2.pending]: (state, action) => {
            state.loadingAutresIdentifiant2 = true;
        },
        [fetchAutresIdentifiant2.fulfilled]: (state, action) => {
            autresIdentifiant2Adapter.setAll(
                state.autresIdentifiant2,
                action.payload
            );
            state.loadingAutresIdentifiant2 = false;
        },
        [fetchAutresIdentifiant3.pending]: (state, action) => {
            state.loadingAutresIdentifiant3 = true;
        },
        [fetchAutresIdentifiant3.fulfilled]: (state, action) => {
            autresIdentifiant3Adapter.setAll(
                state.autresIdentifiant3,
                action.payload
            );
            state.loadingAutresIdentifiant3 = false;
        },

        //Megha...............................................
        [fetchNoEmployes.pending]: (state, action) => {
            state.loadingNoEmployes = true;
        },
        [fetchNoEmployes.rejected]: (state, action) => {
            state.loadingNoEmployes = false;
        },
        [fetchNoEmployes.fulfilled]: (state, action) => {
            noEmployesAdapter.setAll(state.noEmployes, action.payload);
            state.loadingNoEmployes = false;
        },
        [fetchEmployes.pending]: (state, action) => {
            state.loadingSearchEmploye = true;
        },
        [fetchEmployes.rejected]: (state, action) => {
            state.loadingSearchEmploye = false;
        },
        [fetchEmployes.fulfilled]: (state, action) => {
            employeSearchAdapter.setAll(state.employes, action.payload);
            state.loadingSearchEmploye = false;
        },
        [fetchEmployeSkills.pending]: (state, action) => {
            state.loadingEmployesSkills = true;
        },
        [fetchEmployeSkills.rejected]: (state, action) => {
            state.loadingEmployesSkills = false;
        },
        [fetchEmployeSkills.fulfilled]: (state, action) => {
            employeSkillAdapter.setAll(state.employeSkills, [
                ...action.payload,
            ]);
            state.loadingEmployesSkills = false;
        },
        [updateEmployeSkill.fulfilled]: (state, action) => {
            state.updating = false;
        },
        [fetchTypesSite.pending]: (state, action) => {
            state.loadingTypesSite = true;
        },
        [fetchTypesSite.fulfilled]: (state, action) => {
            typesSiteAdapter.setAll(state.typesSite, action.payload);
            state.loadingTypesSite = false;
        },
        [fetchEmpCategorieContact.pending]: (state, action) => {
            state.loadingEmpContact = true;
        },
        [fetchEmpCategorieContact.fulfilled]: (state, action) => {
            empContactAdapter.setAll(state.empContact, action.payload);
            state.loadingEmpContact = false;
        },
        [fetchEmployeStatuts.pending]: (state, action) => {
            state.loadingEmployeStatuts = true;
        },
        [fetchEmployeStatuts.fulfilled]: (state, action) => {
            employeStatutsAdapter.setAll(state.employeStatuts, action.payload);
            state.loadingEmployeStatuts = false;
        },
        [fetchEmployeCourriels.pending]: (state, action) => {
            state.loadingEmployeCourriels = true;
        },
        [fetchEmployeCourriels.fulfilled]: (state, action) => {
            state.loadingEmployeCourriels = false;
        },
        [fetchEmployeCourriels.rejected]: (state, action) => {
            state.loadingEmployeCourriels = false;
        },
        [fetchEmployeQuart.pending]: (state, action) => {
            state.loadingEmployeQuart = true;
        },
        [fetchEmployeQuart.fulfilled]: (state, action) => {
            employeQuartAdapter.setAll(state.employeQuart, action.payload);
            state.loadingEmployeQuart = false;
        },

        [fetchSuperieursImmediatEmplSite.pending]: (state, action) => {
            state.loadingEmployeSuperieursImmediatEmplSite = true;
        },
        [fetchSuperieursImmediatEmplSite.fulfilled]: (state, action) => {
            superieursImmediatEmplSiteAdapter.setAll(
                state.superieursImmediatEmplSite,
                action.payload
            );
            state.loadingEmployeSuperieursImmediatEmplSite = false;
        },
        [fetchSuperieursImmediatEmplSite.rejected]: (state, action) => {
            state.loadingEmployeSuperieursImmediatEmplSite = false;
        },
        [fetchAffectationsCritereEmplSite.pending]: (state, action) => {
            state.loadingAffectationsCritereEmplSite = true;
        },
        [fetchAffectationsCritereEmplSite.fulfilled]: (state, action) => {
            affectationsCritereEmplSiteAdapter.setAll(
                state.affectationsCritereEmplSite,
                action.payload
            );
            state.loadingAffectationsCritereEmplSite = false;
        },
        [fetchAffectationsCritereEmplSite.rejected]: (state, action) => {
            state.loadingAffectationsCritereEmplSite = false;
        },
        [fetchCategorieEmploye.pending]: (state, action) => {
            state.loadingCategorieEmploye = true;
        },
        [fetchCategorieEmploye.fulfilled]: (state, action) => {
            categorieEmployeAdapter.setAll(
                state.categorieEmploye,
                action.payload
            );
            state.loadingCategorieEmploye = false;
        },
        [fetchCategorieEmploye.rejected]: (state, action) => {
            state.loadingCategorieEmploye = false;
        },
        [fetchPostesEmplSites.pending]: (state, action) => {
            state.loadingPostesEmplSites = true;
        },
        [fetchPostesEmplSites.fulfilled]: (state, action) => {
            postesEmplSitesAdapter.setAll(
                state.postesEmplSites,
                action.payload
            );
            state.loadingPostesEmplSites = false;
        },
        [fetchPostesEmplSites.rejected]: (state, action) => {
            state.loadingPostesEmplSites = false;
        },

        [fetchEmployeEquipe.pending]: (state, action) => {
            state.loadingEmployeEquipe = true;
        },
        [fetchEmployeEquipe.fulfilled]: (state, action) => {
            employeEquipeAdapter.setAll(state.employeEquipe, action.payload);
            state.loadingEmployeEquipe = false;
        },
        [fetchEmployeNew.fulfilled]: (state, action) => {
            state.employeNew = action.payload;
        },
        [postEmployeAdd.fulfilled]: (state, action) => {
            state.status = "succeded";
        },
        [postEmployeAdd.rejected]: (state, action) => {
            state.status = "failed";
        },
        [postEmployeAdd.pending]: (state, action) => {
            state.loadingEmployeAdd = "pending";
        },
    },
});

export const employeSelector = (state) => state.employe;
export const selectEmployeImage = createSelector(
    employeSelector,
    (item) => item.employeImage
);
export const selectEmployeSummary = createSelector(
    employeSelector,
    (item) => item.employeSummary
);
export const selectEmployeNew = createSelector(
    employeSelector,
    (item) => item.employeNew
);
export const selectEmployeAdd = createSelector(
    employeSelector,
    (item) => item.employeAdd
);
export const selectActiveEmployeType = createSelector(
    selectAllEmployeTypes,
    (empTypes) => empTypes.filter((empType) => !empType.inactive)
);

export const {
    resetStatus,
    setEmployeSkillList,
    updateOneEmployeSkill,
    removeManyEmployeSkills,
    setDossierEmploye,
    setDossierSearch,
    resetEmployesCriterias,
    setEmpContExtSearchParams,
    resetEmployesSearchResult
} = employesSlice.actions;

export default employesSlice.reducer;
