import { createSlice, createAsyncThunk, createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import axios from "Services/AxiosService";
import { showSnackbarMessage } from "store/slices/MessagesSystemSlice";
import {
  ADD_COMPETENCE_EMPLOYE,
  LOAD_MAJ_COMPETENCE,
  UPDATE_MAJ_COMPETENCE,
  LOAD_COMPETENCE_MAJ_RAPIDE,
  UPDATE_COMPETENCE_MAJ_RAPIDE,
  GET_POSTES_OCCUPES,
} from "store/UrlConstants";
import { paramsToQueryParam } from "store/UrlUtils";
import { updateOneEmployeSkill } from "./EmployeSlice";

// ----------------------------------------------------------------------------------------
// Employés et Contacts Externes - COMPETENCES
// ----------------------------------------------------------------------------------------
export const addCompetenceEmploye = createAsyncThunk(
  "CompetenceEmploye/Add",
  async ({ noEmploye, requestBody } = {}, thunkAPI) => {
    try {
      const result = await axios.put(ADD_COMPETENCE_EMPLOYE + paramsToQueryParam({ noEmploye }), requestBody);

      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      );

      return result.data;
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      );
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const majCompetenceAdpter = createEntityAdapter({ selectId: (entity) => entity.cleCompetenceEmploye });
export const fetchMajCompetence = createAsyncThunk(
  "CompetenceEmploye/LoadMajCompetence",
  async (noEmploye, thunkAPI) => {
    const resp = await axios.get(LOAD_MAJ_COMPETENCE, { params: { noEmploye: noEmploye } });
    return resp.data;
  }
);
export const majCompetencesSelector = (store) => store.competenceEmploye.majCompetences;
export const { selectAll: selectAllMajCompetences } = majCompetenceAdpter.getSelectors(majCompetencesSelector);
export const selectLoadingMajCompetences = createSelector(majCompetencesSelector, (state) => state.loading);

export const updateMajCompetence = createAsyncThunk(
  "CompetenceEmploye/UpdateMajCompetence",
  async (competenceObj, thunkAPI) => {
    try {
      const resp = await axios.put(UPDATE_MAJ_COMPETENCE, competenceObj);

      thunkAPI.dispatch(updateOneEmployeSkill(resp.data));

      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      );

      return resp.data;
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      );
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// ----------------------------------------------------------------------------------------
// MISE A JOUR RAPIDE DES COMPETENCES
// ----------------------------------------------------------------------------------------
const competenceMajRapideAdpter = createEntityAdapter({ selectId: (entity) => entity.codeCompetence });
export const fetchCompetenceMajRapide = createAsyncThunk(
  "CompetenceEmploye/LoadCompetenceMajRapide",
  async ({ noEmploye }) => {
    const resp = await axios.get(LOAD_COMPETENCE_MAJ_RAPIDE, { params: { noEmploye: noEmploye } });
    return resp.data;
  }
);
export const competenceMajRapideSelector = (store) => store.competenceEmploye.competenceMajRapide;
export const { selectAll: selectAllCompetenceMajRapide } =
  competenceMajRapideAdpter.getSelectors(competenceMajRapideSelector);
export const selectLoadingCompetenceMajRapide = createSelector(competenceMajRapideSelector, (state) => state.loading);

const postesOccupesAdpter = createEntityAdapter({});
export const getPostesOccupes = createAsyncThunk("CompetenceEmploye/GetPostesOccupes", async ({ noEmploye }) => {
  const resp = await axios.get(GET_POSTES_OCCUPES, { params: { noEmploye: noEmploye } });
  return resp.data;
});
export const postesOccupesSelector = (store) => store.competenceEmploye.postesOccupes;
export const { selectAll: selectAllPostesOccupes } = postesOccupesAdpter.getSelectors(postesOccupesSelector);
export const selectLoadingPostesOccupes = createSelector(postesOccupesSelector, (state) => state.loading);

export const updateCompetenceMajRapide = createAsyncThunk(
  "CompetenceEmploye/UpdateCompetenceMajRapide",
  async (competenceMajRapideUpdate, thunkAPI) => {
    try {
      const resp = await axios.post(UPDATE_COMPETENCE_MAJ_RAPIDE, competenceMajRapideUpdate);
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      );
      return resp.data;
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      );
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  majCompetences: majCompetenceAdpter.getInitialState({ loading: false }),
  competenceMajRapide: competenceMajRapideAdpter.getInitialState({ loading: false }),
  postesOccupes: postesOccupesAdpter.getInitialState({ loading: false }),
};

const competenceEmployeSlice = createSlice({
  name: "competenceEmploye",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchMajCompetence.pending]: (state) => {
      state.majCompetences.loading = true;
    },
    [fetchMajCompetence.rejected]: (state) => {
      state.majCompetences.loading = false;
    },
    [fetchMajCompetence.fulfilled]: (state, action) => {
      majCompetenceAdpter.setAll(state.majCompetences, action.payload);
      state.majCompetences.loading = false;
    },
    [updateMajCompetence.fulfilled]: (state, action) => {
      majCompetenceAdpter.upsertOne(state.majCompetences, action.payload)
    },
    [fetchCompetenceMajRapide.pending]: (state) => {
      state.competenceMajRapide.loading = true;
    },
    [fetchCompetenceMajRapide.rejected]: (state) => {
      state.competenceMajRapide.loading = false;
    },
    [fetchCompetenceMajRapide.fulfilled]: (state, action) => {
      competenceMajRapideAdpter.setAll(state.competenceMajRapide, action.payload);
      state.competenceMajRapide.loading = false;
    },
    [getPostesOccupes.pending]: (state) => {
      state.postesOccupes.loading = true;
    },
    [getPostesOccupes.rejected]: (state) => {
      state.postesOccupes.loading = false;
    },
    [getPostesOccupes.fulfilled]: (state, action) => {
      postesOccupesAdpter.setAll(state.postesOccupes, action.payload);
      state.postesOccupes.loading = false;
    },
  },
});

// eslint-disable-next-line no-empty-pattern
export const { } = competenceEmployeSlice.actions;

export default competenceEmployeSlice.reducer;
