import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import axios from "../../../Services/AxiosService";
import {
  GET_FAVORITES,
  GET_OBJECT_PUBLICATION_STATUSES,
  GET_OBJECT_SEARCH,
  GET_OBJECT_SPECIAL_STATUSES
} from "../../UrlConstants";

const objectsAdapter = createEntityAdapter();
const favObjectsAdapter = createEntityAdapter({ selectId: (entity) => entity.titre });
const publicatonStatusesAdapter = createEntityAdapter();
const specialStatusesAdapter = createEntityAdapter({ selectId: entity => entity.codeSysteme });

export const getKnowledgeObjectSearch = createAsyncThunk('kmObjectSearch/Search', async (params) => {
  const response = await axios.post(GET_OBJECT_SEARCH, params);
  return response.data;
});

export const getKnowledgeObjectCriteriaFav = createAsyncThunk('kmObjectSearch/KnowledgeObjectCriteriaFav', async () => {
  const response = await axios.get(GET_FAVORITES);
  return response.data;
});

export const getKnowledgeObjCriteriaPublicationStatuses = createAsyncThunk('kmObjectSearch/KnowledgeObjCriteriaPublicationStatuses', async () => {
  const response = await axios.get(GET_OBJECT_PUBLICATION_STATUSES);
  return response.data;
});

export const getKnowledgeObjCriteriaSpecialStatuses = createAsyncThunk('kmObjectSearch/KnowledgeObjCriteriaSpecialStatuses', async () => {
  const response = await axios.get(GET_OBJECT_SPECIAL_STATUSES);
  return response.data;
});

export const {
  selectAll: selectAllKnowledgeObjects,
  selectById: selectKnowledgeObjectById,
} = objectsAdapter.getSelectors((state) => state.kmObjectSearch.objectList);

export const {
  selectAll: selectAllKnowledgeObjectFavorites,
  selectById: selectKnowledgeObjectFavoriteById,
} = favObjectsAdapter.getSelectors((state) => state.kmObjectSearch.favObjects);

export const {
  selectAll: selectAllKnowledgeObjectPubStatuses,
  selectById: selectKnowledgeObjectPubStatusById,
} = publicatonStatusesAdapter.getSelectors((state) => state.kmObjectSearch.publicationStatuses);

export const {
  selectAll: selectAllKnowledgeObjectSpecialStatuses,
  selectById: selectKnowledgeObjectSpecialStatusById,
} = specialStatusesAdapter.getSelectors((state) => state.kmObjectSearch.specialStatuses);

const initialState = {
  objectList: objectsAdapter.getInitialState(),
  searchCount: 0,
  searchParams: {
    count: true,
    page: 1,
    nbParPageId: 3,
    nbByPage: 15,
    sort: 1,
    criterias: {
      id: 0,
      idPere: 0,
      text: "",
      title: "",
      isEndorsed: false,
      isNotEndorsed: false,
      isExpired: false,
      isArchived: false,
      favorites: false,
      evaluation0: false,
      evaluation1: false,
      evaluation2: false,
      evaluation3: false,
      evaluation4: false,
      evaluation5: false,
      objectFavorites: [],
      types: [],
      authors: [],
      responsables: [],
      specialStatuses: [],
      sites: [],
      consultationStateMe: {
        consultationUnit: 1,
        consultationValue: 0
      },
      consultationStateAll: {
        consultationUnit: 1,
        consultationValue: 0
      }
    }
  },
  favObjects: favObjectsAdapter.getInitialState(),
  publicationStatuses: favObjectsAdapter.getInitialState(),
  specialStatuses: specialStatusesAdapter.getInitialState(),
  view: 'grid',
};

const KmObjectSearchSlice = createSlice({
  name: 'kmObjectSearch',
  initialState: initialState,
  reducers: {
    setSearchParams(state, action) {
      state.searchParams = action.payload;
    },
    emptyObjectList(state, action) {
      objectsAdapter.removeAll(state.objectList);
    },
    initSearchParamsSites(state, action) {
      let favCodes = [];
      action.payload.forEach(function (site) {
        if (site.selected) {
          favCodes.push(site.code);
        }
      });
      state.searchParams.criterias.sites = favCodes;
    },
    updateOneObjectList(state, action) {
      objectsAdapter.upsertOne(state.objectList, action.payload)
    },
    updateOCListItemField(state, action) {
      state.objectList.entities[action.payload.id][action.payload.field] = action.payload.value;
    },
    removeOneObjectList(state, action) {
      objectsAdapter.removeOne(state.objectList, action.payload);
    },
    removeManyObjectList(state, action) {
      objectsAdapter.removeMany(state.objectList, action.payload);
    },
    setOCView(state, action) {
      state.view = action.payload;
    },
  },
  extraReducers: {
    [getKnowledgeObjectSearch.fulfilled]: (state, action) => {
      objectsAdapter.setAll(state.objectList, action.payload.results);
      if (action.payload.count)
        state.searchCount = action.payload.count;
    },
    [getKnowledgeObjectCriteriaFav.fulfilled]: (state, action) => {
      favObjectsAdapter.setAll(state.favObjects, action.payload);
    },
    [getKnowledgeObjCriteriaPublicationStatuses.fulfilled]: (state, action) => {
      publicatonStatusesAdapter.setAll(state.publicationStatuses, action.payload);
    },
    [getKnowledgeObjCriteriaSpecialStatuses.fulfilled]: (state, action) => {
      specialStatusesAdapter.setAll(state.specialStatuses, action.payload);
    }
  }
});

export const {
  setSearchParams,
  emptyObjectList,
  initSearchParamsSites,
  updateOneObjectList,
  updateOCListItemField,
  removeOneObjectList,
  removeManyObjectList,
  setOCView, F
} = KmObjectSearchSlice.actions;

export const objectSearchSliceSelector = state => state.kmObjectSearch;

export const selectObjectSearchCount = createSelector(objectSearchSliceSelector, result => result.searchCount);

export default KmObjectSearchSlice.reducer;