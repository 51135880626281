import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'Services/AxiosService';
import { listToQueryParam, paramsToQueryParam } from 'store/UrlUtils';
import { FEL_FETCH_VILLES, FEL_FETCH_TYPE_PERSONNES, FEL_FETCH_SUPERIEURS_IMMEDIATS, FEL_FETCH_POSTES } from 'store/UrlConstants';


// Villes Adapter
const villesAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.value.localeCompare(b.value),
});

export const fetchVilles = createAsyncThunk('FormationEnLigne/Criterias/Villes', async ({action, sites}) => {
  const response = await axios.get(FEL_FETCH_VILLES + listToQueryParam("sites", sites) + paramsToQueryParam({fromAction: action}).replace('?','&') );
  return response.data;
});
export const {
  selectAll: selectAllVilles,
  selectById: selectVillesById,
} = villesAdapter.getSelectors((state) => state.felCriteria.villes);


// Type de Personne Adapter
const typeDePersonnesAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.value.localeCompare(b.value),
});

export const fetchTypeDePersonnes = createAsyncThunk("FormationEnLigne/Criterias/TypeDePersonne", async () => {
  const response = await axios.get(FEL_FETCH_TYPE_PERSONNES);
  return response.data;
});

export const {
  selectAll: selectAllTypeDePersonnes,
  selectById: selectTypeDePersonneById,
} = typeDePersonnesAdapter.getSelectors((state) => state.felCriteria.typeDePersonnes);


// Superieur Immediat Adapter
const superieurImmediatAdapter = createEntityAdapter({
  // sortComparer: (a, b) => a.value.localeCompare(b.value),
});

export const fetchSuperieursImmediats = createAsyncThunk("FormationEnLigne/Criterias/SuperieursImmediats", async () => {
  const response = await axios.get(FEL_FETCH_SUPERIEURS_IMMEDIATS);
  return response.data;
});

export const {
  selectAll: selectAllSuperieursImmediats,
  selectById: selectSuperieurImmediatById,
} = superieurImmediatAdapter.getSelectors((state) => state.felCriteria.superieursImmediats);


// Postes Adapter
const postesAdapter = createEntityAdapter({
  // Les valeurs nulles dans la base de données font une erreur de classement.
  // Programmation défensive pour éviter les valeurs nulle:
  // sortComparer: (a, b) => {
  //   if (!a.value) {
  //     return -1;
  //   } else if (!b.value) {
  //     return +1;
  //   } else {
  //     a.value.localeCompare(b.value);
  //   }
  // }
});

export const fetchPostes = createAsyncThunk("FormationEnLigne/Criterias/Postes", async () => {
  const response = await axios.get(FEL_FETCH_POSTES);
  return response.data;
});

export const {
  selectAll: selectAllPostes,
  selectById: selectPosteById,
} = postesAdapter.getSelectors((state) => state.felCriteria.postes);


const initialState =
{
  villes: villesAdapter.getInitialState(),
  typeDePersonnes: typeDePersonnesAdapter.getInitialState(),
  superieursImmediats: superieurImmediatAdapter.getInitialState(),
  postes: postesAdapter.getInitialState(),
}

const felCriteriaSlice = createSlice({
  name: 'felCriteria',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchVilles.fulfilled]: (state, action) => {
      villesAdapter.setAll(state.villes, action.payload);
    },
    [fetchTypeDePersonnes.fulfilled]: (state, action) => {
      typeDePersonnesAdapter.setAll(state.typeDePersonnes, action.payload)
    },
    [fetchSuperieursImmediats.fulfilled]: (state, action) => {
      superieurImmediatAdapter.setAll(state.superieursImmediats, action.payload)
    },
    [fetchPostes.fulfilled]: (state, action) => {
      postesAdapter.setAll(state.postes, action.payload)
    }
  }
});

export default felCriteriaSlice.reducer;