import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import axios from "Services/AxiosService";
import {
  GET_ACTION,
  POST_SEARCH_ACTIONS,
  POST_UPDATE_ACTIONS,
} from "store/UrlConstants";
import { showSnackbarMessage } from "store/slices/MessagesSystemSlice";

const searchActionsAdapter = createEntityAdapter({
  selectId: (entity) => entity.projetDetailId,
});

export const getActionList = createAsyncThunk(
  "Planification/SearchActions/Get",
  async (filterData) => {
    const result = await axios.post(POST_SEARCH_ACTIONS, filterData);
    return result.data;
  }
);

export const getAction = createAsyncThunk(
  "Planification/Action/Get",
  async (projetDetailId) => {
    const result = await axios.get(GET_ACTION, {
      params: { projetDetailId: projetDetailId },
    });
    return result.data;
  }
);

export const updateAction = createAsyncThunk(
  "Planification/Action/Update",
  async (action, thunkAPI) => {
    try {
      const result = await axios.post(POST_UPDATE_ACTIONS, action);
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      );
      return result.data;
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const searchActionSelector = (store) => store.planification.actionList;

export const { selectAll: selectAllActions, selectById: selectActionsById } =
  searchActionsAdapter.getSelectors(searchActionSelector);

export const selectLoadingSearchAction = createSelector(
  searchActionSelector,
  (state) => state.loading
);

const InitialState = {
  actionList: searchActionsAdapter.getInitialState({ loading: false }),
};

const planificationSlice = createSlice({
  name: "planification",
  initialState: InitialState,
  reducers: {},
  extraReducers: {
    [getActionList.pending]: (state, action) => {
      state.actionList.loading = true;
    },
    [getActionList.rejected]: (state, action) => {
      state.actionList.loading = false;
    },
    [getActionList.fulfilled]: (state, action) => {
      searchActionsAdapter.setAll(state.actionList, action.payload);
      state.actionList.loading = false;
    },    
  },
});

// eslint-disable-next-line no-empty-pattern
export const {} = planificationSlice.actions;

export default planificationSlice.reducer;
