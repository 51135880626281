import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import axios from 'Services/AxiosService';
import { NC_SEARCH_SIMPLE } from 'store/UrlConstants';

const nonConformiteSearchSimpleAdapter = createEntityAdapter({
  selectId: entity => entity.code
});
const _initialState = nonConformiteSearchSimpleAdapter.getInitialState(
  { status: 'idle' }
);

export const nonConformiteSearchSimple = createAsyncThunk('NonConformites/Search/Simple', async (params, thunkAPI) => {
  const response = await axios.post(NC_SEARCH_SIMPLE, params);
  return response.data;
});

const initialState = {
  ..._initialState,
  lastSearchDerrogationForm: null,
  lastSearchRenseignementsForm: null,
  searchDetail: {
    advancedChips: null,
    code: null,
    description: null,
    endDate: null,
    globalSearchTerm: '',
    isClosed: null,
    isInitiated: false,
    isReporting: null,
    noBonCommande: null,
    noBonTravail: null,
    noDessin: null,
    noLot: null,
    noSerie: null,
    otherIdentifiant: null,
    reponse: null,
    recommandationActions: null,
    startDate: null,
    sites: null,
  },
  renseignementsResultats: [],
  loadingInProgress: false
}

const nonConformiteSearchSlice = createSlice({
  name: 'nonConformiteSearch',
  initialState,
  reducers: {
    setSearchDetail(state, action) {
      state.searchDetail = action.payload
    },
    resetSearchDetail(state) {
      state.searchDetail = initialState.searchDetail
    },
    setLastSearchDerrogationForm(state, action) {
      state.lastSearchDerrogationForm = action.payload
    },
    setLastSearchRenseignementsForm(state, action) {
      state.lastSearchRenseignementsForm = action.payload
    },
  },
  extraReducers: {
    [nonConformiteSearchSimple.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.loadingInProgress = false
      nonConformiteSearchSimpleAdapter.setAll(state, action.payload);
    },
    [nonConformiteSearchSimple.pending]: (state, action) => {
      state.loadingInProgress = true
      state.status = 'loading';
    },
    [nonConformiteSearchSimple.rejected]: (state, action) => {
      state.status = 'failed';
      state.loadingInProgress = false
    },
  },
});

export const {
  selectAll: selectAllResults,
  selectById: selectResultById,
} = nonConformiteSearchSimpleAdapter.getSelectors((state) => state.nonConformiteSearch);

export const {
  setSearchDetail,
  resetSearchDetail,
  setLastSearchDerrogationForm,
  setLastSearchRenseignementsForm
} = nonConformiteSearchSlice.actions;

export default nonConformiteSearchSlice.reducer;