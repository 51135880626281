import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "Services/AxiosService";
import { listToQueryParam, paramsToQueryParam } from "store/UrlUtils";
import {
    ADD_AUDITS_INFO_GEN,
    DELETE_AUDITS_INFO_GEN,
    FETCH_AUDITS_CRITERES_MULTI_INFOS,
    FETCH_AUDITS_CRITERES_MULTI_TYPES,
    GET_AUDITS_INFORMATION_VISEE,
    GET_AUDITS_INFO_GEN,
    GET_AUDITS_PARTIE_PRENANTE,
    LOAD_AUDITS_BANQUE_QUESTIONS,
    LOAD_AUDITS_QUESTIONS,
    LOAD_AUDITS_SAISIE_QUESTIONS,
    LOAD_AUDIT_ARTICLES,
    LOAD_AUDIT_ELEMENT_AUDITABLES,
    LOAD_AUDIT_EQUIPE_PROVENANCES,
    LOAD_AUDIT_EQUIPE_ROLES,
    LOAD_AUDIT_NORMES,
    LOAD_ENTITE_AUDITEES,
    LOAD_TYPES_AUDITS,
    SEND_AUDITS_INSPECTION_SEARCH_DETAIL,
    UPDATE_AUDITS_INFORMATION_VISEE,
    UPDATE_AUDITS_INFO_GEN,
    UPDATE_AUDITS_PARTIE_PRENANTE,
    UPDATE_AUDITS_QUESTIONS,
    LOAD_AUDITS_QUESTION_PERSONNES,
    UPDATE_AUDITS_QUESTION_PERSONNES,
    ADD_AUDITS_BANQUE_QUESTION,
    UPDATE_AUDITS_BANQUE_QUESTION,
    DELETE_AUDITS_BANQUE_QUESTION,
    ADD_AUDITS_BANQUE_QUESTION_VERSION,
    UPDATE_AUDITS_BANQUE_QUESTION_VERSION,
    DELETE_AUDITS_BANQUE_QUESTION_VERSION,
    ADD_AUDITS_BANQUE_ARTICLE,
    DELETE_AUDITS_BANQUE_ARTICLE,
    UPDATE_AUDITS_BANQUE_ARTICLE,
    LOAD_AUDITS_PLANIFICATION,
    UPDATE_AUDITS_PLANIFICATION,
    LOAD_AUDITS_CALENDRIERS,
    NEW_AUDITS_CALENDRIER,
    ADD_AUDITS_CALENDRIER,
    UPDATE_AUDITS_CALENDRIER,
    DELETE_AUDITS_CALENDRIER
} from "store/UrlConstants";
import { showSnackbarMessage } from "./MessagesSystemSlice";

export const fetchCriteresMultiTypes = createAsyncThunk(
    FETCH_AUDITS_CRITERES_MULTI_TYPES,
    async (typeAffiche = "") => {
        const response = await axios.get(FETCH_AUDITS_CRITERES_MULTI_TYPES + "?typeAffiche=" + typeAffiche);
        return response.data;
    }
);

export const fetchCriteresMultiInfos = createAsyncThunk(
    FETCH_AUDITS_CRITERES_MULTI_INFOS,
    async (codeSite) => {
        const response = await axios.get(
            FETCH_AUDITS_CRITERES_MULTI_INFOS +
                listToQueryParam("codeSites", codeSite)
        );
        return response.data;
    }
);

export const sendSearchDetail = createAsyncThunk(
    SEND_AUDITS_INSPECTION_SEARCH_DETAIL,
    async (searchDetail = {}, thunkAPI) => {
        const response = await axios.post(
            SEND_AUDITS_INSPECTION_SEARCH_DETAIL,
            searchDetail
        );
        return response.data;
    }
);

export const loadTypesAudits = createAsyncThunk(
    LOAD_TYPES_AUDITS,
    async (codeSites) => {
        const response = await axios.get(
            LOAD_TYPES_AUDITS +
                listToQueryParam("codeSites", codeSites)
        );
        return response.data;
    }
);

export const loadEntiteAuditee = createAsyncThunk(
    LOAD_ENTITE_AUDITEES,
    async (codeSites) => {
        const response = await axios.get(
            LOAD_ENTITE_AUDITEES +
                listToQueryParam("codeSites", codeSites)
        );
        return response.data;
    }
);

export const getAuditsInfoGen = createAsyncThunk(
    GET_AUDITS_INFO_GEN,
    async (params) => {
        const response = await axios.get(
            GET_AUDITS_INFO_GEN + paramsToQueryParam(params)
        );
        return response.data;
    }
);
export const addAuditsInfoGen = createAsyncThunk(ADD_AUDITS_INFO_GEN, async (data, thunkAPI) => {
    try {
        const response = await axios.post(ADD_AUDITS_INFO_GEN, data);
        thunkAPI.dispatch(
            showSnackbarMessage({
            dba: "objetsLangues2",
            messageId: "web_confirmation_enregistrement",
            severity: "success",
            })
        );
        return response.data;
        } catch (error) {
        thunkAPI.dispatch(
            showSnackbarMessage({
            message: error.response.data.messageText,
            severity: "error",
            autoHideDuration: 10000,
            })
        );
    }
});
export const updateAuditsInfoGen = createAsyncThunk(UPDATE_AUDITS_INFO_GEN, async (data, thunkAPI) => {
    try {
      const response = await axios.post(UPDATE_AUDITS_INFO_GEN, data);
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      );
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      );
  
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
export const deleteAuditsInfoGen = createAsyncThunk(DELETE_AUDITS_INFO_GEN, async (params, thunkAPI) => {
    try {
      const { data } = await axios.delete(DELETE_AUDITS_INFO_GEN + paramsToQueryParam(params));
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "information_supprimee",
          severity: "success",
        })
      );
      return data;
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      );
    }
});
export const loadAuditEquipeProvenances = createAsyncThunk(
    LOAD_AUDIT_EQUIPE_PROVENANCES,
    async () => {
        const response = await axios.get(LOAD_AUDIT_EQUIPE_PROVENANCES);
        return response.data;
    }
);
export const loadAuditEquipeRoles = createAsyncThunk(
    LOAD_AUDIT_EQUIPE_ROLES,
    async (codeSites) => {
        const response = await axios.get(
            LOAD_AUDIT_EQUIPE_ROLES +
                listToQueryParam("codeSites", codeSites)
        );
        return response.data;
    }
);
export const getAuditsPartiePrenante = createAsyncThunk(
    GET_AUDITS_PARTIE_PRENANTE,
    async (params) => {
        const response = await axios.get(
            GET_AUDITS_PARTIE_PRENANTE + paramsToQueryParam(params)
        );
        return response.data;
    }
);
export const updateAuditsPartiePrenante = createAsyncThunk(UPDATE_AUDITS_PARTIE_PRENANTE, async (data, thunkAPI) => {
    try {
      const response = await axios.post(UPDATE_AUDITS_PARTIE_PRENANTE, data);
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      );
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      );
  
      return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const getAuditsInformationVisee = createAsyncThunk(
    GET_AUDITS_INFORMATION_VISEE,
    async (params) => {
        const response = await axios.get(
            GET_AUDITS_INFORMATION_VISEE + paramsToQueryParam(params)
        );
        return response.data;
    }
);
export const updateAuditsInformationVisee = createAsyncThunk(UPDATE_AUDITS_INFORMATION_VISEE, async (data, thunkAPI) => {
    try {
      const response = await axios.post(UPDATE_AUDITS_INFORMATION_VISEE, data);
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      );
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      );
  
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
export const loadAuditNormes = createAsyncThunk(
    LOAD_AUDIT_NORMES,
    async () => {
        const response = await axios.get(LOAD_AUDIT_NORMES);
        return response.data;
    }
);
export const loadAuditArticles = createAsyncThunk(
    LOAD_AUDIT_ARTICLES,
    async (codeAudit) => {
        const _params = {}
        if(codeAudit){
            _params.codeAudit = codeAudit
        }
        const response = await axios.get(
            LOAD_AUDIT_ARTICLES + paramsToQueryParam(_params)
        );
        return response.data;
    }
);
export const loadAuditElementAuditables = createAsyncThunk(
    LOAD_AUDIT_ELEMENT_AUDITABLES,
    async (params) => {
        const response = await axios.get(
            LOAD_AUDIT_ELEMENT_AUDITABLES + paramsToQueryParam(params)
        );
        return response.data;
    }
);

export const loadAuditsQuestions = createAsyncThunk(
    LOAD_AUDITS_QUESTIONS,
    async (params) => {
        const response = await axios.get(
            LOAD_AUDITS_QUESTIONS + paramsToQueryParam(params)
        );
        return response.data;
    }
);
export const updateAuditsQuestions = createAsyncThunk(UPDATE_AUDITS_QUESTIONS, async (data, thunkAPI) => {
    try {
        const {auditsElementsId, postBody} = data
        const response = await axios.post(UPDATE_AUDITS_QUESTIONS + paramsToQueryParam({auditsElementsId: auditsElementsId}), postBody);
        thunkAPI.dispatch(
            showSnackbarMessage({
            dba: "objetsLangues2",
            messageId: "web_confirmation_enregistrement",
            severity: "success",
            })
        );
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      );
  
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
export const loadAuditsSaisieQuestions = createAsyncThunk(
    LOAD_AUDITS_SAISIE_QUESTIONS,
    async (params) => {
        const response = await axios.get(
            LOAD_AUDITS_SAISIE_QUESTIONS + paramsToQueryParam(params)
        );
        return response.data;
    }
);
export const loadAuditsBanqueQuestions = createAsyncThunk(
    LOAD_AUDITS_BANQUE_QUESTIONS,
    async () => {
        const response = await axios.get(LOAD_AUDITS_BANQUE_QUESTIONS);
        return response.data;
    }
);
export const addAuditsBanqueQuestion = createAsyncThunk(ADD_AUDITS_BANQUE_QUESTION, async (data, thunkAPI) => {
    try {
        const response = await axios.post(ADD_AUDITS_BANQUE_QUESTION, data);
        thunkAPI.dispatch(
            showSnackbarMessage({
            dba: "objetsLangues2",
            messageId: "web_confirmation_enregistrement",
            severity: "success",
            })
        );
        return response.data;
        } catch (error) {
        thunkAPI.dispatch(
            showSnackbarMessage({
            message: error.response.data.messageText,
            severity: "error",
            autoHideDuration: 10000,
            })
        );
    }
});
export const updateAuditsBanqueQuestion = createAsyncThunk(UPDATE_AUDITS_BANQUE_QUESTION, async (data, thunkAPI) => {
    try {
      const response = await axios.post(UPDATE_AUDITS_BANQUE_QUESTION, data);
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      );
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      );
  
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
export const deleteAuditsBanqueQuestion = createAsyncThunk(DELETE_AUDITS_BANQUE_QUESTION, async (params, thunkAPI) => {
    try {
      const { data } = await axios.get(DELETE_AUDITS_BANQUE_QUESTION + paramsToQueryParam(params));
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "information_supprimee",
          severity: "success",
        })
      );
      return data;
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      );
    }
});

export const addAuditsBanqueQuestionVersion = createAsyncThunk(ADD_AUDITS_BANQUE_QUESTION_VERSION, async (data, thunkAPI) => {
    try {
        const response = await axios.post(ADD_AUDITS_BANQUE_QUESTION_VERSION, data);
        thunkAPI.dispatch(
            showSnackbarMessage({
            dba: "objetsLangues2",
            messageId: "web_confirmation_enregistrement",
            severity: "success",
            })
        );
        return response.data;
        } catch (error) {
        thunkAPI.dispatch(
            showSnackbarMessage({
            message: error.response.data.messageText,
            severity: "error",
            autoHideDuration: 10000,
            })
        );
    }
});
export const updateAuditsBanqueQuestionVersion = createAsyncThunk(UPDATE_AUDITS_BANQUE_QUESTION_VERSION, async (data, thunkAPI) => {
    try {
      const response = await axios.post(UPDATE_AUDITS_BANQUE_QUESTION_VERSION, data);
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      );
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      );
  
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
export const deleteAuditsBanqueQuestionVersion = createAsyncThunk(DELETE_AUDITS_BANQUE_QUESTION_VERSION, async (params, thunkAPI) => {
    try {
      const { data } = await axios.get(DELETE_AUDITS_BANQUE_QUESTION_VERSION + paramsToQueryParam(params));
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "information_supprimee",
          severity: "success",
        })
      );
      return data;
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      );
    }
});

export const addAuditsBanqueArticle = createAsyncThunk(ADD_AUDITS_BANQUE_ARTICLE, async (data, thunkAPI) => {
    try {
        const response = await axios.post(ADD_AUDITS_BANQUE_ARTICLE, data);
        thunkAPI.dispatch(
            showSnackbarMessage({
            dba: "objetsLangues2",
            messageId: "web_confirmation_enregistrement",
            severity: "success",
            })
        );
        return response.data;
        } catch (error) {
        thunkAPI.dispatch(
            showSnackbarMessage({
            message: error.response.data.messageText,
            severity: "error",
            autoHideDuration: 10000,
            })
        );
    }
});
export const updateAuditsBanqueArticle = createAsyncThunk(UPDATE_AUDITS_BANQUE_ARTICLE, async (data, thunkAPI) => {
    try {
      const response = await axios.get(UPDATE_AUDITS_BANQUE_ARTICLE + paramsToQueryParam(data));
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      );
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      );
  
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
export const deleteAuditsBanqueArticle = createAsyncThunk(DELETE_AUDITS_BANQUE_ARTICLE, async ({
    payload, showSuccssAlert = true
}, thunkAPI) => {
    try {
      const { data } = await axios.post(DELETE_AUDITS_BANQUE_ARTICLE,payload);
      if(showSuccssAlert){
        thunkAPI.dispatch(
            showSnackbarMessage({
              dba: "objetsLangues2",
              messageId: "information_supprimee",
              severity: "success",
            })
          );
      }
      return data;
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      );
    }
});

export const loadAuditsQuestionPersonnes = createAsyncThunk(
    LOAD_AUDITS_QUESTION_PERSONNES,
    async (params) => {
        const response = await axios.get(
            LOAD_AUDITS_QUESTION_PERSONNES + paramsToQueryParam(params)
        );
        return response.data;
    }
);

export const updateAuditsQuestionPersonnes = createAsyncThunk(UPDATE_AUDITS_QUESTION_PERSONNES, async (data, thunkAPI) => {
    try {
      const response = await axios.post(UPDATE_AUDITS_QUESTION_PERSONNES, data);
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      );
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      );
  
      return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const loadAuditsPlanification = createAsyncThunk(
    LOAD_AUDITS_PLANIFICATION,
    async (params) => {
        const response = await axios.get(
            LOAD_AUDITS_PLANIFICATION + paramsToQueryParam(params)
        );
        return response.data;
    }
);

export const updateAuditsPlanification = createAsyncThunk(UPDATE_AUDITS_PLANIFICATION, async (data, thunkAPI) => {
    try {
      const response = await axios.post(UPDATE_AUDITS_PLANIFICATION, data);
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      );
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      );
  
      return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const loadAuditsCalendriers = createAsyncThunk(
    LOAD_AUDITS_CALENDRIERS,
    async (params) => {
        const response = await axios.get(
            LOAD_AUDITS_CALENDRIERS + paramsToQueryParam(params)
        );
        return response.data;
    }
);
export const loadNewAuditsCalendrier = createAsyncThunk(
    NEW_AUDITS_CALENDRIER,
    async (params) => {
        const response = await axios.get(
            NEW_AUDITS_CALENDRIER + paramsToQueryParam(params)
        );
        return response.data;
    }
);
export const addAuditsCalendrier = createAsyncThunk(ADD_AUDITS_CALENDRIER, async (data, thunkAPI) => {
    try {
        const response = await axios.post(ADD_AUDITS_CALENDRIER, data);
        thunkAPI.dispatch(
            showSnackbarMessage({
            dba: "objetsLangues2",
            messageId: "web_confirmation_enregistrement",
            severity: "success",
            })
        );
        return response.data;
    } catch (error) {
        thunkAPI.dispatch(
            showSnackbarMessage({
            message: error.response.data.messageText,
            severity: "error",
            autoHideDuration: 10000,
            })
        );
    }
});
export const updateAuditsCalendrier = createAsyncThunk(UPDATE_AUDITS_CALENDRIER, async (data, thunkAPI) => {
    try {
      const response = await axios.post(UPDATE_AUDITS_CALENDRIER, data);
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      );
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      );
  
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
export const deleteAuditsCalendrier = createAsyncThunk(DELETE_AUDITS_CALENDRIER, async (params, thunkAPI) => {
    try {
        const { data } = await axios.delete(DELETE_AUDITS_CALENDRIER + paramsToQueryParam(params));
        thunkAPI.dispatch(
            showSnackbarMessage({
                dba: "objetsLangues2",
                messageId: "information_supprimee",
                severity: "success",
            })
        );
        return data;
    } catch (error) {
        thunkAPI.dispatch(
            showSnackbarMessage({
                message: error.response.data.messageText,
                severity: "error",
                autoHideDuration: 10000,
            })
        );
    }
});

const initialSearchDetail = {
    titre: null,
    autreIdentifiant: null,
    dateDebut: null,
    dateFin: null,
    debutReel: null,
    finReelle: null,
    auditFournisseur: null,
    indComplete: 0,
    description: null,
    codeProcede: null,
    procede: null,
    codeUnite: null,
    unite: null,
    codeComposante: null,
    composante: null,
    codePtEchantillon: null,
    ptEchantillon: null,
};

const initialState = {
    criteresMultiTypes: [],
    criteresMultiInfos: [],
    loadInProgress: false,
    sendingSearchDetail: false,
    searchDetail: initialSearchDetail,
    criteres: {
        selectedCriteresType: null,
        selectedCriteresInfos: [],
    },
    typesAudits: [],
    entiteAuditees: [],
    equipeProvenances: [],
    equipeRoles: [],
    auditNormes: [],
    auditArticles: [],
    auditElementAuditables: [],
    auditsInformationVisee: {},
    auditsQuestions: [],
    auditsSaisieQuestions: [],
    selectedBanqueQuestionLang: null,
};

const inspectionSlice = createSlice({
    name: "inspection",
    initialState,
    reducers: {
        setSearchDetail(state, action) {
            state.searchDetail = { ...action.payload };
        },
        setCriteres(state, action) {
            state.criteres = { ...action.payload };
        },
        resetSearchDetail(state) {
            state.searchDetail = initialSearchDetail;
        },
        setSelectedBanqueQuestionLang(state, action){
            state.selectedBanqueQuestionLang = action.payload
        }
    },
    extraReducers: {
        [fetchCriteresMultiTypes.rejected]: (state, action) => {
            state.loadInProgress = false;
        },
        [fetchCriteresMultiTypes.pending]: (state, action) => {
            state.loadInProgress = true;
        },
        [fetchCriteresMultiTypes.fulfilled]: (state, action) => {
            state.criteresMultiTypes = [...action.payload];
            state.loadInProgress = false;
        },
        [fetchCriteresMultiInfos.rejected]: (state, action) => {
            state.loadInProgress = false;
        },
        [fetchCriteresMultiInfos.pending]: (state, action) => {
            state.loadInProgress = true;
        },
        [fetchCriteresMultiInfos.fulfilled]: (state, action) => {
            state.criteresMultiInfos = [...action.payload];
            state.loadInProgress = false;
        },
        [sendSearchDetail.rejected]: (state, action) => {
            state.sendingSearchDetail = false;
            state.inspectionResultats = [];
        },
        [sendSearchDetail.pending]: (state, action) => {
            state.sendingSearchDetail = true;
        },
        [sendSearchDetail.fulfilled]: (state, action) => {
            state.inspectionResultats = [...action.payload];
            state.sendingSearchDetail = false;
        },
        [getAuditsInfoGen.rejected]: (state, action) => {
            state.loadInProgress = false;
        },
        [getAuditsInfoGen.pending]: (state, action) => {
            state.loadInProgress = true;
        },
        [getAuditsInfoGen.fulfilled]: (state, action) => {
            state.loadInProgress = false;
        },
        [loadTypesAudits.rejected]: (state, action) => {
            state.loadInProgress = false;
            state.typesAudits = [];
        },
        [loadTypesAudits.pending]: (state, action) => {
            state.loadInProgress = true;
        },
        [loadTypesAudits.fulfilled]: (state, action) => {
            state.typesAudits = [...action.payload];
            state.loadInProgress = false;
        },
        [loadEntiteAuditee.rejected]: (state, action) => {
            state.loadInProgress = false;
            state.entiteAuditees = [];
        },
        [loadEntiteAuditee.pending]: (state, action) => {
            state.loadInProgress = true;
        },
        [loadEntiteAuditee.fulfilled]: (state, action) => {
            state.entiteAuditees = [...action.payload];
            state.loadInProgress = false;
        },
        [addAuditsInfoGen.fulfilled]: (state, action) => {
            state.loadInProgress = false
        },
        [addAuditsInfoGen.pending]: (state, action) => {
            state.loadInProgress = true
        },
        [addAuditsInfoGen.rejected]: (state, action) => {
            state.loadInProgress = false
        },
        [deleteAuditsInfoGen.fulfilled]: (state, action) => {
            state.loadInProgress = false
        },
        [deleteAuditsInfoGen.pending]: (state, action) => {
            state.loadInProgress = true
        },
        [deleteAuditsInfoGen.rejected]: (state, action) => {
            state.loadInProgress = false
        },
        [updateAuditsInfoGen.fulfilled]: (state, action) => {
            state.loadInProgress = false
        },
        [updateAuditsInfoGen.pending]: (state, action) => {
            state.loadInProgress = true
        },
        [updateAuditsInfoGen.rejected]: (state, action) => {
            state.loadInProgress = false
        },
        [loadAuditEquipeProvenances.rejected]: (state, action) => {
            state.loadInProgress = false;
            state.equipeProvenances = [];
        },
        [loadAuditEquipeProvenances.pending]: (state, action) => {
            state.loadInProgress = true;
        },
        [loadAuditEquipeProvenances.fulfilled]: (state, action) => {
            state.equipeProvenances = [...action.payload];
            state.loadInProgress = false;
        },
        [loadAuditEquipeRoles.rejected]: (state, action) => {
            state.loadInProgress = false;
            state.equipeRoles = [];
        },
        [loadAuditEquipeRoles.pending]: (state, action) => {
            state.loadInProgress = true;
        },
        [loadAuditEquipeRoles.fulfilled]: (state, action) => {
            state.equipeRoles = [...action.payload];
            state.loadInProgress = false;
        },
        [getAuditsPartiePrenante.rejected]: (state, action) => {
            state.loadInProgress = false;
        },
        [getAuditsPartiePrenante.pending]: (state, action) => {
            state.loadInProgress = true;
        },
        [getAuditsPartiePrenante.fulfilled]: (state, action) => {
            state.loadInProgress = false;
        },
        [updateAuditsPartiePrenante.fulfilled]: (state, action) => {
            state.loadInProgress = false
        },
        [updateAuditsPartiePrenante.pending]: (state, action) => {
            state.loadInProgress = true
        },
        [updateAuditsPartiePrenante.rejected]: (state, action) => {
            state.loadInProgress = false
        },
        [getAuditsInformationVisee.fulfilled]: (state, action) => {
            state.loadInProgress = false
            if(action.payload){
                state.auditsInformationVisee = {...action.payload};
            }
        },
        [getAuditsInformationVisee.pending]: (state, action) => {
            state.loadInProgress = true
        },
        [getAuditsInformationVisee.rejected]: (state, action) => {
            state.loadInProgress = false
            state.auditsInformationVisee = {}
        },
        [loadAuditNormes.fulfilled]: (state, action) => {
            state.loadInProgress = false
            state.auditNormes = [...action.payload];
        },
        [loadAuditNormes.pending]: (state, action) => {
            state.loadInProgress = true
        },
        [loadAuditNormes.rejected]: (state, action) => {
            state.loadInProgress = false
            state.auditNormes = []
        },
        [loadAuditArticles.fulfilled]: (state, action) => {
            state.loadInProgress = false
            state.auditArticles = [...action.payload];
        },
        [loadAuditArticles.pending]: (state, action) => {
            state.loadInProgress = true
        },
        [loadAuditArticles.rejected]: (state, action) => {
            state.loadInProgress = false
            state.auditArticles = []
        },
        [loadAuditElementAuditables.fulfilled]: (state, action) => {
            state.loadInProgress = false
            state.auditElementAuditables = [...action.payload];
        },
        [loadAuditElementAuditables.pending]: (state, action) => {
            state.loadInProgress = true
        },
        [loadAuditElementAuditables.rejected]: (state, action) => {
            state.loadInProgress = false
            state.auditElementAuditables = []
        },
        [updateAuditsInformationVisee.fulfilled]: (state, action) => {
            state.loadInProgress = false
            if(action.payload){
                state.auditsInformationVisee = {...action.payload}
            }
        },
        [loadAuditsQuestions.fulfilled]: (state, action) => {
            state.loadInProgress = false
            state.auditsQuestions = [...action.payload]
        },
        [loadAuditsQuestions.pending]: (state, action) => {
            state.loadInProgress = true
        },
        [loadAuditsQuestions.rejected]: (state, action) => {
            state.loadInProgress = false
            state.auditsQuestions = []
        },
        [loadAuditsSaisieQuestions.fulfilled]: (state, action) => {
            state.loadInProgress = false
            state.auditsSaisieQuestions = [...action.payload]
        },
        [loadAuditsSaisieQuestions.pending]: (state, action) => {
            state.loadInProgress = true
        },
        [loadAuditsSaisieQuestions.rejected]: (state, action) => {
            state.loadInProgress = false
            state.auditsSaisieQuestions = []
        },
        [loadAuditsBanqueQuestions.fulfilled]: (state, action) => {
            state.loadInProgress = false
        },
        [loadAuditsBanqueQuestions.pending]: (state, action) => {
            state.loadInProgress = true
        },
        [loadAuditsBanqueQuestions.rejected]: (state, action) => {
            state.loadInProgress = false
        },
        [addAuditsBanqueQuestion.fulfilled]: (state, action) => {
            state.loadInProgress = false
        },
        [addAuditsBanqueQuestion.pending]: (state, action) => {
            state.loadInProgress = true
        },
        [addAuditsBanqueQuestion.rejected]: (state, action) => {
            state.loadInProgress = false
        },
        [deleteAuditsBanqueQuestion.fulfilled]: (state, action) => {
            state.loadInProgress = false
        },
        [deleteAuditsBanqueQuestion.pending]: (state, action) => {
            state.loadInProgress = true
        },
        [deleteAuditsBanqueQuestion.rejected]: (state, action) => {
            state.loadInProgress = false
        },
        [updateAuditsBanqueQuestion.fulfilled]: (state, action) => {
            state.loadInProgress = false
        },
        [updateAuditsBanqueQuestion.pending]: (state, action) => {
            state.loadInProgress = true
        },
        [updateAuditsBanqueQuestion.rejected]: (state, action) => {
            state.loadInProgress = false
        },
        [addAuditsBanqueQuestionVersion.fulfilled]: (state, action) => {
            state.loadInProgress = false
        },
        [addAuditsBanqueQuestionVersion.pending]: (state, action) => {
            state.loadInProgress = true
        },
        [addAuditsBanqueQuestionVersion.rejected]: (state, action) => {
            state.loadInProgress = false
        },
        [deleteAuditsBanqueQuestionVersion.fulfilled]: (state, action) => {
            state.loadInProgress = false
        },
        [deleteAuditsBanqueQuestionVersion.pending]: (state, action) => {
            state.loadInProgress = true
        },
        [deleteAuditsBanqueQuestionVersion.rejected]: (state, action) => {
            state.loadInProgress = false
        },
        [updateAuditsBanqueQuestionVersion.fulfilled]: (state, action) => {
            state.loadInProgress = false
        },
        [updateAuditsBanqueQuestionVersion.pending]: (state, action) => {
            state.loadInProgress = true
        },
        [updateAuditsBanqueQuestionVersion.rejected]: (state, action) => {
            state.loadInProgress = false
        },
        [addAuditsBanqueArticle.fulfilled]: (state, action) => {
            state.loadInProgress = false
        },
        [addAuditsBanqueArticle.pending]: (state, action) => {
            state.loadInProgress = true
        },
        [addAuditsBanqueArticle.rejected]: (state, action) => {
            state.loadInProgress = false
        },
        [updateAuditsBanqueArticle.fulfilled]: (state, action) => {
            state.loadInProgress = false
        },
        [updateAuditsBanqueArticle.pending]: (state, action) => {
            state.loadInProgress = true
        },
        [updateAuditsBanqueArticle.rejected]: (state, action) => {
            state.loadInProgress = false
        },
        [deleteAuditsBanqueArticle.fulfilled]: (state, action) => {
            state.loadInProgress = false
        },
        [deleteAuditsBanqueArticle.pending]: (state, action) => {
            state.loadInProgress = true
        },
        [deleteAuditsBanqueArticle.rejected]: (state, action) => {
            state.loadInProgress = false
        },
        [loadAuditsPlanification.fulfilled]: (state, action) => {
            state.loadInProgress = false
        },
        [loadAuditsPlanification.pending]: (state, action) => {
            state.loadInProgress = true
        },
        [loadAuditsPlanification.rejected]: (state, action) => {
            state.loadInProgress = false
        },
        [updateAuditsPlanification.fulfilled]: (state, action) => {
            state.loadInProgress = false
        },
        [updateAuditsPlanification.pending]: (state, action) => {
            state.loadInProgress = true
        },
        [updateAuditsPlanification.rejected]: (state, action) => {
            state.loadInProgress = false
        },
        [loadAuditsCalendriers.fulfilled]: (state, action) => {
            state.loadInProgress = false
        },
        [loadAuditsCalendriers.pending]: (state, action) => {
            state.loadInProgress = true
        },
        [loadAuditsCalendriers.rejected]: (state, action) => {
            state.loadInProgress = false
        },
        [loadNewAuditsCalendrier.fulfilled]: (state, action) => {
            state.loadInProgress = false
        },
        [loadNewAuditsCalendrier.pending]: (state, action) => {
            state.loadInProgress = true
        },
        [loadNewAuditsCalendrier.rejected]: (state, action) => {
            state.loadInProgress = false
        },
        [addAuditsCalendrier.fulfilled]: (state, action) => {
            state.loadInProgress = false
        },
        [addAuditsCalendrier.pending]: (state, action) => {
            state.loadInProgress = true
        },
        [addAuditsCalendrier.rejected]: (state, action) => {
            state.loadInProgress = false
        },
        [updateAuditsCalendrier.fulfilled]: (state, action) => {
            state.loadInProgress = false
        },
        [updateAuditsCalendrier.pending]: (state, action) => {
            state.loadInProgress = true
        },
        [updateAuditsCalendrier.rejected]: (state, action) => {
            state.loadInProgress = false
        },
        [deleteAuditsCalendrier.fulfilled]: (state, action) => {
            state.loadInProgress = false
        },
        [deleteAuditsCalendrier.pending]: (state, action) => {
            state.loadInProgress = true
        },
        [deleteAuditsCalendrier.rejected]: (state, action) => {
            state.loadInProgress = false
        },
    },
});

export const { setSearchDetail, setCriteres, resetSearchDetail, setSelectedBanqueQuestionLang } =
    inspectionSlice.actions;

export default inspectionSlice.reducer;
