import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  spacing: 6,
  palette: {
    primary: {
      main: '#003d79',
    },
    secondary: {
      main: '#47aa42',
      contrastText: '#fff',
    },
    readOnly: {
      main: '#000080',
      contrastText: '#fff',
    },

    planned: {
      main: '#1976d2',
      contrastText: '#fff',
    },
    inProgress: {
      main: '#1976d2',
      contrastText: '#fff',
    },
    overdue: {
      main: '#f44336',
      contrastText: '#fff',
    },
    completed: {
      main: '#2e7d32',
      contrastText: '#fff',
    },
    abandoned: {
      main: '#0000001f',
      contrastText: '#000',
    },
    active: {
      main: '#2e7d32',
      contrastText: '#fff',
    },
    inactive: {
      main: '#0000001f',
      contrastText: '#000',
    },
    semiActive: {
      main: '#ffeb3b', // Yellow 500
      contrastText: '#000',
    },
    deleted: {
      main: '#0000001f',
      contrastText: '#fff',
    },

    docEnCreation: {
      main: "#d0d00f",
      contrastText: '#fff',
    },
    docActif: {
      main: "#027608",
      contrastText: '#fff',
    },
    docSemiActif: {
      main: '#00000042',
      contrastText: '#fff',
    },
    docExpire: {
      main: "#cb0101",
      contrastText: '#fff',
    },
    docInactif: {
      main: "#00000042",
      contrastText: '#fff',
    },
    background: {
      default: '#eff6fc',
      paper: '#fff',
    }
  },
  typography: {
    fontSize: 13,
    //fontFamily: 'Arial',
    fontFamily: '"Segoe UI", Arial',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1921,
      xxl: 2560,
    },
  },
  components: {
    MUIDataTableToolbar: {
      styleOverrides: {
        icon: {
          '&:hover': {
            color: "#47aa42"
          }
        },
      },
    },
  },
});

export default theme;