import { createSlice, createAsyncThunk, createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import axios from 'Services/AxiosService';
import { paramsToQueryParam } from 'store/UrlUtils';
import {
  FETCH_ALL_SITES,
  FETCH_ALL_ACTIVE_SITES,
  FETCH_ALL_INACTIVE_SITES,
  FETCH_SITES,
  FETCH_SITES_BY_WINDOW_NAME_ACTION,
  FETCH_SITES_BY_WINDOW_NAME,
  MAKE_SITE_FAVORITE,
  UNMAKE_SITE_FAVORITE,
  LOAD_SITES_SEC_USAGER_ADMIN,
  ADD_ADMINISTRATEUR_SITES,
  DELETE_ADMINISTRATEUR_SITES,
  UNAUTH_FETCH_ALL_ACTIVE_SITES,
  FETCH_ALL_OBJETS_LANGUES_2_BY_LANGUE_LOGGED_OUT, FETCH_ALL_OBJETS_LANGUES_2_BY_LANGUE,
} from 'store/UrlConstants';
import { setSearchParams as setSearchParamsUc, initSearchParamsSites as initSitesUCSearch } from './KM/KnowledgeUniverseSearchSlice'
import { setSearchParams as setSearchParamsOc, initSearchParamsSites as initSitesOCSearch } from './KM/KnowledgeObjectSearchSlice';
import { showSnackbarMessage } from './MessagesSystemSlice';


// All Sites
const allSitesAdapter = createEntityAdapter({
  selectId: (entity) => entity.code,
});

export const fetchAllSites = createAsyncThunk("sites/fetchAllSites", async () => {
  const response = await axios.get(FETCH_ALL_SITES);
  return response.data;
});

export const {
  selectAll: selectAllSitesV2,
  selectById: selectSiteByIdV2,
} = allSitesAdapter.getSelectors((state) => state.sites.allSites);


// All Active Sites
const allActiveSitesAdapter = createEntityAdapter({
  selectId: (entity) => entity.code,
  sortComparer: (a, b) => a.code.localeCompare(b.code),
});

export const fetchAllActiveSites = createAsyncThunk("sites/fetchAllActiveSites", async () => {
  const response = await axios.get(FETCH_ALL_ACTIVE_SITES);
  return response.data;
});

export const {
  selectAll: selectAllActiveSites,
  selectById: selectActiveSiteById,
} = allActiveSitesAdapter.getSelectors((state) => state.sites.allActiveSites);


// All Inactive Sites
const allInactiveSitesAdapter = createEntityAdapter({
  selectId: (entity) => entity.code,
});

export const fetchAllInactiveSites = createAsyncThunk("sites/fetchAllInactiveSites", async () => {
  const response = await axios.get(FETCH_ALL_INACTIVE_SITES);
  return response.data;
});

export const {
  selectAll: selectAllInactiveSites,
  selectById: selectInactiveSiteById,
} = allInactiveSitesAdapter.getSelectors((state) => state.sites.allInactiveSites);


// Sites Values Adapter (old Sites/Criterias)
const sitesAdapter = createEntityAdapter({
  selectId: (entity) => entity.code,
});

export const fetchSites = createAsyncThunk('sites/fetchSites', async (params, thunkAPI) => {
  let response;
  if (params?.loggedOut) {
    response = await axios.get(UNAUTH_FETCH_ALL_ACTIVE_SITES);
  } else {
    response = await axios.get(FETCH_SITES);
  }

  return response.data;
});

export const {
  selectAll: selectAllSites,
  selectById: selectSiteById,
} = sitesAdapter.getSelectors((state) => state.sites.sites);


// Sites Action Values Logged Adapter
const sitesByWindowNameActionAdapter = createEntityAdapter({
  selectId: (entity) => entity.code,
  // sortComparer: (a, b) => a.value.localeCompare(b.value),
});

export const fetchSitesByWindowNameAction = createAsyncThunk("sites/fetchSitesByWindowNameAction", async ({ window, action }) => {
  const response = await axios.get(FETCH_SITES_BY_WINDOW_NAME_ACTION + paramsToQueryParam({ window, action }));
  return response.data;
});

export const fetchSitesByWindowName = createAsyncThunk("sites/fetchSitesByWindowName", async ({ window, action }) => {
  const response = await axios.get(FETCH_SITES_BY_WINDOW_NAME + paramsToQueryParam({ window, action }));
  return response.data;
});

export const makeUnmakeFavorite = createAsyncThunk("Sites/MakeUnmakeFavorite", async ({ mode, codeSite }, thunkAPI) => {
  let path, newFavorites;
  const oldUcSearchParams = thunkAPI.getState().kmUniverseSearch.searchParams;
  const oldOcSearchParams = thunkAPI.getState().kmObjectSearch.searchParams;

  if (mode === "make") {
    path = MAKE_SITE_FAVORITE;
    newFavorites = oldUcSearchParams.criterias.sites.concat(codeSite)
  } else {
    path = UNMAKE_SITE_FAVORITE;
    newFavorites = oldUcSearchParams.criterias.sites.filter(item => item !== codeSite);
  }

  const newUcSearchParams = { ...oldUcSearchParams, criterias: { ...oldUcSearchParams.criterias, sites: newFavorites } };
  const newOcSearchParams = { ...oldOcSearchParams, criterias: { ...oldOcSearchParams.criterias, sites: newFavorites } };

  try {
    const response = await axios.post(path, { paramString: codeSite });
    thunkAPI.dispatch(setSearchParamsUc(newUcSearchParams));
    thunkAPI.dispatch(setSearchParamsOc(newOcSearchParams));    
    return { mode: mode, codeSite: codeSite, resp: response.data };

  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data)
  }

})

export const {
  selectAll: selectAllSitesByWindowNameAction,
  selectById: selectSiteWindowNameActionById,
} = sitesByWindowNameActionAdapter.getSelectors((state) => state.sites.sitesByWindowNameAction);

// ----------------------------------------------------------------------------------------
// AdministrateurSites
// ----------------------------------------------------------------------------------------
export const addAdministrateurSites = createAsyncThunk("AdministrateurSites/AddMany", async (administrateurSites, thunkAPI) => {

  try {
    const resp = await axios.post(ADD_ADMINISTRATEUR_SITES, administrateurSites);

    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );

    return resp.data;

  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const deleteAdministrateurSites = createAsyncThunk("AdministrateurSites/DeleteMany", async (administrateurSites, thunkAPI) => {

  try {
    const resp = await axios.delete(DELETE_ADMINISTRATEUR_SITES, { data: administrateurSites });

    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );

    return resp.data;

  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// ----------------------------------------------------------------------------------------
// SitesUsagerAdmin
// ----------------------------------------------------------------------------------------
const sitesEmpAdminAdapter = createEntityAdapter({ selectId: (entity) => entity.codeSite })

export const fetchSitesEmpAdmin = createAsyncThunk("Sites/Admin/GetByEmploye", async (secUsagerId) => {
  const resp = await axios.get(LOAD_SITES_SEC_USAGER_ADMIN, { params: { secUsagerId: secUsagerId } });
  return resp.data;
});

export const sitesEmpAdminSelector = (store) => store.sites.sitesEmpAdmin;

export const {
  selectAll: selectAllSitesEmpAdmin,
} = sitesEmpAdminAdapter.getSelectors(sitesEmpAdminSelector);

export const selectLoadingSitesEmpAdmin = createSelector(
  sitesEmpAdminSelector,
  (state) => state.loading
);


const initialState = {
  sites: sitesAdapter.getInitialState(),
  allSites: allSitesAdapter.getInitialState(),
  allActiveSites: allActiveSitesAdapter.getInitialState(),
  allInactiveSites: allInactiveSitesAdapter.getInitialState(),
  sitesByWindowNameAction: sitesByWindowNameActionAdapter.getInitialState(),
  sitesEmpAdmin: sitesEmpAdminAdapter.getInitialState({ loading: false }),
};

const sitesSlice = createSlice({
  name: 'sites',
  initialState,
  reducers: {
    removeAllSitesEmpAdmin(state) {
      sitesEmpAdminAdapter.removeAll(state.sitesEmpAdmin);
    }
  },
  extraReducers: {
    [fetchAllSites.fulfilled]: (state, action) => {
      allSitesAdapter.setAll(state.allSites, action.payload);
    },
    [fetchAllActiveSites.fulfilled]: (state, action) => {
      allActiveSitesAdapter.setAll(state.allActiveSites, action.payload);
    },
    [fetchAllInactiveSites.fulfilled]: (state, action) => {
      allInactiveSitesAdapter.setAll(state.allInactiveSites, action.payload);
    },
    [fetchSites.fulfilled]: (state, action) => {
      sitesAdapter.setAll(state.sites, action.payload);
    },
    [fetchSitesByWindowNameAction.fulfilled]: (state, action) => {
      sitesByWindowNameActionAdapter.setAll(state.sitesByWindowNameAction, action.payload);
    },
    [fetchSitesByWindowName.fulfilled]: (state, action) => {
      sitesByWindowNameActionAdapter.setAll(state.sitesByWindowNameAction, action.payload);
    },
    [makeUnmakeFavorite.fulfilled]: (state, action) => {
      const { mode, codeSite, resp } = action.payload;

      if (mode === "make") {
        state.sites.entities[codeSite].selected = true;
      } else {
        if (resp === true) {
          state.sites.entities[codeSite].selected = false;
        }
      }
    },
    // [makeSiteFavorite.fulfilled]: (state, action) => {
    //   state.sites.entities[action.payload].selected = true;
    // },
    // [unmakeSiteFavorite.fulfilled]: (state, action) => {
    //   state.sites.entities[action.payload].selected = false;
    // }
    [fetchSitesEmpAdmin.pending]: (state) => {
      state.sitesEmpAdmin.loading = true;
    },
    [fetchSitesEmpAdmin.rejected]: (state) => {
      state.sitesEmpAdmin.loading = false;
    },
    [fetchSitesEmpAdmin.fulfilled]: (state, action) => {
      sitesEmpAdminAdapter.setAll(state.sitesEmpAdmin, action.payload);
      state.sitesEmpAdmin.loading = false;
    },
  },
});

export const { removeAllSitesEmpAdmin } = sitesSlice.actions;

export default sitesSlice.reducer;