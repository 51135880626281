import {
  createSlice,
  createAsyncThunk, createEntityAdapter
} from '@reduxjs/toolkit';
import axios from 'Services/AxiosService';
import { paramsToQueryParam } from "store/UrlUtils";
import { sortArray } from "Utils/CommonFunctions";
import {
  FETCH_NC_ASPECTS_RISQUE,
  FETCH_NC_EDIT_CLASS,
  FETCH_NC_EDIT_COMPONENTS,
  FETCH_NC_EDIT_LOT_NUMBERS, FETCH_NC_EDIT_NO_BDCS, FETCH_NC_EDIT_NO_DESSINS,
  FETCH_NC_EDIT_ORIGINS,
  FETCH_NC_EDIT_PART_NUMBERS, FETCH_NC_EDIT_POSTES,
  FETCH_NC_EDIT_PROBABILITIES,
  FETCH_NC_EDIT_PROCESSES,
  FETCH_NC_EDIT_PRODUCTS, FETCH_NC_EDIT_RISQUES,
  FETCH_NC_EDIT_SAMPLES,
  FETCH_NC_EDIT_SERIAL_NUMBERS,
  FETCH_NC_EDIT_SEVERITIES,
  FETCH_NC_EDIT_STEPS, FETCH_NC_EDIT_TACHES,
  FETCH_NC_EDIT_TYPES, FETCH_NC_EDIT_TYPES_RISQUES,
  FETCH_NC_EDIT_UNITS,
  FETCH_NC_EDIT_WORK_ORDERS,
  FETCH_NC_EDIT_WORK_SHIFTS,
  FETCH_NC_EDIT_WORK_TEAMS, FETCH_NC_ELEMENTS_AUDIT, FETCH_NC_EQUIPEMENTS, FETCH_NC_GRAVITES, FETCH_NC_IMPACTS_EASM,
  FETCH_NC_CAUSE_IMMEDIATE,
  FETCH_NC_LISTE1,
  FETCH_NC_CODE_DECISION,
  FETCH_NC_TYPE_NON_CONFORMITE,
  FETCH_NC_EDIT_UNITE_MESURE,
  FETCH_NC_EDIT_CATEGORIE_COUT, FETCH_NC_ELEMENTS_INSP,
  FETCH_NC_EDIT_CRITERIAS_IDENTIFIANT2,
  FETCH_NC_TYPE_CAUSE_FONDAM,
  FETCH_NC_CAUSE_FONDAMENTALE,
  FETCH_NC_PRODUIT_NOM,
  FETCH_NC_LISTE_CHOIX_PERS_DETAIL,
  NC_GET_REFERENCE,
  NC_GET_STATUT_ACCIDENT,
  FETCH_NC_ELEMENTS_INSPECTION,
  NC_GET_DISPOSITION,
  NC_GET_RESPONSABILITE,
  NC_GET_TYPE_PRODUIT_DEVERSE,
  GET_NC_ELEMENTS_INSPECTION,
  NC_GET_LISTE_CHOIX,
  NC_GET_SYNCHRO_CHAMPS
} from 'store/UrlConstants';

const processesAdapter = createEntityAdapter({});
const unitsAdapter = createEntityAdapter({});
const componentsAdapter = createEntityAdapter({});
const samplesAdapter = createEntityAdapter({});
const typesAdapter = createEntityAdapter({});
const severitiesAdapter = createEntityAdapter({});
const classificationsAdapter = createEntityAdapter({});
const uniteMesuresAdapter = createEntityAdapter({});
const categorieCoutsAdapter = createEntityAdapter({});
const causeImmediateAdapter = createEntityAdapter({});
const typeCauseFondamAdapter = createEntityAdapter({});
const list1Adapter = createEntityAdapter({});
const codeDecisionAdapter = createEntityAdapter({});
const typeNonConformiteAdapter = createEntityAdapter({});
const stepsAdapter = createEntityAdapter({});
const productsAdapter = createEntityAdapter({});
const serialNumbersAdapter = createEntityAdapter({});
const lotNumbersAdapter = createEntityAdapter({});
const originsAdapter = createEntityAdapter({});
const workOrdersAdapter = createEntityAdapter({});
const partNumbersAdapter = createEntityAdapter({});
const workShiftsAdapter = createEntityAdapter({});
const workTeamsAdapter = createEntityAdapter({});
const probabilitiesAdapter = createEntityAdapter({});
const noBDCsAdapter = createEntityAdapter({});
const noDessinsAdapter = createEntityAdapter({});
const postesAdapter = createEntityAdapter({});
const tachesAdapter = createEntityAdapter({});
const typesRisquesAdapter = createEntityAdapter({});
const risquesAdapter = createEntityAdapter({});
const aspectsRisqueAdapter = createEntityAdapter({});
const gravitesAdapter = createEntityAdapter({});
const elementsAuditAdapter = createEntityAdapter({});
const elementsInspectionAdapter = createEntityAdapter({});
const equipementsAdapter = createEntityAdapter({});
const produitNomsAdapter = createEntityAdapter({});
const listeChoixPersoDetailAdapter = createEntityAdapter({});
const impactsEASMAdapter = createEntityAdapter({});
const elementsInspAdapter = createEntityAdapter({});
const otherIdentifierAdapter = createEntityAdapter({});
const ncReferencesAdapter = createEntityAdapter({});
const statutAccidentAdapter = createEntityAdapter({});
const dispositionAdapter = createEntityAdapter({});
const responsabiliteAdapter = createEntityAdapter({});
const typeProduitDeverseAdapter = createEntityAdapter({});
const ncSynchroChampsAdapter = createEntityAdapter({});

export const fetchProcesses = createAsyncThunk('NonConformites/Edit/Criterias/Processes', async (params, thunkAPI) => {
  if(params.codeNonConformite){
    const response = await axios.get(FETCH_NC_EDIT_PROCESSES + paramsToQueryParam({ codeNonConformite: params.codeNonConformite, codeSite: params.codeSite }));
    return response.data;
  } else{
    const response = await axios.get(FETCH_NC_EDIT_PROCESSES + paramsToQueryParam({ codeSite: params.codeSite }));
    return response.data;
  }
});
export const {
  selectAll: selectAllProcesses,
  selectById: selectProcessById,
} = processesAdapter.getSelectors((state) => state.nonConformiteEditCriteria.processes);

export const fetchUnits = createAsyncThunk('NonConformites/Edit/Criterias/Units', async (processCode, thunkAPI) => {
  const response = await axios.get(FETCH_NC_EDIT_UNITS + paramsToQueryParam({ processCode: processCode }));
  return response.data;
});

export const {
  selectAll: selectAllUnits,
  selectById: selectUnitById,
} = unitsAdapter.getSelectors((state) => state.nonConformiteEditCriteria.units);

export const fetchComponents = createAsyncThunk('NonConformites/Edit/Criterias/Components', async (unitCode, thunkAPI) => {
  const response = await axios.get(FETCH_NC_EDIT_COMPONENTS + paramsToQueryParam({ unitCode: unitCode }));
  return response.data;
});

export const {
  selectAll: selectAllComponents,
  selectById: selectComponentById,
} = componentsAdapter.getSelectors((state) => state.nonConformiteEditCriteria.components);

export const fetchSamples = createAsyncThunk('NonConformites/Edit/Criterias/Samples', async (componentCode, thunkAPI) => {
  const response = await axios.get(FETCH_NC_EDIT_SAMPLES + paramsToQueryParam({ componentCode: componentCode }));
  return response.data;
});

export const {
  selectAll: selectAllSamples,
  selectById: selectSampleById,
} = samplesAdapter.getSelectors((state) => state.nonConformiteEditCriteria.samples);

export const fetchTypes = createAsyncThunk('NonConformites/Edit/Criterias/Types', async (params) => {
  const _params = {}
  if(params[0]){
    _params.codeNonConformite = params[0]
  }
  if(params[1]){
    _params.codeSite = params[1]
  }
  const response = await axios.get(FETCH_NC_EDIT_TYPES + paramsToQueryParam(_params));
  return response.data;
});

export const {
  selectAll: selectAllTypes,
  selectById: selectTypeById,
} = typesAdapter.getSelectors((state) => state.nonConformiteEditCriteria.types);

export const fetchSeverities = createAsyncThunk('NonConformites/Edit/Criterias/Severities', async (params) => {
  const _params = {};
  if(params.codeNonConformite){
    _params.codeNonConformite = params.codeNonConformite
  }
  if(params.nonConformanceType){
    _params.nonConformanceType = params.nonConformanceType
  }
  if(params.codeSite){
    _params.codeSite = params.codeSite
  }
  const response = await axios.get(FETCH_NC_EDIT_SEVERITIES + paramsToQueryParam(_params));
  return response.data;
});
export const {
  selectAll: selectAllSeverities,
  selectById: selectSeverityById,
} = severitiesAdapter.getSelectors((state) => state.nonConformiteEditCriteria.severities);

export const fetchCauseImmediate = createAsyncThunk('NonConformites/Edit/Criterias/CauseImmediate', async (params) => {
  if(params.codeNonConformite){
    const _params = {
      codeNonConformite: params.codeNonConformite
    }
    if(params.codeSite){
      _params.codeSite = params.codeSite
    }
    const response = await axios.get(FETCH_NC_CAUSE_IMMEDIATE + paramsToQueryParam({..._params}));
    return response.data;
  } else{
    const response = await axios.get(FETCH_NC_CAUSE_IMMEDIATE + paramsToQueryParam({ codeSite: params.codeSite }));
    return response.data;
  }
});

export const {
  selectAll: selectAllCauseImmediate,
  selectById: selectCauseImmediateById,
} = causeImmediateAdapter.getSelectors((state) => state.nonConformiteEditCriteria.causeImmediate);

export const fetchTypeCauseFondam = createAsyncThunk('NonConformites/Edit/Criterias/TypeCauseFondam', async (codeNC, thunkAPI) => {
  const response = await axios.get(FETCH_NC_TYPE_CAUSE_FONDAM + paramsToQueryParam({ codeNonConformite: codeNC }));
  return response.data;
});
export const {
  selectAll: selectAllTypeCauseFondam,
  selectById: selectTypeCauseFondamById,
} = typeCauseFondamAdapter.getSelectors((state) => state.nonConformiteEditCriteria.typeCauseFondam);

export const fetchCauseFondamentale = createAsyncThunk('NonConformites/Edit/Criterias/CauseFondamentale', async ({ codeNonConformite, typeCauseFondamId }, thunkAPI) => {
  const response = await axios.get(FETCH_NC_CAUSE_FONDAMENTALE + paramsToQueryParam({ codeNonConformite, typeCauseFondamId }));
  return {
    fondamId: typeCauseFondamId,
    data: response.data
  };
});

export const fetchList1 = createAsyncThunk('NonConformites/Edit/Criterias/list1', async (params) => {
  const apiParam = params.codeNonConformite 
    ? { codeNonConformite: params.codeNonConformite, codeSite: params.codeSite } 
    : { codeSite: params.codeSite }
  const response = await axios.get(FETCH_NC_LISTE1 + paramsToQueryParam(apiParam));
  return response.data;
});
export const {
  selectAll: selectAllList1,
  selectById: selectList1ById,
} = list1Adapter.getSelectors((state) => state.nonConformiteEditCriteria.list1);

export const fetchCodeDecision = createAsyncThunk('NonConformites/Edit/Criterias/CodeDecision', async () => {
  const response = await axios.get(FETCH_NC_CODE_DECISION);
  return response.data;
});
export const {
  selectAll: selectAllCodeDecision,
  selectById: selectCodeDecisionById,
} = codeDecisionAdapter.getSelectors((state) => state.nonConformiteEditCriteria.codeDecision);

export const fetchTypeNonConformite = createAsyncThunk('NonConformites/Edit/Criterias/TypeNonConformite', async (params) => {
  const response = await axios.get(FETCH_NC_TYPE_NON_CONFORMITE + paramsToQueryParam({ 
    codeNonConformite: params.codeNonConformite, nonConformanceType: params.nonConformanceType 
  }));
  return response.data;
});

export const {
  selectAll: selectAllTypeNonConformite,
  selectById: selectTypeNonConformiteById,
} = typeNonConformiteAdapter.getSelectors((state) => state.nonConformiteEditCriteria.typeNonConformite);

export const fetchClassifications = createAsyncThunk('NonConformites/Edit/Criterias/Classifications', async (params) => {
  const apiParam = params.codeNonConformite 
    ? { codeNonConformite: params.codeNonConformite, codeSite: params.codeSite } 
    : { codeSite: params.codeSite }
  const response = await axios.get(FETCH_NC_EDIT_CLASS + paramsToQueryParam(apiParam));   
  return response.data;
});
export const {
  selectAll: selectAllClassifications,
  selectById: selectClassificationById,
} = classificationsAdapter.getSelectors((state) => state.nonConformiteEditCriteria.classifications);

export const fetchUniteMesures = createAsyncThunk('NonConformites/Edit/Criterias/UniteMesure', async (params) => {
  const response = await axios.get(FETCH_NC_EDIT_UNITE_MESURE + paramsToQueryParam(params));
  return response.data;
});

export const {
  selectAll: selectAllUniteMesures,
  selectById: selectUniteMesureById,
} = uniteMesuresAdapter.getSelectors((state) => state.nonConformiteEditCriteria.uniteMesures);

export const fetchCategorieCouts = createAsyncThunk('NonConformites/Edit/Criterias/CategorieCout', async (params) => {
  const response = await axios.get(FETCH_NC_EDIT_CATEGORIE_COUT + paramsToQueryParam(params));
  return response.data;
});

export const {
  selectAll: selectAllCategorieCouts,
  selectById: selectCategorieCoutById,
} = categorieCoutsAdapter.getSelectors((state) => state.nonConformiteEditCriteria.categorieCouts);

export const fetchSteps = createAsyncThunk('NonConformites/Edit/Criterias/Steps', async (params) => {
  const apiParam = params.codeNonConformite 
    ? { codeNonConformite: params.codeNonConformite, codeSite: params.codeSite } 
    : { codeSite: params.codeSite }
  const response = await axios.get(FETCH_NC_EDIT_STEPS + paramsToQueryParam(apiParam));
  return response.data;
});
export const {
  selectAll: selectAllSteps,
  selectById: selectStepById,
} = stepsAdapter.getSelectors((state) => state.nonConformiteEditCriteria.steps);

export const fetchProducts = createAsyncThunk('NonConformites/Edit/Criterias/Products', async (params) => {
  const apiParam = params.codeNonConformite 
    ? { codeNonConformite: params.codeNonConformite, codeSite: params.codeSite } 
    : { codeSite: params.codeSite }
  const response = await axios.get(FETCH_NC_EDIT_PRODUCTS + paramsToQueryParam(apiParam));
  return response.data;
});
export const {
  selectAll: selectAllProducts,
  selectById: selectProductById,
} = productsAdapter.getSelectors((state) => state.nonConformiteEditCriteria.products);

export const fetchSerialNumbers = createAsyncThunk('NonConformites/Edit/Criterias/SerialNumbers', async () => {
  const response = await axios.get(FETCH_NC_EDIT_SERIAL_NUMBERS);
  return response.data;
});
export const {
  selectAll: selectAllSerialNumbers,
  selectById: selectSerialNumbersById,
} = serialNumbersAdapter.getSelectors((state) => state.nonConformiteEditCriteria.serialNumbers);

export const fetchLotNumbers = createAsyncThunk('NonConformites/Edit/Criterias/LotNumbers', async () => {
  const response = await axios.get(FETCH_NC_EDIT_LOT_NUMBERS);
  return response.data;
});
export const {
  selectAll: selectAllLotNumbers,
  selectById: selectLotNumbersById,
} = lotNumbersAdapter.getSelectors((state) => state.nonConformiteEditCriteria.lotNumbers);

export const fetchOrigins = createAsyncThunk('NonConformites/Edit/Criterias/Origins', async (params) => {
  const apiParam = params.codeNonConformite 
    ? { codeNonConformite: params.codeNonConformite, codeSite: params.codeSite } 
    : { codeSite: params.codeSite }
  const response = await axios.get(FETCH_NC_EDIT_ORIGINS + paramsToQueryParam(apiParam));
  return response.data;
});
export const {
  selectAll: selectAllOrigins,
  selectById: selectOriginById,
} = originsAdapter.getSelectors((state) => state.nonConformiteEditCriteria.origins);

export const fetchWorkOrders = createAsyncThunk('NonConformites/Edit/Criterias/WorkOrders', async () => {
  const response = await axios.get(FETCH_NC_EDIT_WORK_ORDERS);
  return response.data;
});
export const {
  selectAll: selectAllWorkOrders,
  selectById: selectWorkOderById,
} = workOrdersAdapter.getSelectors((state) => state.nonConformiteEditCriteria.workOrders);

export const fetchPartNumbers = createAsyncThunk('NonConformites/Edit/Criterias/PartNumbers', async () => {
  const response = await axios.get(FETCH_NC_EDIT_PART_NUMBERS);
  return response.data;
});

export const {
  selectAll: selectAllPartNumbers,
  selectById: selectPartNumberById,
} = partNumbersAdapter.getSelectors((state) => state.nonConformiteEditCriteria.partNumbers);

export const fetchWorkShifts = createAsyncThunk('NonConformites/Edit/Criterias/WorkShifts', async (params) => {
  const apiParam = params.codeNonConformite 
    ? { codeNonConformite: params.codeNonConformite, codeSite: params.codeSite } 
    : { codeSite: params.codeSite }
  const response = await axios.get(FETCH_NC_EDIT_WORK_SHIFTS + paramsToQueryParam(apiParam));
  return response.data;
});
export const {
  selectAll: selectAllWorkShifts,
  selectById: selectWorkShiftById,
} = workShiftsAdapter.getSelectors((state) => state.nonConformiteEditCriteria.workShifts);

export const fetchWorkTeams = createAsyncThunk('NonConformites/Edit/Criterias/WorkTeams', async (params) => {
  const apiParam = params.codeNonConformite 
    ? { codeNonConformite: params.codeNonConformite, codeSite: params.codeSite } 
    : { codeSite: params.codeSite }
  const response = await axios.get(FETCH_NC_EDIT_WORK_TEAMS + paramsToQueryParam(apiParam));
  return response.data;
});
export const {
  selectAll: selectAllWorkTeams,
  selectById: selectWorkTeamById,
} = workTeamsAdapter.getSelectors((state) => state.nonConformiteEditCriteria.workTeams);

export const fetchProbabilities = createAsyncThunk('NonConformites/Edit/Criterias/Probabilities', async (codeNC) => {
  const response = await axios.get(FETCH_NC_EDIT_PROBABILITIES + paramsToQueryParam({ codeNonConformite: codeNC }));
  return response.data;
});

export const {
  selectAll: selectAllProbabilities,
  selectById: selectProbabilityById,
} = probabilitiesAdapter.getSelectors((state) => state.nonConformiteEditCriteria.probabilities);

export const fetchNoBDCs = createAsyncThunk('NonConformites/Edit/Criterias/NoBDCs', async () => {
  const response = await axios.get(FETCH_NC_EDIT_NO_BDCS);
  return response.data;
});

export const {
  selectAll: selectAllNoBDCs,
  selectById: selectNoBDCById,
} = noBDCsAdapter.getSelectors((state) => state.nonConformiteEditCriteria.noBDCs);

export const fetchNoDessins = createAsyncThunk('NonConformites/Edit/Criterias/NoDessins', async () => {
  const response = await axios.get(FETCH_NC_EDIT_NO_DESSINS);
  return response.data;
});

export const {
  selectAll: selectAllNoDessins,
  selectById: selectNoDessinById,
} = noDessinsAdapter.getSelectors((state) => state.nonConformiteEditCriteria.noDessins);

export const fetchPostes = createAsyncThunk('NonConformites/Edit/Criterias/Postes', async () => {
  const response = await axios.get(FETCH_NC_EDIT_POSTES);
  return response.data;
});

export const {
  selectAll: selectAllPostes,
  selectById: selectPosteById,
} = noDessinsAdapter.getSelectors((state) => state.nonConformiteEditCriteria.postes);

export const fetchTaches = createAsyncThunk('NonConformites/Edit/Criterias/Taches', async () => {
  const response = await axios.get(FETCH_NC_EDIT_TACHES);
  return response.data;
});

export const {
  selectAll: selectAllTaches,
  selectById: selectTacheById,
} = tachesAdapter.getSelectors((state) => state.nonConformiteEditCriteria.taches);

export const fetchTypesRisques = createAsyncThunk('NonConformites/Edit/Criterias/TypesRisques', async (params) => {
  const apiParam = params.codeNonConformite 
    ? { codeNonConformite: params.codeNonConformite, codeSite: params.codeSite } 
    : { codeSite: params.codeSite }
  const response = await axios.get(FETCH_NC_EDIT_TYPES_RISQUES + paramsToQueryParam(apiParam));
  return response.data;
});

export const {
  selectAll: selectAllTypesRisques,
  selectById: selectTypeRisquesById,
} = typesRisquesAdapter.getSelectors((state) => state.nonConformiteEditCriteria.typesRisques);

export const fetchRisques = createAsyncThunk('NonConformites/Edit/Criterias/Risques', async (codeNC) => {
  const response = await axios.get(FETCH_NC_EDIT_RISQUES + paramsToQueryParam({ codeNC: codeNC }));
  return response.data;
});

export const {
  selectAll: selectAllRisques,
  selectById: selectRisqueById,
} = risquesAdapter.getSelectors((state) => state.nonConformiteEditCriteria.risques);

export const fetchAspectsRisque = createAsyncThunk('NonConformites/Edit/Criterias/AspectsRisque', async (codeInExtrant) => {
  const response = await axios.get(FETCH_NC_ASPECTS_RISQUE + paramsToQueryParam({ codeInExtrant }));
  return response.data;
});

export const {
  selectAll: selectAllAspectsRisque,
  selectById: selectAspectRisqueById,
} = aspectsRisqueAdapter.getSelectors((state) => state.nonConformiteEditCriteria.aspectsRisque);

export const fetchGravites = createAsyncThunk('NonConformites/Edit/Criterias/Gravites', async (codeNC) => {
  const response = await axios.get(FETCH_NC_GRAVITES + paramsToQueryParam({ codeNonConformite: codeNC }));
  return response.data;
});

export const {
  selectAll: selectAllGravites,
  selectById: selectGraviteById,
} = gravitesAdapter.getSelectors((state) => state.nonConformiteEditCriteria.gravites);

export const fetchElementsAudit = createAsyncThunk('NonConformites/Edit/Criterias/ElementsAudit', async (codeAudit) => {
  const apiParam = {}
  if(codeAudit){
    apiParam.codeAudit = codeAudit
  }
  const response = await axios.get(FETCH_NC_ELEMENTS_AUDIT + paramsToQueryParam(apiParam));
  return response.data;
});

export const {
  selectAll: selectAllElementsAudit,
  selectById: selectElementAuditById,
} = elementsAuditAdapter.getSelectors((state) => state.nonConformiteEditCriteria.elementsAudit);

export const fetchElementsInspection = createAsyncThunk('NonConformites/Edit/Criterias/ElementsInspection', async (inspFicheResultatsId) => {
  const response = await axios.get(FETCH_NC_ELEMENTS_INSPECTION + paramsToQueryParam({ inspFicheResultatsId: inspFicheResultatsId }));
  return response.data;
});
// export const fetchElementsInspection = createAsyncThunk('NonConformites/Edit/Criterias/GetElementsInspection', async (inspFicheResultatsId) => {
//   const response = await axios.get(GET_NC_ELEMENTS_INSPECTION + paramsToQueryParam({ inspFicheResultatsId: inspFicheResultatsId }));
//   return response.data;
// });

export const {
  selectAll: selectAllElementsInspection,
  selectById: selectElementInspectionById,
} = elementsInspectionAdapter.getSelectors((state) => state.nonConformiteEditCriteria.elementsInspection);

export const fetchEquipements = createAsyncThunk('NonConformites/Edit/Criterias/Equipements', async (params) => {
  const apiParam = params.codeNonConformite 
    ? { codeNonConformite: params.codeNonConformite, codeSite: params.codeSite } 
    : { codeSite: params.codeSite }
  const response = await axios.get(FETCH_NC_EQUIPEMENTS + paramsToQueryParam(apiParam));
  return response.data;
});
export const {
  selectAll: selectAllEquipements,
  selectById: selectEquipementById,
} = equipementsAdapter.getSelectors((state) => state.nonConformiteEditCriteria.equipements);

export const fetchProduitNoms = createAsyncThunk('NonConformites/Edit/Criterias/ProduitNom', async (codeNC) => {
  const response = await axios.get(FETCH_NC_PRODUIT_NOM + paramsToQueryParam({ codeNonConformite: codeNC }));
  return response.data;
});

export const {
  selectAll: selectAllProduitNoms,
  selectById: selectProduitNomById,
} = produitNomsAdapter.getSelectors((state) => state.nonConformiteEditCriteria.produitNoms);

export const fetchListeChoixPersoDetail = createAsyncThunk('NonConformites/Edit/Criterias/GetListeChoixPersoDetail', async (params) => {
  const response = await axios.get(FETCH_NC_LISTE_CHOIX_PERS_DETAIL + paramsToQueryParam(params));
  return response.data;
});

export const {
  selectAll: selectAllListeChoixPersoDetail,
  selectById: selectChoixPersoDetailById,
} = listeChoixPersoDetailAdapter.getSelectors((state) => state.nonConformiteEditCriteria.listeChoixPersoDetail);

export const fetchImpactsEASM = createAsyncThunk('NonConformites/Edit/Criterias/ImpactsEASM', async () => {
  const response = await axios.get(FETCH_NC_IMPACTS_EASM);
  return response.data;
});

export const {
  selectAll: selectAllImpactsEASM,
  selectById: selectImpactEASMById,
} = impactsEASMAdapter.getSelectors((state) => state.nonConformiteEditCriteria.impactsEASM);

export const fetchElementsInsp = createAsyncThunk('NonConformites/Edit/Criterias/ElementsInsp', async (inspFicheResultatsId) => {
  const response = await axios.get(FETCH_NC_ELEMENTS_INSP + paramsToQueryParam({inspFicheResultatsId: inspFicheResultatsId}));
  return response.data;
});

export const {
  selectAll: selectAllElementsInsp,
  selectById: selectElementInspById,
} = elementsInspAdapter.getSelectors((state) => state.nonConformiteEditCriteria.elementsInsp);

export const fetchOtherIdentifier = createAsyncThunk('NonConformites/Edit/Criterias/Identifiant2', async (params) => {
  const response = await axios.get(FETCH_NC_EDIT_CRITERIAS_IDENTIFIANT2 + paramsToQueryParam(params));
  return response.data;
});

export const {
  selectAll: selectAllOtherIdentifier,
  selectById: selectOtherIdentifier,
} = otherIdentifierAdapter.getSelectors((state) => state.nonConformiteEditCriteria.otherIdentifier);


export const {
  selectAll: selectAllNcReferences,
  selectById: selectNcReferenceById,
} = ncReferencesAdapter.getSelectors((state) => state.nonConformiteEditCriteria.ncReferences);
export const getNCReferences = createAsyncThunk("NonConformites/Edit/Criterias/GetNCReference", async (params, thunkAPI) => {
  const response = await axios.get(NC_GET_REFERENCE + paramsToQueryParam(params));
  return response.data;
});

export const {
  selectAll: selectAllStatutAccidents,
  selectById: selectStatutAccidentById,
} = statutAccidentAdapter.getSelectors((state) => state.nonConformiteEditCriteria.statutAccidents);
export const getStatutAccident = createAsyncThunk("NonConformites/Edit/Criterias/GetStatutAccident", async (params, thunkAPI) => {
  const response = await axios.get(NC_GET_STATUT_ACCIDENT + paramsToQueryParam(params));
  return response.data;
});

export const {
  selectAll: selectAllDispositions,
  selectById: selectDispositionById,
} = dispositionAdapter.getSelectors((state) => state.nonConformiteEditCriteria.dispositions);
export const fetchDispositions = createAsyncThunk("NonConformites/Edit/Criterias/Disposition", async (codeSite, thunkAPI) => {
  const response = await axios.get(NC_GET_DISPOSITION + paramsToQueryParam({ codeSite }));
  return response.data;
});

export const {
  selectAll: selectAllResponsabilites,
  selectById: selectResponsabiliteById,
} = responsabiliteAdapter.getSelectors((state) => state.nonConformiteEditCriteria.responsabilites);
export const fetchResponsabilites = createAsyncThunk("NonConformites/Edit/Criterias/Responsabilite", async (codeSite, thunkAPI) => {
  const response = await axios.get(NC_GET_RESPONSABILITE + paramsToQueryParam({ codeSite }));
  return response.data;
});

export const {
  selectAll: selectAllTypeProduitDeverses,
  selectById: selectTypeProduitDeverseById,
} = typeProduitDeverseAdapter.getSelectors((state) => state.nonConformiteEditCriteria.typeProduitDeverses);
export const fetchTypeProduitDeverse = createAsyncThunk("NonConformites/Edit/Criterias/TypeProduitDeverse", async (codeSite, thunkAPI) => {
  const response = await axios.get(NC_GET_TYPE_PRODUIT_DEVERSE + paramsToQueryParam({ codeSite }));
  return response.data;
});

export const fetchListeChoix = createAsyncThunk("NonConformites/Edit/Criterias/ListeChoix", async (codeSite, thunkAPI) => {
  const response = await axios.get(NC_GET_LISTE_CHOIX + paramsToQueryParam({ codeSite }));
  return response.data;
});

export const {
  selectAll: selectAllNcSynchroChamps,
  selectById: selectNcSynchroChampById,
} = ncSynchroChampsAdapter.getSelectors((state) => state.nonConformiteEditCriteria.ncSynchroChamps);
export const fetchNcSynchroChamps= createAsyncThunk("NonConformites/Edit/Criterias/NcSynchroChamps", async (nomTable, thunkAPI) => {
  const response = await axios.get(NC_GET_SYNCHRO_CHAMPS + paramsToQueryParam({ nomTable }));
  return response.data;
});

const initialState = {
  processes: processesAdapter.getInitialState(),
  units: unitsAdapter.getInitialState(),
  components: componentsAdapter.getInitialState(),
  samples: samplesAdapter.getInitialState(),
  types: typesAdapter.getInitialState(),
  severities: severitiesAdapter.getInitialState(),
  classifications: classificationsAdapter.getInitialState(),
  uniteMesures: uniteMesuresAdapter.getInitialState(),
  categorieCouts: categorieCoutsAdapter.getInitialState(),
  causeImmediate: causeImmediateAdapter.getInitialState(),
  typeCauseFondam: typeCauseFondamAdapter.getInitialState(),
  causeFondamentale: {},
  list1: list1Adapter.getInitialState(),
  codeDecision: codeDecisionAdapter.getInitialState(),
  typeNonConformite: typeNonConformiteAdapter.getInitialState(),
  steps: stepsAdapter.getInitialState(),
  products: productsAdapter.getInitialState(),
  serialNumbers: serialNumbersAdapter.getInitialState(),
  lotNumbers: lotNumbersAdapter.getInitialState(),
  origins: originsAdapter.getInitialState(),
  workOrders: workOrdersAdapter.getInitialState(),
  partNumbers: partNumbersAdapter.getInitialState(),
  workShifts: workShiftsAdapter.getInitialState(),
  workTeams: workTeamsAdapter.getInitialState(),
  probabilities: probabilitiesAdapter.getInitialState(),
  noBDCs: noBDCsAdapter.getInitialState(),
  noDessins: noDessinsAdapter.getInitialState(),
  postes: postesAdapter.getInitialState(),
  taches: tachesAdapter.getInitialState(),
  typesRisques: typesRisquesAdapter.getInitialState(),
  risques: risquesAdapter.getInitialState(),
  aspectsRisque: aspectsRisqueAdapter.getInitialState(),
  gravites: gravitesAdapter.getInitialState(),
  elementsAudit: elementsAuditAdapter.getInitialState(),
  elementsInspection: elementsInspectionAdapter.getInitialState(),
  equipements: equipementsAdapter.getInitialState(),
  produitNoms: produitNomsAdapter.getInitialState(),
  listeChoixPersoDetail: listeChoixPersoDetailAdapter.getInitialState(),
  impactsEASM: impactsEASMAdapter.getInitialState(),
  elementsInsp: impactsEASMAdapter.getInitialState(),
  otherIdentifier: otherIdentifierAdapter.getInitialState(),
  ncReferences: ncReferencesAdapter.getInitialState(),
  statutAccidents: statutAccidentAdapter.getInitialState(),
  dispositions: dispositionAdapter.getInitialState(),
  responsabilites: responsabiliteAdapter.getInitialState(),
  typeProduitDeverses: typeProduitDeverseAdapter.getInitialState(),
  ncSynchroChamps: ncSynchroChampsAdapter.getInitialState(),
  loadingProcesses: false,
  loadingUnits: false,
  loadingComponents: false,
  loadingSamples: false,
  clientDialog: {}
};

const nonConformiteEditCriteriaSlice = createSlice({
  name: 'nonConformiteEditCriteria',
  initialState,
  reducers: {
  },
  extraReducers: {
    [fetchProcesses.pending]: (state, action) => {
      state.loadingProcesses = true;
    },
    [fetchProcesses.fulfilled]: (state, action) => {
      processesAdapter.setAll(state.processes, sortArray(action.payload));
      state.loadingProcesses = false;
    },
    [fetchUnits.pending]: (state, action) => {
      state.loadingUnits = true;
    },
    [fetchUnits.fulfilled]: (state, action) => {
      unitsAdapter.setAll(state.units, sortArray(action.payload));
      state.loadingUnits = false;
    },
    [fetchComponents.pending]: (state, action) => {
      state.loadingComponents = true;
    },
    [fetchComponents.fulfilled]: (state, action) => {
      componentsAdapter.setAll(state.components, sortArray(action.payload));
      state.loadingComponents = false;
    },
    [fetchSamples.pending]: (state, action) => {
      state.loadingSamples = true;
    },
    [fetchSamples.fulfilled]: (state, action) => {
      samplesAdapter.setAll(state.samples, sortArray(action.payload));
      state.loadingSamples = false;
    },
    [fetchTypes.fulfilled]: (state, action) => {
      typesAdapter.setAll(state.types, sortArray(action.payload));
    },
    [fetchSeverities.fulfilled]: (state, action) => {
      severitiesAdapter.setAll(state.severities, sortArray(action.payload));
    },
    [fetchClassifications.fulfilled]: (state, action) => {
      classificationsAdapter.setAll(state.classifications, sortArray(action.payload));
    },
    [fetchUniteMesures.fulfilled]: (state, action) => {
      uniteMesuresAdapter.setAll(state.uniteMesures, sortArray(action.payload));
    },
    [fetchCategorieCouts.fulfilled]: (state, action) => {
      categorieCoutsAdapter.setAll(state.categorieCouts, sortArray(action.payload));
    },
    [fetchCauseImmediate.fulfilled]: (state, action) => {
      causeImmediateAdapter.setAll(state.causeImmediate, sortArray(action.payload));
    },
    [fetchTypeCauseFondam.fulfilled]: (state, action) => {
      typeCauseFondamAdapter.setAll(state.typeCauseFondam, sortArray(action.payload));
    },
    [fetchCauseFondamentale.fulfilled]: (state, action) => {
      const { fondamId, data } = action.payload
      if(fondamId){
        state.causeFondamentale[fondamId] = data
      }
    },
    [fetchList1.fulfilled]: (state, action) => {
      list1Adapter.setAll(state.list1, sortArray(action.payload));
    },
    [fetchCodeDecision.fulfilled]: (state, action) => {
      codeDecisionAdapter.setAll(state.codeDecision, sortArray(action.payload));
    },
    [fetchTypeNonConformite.fulfilled]: (state, action) => {
      typeNonConformiteAdapter.setAll(state.typeNonConformite, sortArray(action.payload));
    },
    [fetchSteps.fulfilled]: (state, action) => {
      stepsAdapter.setAll(state.steps, sortArray(action.payload));
    },
    [fetchProducts.fulfilled]: (state, action) => {
      productsAdapter.setAll(state.products, sortArray(action.payload));
    },
    [fetchSerialNumbers.fulfilled]: (state, action) => {
      serialNumbersAdapter.setAll(state.serialNumbers, sortArray(action.payload));
    },
    [fetchLotNumbers.fulfilled]: (state, action) => {
      lotNumbersAdapter.setAll(state.lotNumbers, sortArray(action.payload));
    },
    [fetchOrigins.fulfilled]: (state, action) => {
      originsAdapter.setAll(state.origins, sortArray(action.payload));
    },
    [fetchWorkOrders.fulfilled]: (state, action) => {
      workOrdersAdapter.setAll(state.workOrders, sortArray(action.payload));
    },
    [fetchPartNumbers.fulfilled]: (state, action) => {
      partNumbersAdapter.setAll(state.partNumbers, sortArray(action.payload));
    },
    [fetchWorkShifts.fulfilled]: (state, action) => {
      workShiftsAdapter.setAll(state.workShifts, sortArray(action.payload));
    },
    [fetchWorkTeams.fulfilled]: (state, action) => {
      workTeamsAdapter.setAll(state.workTeams, sortArray(action.payload));
    },
    [fetchProbabilities.fulfilled]: (state, action) => {
      probabilitiesAdapter.setAll(state.probabilities, sortArray(action.payload));
    },
    [fetchNoBDCs.fulfilled]: (state, action) => {
      noBDCsAdapter.setAll(state.noBDCs, sortArray(action.payload));
    },
    [fetchNoDessins.fulfilled]: (state, action) => {
      noDessinsAdapter.setAll(state.noDessins, sortArray(action.payload));
    },
    [fetchPostes.fulfilled]: (state, action) => {
      postesAdapter.setAll(state.postes, sortArray(action.payload));
    },
    [fetchTaches.fulfilled]: (state, action) => {
      tachesAdapter.setAll(state.taches, sortArray(action.payload));
    },
    [fetchTypesRisques.fulfilled]: (state, action) => {
      typesRisquesAdapter.setAll(state.typesRisques, sortArray(action.payload));
    },
    [fetchRisques.fulfilled]: (state, action) => {
      risquesAdapter.setAll(state.risques, sortArray(action.payload));
    },
    [fetchAspectsRisque.fulfilled]: (state, action) => {
      aspectsRisqueAdapter.setAll(state.aspectsRisque, action.payload);
    },
    [fetchGravites.fulfilled]: (state, action) => {
      gravitesAdapter.setAll(state.gravites, sortArray(action.payload));
    },
    [fetchElementsAudit.fulfilled]: (state, action) => {
      elementsAuditAdapter.setAll(state.elementsAudit, action.payload);
    },
    [fetchElementsInspection.fulfilled]: (state, action) => {
      elementsInspectionAdapter.setAll(state.elementsInspection, action.payload);
    },
    [fetchEquipements.fulfilled]: (state, action) => {
      equipementsAdapter.setAll(state.equipements, sortArray(action.payload));
    },
    [fetchProduitNoms.fulfilled]: (state, action) => {
      produitNomsAdapter.setAll(state.produitNoms, sortArray(action.payload));
    },
    [fetchListeChoixPersoDetail.fulfilled]: (state, action) => {
      listeChoixPersoDetailAdapter.setAll(state.listeChoixPersoDetail, sortArray(action.payload));
    },
    [fetchImpactsEASM.fulfilled]: (state, action) => {
      impactsEASMAdapter.setAll(state.impactsEASM, action.payload);
    },
    [fetchElementsInsp.fulfilled]: (state, action) => {
      elementsInspAdapter.setAll(state.elementsInsp, action.payload);
    },
    [fetchOtherIdentifier.fulfilled]: (state, action) => {
      otherIdentifierAdapter.setAll(state.otherIdentifier, action.payload);
    },
    [getNCReferences.fulfilled]: (state, action) => {
      ncReferencesAdapter.setAll(state.ncReferences, action.payload);
    },
    [getStatutAccident.fulfilled]: (state, action) => {
      statutAccidentAdapter.setAll(state.statutAccidents, action.payload);
    },
    [fetchDispositions.fulfilled]: (state, action) => {
      dispositionAdapter.setAll(state.dispositions, action.payload);
    },
    [fetchResponsabilites.fulfilled]: (state, action) => {
      responsabiliteAdapter.setAll(state.responsabilites, action.payload);
    },
    [fetchTypeProduitDeverse.fulfilled]: (state, action) => {
      typeProduitDeverseAdapter.setAll(state.typeProduitDeverses, action.payload);
    },
    [fetchNcSynchroChamps.fulfilled]: (state, action) => {
      ncSynchroChampsAdapter.setAll(state.ncSynchroChamps, action.payload);
    },
    [fetchListeChoix.fulfilled]: (state, action) => {
      state.listeChoixes = action.payload
    }
  }
});

export default nonConformiteEditCriteriaSlice.reducer;