import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'Services/AxiosService';
import { listToQueryParam, paramsToQueryParam } from 'store/UrlUtils';
import { FORMATION_SEARCH } from 'store/UrlConstants';

const formationSearchAdapter = createEntityAdapter({
    selectId: (entity) => entity.formationId
});

export const fetchFormations = createAsyncThunk("FormationSearch/Search", async (criteriasObj) => {
    const response = await axios.post(FORMATION_SEARCH, criteriasObj);
    return response.data;
});

export const {
    selectAll: selectAllFormations,
    selectById: selectFormationById,
} = formationSearchAdapter.getSelectors((state) => state.formationSearch.formations);

const initialState = {
    formations: formationSearchAdapter.getInitialState(),
    loadingFormations: false,
};

const formationSearchSlice = createSlice({
    name: "FormationSearchSlice",
    initialState,
    reducers: {},
    extraReducers: {
        [fetchFormations.pending]: (state, action) => {
            state.loadingFormations = true;
        },
        [fetchFormations.rejected]: (state, action) => {
            state.loadingFormations = true;
        },
        [fetchFormations.fulfilled]: (state, action) => {
            formationSearchAdapter.setAll(state.formations, action.payload);
            state.loadingFormations = false;
        }
    },
});

export const { } = formationSearchSlice.actions;

export default formationSearchSlice.reducer;