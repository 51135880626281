import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import axios from "Services/AxiosService";
import { GET_UNIVERS_CRITERIA_FAVORITES, GET_UNIVERS_SEARCH, POST_UNIVERS_DELETE } from "store/UrlConstants";

const universeAdapter = createEntityAdapter();
const favUniversesAdapter = createEntityAdapter({ selectId: (entity) => entity.titre });

export const getKnowledgeUniversSearch = createAsyncThunk('kmUniverseSearch/KnowledgeUniversSearch', async (params) => {
  const response = await axios.post(GET_UNIVERS_SEARCH, params);
  return response.data;
});

export const knowledgeUniverseDeleteOne = createAsyncThunk('KnowledgeUnivers/DeleteOne', async (_knowledgeUniversId, { rejectWithValue }) => {
  const _params = { idDossier: _knowledgeUniversId };

  try {
    const response = await axios.delete(POST_UNIVERS_DELETE, { params: _params });
    return { deleted: response.data, KnowledgeUniversId: _knowledgeUniversId };

  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const {
  selectAll: selectAllUniverses,
  selectById: selectUniverseById,
} = universeAdapter.getSelectors((state) => state.kmUniverseSearch.universeList);

const initialState = {
  universeList: universeAdapter.getInitialState(),
  searchParams: {
    count: true,
    page: 1,
    nbByPage: 15,
    nbParPageId: 3,
    sort: 1,
    criterias: {
      favorites: false,
      sites: [],
      universFavorites: [],
      consultationStateMe: {
        consultationUnit: 1,
        consultationValue: 0
      },
    }
  },
  searchCount: 0,
  searchInProgress: false,
  view: 'grid',
  lastSearchContentForm: null,
  contentViewMode: 'grid'
};

const KmUniverseSearchSlice = createSlice({
  name: 'kmUniverseSearch',
  initialState: initialState,
  reducers: {
    updateUCListItemField(state, action) {
      state.universeList.entities[action.payload.id][action.payload.field] = action.payload.value;
    },
    updateUC(state, action) {
      universeAdapter.updateOne(state.item, action.payload);
    },
    emptyUniverseList(state, action) {
      universeAdapter.removeAll(state.universeList);
    },
    setSearchParams(state, action) {
      state.searchParams = action.payload;
    },
    initSearchParamsSites(state, action) {
      let favCodes = [];
      action.payload.forEach(function (site) {
        if (site.selected) {
          favCodes.push(site.code);
        }
      });
      state.searchParams.criterias.sites = favCodes;
    },
    setUCView(state, action) {
      state.view = action.payload;
    },
    setLastSearchContentForm(state, action) {
      state.lastSearchContentForm = action.payload
    },
    setContentViewMode(state, action) {
      state.contentViewMode = action.payload;
    }
  },
  extraReducers: {
    [getKnowledgeUniversSearch.pending]: (state, action) => {
      state.searchInProgress = true;
    },
    [getKnowledgeUniversSearch.rejected]: (state, action) => {
      state.searchInProgress = false;
    },
    [getKnowledgeUniversSearch.fulfilled]: (state, action) => {
      universeAdapter.setAll(state.universeList, action.payload.results);
      if (action.payload.count)
        state.searchCount = action.payload.count;
      state.searchInProgress = false;
    },
    [knowledgeUniverseDeleteOne.fulfilled]: (state, action) => {
      universeAdapter.removeOne(state.universeList, action.payload.KnowledgeUniversId)
    },
  }
});

export const {
  updateUCListItemField,
  updateUC,
  emptyUniverseList,
  setSearchParams,
  initSearchParamsSites,
  setUCView,
  setLastSearchContentForm,
  setContentViewMode
} = KmUniverseSearchSlice.actions;

export const universeSearchSliceSelector = state => state.kmUniverseSearch;

export const selectUniverseSearchCount = createSelector(universeSearchSliceSelector, result => result.searchCount);
export const selectContentViewMode = createSelector(universeSearchSliceSelector, result => result.contentViewMode);

export default KmUniverseSearchSlice.reducer;