import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import axios from "Services/AxiosService";
import { GET_KM_OBJECT_EMPLOYES } from "store/UrlConstants";

const kmObjAuthorsAdapter = createEntityAdapter({ selectId: entity => entity.employeId });
const kmObjResponsiblesAdapter = createEntityAdapter({ selectId: entity => entity.employeId });

export const {
    selectAll: selectAllKmObjAuthors,
    selectById: selectOneKmObjAuthorsById
} = kmObjAuthorsAdapter.getSelectors(store => store.kmObjectCriteria.kmObjAuthors)

export const {
    selectAll: selectAllKmObjResponsibles,
    selectById: selectOneKmObjResponsibleById
} = kmObjResponsiblesAdapter.getSelectors(store => store.kmObjectCriteria.kmObjResponsibles)

export const getKmObjEmployes = createAsyncThunk("KmObj/Criteria/Employes/Get", async () => {
    const res = await axios.get(GET_KM_OBJECT_EMPLOYES);
    return res.data
});

const initialState = {
    kmObjResponsibles: kmObjResponsiblesAdapter.getInitialState({ loading: false }),
    kmObjAuthors: kmObjAuthorsAdapter.getInitialState({ loading: false }),
}

const knowledgeObjectCriteriaSlice = createSlice({
    name: "knowledgeObjectCriteria",
    initialState,
    reducers: {
        resetKmObjResponsibles(state, action) {
            kmObjResponsiblesAdapter.removeAll(state.kmObjResponsibles);
        },
        resetKmObjAuthors(state, action) {
            kmObjAuthorsAdapter.removeAll(state.kmObjAuthors);
        }
    },
    extraReducers: {
        [getKmObjEmployes.pending]: (state, action) => {
            state.kmObjAuthors.loading = true
            state.kmObjResponsibles.loading = true
        },
        [getKmObjEmployes.rejected]: (state, action) => {
            state.kmObjAuthors.loading = false
            state.kmObjResponsibles.loading = false
        },
        [getKmObjEmployes.fulfilled]: (state, action) => {
            kmObjAuthorsAdapter.setAll(state.kmObjAuthors, action.payload.auteurs);
            state.kmObjAuthors.loading = false

            kmObjResponsiblesAdapter.setAll(state.kmObjResponsibles, action.payload.responsables);
            state.kmObjResponsibles.loading = false
        }
    }
})

export const { resetKmObjAuthors, resetKmObjResponsibles } = knowledgeObjectCriteriaSlice.actions;

export default knowledgeObjectCriteriaSlice.reducer;