import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import axios from "Services/AxiosService";
import {
    maintCrudUrlsFactory, UPDATE_OBJETS_LANGUES_2,
    ADD_MAINT_MULTILANGUE_TRADUCTION, UPDATE_MAINT_MULTILANGUE_DESCRIPTION_TRADUITE,
    ADD_SITE_ASSOCIE, ADD_SITES_ASSOCIES, DELETE_SITE_ASSOCIE, DELETE_SITES_ASSOCIES,
} from "store/UrlConstants";
import { showSnackbarMessage } from "store/slices/MessagesSystemSlice";

var maintCrud = {};
var tabAssocieCrud = {};

function getExecutedErrorMsg(error) {
    const originalMessage = error.response.data?.messageText || error.response.data;
    const cutIndex = originalMessage.indexOf("executed:")
    return originalMessage.slice(cutIndex, originalMessage.length).replace("executed: ", "")
}


/* ======================================================================= */
// MaintObj
/* ======================================================================= */
const maintObjAdapter = createEntityAdapter();

export const addMaintObj = createAsyncThunk("MaintenanceGeneral/Add", async (maintObj, thunkAPI) => {
    try {
        const response = await axios.post(maintCrud.addOneUrl, maintObj);

        thunkAPI.dispatch(
            showSnackbarMessage({
                dba: "objetsLangues2",
                messageId: "web_confirmation_enregistrement",
                severity: "success",
            })
        );

        return response.data;

    } catch (error) {
        if (error.response.data?.messageId) {
            thunkAPI.dispatch(
                showSnackbarMessage({
                    messageId: error.response.data.messageId,
                    severity: "error",
                })
            );
        } else {
            thunkAPI.dispatch(
                showSnackbarMessage({
                    message: getExecutedErrorMsg(error),
                    severity: "error",
                })
            );
        }
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const fetchMaintObj = createAsyncThunk("MaintenanceGeneral/Get", async () => {
    const response = await axios.get(maintCrud.getOneUrl);
    return response.data;
});

export const updateMaintObj = createAsyncThunk("MaintenanceGeneral/Update", async (maintObj, thunkAPI) => {
    var objForUpdate = maintObj;
    var path = maintCrud.updateOneUrl;

    if (maintObj.isSysteme === 1) {
        objForUpdate = {
            code: maintObj.gravite ? maintObj.gravite : maintObj.descriptionParDefaut,
            langue: "FR",
            valeurAffichee: maintObj.description
        };
        path = UPDATE_OBJETS_LANGUES_2
    }

    try {
        const response = await axios.put(path, objForUpdate);

        thunkAPI.dispatch(
            showSnackbarMessage({
                dba: "objetsLangues2",
                messageId: "web_confirmation_enregistrement",
                severity: "success",
            })
        );

        return { updated: response.data, maintObjUpdated: maintObj };

    } catch (error) {
        if (error.response.data?.messageId) {
            thunkAPI.dispatch(
                showSnackbarMessage({
                    messageId: error.response.data.messageId,
                    severity: "error",
                })
            );
        } else {
            thunkAPI.dispatch(
                showSnackbarMessage({
                    message: getExecutedErrorMsg(error),
                    severity: "error",
                })
            );
        }
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const deleteMaintObj = createAsyncThunk("MaintenanceGeneral/Delete", async (id, thunkAPI) => {
    try {
        if (id.id === 0) {
            return { deleted: true, id: id.id }
        } else {
            const response = await axios.delete(maintCrud.deleteOneUrl, { params: id });

            thunkAPI.dispatch(
                showSnackbarMessage({
                    dba: "objetsLangues2",
                    messageId: "information_supprimee",
                    severity: "success",
                })
            );

            return { deleted: response.data, id: id.id }
        }
    }
    catch (error) {
        thunkAPI.dispatch(
            showSnackbarMessage({
                messageId: "sogetel_baselib_utilisepasdelete",
                severity: "error",
            })
        );
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const {
    selectAll: selectAllMaintObj,
    selectById: selectMaintObjById,
} = maintObjAdapter.getSelectors((state) => state.maintenanceGeneral.maintObj);


/* ======================================================================= */
// MaintMultilangueTraduction
/* ======================================================================= */
export const addMaintMultilangueTraductions = createAsyncThunk("MaintenanceGeneral/MaintMultilangueTraduction/Add", async (maintMultilangueTraduction, thunkAPI) => {
    try {
        const response = await axios.post(ADD_MAINT_MULTILANGUE_TRADUCTION, maintMultilangueTraduction);

        thunkAPI.dispatch(
            showSnackbarMessage({
                dba: "objetsLangues2",
                messageId: "web_confirmation_enregistrement",
                severity: "success",
            })
        );

        return response.data;

    } catch (error) {
        const originalMessage = error.response.data;
        const cutIndex = originalMessage.indexOf("executed:")
        const msg = originalMessage.slice(cutIndex, originalMessage.length).replace("executed: ", "")

        thunkAPI.dispatch(
            showSnackbarMessage({
                message: cutIndex ? msg : error.message,
                severity: "error",
            })
        );
    }
});

export const updateMaintMultilangueTraduction = createAsyncThunk("MaintenanceGeneral/MaintMultilangueTraduction/Update", async (maintMultilangueTraduction, thunkAPI) => {
    try {
        const response = await axios.put(UPDATE_MAINT_MULTILANGUE_DESCRIPTION_TRADUITE, maintMultilangueTraduction);

        thunkAPI.dispatch(
            showSnackbarMessage({
                dba: "objetsLangues2",
                messageId: "web_confirmation_enregistrement",
                severity: "success",
            })
        );

        return { updated: response.data, maintMultilangueTraductionUpdated: maintMultilangueTraduction };

    } catch (error) {

        const originalMessage = error.response.data;
        const cutIndex = originalMessage.indexOf("executed:")
        const msg = originalMessage.slice(cutIndex, originalMessage.length).replace("executed: ", "")

        thunkAPI.dispatch(
            showSnackbarMessage({
                message: cutIndex ? msg : error.message,
                severity: "error",
            })
        );
        return thunkAPI.rejectWithValue(error.response.data);
    }
});


/* ======================================================================= */
// SitesAssocies
/* ======================================================================= */
// maintSiteAssocie = { siteId, maintSiteId }
export const addSiteAssocie = createAsyncThunk("MaintenanceGeneral/SitesAssocies/Add", async (maintSiteAssocie, thunkAPI) => {
    try {
        const response = await axios.post(ADD_SITE_ASSOCIE, maintSiteAssocie);

        thunkAPI.dispatch(
            showSnackbarMessage({
                dba: "objetsLangues2",
                messageId: "web_confirmation_enregistrement",
                severity: "success",
            })
        );

        return response.data;

    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

// maintSitesAssocies = [{ siteId, maintSiteId }, ...]
export const addSitesAssocies = createAsyncThunk("MaintenanceGeneral/SitesAssocies/AddList", async (maintSitesAssocies, thunkAPI) => {
    try {
        const response = await axios.post(ADD_SITES_ASSOCIES, maintSitesAssocies);

        thunkAPI.dispatch(
            showSnackbarMessage({
                dba: "objetsLangues2",
                messageId: "web_confirmation_enregistrement",
                severity: "success",
            })
        );

        return response.data;

    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

// maintSitesAssocies = { siteId, maintSiteId }
export const deleteSiteAssocie = createAsyncThunk("MaintenanceGeneral/SitesAssocies/Delete", async (maintSitesAssocies, thunkAPI) => {
    try {
        const response = await axios.delete(DELETE_SITE_ASSOCIE, { params: maintSitesAssocies });

        thunkAPI.dispatch(
            showSnackbarMessage({
                dba: "objetsLangues2",
                messageId: "web_confirmation_enregistrement",
                severity: "success",
            })
        );

        return { deleted: response.data, siteId: maintSitesAssocies.siteId };

    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const deleteSitesAssocies = createAsyncThunk("MaintenanceGeneral/SitesAssocies/DeleteList", async (maintSitesAssocies, thunkAPI) => {
    const response = await axios.delete(DELETE_SITES_ASSOCIES, { data: maintSitesAssocies });

    try {
        thunkAPI.dispatch(
            showSnackbarMessage({
                dba: "objetsLangues2",
                messageId: "web_confirmation_enregistrement",
                severity: "success",
            })
        );

        return { deleted: response.data, maintSitesAssocies: maintSitesAssocies };

    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});


/* ======================================================================= */
// TABLEAU ASSOCIÉS
/* ======================================================================= */
const tabAssocieAdapter = createEntityAdapter();

export const fetchTabAssocie = createAsyncThunk("MaintenanceGeneral/TabAssocie/Get", async (url, { rejectWithValue }) => {
    try {
        tabAssocieCrud = maintCrudUrlsFactory(url);
        const response = await axios.get(tabAssocieCrud.getOneUrl);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const {
    selectAll: selectAllTabAssocie,
} = tabAssocieAdapter.getSelectors((state) => state.maintenanceGeneral.tabAssocie);


/* ======================================================================= */
// Main Slice
/* ======================================================================= */
const initialState = {
    maintObj: maintObjAdapter.getInitialState({ loading: false }),
    tabAssocie: tabAssocieAdapter.getInitialState({ loading: false }),
    sitesApplicablesAjout: []
}

const maintenanceGeneralSlice = createSlice({
    name: "MaintenanceGeneral",
    initialState,
    reducers: {
        /* ----------------------------------------------------------------------- */
        // MaintObj
        /* ----------------------------------------------------------------------- */
        setMaintGeneralCrud(state, action) {
            maintCrud = maintCrudUrlsFactory(action.payload);
        },
        upsertOneMaintObj(state, action) {
            maintObjAdapter.upsertOne(state.maintObj, action.payload);
        },
        upsertManyMaintObj(state, action) {
            maintObjAdapter.upsertMany(state.maintObj, action.payload);
        },
        clearMaintObj(state) {
            maintObjAdapter.removeAll(state.maintObj);
        },
        setSitesApplicablesAjout(state, action){
            state.sitesApplicablesAjout = action.payload;
        }
    },
    extraReducers: {
        /* ----------------------------------------------------------------------- */
        // MaintObj
        /* ----------------------------------------------------------------------- */
        [addMaintObj.fulfilled]: (state, action) => {
            maintObjAdapter.addOne(state.maintObj, action.payload);
            maintObjAdapter.removeOne(state.maintObj, 0);
        },
        [fetchMaintObj.pending]: (state, action) => {
            state.maintObj.loading = true;
        },
        [fetchMaintObj.fulfilled]: (state, action) => {
            maintObjAdapter.setAll(state.maintObj, action.payload);
            state.maintObj.loading = false;
        },
        [updateMaintObj.fulfilled]: (state, action) => {
            maintObjAdapter.upsertOne(state.maintObj, action.payload.maintObjUpdated);
        },
        [deleteMaintObj.fulfilled]: (state, action) => {
            maintObjAdapter.removeOne(state.maintObj, action.payload.id);
        },

        /* ----------------------------------------------------------------------- */
        // Tableau Associé
        /* ----------------------------------------------------------------------- */
        [fetchTabAssocie.pending]: (state, action) => {
            state.tabAssocie.loading = true;
        },
        [fetchTabAssocie.fulfilled]: (state, action) => {
            tabAssocieAdapter.setAll(state.tabAssocie, action.payload);
            state.tabAssocie.loading = false;
        },
    },
});

export const {
    setMaintGeneralCrud, upsertOneMaintObj, upsertManyMaintObj, clearMaintObj, setSitesApplicablesAjout
} = maintenanceGeneralSlice.actions;

export default maintenanceGeneralSlice.reducer;