import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import axios from "Services/AxiosService";
import {
  ADD_DOCUMENT_CONNAISSANCE,
  DELETE_DOCUMENT_CONNAISSANCE,
  CONSULTATION_STOP,
  GET_DOCUMENT_CONNAISSANCE_INFO,
  GET_DOCUMENT_CONNAISSANCE_MANAGE,
  GET_DOC_CREATION_HISTORY,
  GET_KNOWLEDGE_OBJECT_ENDORSERS,
  GET_KNOWLEDGE_OBJECT_ENDOSSED_BY,
  GET_KNOWLEDGE_OBJECT_ONE,
  GET_KNOWLEDGE_OBJECT_OPTION_LIST,
  GET_KNOWLEDGE_OBJECT_SUBSCRIBERS,
  GET_OBJECT_RECENTS,
  GET_OBJECT_SEARCH,
  KNOWLEDGE_OBJECT_ADD_BOOKMARK,
  KNOWLEDGE_OBJECT_ALL_BOOKMARK,
  KNOWLEDGE_OBJECT_UPDATE_BOOKMARK,
  KNOWLEDGE_OBJECT_DELETE_BOOKMARK,
  KNOWLEDGE_OBJECT_COMMENT_ADD,
  KNOWLEDGE_OBJECT_CONFIRM,
  KNOWLEDGE_OBJECT_DELETE_EVALUATE,
  KNOWLEDGE_OBJECT_EVALUATE,
  KNOWLEDGE_OBJECT_TYPE_DOC,
  POST_KNOWLEDGE_OBJECT_ADD,
  POST_KM_OBJECT_NEW_VERSION,
  POST_KNOWLEDGE_OBJECT_UPDATE,
  POST_KNOWLEDGE_SUBSCRIBE,
  POST_KNOWLEDGE_UNSUBSCRIBE,
  POST_KNOWLEDGE_UPDATE_IMAGE,
  POST_OBJECT_APPROVE,
  POST_OBJECT_DELETE,
  POST_OBJECT_REVALIDATION,
  POST_OBJECT_UNAPPROVE,
  KNOWLEDGE_OBJECT_NOTIFICATION_RAPIDE,
  GET_CONSULTANTS_BY_DOSSIER_ID, UNAUTH_GET_FICHIER_DOCUMENT_DOC_MANAGE, KNOWLEDGE_OBJECT_COMMENT_FILES_ADD, GET_KM_OBJECT_LATEST_VERSION_BY_ID, GET_KM_OBJECT_LATEST_VERSION_BY_COTE, GET_TRUSTED_AGENTS, GET_ENDORSEMENT_TYPE_COUNTERS,
} from "store/UrlConstants";
import { listToQueryParam, paramsToQueryParam } from "store/UrlUtils";
import { showSnackbarMessage } from "../MessagesSystemSlice";
import { addManyBalise, deleteOneBalise } from "./KnowledgeBaliseSlice";
import {
  updateOneObjectList,
  removeOneObjectList,
} from "./KnowledgeObjectSearchSlice";
import KmObject from "store/Models/KmObject";
import { deleteNullValues } from "Utils/CommonFunctions";

const subscribersAdapter = createEntityAdapter({
  selectId: (entity) => entity.idEmploye,
});
const endorserAdapter = createEntityAdapter({
  selectId: (entity) => entity.employeId,
});
const endosedByAdapter = createEntityAdapter({
  selectId: (entity) => entity.employeId,
});
const recemmentConsultesAdapter = createEntityAdapter();
const typesDocAdapter = createEntityAdapter({
  selectId: (entity) => entity.noTypeDoc,
});
const objectOptionListAdapter = createEntityAdapter();
const creationHistoryAdapter = createEntityAdapter({
  selectId: (entity) => entity.historiqueDdossierId
});
const consultantsAdapter = createEntityAdapter({
  selectId: (entity) => entity.employeId
});

const trustedAgentsAdapter = createEntityAdapter({
  selectId: (entity) => entity.employeId
});

export const getKnowledgeObjectOne = createAsyncThunk(
  "KnowledgeObject/OneLogged",
  async (id) => {
    const params = { idDocument: id };
    const response = await axios.get(GET_KNOWLEDGE_OBJECT_ONE, {
      params: params,
    });
    return response.data;
  }
);

export const getKmObjectLatestVersionById = createAsyncThunk("KnowledgeObject/GetLatestVersion", async (dossierId) => {
  const resp = await axios.get(GET_KM_OBJECT_LATEST_VERSION_BY_ID, { params: { dossierId: dossierId } })
  return resp.data
});

export const getKmObjectLatestVersionByCote = createAsyncThunk("KnowledgeObject/GetLatestVersion", async (dosCote) => {
  const resp = await axios.get(GET_KM_OBJECT_LATEST_VERSION_BY_COTE, { params: { dosCote: dosCote } })
  return resp.data
});

function uploadFile(thunkAPI, file, url, promiseName) {
  const onUploadProgress = (eventProgress) => {
    thunkAPI.dispatch(
      setKmObjectUploadProgress(
        Math.round((100 * eventProgress.loaded) / eventProgress.total)
      )
    );
  };

  let formData = new FormData();
  formData.append("file", file);

  try {
    return axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
      })
      .then((res) => ({ res: res, promise: promiseName }));
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
}

export const addUpdateObjetConnaissance = createAsyncThunk("KnowledgeObject/AddUpdate",
  async ({ objetConnaissance, balises, mode, trustedAgentsIds }, thunkAPI) => {
    let idOC, oc;
    let url = "";

    if ((mode === "edit" || mode === "newVersion") && objetConnaissance.fichierJoint?.length > 0) {
      objetConnaissance.idAuteur = objetConnaissance.idEmploye
    }

    let formData = new FormData();
    for (let key in objetConnaissance) {
      if (key === 'fichierJoint' && Array.isArray(objetConnaissance[key])) {
        formData.append(key, objetConnaissance[key][0]);
      } else if (key === 'miniatures' && Array.isArray(objetConnaissance[key])) {
        objetConnaissance[key].forEach((miniature) => {
          formData.append(key, miniature);
        })
      } else {
        formData.append(key, objetConnaissance[key]);
      }
    }

    switch (mode) {
      case "add":
        url = POST_KNOWLEDGE_OBJECT_ADD + listToQueryParam("trustedAgentsIds", trustedAgentsIds);
        break;
      case "newVersion":
        url = POST_KM_OBJECT_NEW_VERSION + listToQueryParam("trustedAgentsIds", trustedAgentsIds);
        break;
      case "edit":
        url = POST_KNOWLEDGE_OBJECT_UPDATE + listToQueryParam("trustedAgentsIds", trustedAgentsIds);
        break;
      default:
        break;
    }

    const onUploadProgress = (eventProgress) => {
      thunkAPI.dispatch(
        setKmObjectUploadProgress(
          Math.round((100 * eventProgress.loaded) / eventProgress.total)
        )
      );
    };

    thunkAPI.dispatch(setKmObjectUploadInProgress(true));

    // Ajout / Nouvelle version / Edit de l'OC
    try {
      const responseAddOC = await axios.post(url, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress
      });

      oc = responseAddOC.data;
      idOC = oc.id;
      thunkAPI.dispatch(setKmObject(oc));
      thunkAPI.dispatch(getKMDocumentInfo(idOC));

    } catch (error) {
      let errorMsg = ""

      if (error.code === "ERR_NETWORK") {
        errorMsg = error.message
      } else {
        errorMsg = error.response.data.messageText
      }

      thunkAPI.dispatch(
        showSnackbarMessage({
          message: errorMsg,
          severity: "error",
          autoHideDuration: 10000,
        })
      );

      thunkAPI.dispatch(setKmObjectUploadInProgress(false));
      return thunkAPI.rejectWithValue(error.response.data);
    }

    // Ajout balises
    if (balises.toAdd && balises.toAdd.length > 0) {
      const baliseObjList = [];

      for (const baliseText of balises.toAdd) {
        const newBaliseObj = {
          idDossier: parseInt(idOC),
          balise: baliseText,
        };
        baliseObjList.push(newBaliseObj);
      }
      thunkAPI.dispatch(addManyBalise(baliseObjList));
    }

    // Retrait balises
    if (balises.toDelete && balises.toDelete.length > 0) {
      for (const baliseId of balises.toDelete) {
        thunkAPI.dispatch(deleteOneBalise(baliseId));
      }
    }

    // Active l'abonnement pour l'auteur du OC lors de la création
    if (mode === "add" || mode === "newVersion") {
      thunkAPI.dispatch(postKnowledgeUpdateSub(oc));
    } else if (objetConnaissance.fichierJoint?.length > 0 && !oc.abonne) {
      thunkAPI.dispatch(postKnowledgeUpdateSub(oc));
    }

    // Réinitialisation de la "liste des endosseurs" et des "endosseurs par" ainsi que de leur compteur
    // if (mode === "edit" && objetConnaissance.fichierJoint?.length > 0) {
    //   thunkAPI.dispatch(resetEndorsers);
    //   thunkAPI.dispatch(resetEndossedBy);
    // }

    thunkAPI.dispatch(
      showSnackbarMessage({
        messageId: 'IW_MSG_OC_ENREGISTRE',
        dba: 'objetsLangues2',
        severity: "success",
      })
    );

    // Reset des états d'uploads
    thunkAPI.dispatch(setKmObjectUploadProgress(0));
    thunkAPI.dispatch(setKmObjectUploadInProgress(false));

    return oc;
  }
);

export const addNewVersionKmObject = createAsyncThunk("KnowledgeObject/NewVersion", async (params, thunkAPI) => {
  const { id, title, description, typeDoc, natureChangement, idResponsable, fichierJoint, miniatures, balises, trustedAgentIds } = params

  if (fichierJoint?.length > 0) {
    let kmObj = {};
    let formData = new FormData();

    formData.append("fichierJoint", fichierJoint[0]);

    if (miniatures) {
      formData.append("miniatures", miniatures[0]);
    }

    const onUploadProgress = (eventProgress) => {
      thunkAPI.dispatch(
        setKmObjectUploadProgress(
          Math.round((100 * eventProgress.loaded) / eventProgress.total)
        )
      );
    };

    thunkAPI.dispatch(setKmObjectUploadInProgress(true));

    try {
      const response = await axios.post(POST_KM_OBJECT_NEW_VERSION + paramsToQueryParam({
        id: id,
        title: title,
        description: description,
        typeDoc: typeDoc,
        natureChangement: natureChangement,
        idResponsable: idResponsable
      }) + "&" + listToQueryParam("trustedAgentsIds", trustedAgentIds, false),
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress
        });

      kmObj = response.data;

      // Ajout balises
      if (balises.toAdd && balises.toAdd.length > 0) {
        const baliseObjList = [];

        for (const baliseText of balises.toAdd) {
          const newBaliseObj = {
            idDossier: parseInt(kmObj.id),
            balise: baliseText,
          };
          baliseObjList.push(newBaliseObj);
        }
        thunkAPI.dispatch(addManyBalise(baliseObjList));
      }

      // Retrait balises
      if (balises.toDelete && balises.toDelete.length > 0) {
        for (const baliseId of balises.toDelete) {
          thunkAPI.dispatch(deleteOneBalise(baliseId));
        }
      }

      // Active l'abonnement pour l'auteur du OC lors de la création
      if (!kmObj.abonne) {
        thunkAPI.dispatch(postKnowledgeUpdateSub(kmObj));
      }

      thunkAPI.dispatch(getKMDocumentInfo(kmObj.id));
      thunkAPI.dispatch(setKmObjectUploadInProgress(false));

      thunkAPI.dispatch(
        showSnackbarMessage({
          messageId: 'IW_MSG_OC_ENREGISTRE',
          dba: 'objetsLangues2',
          severity: "success",
        })
      );

      // Reset des états d'uploads
      thunkAPI.dispatch(setKmObjectUploadProgress(0));
      thunkAPI.dispatch(setKmObjectUploadInProgress(false));

      return response.data;

    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
        })
      );

      thunkAPI.dispatch(setKmObjectUploadInProgress(false));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
});

export const getKnowledgeObjectSearch = createAsyncThunk(
  "KnowledgeObject/Search",
  async (params) => {
    const response = await axios.get(
      GET_OBJECT_SEARCH + paramsToQueryParam(params)
    );
    return response.data;
  }
);

export const postKnowledgeObjectDelete = createAsyncThunk(
  "KnowledgeObject/Delete",
  async (KnowledgeObjectId, thunkAPI) => {
    const params = { idDocument: KnowledgeObjectId };
    try {
      await axios.delete(POST_OBJECT_DELETE, { params: params });
      thunkAPI.dispatch(removeOneObjectList(KnowledgeObjectId));
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getKnowledgeObjectSubscribers = createAsyncThunk(
  "KnowledgeObject/Subscribers",
  async (idDossier) => {
    const response = await axios.get(
      `${GET_KNOWLEDGE_OBJECT_SUBSCRIBERS}?idDocument=${idDossier}`
    );
    return response.data;
  }
);

export const getKnowledgeObjectEndorsers = createAsyncThunk(
  "KnowledgeObject/Endorsers",
  async (idDossier) => {
    const response = await axios.get(
      `${GET_KNOWLEDGE_OBJECT_ENDORSERS}?idDocument=${idDossier}`
    );
    return response.data;
  }
);

export const getKnowledgeObjectEndosedBy = createAsyncThunk(
  "KnowledgeObject/EndosedBy",
  async (idDossier) => {
    const response = await axios.get(
      `${GET_KNOWLEDGE_OBJECT_ENDOSSED_BY}?idDocument=${idDossier}`
    );
    return response.data;
  }
);

export const addKnowledgeObjectComment = createAsyncThunk("KnowledgeObjectComment/Add", async ({ comment, files }, thunkAPI) => {
  try {
    const newCommentResp = await axios.post(KNOWLEDGE_OBJECT_COMMENT_ADD, comment);
    let addedComment = newCommentResp.data;

    if (files?.length > 0) {
      let formData = new FormData()

      for (let key in files) {
        const file = files[key]
        if (typeof file === "object") {
          formData.append("files", file, file.name)
        }
      }

      const addFilesResp = await axios.post(
        KNOWLEDGE_OBJECT_COMMENT_FILES_ADD,
        formData,
        {
          params: { dossierCommentaireId: newCommentResp.data.id },
          headers: { "Content-Type": "multipart/form-data" },
        });

      addedComment = { ...newCommentResp.data, fichiers: addFilesResp.data }
    }
    return addedComment;

  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getKMDocumentInfo = createAsyncThunk("kmGeneral/DocumentInfo", async (idDossier, thunkAPI) => {
  try {
    const response = await axios.get(`${GET_DOCUMENT_CONNAISSANCE_INFO}/${idDossier}`);
    return response.data;

  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText.replace("~r~n", "\n"),
        severity: "error",
      })
    );

    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getKMDocumentManage = createAsyncThunk(
  "KnowledgeObject/GetDocumentManage",
  async ({ idDossier, loggedOut }, thunkAPI) => {
    const response = await axios.get(
      `${loggedOut ? UNAUTH_GET_FICHIER_DOCUMENT_DOC_MANAGE : GET_DOCUMENT_CONNAISSANCE_MANAGE}/${idDossier}`
    );
    return response.data;
  }
);

export const getObjetConnaissanceRecementConsulte = createAsyncThunk(
  "KnowledgeObject/RecentsLogged/Get",
  async (params) => {
    const response = await axios.get(
      GET_OBJECT_RECENTS + paramsToQueryParam(params)
    );
    return response.data;
  }
);

export const deleteKnowledgeObjectRecentsLogged = createAsyncThunk(
  "KnowledgeObject/RecentsLogged/Delete",
  async (idDossier) => {
    const paramInt = { paramInt: idDossier };
    const response = await axios.post(CONSULTATION_STOP, paramInt);
    return { deleted: response.data, id: idDossier };
  }
);

export const approuverUnapprouverObjetConnaissance = createAsyncThunk(
  "KnowledgeObject/ApprouverUnapprouver",
  async (objetConnaissance, thunkAPI) => {
    const path = objetConnaissance.approuved
      ? POST_OBJECT_UNAPPROVE
      : POST_OBJECT_APPROVE;
    const paramInt = { paramInt: objetConnaissance.id };

    try {
      const response = await axios.post(path, paramInt).then((resp) => {
        const newCount = objetConnaissance.approuved
          ? objetConnaissance.approuvedCount - 1
          : objetConnaissance.approuvedCount + 1;

        // For screen CardView / ListView
        const kmObjectList = thunkAPI.getState().kmObjectSearch.objectList.entities
        const staDescActuel = kmObjectList[objetConnaissance.id]?.staDesc

        const updatedObjetConnaissance = {
          ...objetConnaissance,
          approuved: !objetConnaissance.approuved,
          approuvedCount: newCount,
          staDesc: objetConnaissance.approuved ? staDescActuel : (resp.data ? 'Actif' : staDescActuel)
        }

        thunkAPI.dispatch(updateOneObjectList(updatedObjetConnaissance));

        if (resp.data) { //true == Document changed its status to endorsed
          const otherVersions = [];

          for (const key in kmObjectList) {
            const kmObj = kmObjectList[key];

            if (kmObj.cote === objetConnaissance.cote && kmObj.id !== objetConnaissance.id && kmObj.staDesc === "Actif") {
              otherVersions.push(kmObj);
            }
          }

          otherVersions.forEach(kmObj => {
            thunkAPI.dispatch(updateOneObjectList({
              ...kmObj,
              staDesc: "Semi-actif"
            }));
          });
        }

        // For screen KmObjectDetails
        const oldKmObject = thunkAPI.getState().kmObject.object;

        if (oldKmObject.id) {
          const newKmObject = {
            ...oldKmObject,
            approuved: !oldKmObject.approuved,
            approuvedCount: newCount,
            staDesc: objetConnaissance.approuved ? oldKmObject.staDesc : (resp.data ? 'Actif' : oldKmObject.staDesc)
          }
          thunkAPI.dispatch(setKmObject(newKmObject));
        }

      });

      thunkAPI.dispatch(getKnowledgeObjectEndorsers(objetConnaissance.id))
      thunkAPI.dispatch(getKnowledgeObjectEndosedBy(objetConnaissance.id))

      return response;

    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getKnowledgeObjectOptionList = createAsyncThunk(
  "KmKnowledge/ObjectOptionList",
  async (_params, { rejectWithValue }) => {
    try {
      const response = await axios.get(GET_KNOWLEDGE_OBJECT_OPTION_LIST, {
        params: _params,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue;
    }
  }
);

export const getKnowledgeObjectTypesDoc = createAsyncThunk(
  "universe/KnowledgeObjectGetTypesDoc",
  async (idDocument) => {
    const response = await axios.get(`${KNOWLEDGE_OBJECT_TYPE_DOC}?idDocument=${idDocument ? idDocument : ''}`);
    return response.data;
  }
);

export const addOneDocumentConnaissance = createAsyncThunk(
  "KnowledgeObject/AddOneDocument",
  async ({ file, idDossier, onUploadProgress }, thunkAPI) => {
    try {
      let formData = new FormData();
      formData.append("file", file);

      const response = await axios.post(
        `${ADD_DOCUMENT_CONNAISSANCE}?idDossier=${idDossier}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress,
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteOneDocumentConnaissance = createAsyncThunk("KnowledgeObject/DeleteOneDocument", async (idDocument, thunkAPI) => {
  try {
    const response = await axios.delete(`${DELETE_DOCUMENT_CONNAISSANCE}/${idDocument}`);

    // thunkAPI.dispatch(getKMDocumentInfo(idDocument));
    thunkAPI.dispatch(resetDocInfo());

    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "information_supprimee",
        severity: "success",
      })
    );

    return response.data;

  } catch (error) {

    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );

    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getDocCreationHistory = createAsyncThunk("KnowledgeObject/CreationHistory/Get", async (dosCote) => {
  const response = await axios.get(`${GET_DOC_CREATION_HISTORY}/${dosCote}`);
  return response.data;
});

export const knowledgeEvaluate = createAsyncThunk(
  "KnowledgeObject/Evaluation",
  async (objEvaluation, thunkAPI) => {
    try {
      const response = await axios.post(
        KNOWLEDGE_OBJECT_EVALUATE,
        objEvaluation
      );
      thunkAPI.dispatch(updateOCField({ field: "evaluationCount", value: response.data.evaluationCount }));
      thunkAPI.dispatch(updateOCField({ field: "evaluationMoyenne", value: response.data.evaluationMoyenne }));
      thunkAPI.dispatch(updateOCField({ field: "evaluationPersonnel", value: response.data.evaluationPersonnel }));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const knowledgeEvaluateDelete = createAsyncThunk("KnowledgeObject/EvaluationDelete", async (idDocument, thunkAPI) => {
  const param = { idDocument: idDocument };

  try {
    const response = await axios.delete(KNOWLEDGE_OBJECT_DELETE_EVALUATE, { params: param, });

    thunkAPI.dispatch(updateOCField({ field: "evaluationCount", value: response.data.evaluationCount }));
    thunkAPI.dispatch(updateOCField({ field: "evaluationMoyenne", value: response.data.evaluationMoyenne }));
    thunkAPI.dispatch(updateOCField({ field: "evaluationPersonnel", value: response.data.evaluationPersonnel }));

    return response.data;

  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
}
);

export const potKnowledgeObjectRevalidation = createAsyncThunk(
  "KnowledgeObject/Revalidate",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.post(POST_OBJECT_REVALIDATION, params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const knowledgeObjectConfirmation = createAsyncThunk(
  "KnowledgeObject/Confirm",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axios.post(KNOWLEDGE_OBJECT_CONFIRM, params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const postKnowledgeUpdateSub = createAsyncThunk(
  "KnowledgeObject/SubscriberUnsubscriber",
  async (objetConnaissance, thunkAPI) => {
    const path = objetConnaissance.abonne ? POST_KNOWLEDGE_UNSUBSCRIBE : POST_KNOWLEDGE_SUBSCRIBE;
    const paramInt = { paramInt: objetConnaissance.id };

    try {
      const response = await axios.post(path, paramInt).then(() => {
        const newCount = objetConnaissance.abonne ? objetConnaissance.abonneCount - 1 : objetConnaissance.abonneCount + 1;
        const updatedObjetConnaissance = { ...objetConnaissance, abonne: !objetConnaissance.abonne, abonneCount: newCount }
        thunkAPI.dispatch(updateOneObjectList(updatedObjetConnaissance));
        thunkAPI.dispatch(updateOCField({ field: "abonneCount", value: newCount }));
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const allBookmark = createAsyncThunk("KnowledgeObject/allBookmark", async (idDossier, thunkAPI) => {
  const response = await axios.get(KNOWLEDGE_OBJECT_ALL_BOOKMARK, { params: { idDossier: idDossier } });
  return response.data
})

export const addBookmark = createAsyncThunk("KnowledgeObject/addBookmark", async (bookmark, thunkAPI) => {
  try {
    const response = await axios.post(KNOWLEDGE_OBJECT_ADD_BOOKMARK, bookmark);
    return response.data;

  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateBookmark = createAsyncThunk("KnowledgeObject/updateBookmark", async (bookmark, thunkAPI) => {
  try {
    const response = await axios.post(KNOWLEDGE_OBJECT_UPDATE_BOOKMARK, bookmark)
    return response.data;

  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
})

export const deleteBookmark = createAsyncThunk("KnowledgeObject/deleteBookmark", async (idBookmark, thunkAPI) => {
  try {
    const response = await axios.delete(KNOWLEDGE_OBJECT_DELETE_BOOKMARK, { params: { idBookmark: idBookmark } });
    return response.data;

  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
})

export const sendNotificationRapide = createAsyncThunk("KnowledgeObject/notificationRapide", async (params, thunkAPI) => {
  try {
    const response = await axios.post(KNOWLEDGE_OBJECT_NOTIFICATION_RAPIDE, params);

    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "iw_oc_confirm_env_notif",
        severity: "success",
      })
    );

    return response.data;

  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getConsultantsByDossierId = createAsyncThunk("KnowledgeObject/ConsultantsByDossierId", async (dossierId) => {
  const response = await axios.get(GET_CONSULTANTS_BY_DOSSIER_ID, { params: { dossierId: dossierId } });
  return response.data;
})

export const getTrustedAgents = createAsyncThunk("KnowledgeObject/TrustedAgents/Get", async ({ kmUcId, kmOcId }) => {
  const response = await axios.get(GET_TRUSTED_AGENTS, { params: { kmUcId: kmUcId, kmOcId: kmOcId } })
  return response.data;
})

export const {
  selectAll: selectAllSubscribers,
  selectById: selectSubsriberById,
} = subscribersAdapter.getSelectors((state) => state.kmObject.subscribers);

export const { selectAll: selectAllEndorsers, selectById: selectEndorserById } =
  endorserAdapter.getSelectors((state) => state.kmObject.endorsers);

export const { selectAll: selectAllEndosedBy, selectById: selectEndosedById } =
  endosedByAdapter.getSelectors((state) => state.kmObject.endossedBy);

export const { selectAll: selectAllObjetsConnaissanceRescementConsulte } =
  recemmentConsultesAdapter.getSelectors(
    (state) => state.kmObject.recemmentConsultes
  );

export const kmObjectTypesDocsSelector = (state) => state.kmObject.knowledgeObjTypesDoc;
export const { selectAll: selectAllTypesDocOC } = typesDocAdapter.getSelectors(kmObjectTypesDocsSelector);
export const selectAllActivesKmObjectTypesDocs = createSelector(
  selectAllTypesDocOC,
  typesDocs => typesDocs.filter((typeDoc) => typeDoc.inactif === false)
);

export const creationHistorySelector = (store) => store.kmObject.creationHistory;
export const {
  selectAll: selectAllCreationHistory,
} = creationHistoryAdapter.getSelectors(creationHistorySelector);
export const selectLoadingCreationHistory = createSelector(
  creationHistorySelector,
  (state) => state.loading
);

export const {
  selectAll: selectAllObjectOptionList,
  selectById: selectObjectOptionListById,
} = objectOptionListAdapter.getSelectors(
  (state) => state.kmObject.objectOptionList
);

export const {
  selectAll: selectAllConsultantsByDossier
} = consultantsAdapter.getSelectors(
  (store) => store.kmObject.consultants
);

export const trustedAgentsSelector = (state) => state.kmObject.trustedAgents
export const { selectAll: selectAllTrustedAgents } = trustedAgentsAdapter.getSelectors(trustedAgentsSelector)
export const selectAllRecommendTrustedAgents = createSelector(
  selectAllTrustedAgents,
  (agents) => agents.filter((i) => i.section === 1)
)
export const selectAllSuggestTrustedAgents = createSelector(
  selectAllTrustedAgents,
  (agents) => agents.filter((i) => i.section === 2)
)
export const selectAllAvailableTrustedAgents = createSelector(
  selectAllTrustedAgents,
  (agents) => agents.filter((i) => i.section === 3)
)
export const selectLoadingTrustedAgents = createSelector(
  trustedAgentsSelector,
  (state) => state.loading
)

export const getEndorsementTypeCounters = createAsyncThunk("KnowledgeObject/GetEndorsementTypeCounters", async (dossierId) => {
  const response = await axios.get(GET_ENDORSEMENT_TYPE_COUNTERS, { params: { dossierId: dossierId } })
  return response.data;
})

export const endorsementTypeCountersSelector = (store) => store.kmObject.endorsementTypeCounters;

export const setEndorsementType = createAsyncThunk("KnowledgeObject/SetEndorsementType", async ({ dossierId, indTypeEndossement }, thunkAPI) => {
  const kmObjectSearch = thunkAPI.getState().kmObjectSearch.objectList.entities[dossierId];
  const kmObjectDetails = thunkAPI.getState().kmObject.object;
  const oldKmObject = await thunkAPI.dispatch(getKnowledgeObjectOne(dossierId)).unwrap()
  let kmObjectUpdated = { ...oldKmObject, indTypeEndossement: parseInt(indTypeEndossement) };

  let formData = new FormData();
  for (let key in deleteNullValues(kmObjectUpdated)) {
    if (key === 'fichierJoint' && Array.isArray(kmObjectUpdated[key])) {
      formData.append(key, kmObjectUpdated[key][0]);
    } else if (key === 'miniatures' && Array.isArray(kmObjectUpdated[key])) {
      kmObjectUpdated[key].forEach((miniature) => {
        formData.append(key, miniature);
      })
    } else {
      formData.append(key, kmObjectUpdated[key]);
    }
  }

  try {
    const response = await axios.post(POST_KNOWLEDGE_OBJECT_UPDATE, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    kmObjectUpdated = response.data;

    if (kmObjectSearch?.id === dossierId) {
      thunkAPI.dispatch(updateOneObjectList(kmObjectUpdated))
    } else if (kmObjectDetails?.id === dossierId) {
      thunkAPI.dispatch(setKmObject(kmObjectUpdated))
    }

    thunkAPI.dispatch(showSnackbarMessage({
      messageId: "web_confirmation_enregistrement",
      severity: "success",
      dba: "objetsLangues2"
    }));

    return response.data;

  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
})


const objectMetadaInitialValues = {
  key: null,
  name: null,
  mimeType: null,
  warning: null,
  size: 0,
};

const initialState = {
  object: KmObject(),
  kmObjLatestVersion: KmObject(),
  objectMetadata: objectMetadaInitialValues,
  kmObjectUploadInProgress: false,
  kmObjectUploadProgress: 0,
  kmImageUploadInProgress: false,
  kmImageUploadProgress: 0,
  subscribers: subscribersAdapter.getInitialState(),
  endorsers: endorserAdapter.getInitialState(),
  endossedBy: endosedByAdapter.getInitialState(),
  recemmentConsultes: recemmentConsultesAdapter.getInitialState(),
  knowledgeObjTypesDoc: recemmentConsultesAdapter.getInitialState(),
  objectOptionList: objectOptionListAdapter.getInitialState(),
  creationHistory: creationHistoryAdapter.getInitialState({ loading: false }),
  consultants: consultantsAdapter.getInitialState({ loading: false }),
  trustedAgents: trustedAgentsAdapter.getInitialState({ loading: false }),
  endorsementTypeCounters: { quantityEmployeesToDiffusion: 0, quantityEmployeesToSign: 0 }
};

const KmObjectSlice = createSlice({
  name: "kmObject",
  initialState: initialState,
  reducers: {
    updateOCField(state, action) {
      state.object[action.payload.field] = action.payload.value;
    },
    updateOneKnowledgeObject(state, action) {
      state.object = action.payload;
    },
    setKmObject(state, action) {
      state.object = action.payload;
    },
    resetKmObject(state, action) {
      state.object = KmObject();
    },
    resetKmObjLatestVersion(state) {
      state.kmObjLatestVersion = KmObject();
    },
    setKmObjectUploadProgress(state, action) {
      state.kmObjectUploadProgress = action.payload;
    },
    setKmImageUploadProgress(state, action) {
      state.kmImageUploadProgress = action.payload;
    },
    setKmObjectUploadInProgress(state, action) {
      state.kmObjectUploadInProgress = action.payload;
    },
    setKmImageUploadInProgress(state, action) {
      state.kmImageUploadInProgress = action.payload;
    },
    resetDocInfo(state) {
      state.objectMetadata = objectMetadaInitialValues;
    },
    resetConsultants(state) {
      state.consultants = consultantsAdapter.removeAll(state.consultants);
    },
    resetTrustedAgents(state) {
      trustedAgentsAdapter.removeAll(state.trustedAgents);
    },
    resetEndorsementTypeCounters(state) {
      state.endorsementTypeCounters = initialState.endorsementTypeCounters
    },
    resetEndorsers(state) {
      state.endorsers = endorserAdapter.removeAll(state.endorsers);
    },
    resetEndossedBy(state) {
      state.endossedBy = endosedByAdapter.removeAll(state.endossedBy);
    },
  },
  extraReducers: {
    [getKnowledgeObjectOne.fulfilled]: (state, action) => {
      state.object = action.payload;
    },
    [getKmObjectLatestVersionById.fulfilled]: (state, action) => {
      state.kmObjLatestVersion = action.payload;
    },
    [getKmObjectLatestVersionByCote.fulfilled]: (state, action) => {
      state.kmObjLatestVersion = action.payload;
    },
    [getKnowledgeObjectSubscribers.fulfilled]: (state, action) => {
      subscribersAdapter.setAll(state.subscribers, action.payload);
    },
    [getKnowledgeObjectEndorsers.fulfilled]: (state, action) => {
      endorserAdapter.setAll(state.endorsers, action.payload);
    },
    [getKnowledgeObjectEndosedBy.fulfilled]: (state, action) => {
      endosedByAdapter.setAll(state.endossedBy, action.payload);
    },
    [getKMDocumentInfo.rejected]: (state, action) => {
      state.objectMetadata = action.payload;
    },
    [getKMDocumentInfo.fulfilled]: (state, action) => {
      state.objectMetadata = action.payload;
    },
    [getObjetConnaissanceRecementConsulte.fulfilled]: (state, action) => {
      recemmentConsultesAdapter.setAll(
        state.recemmentConsultes,
        action.payload
      );
    },
    [deleteKnowledgeObjectRecentsLogged.fulfilled]: (state, action) => {
      recemmentConsultesAdapter.removeOne(
        state.recemmentConsultes,
        action.payload.id
      );
    },
    [addOneDocumentConnaissance.fulfilled]: (state, action) => {
      state.kmObjectUploadInProgress = false;
    },
    [getKnowledgeObjectTypesDoc.fulfilled]: (state, action) => {
      typesDocAdapter.setAll(state.knowledgeObjTypesDoc, action.payload);
    },
    [getKnowledgeObjectOptionList.fulfilled]: (state, action) => {
      objectOptionListAdapter.setAll(state.objectOptionList, action.payload);
    },
    [getDocCreationHistory.pending]: (state) => {
      state.creationHistory.loading = true;
    },
    [getDocCreationHistory.rejected]: (state) => {
      state.creationHistory.loading = false;
    },
    [getDocCreationHistory.fulfilled]: (state, action) => {
      creationHistoryAdapter.setAll(state.creationHistory, action.payload)
      state.creationHistory.loading = false;
    },
    [getConsultantsByDossierId.pending]: (state) => {
      state.consultants.loading = true;
    },
    [getConsultantsByDossierId.rejected]: (state) => {
      state.consultants.loading = false;
    },
    [getConsultantsByDossierId.fulfilled]: (state, action) => {
      consultantsAdapter.setAll(state.consultants, action.payload)
      state.consultants.loading = false;
    },
    [getTrustedAgents.pending]: (state) => {
      state.trustedAgents.loading = true
    },
    [getTrustedAgents.rejected]: (state) => {
      state.trustedAgents.loading = false
    },
    [getTrustedAgents.fulfilled]: (state, action) => {
      trustedAgentsAdapter.setAll(state.trustedAgents, action.payload)
      state.trustedAgents.loading = true
    },
    [getEndorsementTypeCounters.fulfilled]: (state, action) => {
      state.endorsementTypeCounters = action.payload
    },
  },
});

export const {
  updateOCField,
  updateOneKnowledgeObject,
  setKmObject,
  resetKmObject,
  resetKmObjLatestVersion,
  setKmObjectUploadProgress,
  setKmObjectUploadInProgress,
  setKmImageUploadInProgress,
  setKmImageUploadProgress,
  resetDocInfo,
  resetConsultants,
  resetTrustedAgents,
  resetEndorsementTypeCounters,
  resetEndorsers,
  resetEndossedBy
} = KmObjectSlice.actions;

export const kmObjectSelector = (state) => state.kmObject;

export const selectDocInfo = createSelector(
  kmObjectSelector,
  (state) => state.objectMetadata
);

export default KmObjectSlice.reducer;
