import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import axios from 'Services/AxiosService';
import { FETCH_COMPANY_CONTACT_DETAILS } from 'store/UrlConstants';

export const fetchCompanyContactDetails = createAsyncThunk('CompanyContactDetails', async () => {
  const response = await axios.get(FETCH_COMPANY_CONTACT_DETAILS);
  return response.data;
});

export const companyContactDetailsSelector = (store) => store.companyContactDetails.data;

export const getCompanyOfficialLanguageCode = createSelector(
  companyContactDetailsSelector,
  (state) => state.langueOfficielle
)

const companyContactDetailsSlice = createSlice({
  name: 'companyContactDetails',
  initialState: {
    data: {},
    loadInProgress: false
  },
  reducers: {},
  extraReducers: {
    [fetchCompanyContactDetails.pending]: (state, action) => {
      state.loadInProgress = true;
    },
    [fetchCompanyContactDetails.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loadInProgress = false;
    },
  }
});

export default companyContactDetailsSlice.reducer;