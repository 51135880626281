import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import axios from "Services/AxiosService";
import { paramsToQueryParam } from "store/UrlUtils";
import moment from "moment";
import Signalement from "store/Models/Signalement";
import {
  NC_LOAD,
  NC_LOAD_SIGNALEMENT,
  NC_LOAD_SIGNALEMENT_ACCPETATION,
  NC_LOAD_PERSO,
  NC_DUPLIQUER_PERSO,
  NC_LOAD_ECRAN_MENU,
  NC_UPDATE_ECRAN_MENU,
  NC_LOAD_TYPE_NC,
  NC_UPDATE_TYPE_NC,
  NC_LOAD_LIGNE_PRODUIT,
  NC_UPDATE_LIGNE_PRODUIT,
  NC_LOAD_HEURES_TRAVAILLEES,
  NC_UPDATE_HEURES_TRAVAILLEES,
  NC_ADD_SIGNALEMENT,
  NC_GET_NEW_SIGNALEMENT,
  NC_UPDATE_SIGNALEMENT,
  NC_UPDATE_SIGNALEMENT_ACCPETATION,
  NC_CHECK_AUTRE_IDENTIFIANT,
  NC_GET_SIGNALEMENT_COURRIEL,
  NC_LOAD_RENSEIGNEMENT,
  NC_UPDATE_RENSEIGNEMENT,
  NC_GET_RENSEIGNEMENT_COURRIEL,
  NC_LOAD_DEROGATION,
  NC_UPDATE_DEROGATION,
  NC_GET_DEROGATION_COURRIEL,
  NC_LOAD_DEROGATION_APPROBATION,
  NC_UPDATE_DEROGATION_APPROBATION,
  NC_DEFAULT_LOAD,
  NC_UPDATE_ADD,
  NC_LOAD_ANALYSE,
  NC_UPDATE_ANALYSE,
  NC_LOAD_ANALYSE_ENQUETE_DETAIL,
  NC_UPDATE_ANALYSE_ENQUETE_DETAIL,
  NC_UPDATE_ANALYSE_CAUSE_IMM_NC,
  NC_DELETE_ANALYSE_CAUSE_IMM_NC,
  NC_UPDATE_ANALYSE_CAUSE_FOND_ANALYSE,
  NC_DELETE_ANALYSE_CAUSE_FOND_NC,
  NC_UPDATE_ANALYSE_CAUSE_FOND_NC,
  NC_DELETE_ANALYSE_CAUSE_FOND_ANALYSE,
  NC_LOAD_ACTION,
  NC_ACTTION_UPDATE_RESUME,
  NC_ACTTION_DELETE_RESUME,
  NC_UPDATE_ACTION_ACTION,
  NC_DELETE_ACTION_ACTION,
  NC_LOAD_NEW_ACTION_ACTION,
  NC_LOAD_NEW_ACTION_SUIVI,
  NC_ACTION_UPDATE_SUIVI,
  NC_ACTION_DELETE_SUIVI,
  NC_LOAD_NEW_ACTION_COUT,
  NC_ACTION_UPDATE_COUT,
  NC_ACTION_DELETE_COUT,
  NC_LOAD_NEW_ACTION_CALENDRIER,
  NC_ACTION_ADD_CALENDRIER,
  NC_ACTION_UPDATE_CALENDRIER,
  NC_ACTION_DELETE_CALENDRIER,
  NC_LOAD_NEW_ACTION_RESUME,
  NC_LOAD_COUTS,
  NC_LOAD_NEW_COUT,
  NC_UPDATE_COUT,
  NC_DELETE_COUT,
  NC_GET_HYPERLIENS_EXIST,
  NC_DUPLIQUER,
  NC_LOAD_INCIDENTS_SOINS,
  NC_UPDATE_INCIDENTS_SOINS,
  NC_UPDATE_INCIDENTS_SOINS_STATUT,
  NC_UPDATE_INCIDENTS_SOINS_PREMIER_SOIN,
  NC_UPDATE_INCIDENTS_SOINS_PARTIE_CORPS_TOUCHES,
  NC_DELETE_INCIDENTS_SOINS_PARTIE_CORPS_TOUCHES,
  NC_UPDATE_INCIDENTS_SOINS_DOMMAGES_AUTRES,
  NC_UPDATE_INCIDENTS_SOINS_SUIVI_MEDICAL,
  NC_DELETE_INCIDENTS_SOINS_SUIVI_MEDICAL,
  NC_UPDATE_INCIDENTS_SOINS_ARRET_ET_ASSIGNATION,
  NC_DELETE_INCIDENTS_SOINS_ARRET_ET_ASSIGNATION,
  NC_UPDATE_INCIDENTS_SOINS_ASSIGNATION_TEMP,
  NC_DELETE_INCIDENTS_SOINS_ASSIGNATION_TEMP,
  NC_UPDATE_INCIDENTS_SOINS_COUT,
  NC_DELETE_INCIDENTS_SOINS_COUT,
  NC_UPDATE_INCIDENTS_SOINS_COMMENTAIRE,
  NC_DELETE_INCIDENTS_SOINS_COMMENTAIRE,
  NC_DELETE,
  NC_LOAD_CONFIG_SYNCHRO,
  NC_UPDATE_CONFIG_SYNCHRO_DSN,
  NC_UPDATE_CONFIG_SYNCHRO_TYPE_NC,
  NC_UPDATE_CONFIG_SYNCHRO_TYPE_DETAILS,
  NC_LOAD_CONFIG_NC,
  NC_UPDATE_CONFIG_NC,
  NC_LOAD_CONFIG_ALERTE,
  NC_LOAD_CONFIG_HEURES_TRAVAILLEES,
  NC_UPDATE_CONFIG_HEURES_TRAVAILLEES,
} from "store/UrlConstants";
import { showSnackbarMessage } from "../MessagesSystemSlice";
import { removeObjectProperties } from "Utils/CommonFunctions";

const initialState = {
  status: "idle",
  loadingInProgress: false,
  nonConformite: {
    code: "",
    codeSite: "",
    dateNonConform: moment().format("YYYY-MM-DD"),
    heureNonConform: moment().format("HH:mm:ss"),
    dateClassement: moment().format("YYYY-MM-DD"),
    description: "",
    identifiant2: null,
    rapportePar: null,
    codeProcede: null,
    codeUnite: null,
    codeComposante: null,
    codePtEchantillon: null,
    gravite: null,
    typeNonConformite: "",
    dossierClosON: "",
    isTraitementRequis: false,
    isDacpRequise: false,
    actionsFermees: 0,
    actionsTotal: 0,
    decisionInitDate: moment().format("YYYY-MM-DD"),
    decisionInitPar: "",
    decisionInitRaison: "",
    codeDecision: "",
    volets: [],
    voletIds: [],
    classeNC: null,
    etape: null,
    codeProduit: null,
    noSerie: null,
    noLot: null,
    inspectePar: null,
    origine: null,
    noBonTravail: null,
    noPiece: null,
    quartDeTravail: null,
    enTempsSuppON: false,
    equipeTravail: null,
    fournisseurNC: null,
    employe: null,
    clientNC: null,
    probabilite: null,
    noBonCommande: null,
    noDessin: null,
    recommandationActions: null,
    detailRemarque: null,
    codePoste: null,
    codeTache: null,
    victimeExterne: null,
    nomCieEmpBlesse: null,
    codeTypeRisque: null,
    codeRisque: null,
    temoin1: null,
    temoin2: null,
    codeAspectParametre: null,
    gravitePotentiel: null,
    idElement: null,
    codeAudit: null,
    noEchantillon: null,
    noEquipement: null,
    impactEASM: null,
  },
  ncAnalyse: {},
  loadingSignalement: false,
  savingSignalement: false,
  signalement: Signalement(),
  loadingSignalementCourriel: false,
  signalementsAcceptation: {},
  signalementCourriel: {},
  openEditDrawer: false,
  activeEditSection: null,
  loadingRenseignement: false,
  loadingRenseignementCourriel: false,
  rensiegnement: {},
  derogation: {},
  loadingCauseFondAnalyse: false,
  ncAction: {},
  causeImmNC: {
    causeImmNCId: '',
    codeNonConform: '',
    causeImmediate: '',
    remarque: '',
    nbHyperliens: 0,
    causeFondNCs: []
  },
  causeFondNC: {
    causeFondAnalyses: [],
    causeFondNCId: '',
    causeFondamentale: null,
    causeFondamentaleAffiche: null,
    causeImmediate: '',
    codeNonConform: '',
    dossierClosON: null,
    nbHyperliens: 0,
    remarque: null,
    typeCauseFondam: null,
    typeCauseFondamffiche: null,
    indInactifCauseFondamentale: 0,
    indInactifTypeCauseFondam: 0
  },
  causeFondAnalyse: {
    causeFondAnalyseId: null,
    causeFondNCId: null,
    descAnalyse: ""
  },
  actionResume: {},
  actionAction: {},
  suiviAction: {},
  actionCout: {},
  actionCalendrier: {},
  ncCouts: [],
  ncCout: {},
  loadPerso: {},
  loadingLoadPerso: false,
  ecranMenu: {},
  loadingEcranMenu: false,
  typeNc: {},
  loadingTypeNc: false,
  ligneProduit: {},
  loadingLigneProduit: false,
  heuresTravaillees: {},
  loadingHeuresTravaillees: false,
  duplicatingNC: false,
  incidentsSoins: {},
  loadingIncidentsSoins: false,
  ncConfigNC: {},
  loadingConfigNC: false,
  configSynchro: {},
  loadingConfigSynchro: false,
  configAlertes: [],
  loadingConfigAlerte: false,
  configHeuresTravaillees: {},
  loadingConfigHeuresTravaillees: false,
};

export const nonConformiteEditLoad = createAsyncThunk(
  "NonConformites/Edit/Load",
  async (params, thunkAPI) => {
    const response = await axios.get(NC_LOAD + paramsToQueryParam(params));
    return response.data;
  }
);

export const nonConformiteEditDefaultLoad = createAsyncThunk(
  "NonConformites/Edit/NewNc",
  async (params, thunkAPI) => {
    const response = await axios.get(NC_DEFAULT_LOAD + paramsToQueryParam(params));
    return response.data;
  }
);

export const nonConformiteDelete = createAsyncThunk("NonConformites/Edit/DeleteNC", async (params, thunkAPI) => {
  try {
    const { data } = await axios.delete(NC_DELETE + paramsToQueryParam(params));
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "information_supprimee",
        severity: "success",
      })
    );
    return data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const UpdateAddNC = createAsyncThunk("NonConformites/Edit/UpdateAddNC", async (data, thunkAPI) => {
  try {
    const response = await axios.post(NC_UPDATE_ADD, data);
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    return thunkAPI.rejectWithValue();
  }
});

export const nonConformiteAnalyseLoad = createAsyncThunk(
  "NonConformites/Edit/LoadAnalyse",
  async (params, thunkAPI) => {
    const response = await axios.get(NC_LOAD_ANALYSE + paramsToQueryParam(params));
    return response.data;
  }
);

export const updateNonConformiteAnalyse = createAsyncThunk("NonConformites/Edit/UpdateAnalyse", async (data, thunkAPI) => {
  try {
    let postData = { ...data }
    const { causeImmNCs } = data
    removeObjectProperties(postData, ['isUpdated'])
    postData.causeImmNCs = causeImmNCs?.map(cause => {
      let _cause = { ...cause }
      if (_cause.causeImmNCId === 'new') {
        _cause.causeImmNCId = null
      }
      _cause.causeFondNCs = _cause?.causeFondNCs?.map(fond => {
        let _fond = { ...fond }
        if (_fond.causeFondNCId === 'new') {
          _fond.causeFondNCId = null
        }
        _fond.causeFondAnalyses = _fond.causeFondAnalyses?.map(analyse => {
          let _analyse = { ...analyse }
          if (_analyse.causeFondAnalyseId === 'new') {
            _analyse.causeFondAnalyseId = null
          }
          return _analyse
        })
        return _fond
      })
      return _cause
    })
    const response = await axios.post(NC_UPDATE_ANALYSE, postData);
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const updateAnalyseCauseImmNC = createAsyncThunk("NonConformites/Edit/UpdateAnalyseCauseImmNC", async (data, thunkAPI) => {
  try {
    const postData = { ...data }
    const { causeFondNCs } = data
    if (postData.causeImmNCId === 'new') {
      postData.causeImmNCId = null
    }
    removeObjectProperties(postData, ['isUpdated'])
    postData.causeFondNCs = causeFondNCs?.map(fond => {
      let _fond = { ...fond }
      if (_fond.causeFondNCId === 'new') {
        _fond.causeFondNCId = null
      }
      _fond.causeFondAnalyses = _fond.causeFondAnalyses?.map(analyse => {
        let _analyse = { ...analyse }
        if (_analyse.causeFondAnalyseId === 'new') {
          _analyse.causeFondAnalyseId = null
        }
        return _analyse
      })
      return _fond
    })
    const response = await axios.post(NC_UPDATE_ANALYSE_CAUSE_IMM_NC, postData);
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const deleteAnalyseCauseImmNC = createAsyncThunk("NonConformites/Edit/DeleteAnalyseCauseImmNC", async (params, thunkAPI) => {
  try {
    const { data } = await axios.delete(NC_DELETE_ANALYSE_CAUSE_IMM_NC + paramsToQueryParam(params));
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "information_supprimee",
        severity: "success",
      })
    );
    return data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const updateAnalyseCauseFondNC = createAsyncThunk("NonConformites/Edit/UpdateAnalyseCauseFondNC", async (data, thunkAPI) => {
  try {
    const postData = { ...data }
    removeObjectProperties(postData, ['isUpdated'])
    if (postData.causeFondNCId === 'new') {
      postData.causeFondNCId = null
    }
    const { causeFondAnalyses } = data
    postData.causeFondAnalyses = causeFondAnalyses?.map(analyse => {
      let _analyse = { ...analyse }
      if (_analyse.causeFondAnalyseId === 'new') {
        _analyse.causeFondAnalyseId = null
      }
      return _analyse
    })
    const response = await axios.post(NC_UPDATE_ANALYSE_CAUSE_FOND_NC, postData);
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const deleteAnalyseCauseFondNC = createAsyncThunk("NonConformites/Edit/DeleteAnalyseCauseFondNC", async (params, thunkAPI) => {
  try {
    const { data } = await axios.delete(NC_DELETE_ANALYSE_CAUSE_FOND_NC + paramsToQueryParam(params));
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "information_supprimee",
        severity: "success",
      })
    );
    return data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const updateAnalyseCauseFondAnalyse = createAsyncThunk("NonConformites/Edit/UpdateAnalyseCauseFondAnalyse", async (data, thunkAPI) => {
  try {
    const postData = { ...data }
    if (postData.causeFondAnalyseId === 'new') {
      postData.causeFondAnalyseId = null
    }
    removeObjectProperties(postData, ['isUpdated'])
    const response = await axios.post(NC_UPDATE_ANALYSE_CAUSE_FOND_ANALYSE, postData);
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const deleteAnalyseCauseFondAnalyse = createAsyncThunk("NonConformites/Edit/DeleteAnalyseCauseFondAnalyse", async (params, thunkAPI) => {
  try {
    const { data } = await axios.delete(NC_DELETE_ANALYSE_CAUSE_FOND_ANALYSE + paramsToQueryParam(params));
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "information_supprimee",
        severity: "success",
      })
    );
    return data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const nonConformiteLoadAnalyseEnqueteDetail = createAsyncThunk(
  "NonConformites/Edit/LoadAnalyseEnqueteDetail",
  async (params, thunkAPI) => {
    const response = await axios.get(NC_LOAD_ANALYSE_ENQUETE_DETAIL + paramsToQueryParam(params));
    return response.data;
  }
);

export const nonConformiteUpdateAnalyseEnqueteDetail = createAsyncThunk(
  "NonConformites/Edit/LoadAnalyseEnqueteDetail",
  async (data, thunkAPI) => {
    try {
      const postData = [...data]
      const response = await axios.post(NC_UPDATE_ANALYSE_ENQUETE_DETAIL, postData);
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      );
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      );
    }
  }
);

export const nonConformiteSignalementLoad = createAsyncThunk(
  "NonConformites/LoadSignalement",
  async (params, thunkAPI) => {
    const response = await axios.get(
      NC_LOAD_SIGNALEMENT + paramsToQueryParam(params)
    );
    return response.data;
  }
);

export const nonConformiteSignalementAcceptationLoad = createAsyncThunk(
  "NonConformites/LoadSignalementAcceptation",
  async (params, thunkAPI) => {
    const response = await axios.get(
      NC_LOAD_SIGNALEMENT_ACCPETATION + paramsToQueryParam(params)
    );
    return response.data;
  }
);

export const newSignalement = createAsyncThunk("NonConformites/GET/NewSignalement", async (params, thunkAPI) => {
  const response = await axios.get(NC_GET_NEW_SIGNALEMENT + paramsToQueryParam(params));
  return response.data;
});

export const addSignalement = createAsyncThunk(
  "NonConformites/PUT/AddSignalement",
  async (params, thunkAPI) => {
    try {
      const { data } = await axios.put(NC_ADD_SIGNALEMENT, params);
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      );
      return data;
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      );
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateSignalement = createAsyncThunk(
  "NonConformites/UpdateSignalement",
  async (params, thunkAPI) => {
    try {
      const resp = await axios.put(NC_UPDATE_SIGNALEMENT, params);
      return resp.data;
    } catch (error) {
      thunkAPI.dispatch(
          showSnackbarMessage({
            message: error.response.data.messageText,
            severity: "error",
            autoHideDuration: 10000,
          })
      );
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateSignalementAcceptation = createAsyncThunk(
  "NonConformites/UpdateSignalementAcceptation",
  async (params, thunkAPI) => {
    const response = await axios.put(NC_UPDATE_SIGNALEMENT_ACCPETATION, params);
    return response.data;
  }
);

export const nonConformiteEditCheckAutreIdentifiant = createAsyncThunk(
  "NonConformites/Edit/CheckAutreIdentifiant",
  async (params, thunkAPI) => {
    const response = await axios.get(
      NC_CHECK_AUTRE_IDENTIFIANT + paramsToQueryParam(params)
    );
    return response.data;
  }
);

export const nonConformiteEditGetSignalementCourriel = createAsyncThunk(
  NC_GET_SIGNALEMENT_COURRIEL,
  async (params, thunkAPI) => {
    const response = await axios.get(
      NC_GET_SIGNALEMENT_COURRIEL + paramsToQueryParam(params)
    );
    return response.data;
  }
);

export const nonConformiteEditGetRenseignementCourriel = createAsyncThunk(
  NC_GET_RENSEIGNEMENT_COURRIEL,
  async (params, thunkAPI) => {
    const response = await axios.get(
      NC_GET_RENSEIGNEMENT_COURRIEL + paramsToQueryParam(params)
    );
    return response.data;
  }
);

export const nonConformiteRenseignementLoad = createAsyncThunk(
  NC_LOAD_RENSEIGNEMENT,
  async (params, thunkAPI) => {
    const { data } = await axios.get(
      NC_LOAD_RENSEIGNEMENT + paramsToQueryParam(params)
    );
    return data;
  }
);

export const updateRenseignement = createAsyncThunk(
  "NonConformites/UpdateRenseignements",
  async (params, thunkAPI) => {
    const response = await axios.put(NC_UPDATE_RENSEIGNEMENT, params);
    return response.data;
  }
);

export const nonConformiteDerogationLoad = createAsyncThunk(
  "NonConformites/Edit/LoadDerogation",
  async (params, thunkAPI) => {
    const { data } = await axios.get(
      NC_LOAD_DEROGATION + paramsToQueryParam(params)
    );
    return data;
  }
);

export const nonConformiteEditGetDerogationCourriel = createAsyncThunk(
  NC_GET_DEROGATION_COURRIEL,
  async (params, thunkAPI) => {
    const response = await axios.get(
      NC_GET_DEROGATION_COURRIEL + paramsToQueryParam(params)
    );
    return response.data;
  }
);

export const updateDerogation = createAsyncThunk(
  "NonConformites/UpdateDerogation",
  async (params, thunkAPI) => {
    const response = await axios.put(NC_UPDATE_DEROGATION, params);
    return response.data;
  }
);

export const nonConformiteDerogationApprobationLoad = createAsyncThunk(
  "NonConformites/Edit/LoadDerogationApprobation",
  async (params, thunkAPI) => {
    const { data } = await axios.get(
      NC_LOAD_DEROGATION_APPROBATION + paramsToQueryParam(params)
    );
    return data;
  }
);

export const updateDerogationApprobation = createAsyncThunk(
  "NonConformites/UpdateDerogationApprobation",
  async (params, thunkAPI) => {
    const response = await axios.put(NC_UPDATE_DEROGATION_APPROBATION, params);
    return response.data;
  }
);

export const fetchNCActions = createAsyncThunk('NonConformites/Edit/LoadAction', async (params, thunkAPI) => {
  const response = await axios.get(NC_LOAD_ACTION + paramsToQueryParam(params));
  return response.data;
});

export const updateNCActionResume = createAsyncThunk("NonConformites/Edit/UpdateActionResume", async (data, thunkAPI) => {
  try {
    const postData = { ...data }
    removeObjectProperties(postData, ['isUpdated'])
    if (postData.codeActionCorr === 'new') {
      postData.codeActionCorr = null
    }
    const response = await axios.post(NC_ACTTION_UPDATE_RESUME, postData);
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const loadNCNewActionResume = createAsyncThunk("NonConformites/Edit/NewActionResume", async (params) => {
  const { data } = await axios.get(NC_LOAD_NEW_ACTION_RESUME + paramsToQueryParam(params));
  return data;
});

export const deleteNCActionResume = createAsyncThunk("NonConformites/Edit/DeleteActionResume", async (params, thunkAPI) => {
  try {
    const { data } = await axios.delete(NC_ACTTION_DELETE_RESUME + paramsToQueryParam(params));
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "information_supprimee",
        severity: "success",
      })
    );
    return data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const loadNCNewActionAction = createAsyncThunk("NonConformites/Edit/NewActionAction", async (params) => {
  const { data } = await axios.get(NC_LOAD_NEW_ACTION_ACTION + paramsToQueryParam(params));
  return data;
});

export const updateNCActionAction = createAsyncThunk("NonConformites/Edit/UpdateActionAction", async (data, thunkAPI) => {
  try {
    const postData = { ...data }
    removeObjectProperties(postData, ['isUpdated'])
    if (postData.codeActionCorr === 'new') {
      postData.codeActionCorr = null
    }
    const response = await axios.post(NC_UPDATE_ACTION_ACTION, postData);
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const deleteNCActionAction = createAsyncThunk("NonConformites/Edit/DeleteActionAction", async (params, thunkAPI) => {
  try {
    const { data } = await axios.delete(NC_DELETE_ACTION_ACTION + paramsToQueryParam(params));
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "information_supprimee",
        severity: "success",
      })
    );
    return data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const loadNCNewActionSuivi = createAsyncThunk("NonConformites/Edit/NewActionSuivi", async () => {
  const { data } = await axios.get(NC_LOAD_NEW_ACTION_SUIVI);
  return data;
});

export const updateNCSuiviAction = createAsyncThunk("NonConformites/Edit/UpdateActionSuivi", async (data, thunkAPI) => {
  try {
    const postData = { ...data }
    removeObjectProperties(postData, ['isUpdated'])
    if (postData.actionsCorrecPrevenSuiviId === 'new') {
      postData.actionsCorrecPrevenSuiviId = null
    }
    const response = await axios.post(NC_ACTION_UPDATE_SUIVI, postData);
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const deleteNCSuiviAction = createAsyncThunk("NonConformites/Edit/DeleteActionSuivi", async (params, thunkAPI) => {
  try {
    const { data } = await axios.delete(NC_ACTION_DELETE_SUIVI + paramsToQueryParam(params));
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "information_supprimee",
        severity: "success",
      })
    );
    return data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const loadNCNewActionCout = createAsyncThunk("NonConformites/Edit/NewActionCout", async (params) => {
  const { data } = await axios.get(NC_LOAD_NEW_ACTION_COUT + paramsToQueryParam(params));
  return data;
});

export const updateNCActionCout = createAsyncThunk("NonConformites/Edit/UpdateActionCout", async (data, thunkAPI) => {
  try {
    const postData = { ...data }
    removeObjectProperties(postData, ['isUpdated'])
    if (postData.coutNCId === 'new') {
      postData.coutNCId = null
    }
    const response = await axios.post(NC_ACTION_UPDATE_COUT, postData);
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const deleteNCActionCout = createAsyncThunk("NonConformites/Edit/DeleteActionCout", async (params, thunkAPI) => {
  try {
    const { data } = await axios.delete(NC_ACTION_DELETE_COUT + paramsToQueryParam(params));
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "information_supprimee",
        severity: "success",
      })
    );
    return data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const loadNCNewActionCalendrier = createAsyncThunk("NonConformites/Edit/NewActionCalendrier", async (params) => {
  const { data } = await axios.get(NC_LOAD_NEW_ACTION_CALENDRIER + paramsToQueryParam(params));
  return data;
});

export const addNCActionCalendrier = createAsyncThunk("NonConformites/Edit/AddActionCalendrier", async (data, thunkAPI) => {
  try {
    const postData = { ...data }
    removeObjectProperties(postData, ['isUpdated'])
    if (postData.codeFreqCalen === 'new') {
      postData.codeFreqCalen = null
    }
    const response = await axios.post(NC_ACTION_ADD_CALENDRIER, postData);
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const updateNCActionCalendrier = createAsyncThunk("NonConformites/Edit/UpdateActionCalendrier", async (data, thunkAPI) => {
  try {
    const postData = { ...data }
    removeObjectProperties(postData, ['isUpdated'])
    const response = await axios.post(NC_ACTION_UPDATE_CALENDRIER, postData);
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const deleteNCActionCalendrier = createAsyncThunk("NonConformites/Edit/DeleteActionCalendrier", async (params, thunkAPI) => {
  try {
    const { data } = await axios.delete(NC_ACTION_DELETE_CALENDRIER + paramsToQueryParam(params));
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "information_supprimee",
        severity: "success",
      })
    );
    return data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const loadNCCouts = createAsyncThunk("NonConformites/Edit/LoadCouts", async (params) => {
  const { data } = await axios.get(NC_LOAD_COUTS + paramsToQueryParam(params));
  return data;
});

export const loadNCNewCout = createAsyncThunk("NonConformites/Edit/NewCout", async (params) => {
  const { data } = await axios.get(NC_LOAD_NEW_COUT + paramsToQueryParam(params));
  return data;
});

export const updateNCCout = createAsyncThunk("NonConformites/Edit/UpdateCout", async (data, thunkAPI) => {
  try {
    const postData = { ...data }
    removeObjectProperties(postData, ['isUpdated'])
    if (postData.coutNCId === 'new') {
      postData.coutNCId = null
    }
    const response = await axios.post(NC_UPDATE_COUT, postData);
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const deleteNCCout = createAsyncThunk("NonConformites/Edit/DeleteCout", async (params, thunkAPI) => {
  try {
    const { data } = await axios.delete(NC_DELETE_COUT + paramsToQueryParam(params));
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "information_supprimee",
        severity: "success",
      })
    );
    return data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const getHyperliensExist = createAsyncThunk("NonConformites/Edit/GetHyperliensExist", async (params, thunkAPI) => {
  const response = await axios.get(NC_GET_HYPERLIENS_EXIST + paramsToQueryParam(params));
  return response.data;
});

export const dupliquerNC = createAsyncThunk("NonConformites/Edit/DupliquerNC", async (params, thunkAPI) => {
  const response = await axios.get(NC_DUPLIQUER + paramsToQueryParam(params));
  return response.data;
});

export const loadIncidentsSoins = createAsyncThunk("NonConformites/Edit/LoadIncidentsSoins", async (params, thunkAPI) => {
  const response = await axios.get(NC_LOAD_INCIDENTS_SOINS + paramsToQueryParam(params));
  return response.data;
});

export const updateIncidentsSoinsPremierSoin = createAsyncThunk("NonConformites/Edit/UpdateIncidentsSoinsPremierSoin", async (data, thunkAPI) => {
  try {
    let postData = { ...data }
    removeObjectProperties(postData, ['isUpdated'])
    const response = await axios.post(NC_UPDATE_INCIDENTS_SOINS_PREMIER_SOIN, postData);
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});
export const updateIncidentsSoinsStatut = createAsyncThunk("NonConformites/Edit/UpdateIncidentsSoinsStatut", async (data, thunkAPI) => {
  try {
    const postData = { ...data }
    removeObjectProperties(postData, ['isUpdated'])
    const response = await axios.post(NC_UPDATE_INCIDENTS_SOINS_STATUT, postData);
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const updateIncidentsSoinsPartieCorpsTouches = createAsyncThunk("NonConformites/Edit/UpdateIncidentsSoinsPartieCorpsTouches", async (data, thunkAPI) => {
  try {
    const postData = { ...data }
    removeObjectProperties(postData, ['isUpdated'])
    if (postData.partieCorpsToucheId === 'new') {
      postData.partieCorpsToucheId = null
    }
    const response = await axios.post(NC_UPDATE_INCIDENTS_SOINS_PARTIE_CORPS_TOUCHES, postData);
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});
export const deleteIncidentsSoinsPartieCorpsTouches = createAsyncThunk("NonConformites/Edit/DeleteIncidentsSoinsPartieCorpsTouches", async (postData, thunkAPI) => {
  try {
    const { data } = await axios.delete(NC_DELETE_INCIDENTS_SOINS_PARTIE_CORPS_TOUCHES, {
      data: postData
    });
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "information_supprimee",
        severity: "success",
      })
    );
    return data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});
export const updateIncidentsSoinsDommagesAutres = createAsyncThunk("NonConformites/Edit/UpdateIncidentsSoinsDommagesAutres", async (data, thunkAPI) => {
  try {
    const postData = { ...data }
    removeObjectProperties(postData, ['isUpdated'])
    const response = await axios.post(NC_UPDATE_INCIDENTS_SOINS_DOMMAGES_AUTRES, postData);
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const updateIncidentsSoinsSuiviMedical = createAsyncThunk("NonConformites/Edit/UpdateIncidentsSoinsSuiviMedical", async (data, thunkAPI) => {
  try {
    const postData = { ...data }
    removeObjectProperties(postData, ['isUpdated'])
    if (postData.suiviMedicalId === 'new') {
      postData.suiviMedicalId = null
    }
    const response = await axios.post(NC_UPDATE_INCIDENTS_SOINS_SUIVI_MEDICAL, postData);
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});
export const deleteIncidentsSoinsSuiviMedical = createAsyncThunk("NonConformites/Edit/DeleteIncidentsSoinsSuiviMedical", async (postData, thunkAPI) => {
  try {
    const { data } = await axios.delete(NC_DELETE_INCIDENTS_SOINS_SUIVI_MEDICAL, {
      data: postData
    });
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "information_supprimee",
        severity: "success",
      })
    );
    return data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const updateIncidentsSoinsArretEtAssignation = createAsyncThunk("NonConformites/Edit/updateIncidentsSoinsArretEtAssignation", async (data, thunkAPI) => {
  try {
    const postData = { ...data }
    removeObjectProperties(postData, ['isUpdated'])
    if (postData.noAuto === 'new') {
      postData.noAuto = null
    }
    const response = await axios.post(NC_UPDATE_INCIDENTS_SOINS_ARRET_ET_ASSIGNATION, postData);
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    return {
      error: error.response.data.messageId
    }
  }
});
export const deleteIncidentsSoinsArretEtAssignation = createAsyncThunk("NonConformites/Edit/deleteIncidentsSoinsArretEtAssignation", async (postData, thunkAPI) => {
  try {
    const { data } = await axios.delete(NC_DELETE_INCIDENTS_SOINS_ARRET_ET_ASSIGNATION, {
      data: postData
    });
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "information_supprimee",
        severity: "success",
      })
    );
    return data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

// ----------------------------------------------------------------------------------------
// TRAITEMENTS
// ----------------------------------------------------------------------------------------

export const nonConformiteEditLoadPerso = createAsyncThunk(
  "NonConformites/Edit/LoadPerso",
  async () => {
    const response = await axios.get(NC_LOAD_PERSO);
    return response.data;
  }
);

export const nonConformiteEditDupliquerPerso = createAsyncThunk(
  "NonConformites/Edit/DupliquerPerso",
  async (params) => {
    const response = await axios.get(
      NC_DUPLIQUER_PERSO + paramsToQueryParam({
        typeNC: params.typeNC,
        typeNCSelect: params.typeNCSelect,
        sections: params.sections
      })
    );
    return response.data;
  }
);

export const nonConformiteEditLoadEcranMenu = createAsyncThunk(
  "NonConformites/Edit/LoadEcranMenu",
  async () => {
    const response = await axios.get(NC_LOAD_ECRAN_MENU);
    return response.data;
  }
);

export const nonConformiteEditUpddateEcranMenu = createAsyncThunk(
  "NonConformites/Edit/UpddateEcranMenu",
  async (params) => {
    const response = await axios.put(NC_UPDATE_ECRAN_MENU, params);
    return response.data;
  }
);

export const nonConformiteEditLoadTypeNc = createAsyncThunk(
  "NonConformites/Edit/LoadTypeNc",
  async () => {
    const response = await axios.get(NC_LOAD_TYPE_NC);
    return response.data;
  }
);

export const nonConformiteEditUpddateTypeNc = createAsyncThunk(
  "NonConformites/Edit/UpddateTypeNc",
  async (params) => {
    const response = await axios.put(NC_UPDATE_TYPE_NC, params);
    return response.data;
  }
);

export const nonConformiteEditLoadLigneProduit = createAsyncThunk(
  "NonConformites/Edit/LoadLigneProduit",
  async (params) => {
    const response = await axios.get(NC_LOAD_LIGNE_PRODUIT + paramsToQueryParam(params));
    return response.data;
  }
);

export const nonConformiteEditUpddateLigneProduit = createAsyncThunk(
  "NonConformites/Edit/UpddateTypeNc",
  async (params) => {
    const { ligneProduitIdOrigine, ligneProduitIdDestination, selectedArticles } = params;
    const response = await axios.put(NC_UPDATE_LIGNE_PRODUIT + paramsToQueryParam({ ligneProduitIdOrigine, ligneProduitIdDestination }), selectedArticles);
    return response.data;
  }
);

export const nonConformiteEditLoadHeuresTravaillees = createAsyncThunk(
  "NonConformites/Edit/LoadHeuresTravaillees",
  async (params) => {
    const response = await axios.get(NC_LOAD_HEURES_TRAVAILLEES + paramsToQueryParam(params));
    return response.data;
  }
);

export const nonConformiteEditUpdateHeuresTravaillees = createAsyncThunk(
  "NonConformites/Edit/UpdateHeuresTravaillees",
  async (params) => {
    const response = await axios.put(NC_UPDATE_HEURES_TRAVAILLEES, params);
    return response.data;
  }
);

// ----------------------------------------------------------------------------------------
// 
// ----------------------------------------------------------------------------------------

export const updateIncidentsSoinsAssignationTemp = createAsyncThunk("NonConformites/Edit/UpdateIncidentsSoinsAssignationTemp", async (data, thunkAPI) => {
  try {
    const postData = { ...data }
    removeObjectProperties(postData, ['isUpdated'])
    if (postData.noAuto === 'new') {
      postData.noAuto = null
    }
    const response = await axios.post(NC_UPDATE_INCIDENTS_SOINS_ASSIGNATION_TEMP, postData);
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});
export const deleteIncidentsSoinsAssignationTemp = createAsyncThunk("NonConformites/Edit/DeleteIncidentsSoinsAssignationTemp", async (postData, thunkAPI) => {
  try {
    const { data } = await axios.delete(NC_DELETE_INCIDENTS_SOINS_ASSIGNATION_TEMP, {
      data: postData
    });
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "information_supprimee",
        severity: "success",
      })
    );
    return data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const updateIncidentsSoinsCommentaire = createAsyncThunk("NonConformites/Edit/UpdateIncidentsSoinsCommentaire", async (data, thunkAPI) => {
  try {
    const postData = { ...data }
    removeObjectProperties(postData, ['isUpdated'])
    if (postData.noAuto === 'new') {
      postData.noAuto = null
    }
    const response = await axios.post(NC_UPDATE_INCIDENTS_SOINS_COMMENTAIRE, postData);
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});
export const deleteIncidentsSoinsCommentaire = createAsyncThunk("NonConformites/Edit/DeleteIncidentsSoinsCommentaire", async (postData, thunkAPI) => {
  try {
    const { data } = await axios.delete(NC_DELETE_INCIDENTS_SOINS_COMMENTAIRE, {
      data: postData
    });
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "information_supprimee",
        severity: "success",
      })
    );
    return data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const updateIncidentsSoinsCout = createAsyncThunk("NonConformites/Edit/UpdateIncidentsSoinsCout", async (data, thunkAPI) => {
  try {
    const postData = { ...data }
    removeObjectProperties(postData, ['isUpdated'])
    if (postData.coutNCId === 'new') {
      postData.coutNCId = null
    }
    const response = await axios.post(NC_UPDATE_INCIDENTS_SOINS_COUT, postData);
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});
export const deleteIncidentsSoinsCout = createAsyncThunk("NonConformites/Edit/DeleteIncidentsSoinsCout", async (postData, thunkAPI) => {
  try {
    const { data } = await axios.delete(NC_DELETE_INCIDENTS_SOINS_COUT, {
      data: postData
    });
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "information_supprimee",
        severity: "success",
      })
    );
    return data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const loadConfigSynchro = createAsyncThunk("NonConformites/Edit/LoadConfigSynchro", async () => {
  const response = await axios.get(NC_LOAD_CONFIG_SYNCHRO);
  return response.data;
});

export const updateConfigSynchroDsn = createAsyncThunk("NonConformites/Edit/UpdateConfigSynchroDsn", async (data, thunkAPI) => {
  try {
    const postData = { ...data }
    removeObjectProperties(postData, ['isUpdated'])
    const response = await axios.post(NC_UPDATE_CONFIG_SYNCHRO_DSN, postData);
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});
export const updateConfigSynchroTypeNc = createAsyncThunk("NonConformites/Edit/UpdateConfigSynchroTypeNc", async (data, thunkAPI) => {
  try {
    const response = await axios.put(NC_UPDATE_CONFIG_SYNCHRO_TYPE_NC, data);
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});
export const updateConfigSynchroDetails = createAsyncThunk("NonConformites/Edit/UpdateConfigSynchroDetails", async (data, thunkAPI) => {
  try {
    const response = await axios.put(NC_UPDATE_CONFIG_SYNCHRO_TYPE_DETAILS, data);
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const loadConfigNc = createAsyncThunk("NonConformites/Edit/LoadConfigNc", async () => {
  const response = await axios.get(NC_LOAD_CONFIG_NC);
  return response.data;
});
export const updateConfigNc = createAsyncThunk("NonConformites/Edit/UpdateConfigNc", async (data, thunkAPI) => {
  try {
    const postData = { ...data }
    removeObjectProperties(postData, ['isUpdated'])
    const response = await axios.put(NC_UPDATE_CONFIG_NC, postData);
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const loadConfigAlerte = createAsyncThunk("NonConformites/Edit/LoadConfigAlerte", async () => {
  const response = await axios.get(NC_LOAD_CONFIG_ALERTE);
  return response.data;
});

export const loadConfigHeuresTravaillees = createAsyncThunk("NonConformites/Edit/LoadConfigHeuresTravaillees", async () => {
  const response = await axios.get(NC_LOAD_CONFIG_HEURES_TRAVAILLEES);
  return response.data;
});
export const updateConfigHeuresTravaillees = createAsyncThunk("NonConformites/Edit/UpdateConfigHeuresTravaillees", async (data, thunkAPI) => {
  try {
    const postData = { ...data }
    removeObjectProperties(postData, ['isUpdated'])
    const response = await axios.put(NC_UPDATE_CONFIG_HEURES_TRAVAILLEES, postData);
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

const nonConformiteEditSlice = createSlice({
  name: "nonConformiteEdit",
  initialState,
  reducers: {
    updateNCField(state, action) {
      const { isUpdated = true, field, value } = action.payload
      if (field !== 'isUpdated' && !state.nonConformite.isUpdated && isUpdated) {
        state.nonConformite.isUpdated = true
      }
      state.nonConformite[field] = value;
    },
    updateNCDetailField(state, action) {
      const { isUpdated = true, field, value } = action.payload
      if (field !== 'isUpdated' && isUpdated) {
        state.nonConformite.isUpdated = true
      }
      state.nonConformite.detail[field] = value;
    },
    updateNCDetail2Field(state, action) {
      const { isUpdated = true, field, value } = action.payload
      if (field !== 'isUpdated' && isUpdated) {
        state.nonConformite.detail2.isUpdated = true
      }
      state.nonConformite.detail2[field] = value;
    },
    updateNCAnalyseField(state, action) {
      const { isUpdated = true, field, value } = action.payload
      if (field !== 'isUpdated' && !state?.ncAnalyse?.isUpdated && isUpdated) {
        state.ncAnalyse.isUpdated = true
      }
      state.ncAnalyse[field] = value;
    },
    updateNCActionField(state, action) {
      const { isUpdated = true, field, value } = action.payload
      if (field !== 'isUpdated' && !state.ncAction.isUpdated && isUpdated) {
        state.ncAction.isUpdated = true
      }
      state.ncAction[field] = value;
    },

    addOneNCAnalyseCauseImmNC(state, action) {
      const { codeNonConform, causeImmNCPayload } = action.payload
      const _causeImmNCs = state.ncAnalyse?.causeImmNCs || []
      if (causeImmNCPayload) {
        _causeImmNCs.push(causeImmNCPayload)
      } else {
        _causeImmNCs.push({
          ...state.causeImmNC,
          causeImmNCId: 'new',
          codeNonConform,
          isUpdated: true
        })
      }
      state.ncAnalyse.causeImmNCs = _causeImmNCs
    },
    deleteOneNCAnalyseCauseImmNC(state, action) {
      const _causeImmNCs = state.ncAnalyse?.causeImmNCs || []
      state.ncAnalyse.causeImmNCs = _causeImmNCs.filter(item => item.causeImmNCId !== action.payload)
    },
    updateNCAnalyseCauseImmNCProps(state, action) {
      const _causeImmNC = action.payload
      const _causeImmNCIdx = state.ncAnalyse?.causeImmNCs?.findIndex(item => item.causeImmNCId === _causeImmNC.causeImmNCId)
      if (_causeImmNCIdx > -1) {
        state.ncAnalyse.causeImmNCs[_causeImmNCIdx] = { ..._causeImmNC }
      }
    },
    updateNCAnalyseCauseImmNCField(state, action) {
      const { isUpdated = true, id, field, value } = action.payload
      const _causeImmNCIdx = state.ncAnalyse?.causeImmNCs?.findIndex(item => item.causeImmNCId === id)
      if (_causeImmNCIdx > -1) {
        if (field !== 'isUpdated' && isUpdated) {
          state.ncAnalyse.causeImmNCs[_causeImmNCIdx].isUpdated = true
        }
        state.ncAnalyse.causeImmNCs[_causeImmNCIdx][field] = value
        if (field === 'causeImmediate') {
          state.ncAnalyse.causeImmNCs[_causeImmNCIdx]['causeImmediateAffiche'] = value
        }
      }
    },
    addOneNCAnalyseCauseFondNC(state, action) {
      const { causeImmediate, codeNonConform, fondPayload } = action.payload
      const causeIdx = state.ncAnalyse?.causeImmNCs?.findIndex(item => item.causeImmediate === causeImmediate)
      if (causeIdx > -1) {
        const _causeFondNCs = state.ncAnalyse.causeImmNCs[causeIdx].causeFondNCs || []
        if (fondPayload) {
          _causeFondNCs.push(fondPayload)
        } else {
          _causeFondNCs.push({
            ...state.causeFondNC,
            codeNonConform: codeNonConform,
            causeImmediate: causeImmediate,
            causeFondNCId: 'new',
            isUpdated: true
          })
        }
        state.ncAnalyse.causeImmNCs[causeIdx].causeFondNCs = _causeFondNCs
      }
    },
    deleteOneNCAnalyseCauseFondNC(state, action) {
      const { causeImmediate, causeFondNCId } = action.payload
      const causeIdx = state.ncAnalyse?.causeImmNCs?.findIndex(item => item.causeImmediate === causeImmediate)
      if (causeIdx > -1) {
        const _causeFondNCs = state.ncAnalyse.causeImmNCs[causeIdx].causeFondNCs?.filter(item => item.causeFondNCId !== causeFondNCId)
        state.ncAnalyse.causeImmNCs[causeIdx].causeFondNCs = _causeFondNCs
      }
    },
    updateNCAnalyseCauseFondNCProps(state, action) {
      const { causeImmediate, causeFondNCId } = action.payload
      const causeIdx = state.ncAnalyse?.causeImmNCs?.findIndex(item => item.causeImmediate === causeImmediate)
      if (causeIdx > -1) {
        const fondIdx = state.ncAnalyse.causeImmNCs[causeIdx]?.causeFondNCs?.findIndex(item => item.causeFondNCId === causeFondNCId)
        if (fondIdx > -1) {
          state.ncAnalyse.causeImmNCs[causeIdx].causeFondNCs[fondIdx] = action.payload
        }
      }
    },
    updateNCAnalyseCauseFondNCField(state, action) {
      const { causeImmediate, causeFondNCId, isUpdated = true, field, value } = action.payload
      const causeIdx = state.ncAnalyse?.causeImmNCs?.findIndex(item => item.causeImmediate === causeImmediate)
      if (causeIdx > -1) {
        const fondIdx = state.ncAnalyse.causeImmNCs[causeIdx]?.causeFondNCs?.findIndex(item => item.causeFondNCId === causeFondNCId)
        if (fondIdx > -1) {
          if (field !== 'isUpdated' && isUpdated) {
            state.ncAnalyse.causeImmNCs[causeIdx].causeFondNCs[fondIdx].isUpdated = true
          }
          state.ncAnalyse.causeImmNCs[causeIdx].causeFondNCs[fondIdx][field] = value
        }
      }
    },
    addOneNCAnalyseFondAnalyse(state, action) {
      const { causeImmediate, causeFondNCId, analysePayload } = action.payload
      const causeIdx = state.ncAnalyse?.causeImmNCs?.findIndex(item => item.causeImmediate === causeImmediate)
      if (causeIdx > -1) {
        const _causeFondNCs = state.ncAnalyse.causeImmNCs[causeIdx].causeFondNCs || []
        const fondIdx = _causeFondNCs.findIndex(item => item.causeFondNCId === causeFondNCId)
        if (fondIdx > -1) {
          const _fondAnalyses = _causeFondNCs[fondIdx].causeFondAnalyses || []
          if (analysePayload) {
            _fondAnalyses.push(analysePayload)
          } else {
            _fondAnalyses.push({
              ...state.causeFondAnalyse,
              causeFondNCId: causeFondNCId,
              causeFondAnalyseId: 'new',
              isUpdated: true
            })
          }
          state.ncAnalyse.causeImmNCs[causeIdx].causeFondNCs[fondIdx].causeFondAnalyses = _fondAnalyses
        }
      }
    },
    deleteOneNCAnalyseFondAnalyse(state, action) {
      const { causeImmediate, causeFondNCId, causeFondAnalyseId } = action.payload
      const causeIdx = state.ncAnalyse?.causeImmNCs?.findIndex(item => item.causeImmediate === causeImmediate)
      if (causeIdx > -1) {
        const fondIdx = state.ncAnalyse?.causeImmNCs[causeIdx]?.causeFondNCs?.findIndex(item => item.causeFondNCId === causeFondNCId)
        if (fondIdx > -1) {
          const _causeFondAnalyses = state.ncAnalyse.causeImmNCs[causeIdx].causeFondNCs[fondIdx].causeFondAnalyses?.filter(item => item.causeFondAnalyseId !== causeFondAnalyseId)
          state.ncAnalyse.causeImmNCs[causeIdx].causeFondNCs[fondIdx].causeFondAnalyses = _causeFondAnalyses
        }
      }
    },
    updateNCAnalyseFondAnalyseProps(state, action) {
      const { causeImmediate, causeFondNCId, causeFondAnalyseId, analysePayload } = action.payload
      const causeIdx = state.ncAnalyse?.causeImmNCs?.findIndex(item => item.causeImmediate === causeImmediate)
      if (causeIdx > -1) {
        const fondIdx = state.ncAnalyse.causeImmNCs[causeIdx]?.causeFondNCs?.findIndex(item => item.causeFondNCId === causeFondNCId)
        if (fondIdx > -1) {
          const analyseIdx = state.ncAnalyse.causeImmNCs[causeIdx].causeFondNCs[fondIdx].causeFondAnalyses.findIndex(item => item.causeFondAnalyseId === causeFondAnalyseId)
          if (analyseIdx > -1) {
            state.ncAnalyse.causeImmNCs[causeIdx].causeFondNCs[fondIdx].causeFondAnalyses[analyseIdx] = analysePayload
          }
        }
      }
    },
    updateNCAnalyseFondAnalyseField(state, action) {
      const { causeImmediate, causeFondNCId, causeFondAnalyseId, isUpdated = true, field, value } = action.payload
      const causeIdx = state.ncAnalyse?.causeImmNCs?.findIndex(item => item.causeImmediate === causeImmediate)
      if (causeIdx > -1) {
        const fondIdx = state.ncAnalyse.causeImmNCs[causeIdx]?.causeFondNCs?.findIndex(item => item.causeFondNCId === causeFondNCId)
        if (fondIdx > -1) {
          const analyseIdx = state.ncAnalyse.causeImmNCs[causeIdx].causeFondNCs[fondIdx].causeFondAnalyses?.findIndex(item => item.causeFondAnalyseId === causeFondAnalyseId)
          if (analyseIdx > -1) {
            if (field !== 'isUpdated' && isUpdated) {
              state.ncAnalyse.causeImmNCs[causeIdx].causeFondNCs[fondIdx].causeFondAnalyses[analyseIdx].isUpdated = true
            }
            state.ncAnalyse.causeImmNCs[causeIdx].causeFondNCs[fondIdx].causeFondAnalyses[analyseIdx][field] = value
          }
        }
      }
    },
    addNCActionQuickEmps(state, action) {
      const _employeQuickInfos = state.ncAction?.employeQuickInfos || []
      action.payload?.map(item => {
        const _idx = _employeQuickInfos.findIndex(e => e.noEmploye === item.noEmploye)
        if (_idx < 0) {
          _employeQuickInfos.push({
            ...item
          })
        }
      })
      state.ncAction.employeQuickInfos = _employeQuickInfos
    },
    addOneNCActionResume(state, action) {
      const _ncActionResume = state.ncAction?.ncActionResume || []
      if (action && action.payload) {
        _ncActionResume.push(action.payload)
      } else {
        _ncActionResume.push({
          ...state.actionResume,
          codeActionCorr: 'new',
          isUpdated: true
        })
      }
      state.ncAction.ncActionResume = _ncActionResume
    },
    deleteOneNCActionResume(state, action) {
      const _ncActionResume = state.ncAction?.ncActionResume || []
      state.ncAction.ncActionResume = _ncActionResume.filter(item => item.codeActionCorr !== action.payload)
    },
    updateNCActionResumeProps(state, action) {
      const _actionResume = action.payload
      const _actionResumeIdx = state.ncAction?.ncActionResume?.findIndex(item => item.codeActionCorr === _actionResume.codeActionCorr)
      if (_actionResumeIdx > -1) {
        state.ncAction.ncActionResume[_actionResumeIdx] = { ..._actionResume }
      }
    },
    updateNCActionResumeField(state, action) {
      const { isUpdated = true, id, field, value } = action.payload
      const _actionResumeIdx = state.ncAction?.ncActionResume?.findIndex(item => item.codeActionCorr === id)
      if (_actionResumeIdx > -1) {
        if (field !== 'isUpdated' && isUpdated) {
          state.ncAction.ncActionResume[_actionResumeIdx].isUpdated = true
        }
        state.ncAction.ncActionResume[_actionResumeIdx][field] = value
      }
    },
    addOneNCActionAction(state, action) {
      const _ncActionActions = state.ncAction?.ncActionActions || []
      if (action && action.payload) {
        _ncActionActions.push(action.payload)
      } else {
        _ncActionActions.push({
          ...state.actionAction,
          codeActionCorr: 'new',
          isUpdated: true
        })
      }
      state.ncAction.ncActionActions = _ncActionActions
    },
    deleteOneNCActionAction(state, action) {
      const _ncActionActions = state.ncAction?.ncActionActions || []
      state.ncAction.ncActionActions = _ncActionActions.filter(item => item.codeActionCorr !== action.payload)
    },
    updateNCActionActionProps(state, action) {
      const _actionAction = action.payload
      const _actionActionIdx = state.ncAction?.ncActionActions?.findIndex(item => item.codeActionCorr === _actionAction.codeActionCorr)
      if (_actionActionIdx > -1) {
        state.ncAction.ncActionActions[_actionActionIdx] = { ..._actionAction }
      }
    },
    updateNCActionActionField(state, action) {
      const { isUpdated = true, id, field, value } = action.payload
      const _actionActionIdx = state.ncAction?.ncActionActions?.findIndex(item => item.codeActionCorr === id)
      if (_actionActionIdx > -1) {
        if (field !== 'isUpdated' && isUpdated) {
          state.ncAction.ncActionActions[_actionActionIdx].isUpdated = true
        }
        state.ncAction.ncActionActions[_actionActionIdx][field] = value
      }
    },
    updateNCSuiviActionField(state, action) {
      const { id, isUpdated = true, field, value } = action.payload
      const actionIdx = state.ncAction?.ncActionActions?.findIndex(item => item.codeActionCorr === id)
      if (actionIdx > -1) {
        const _suivi = state.ncAction.ncActionActions[actionIdx].suivi || {}
        if (field !== 'isUpdated' && isUpdated) {
          _suivi.isUpdated = true
        }
        _suivi[field] = value
        state.ncAction.ncActionActions[actionIdx].suivi = _suivi
      }
    },
    addOneNCActionCout(state, action) {
      const { codeActionCorr, coutPayload } = action.payload
      const actionIdx = state.ncAction?.ncActionActions?.findIndex(item => item.codeActionCorr === codeActionCorr)
      if (actionIdx > -1) {
        const _couts = state.ncAction.ncActionActions[actionIdx].couts || []
        if (coutPayload) {
          _couts.push(coutPayload)
        } else {
          _couts.push({
            ...state.actionCout,
            codeActionCorr: codeActionCorr,
            coutNCId: 'new',
            isUpdated: true
          })
        }
        state.ncAction.ncActionActions[actionIdx].couts = _couts
      }
    },
    deleteOneNCActionCout(state, action) {
      const { codeActionCorr, coutNCId } = action.payload
      const actionIdx = state.ncAction?.ncActionActions?.findIndex(item => item.codeActionCorr === codeActionCorr)
      if (actionIdx > -1) {
        const _couts = state.ncAction.ncActionActions[actionIdx].couts?.filter(item => item.coutNCId !== coutNCId)
        state.ncAction.ncActionActions[actionIdx].couts = _couts
      }
    },
    updateNCActionCoutProps(state, action) {
      const { codeActionCorr, coutNCId } = action.payload
      const actionIdx = state.ncAction?.ncActionActions?.findIndex(item => item.codeActionCorr === codeActionCorr)
      if (actionIdx > -1) {
        const coutIdx = state.ncAction.ncActionActions[actionIdx]?.couts?.findIndex(item => item.coutNCId === coutNCId)
        if (coutIdx > -1) {
          state.ncAction.ncActionActions[actionIdx].couts[coutIdx] = action.payload
        }
      }
    },
    updateNCActionCoutField(state, action) {
      const { codeActionCorr, coutNCId, isUpdated = true, field, value } = action.payload
      const actionIdx = state.ncAction?.ncActionActions?.findIndex(item => item.codeActionCorr === codeActionCorr)
      if (actionIdx > -1) {
        const coutIdx = state.ncAction.ncActionActions[actionIdx]?.couts?.findIndex(item => item.coutNCId === coutNCId)
        if (coutIdx > -1) {
          if (field !== 'isUpdated' && isUpdated) {
            state.ncAction.ncActionActions[actionIdx].couts[coutIdx].isUpdated = true
          }
          state.ncAction.ncActionActions[actionIdx].couts[coutIdx][field] = value
        }
      }
    },
    addOneNCActionCalendrier(state, action) {
      const { codeActionCorr, calendrierPayload } = action.payload
      const actionIdx = state.ncAction?.ncActionActions?.findIndex(item => item.codeActionCorr === codeActionCorr)
      if (actionIdx > -1) {
        const _calendriers = state.ncAction.ncActionActions[actionIdx].calendriers || []
        if (calendrierPayload) {
          _calendriers.push(calendrierPayload)
        } else {
          _calendriers.push({
            ...state.actionCalendrier,
            codeActionCorr: codeActionCorr,
            codeFreqCalen: 'new',
            isUpdated: true
          })
        }
        state.ncAction.ncActionActions[actionIdx].calendriers = _calendriers
      }
    },
    deleteOneNCActionCalendrier(state, action) {
      const { codeActionCorr, codeFreqCalen } = action.payload
      const actionIdx = state.ncAction?.ncActionActions?.findIndex(item => item.codeActionCorr === codeActionCorr)
      if (actionIdx > -1) {
        const _calendriers = state.ncAction.ncActionActions[actionIdx].calendriers?.filter(item => item.codeFreqCalen !== codeFreqCalen)
        state.ncAction.ncActionActions[actionIdx].calendriers = _calendriers
      }
    },
    updateNCActionCalendrierProps(state, action) {
      const { codeActionCorr, codeFreqCalen } = action.payload
      const actionIdx = state.ncAction?.ncActionActions?.findIndex(item => item.codeActionCorr === codeActionCorr)
      if (actionIdx > -1) {
        const calendrierIdx = state.ncAction.ncActionActions[actionIdx]?.calendriers?.findIndex(item => item.codeFreqCalen === codeFreqCalen)
        if (calendrierIdx > -1) {
          state.ncAction.ncActionActions[actionIdx].calendriers[calendrierIdx] = action.payload
        }
      }
    },
    updateNCActionCalendrierField(state, action) {
      const { codeActionCorr, codeFreqCalen, isUpdated = true, field, value } = action.payload
      const actionIdx = state.ncAction?.ncActionActions?.findIndex(item => item.codeActionCorr === codeActionCorr)
      if (actionIdx > -1) {
        const calendrierIdx = state.ncAction.ncActionActions[actionIdx]?.calendriers?.findIndex(item => item.codeFreqCalen === codeFreqCalen)
        if (calendrierIdx > -1) {
          if (field !== 'isUpdated' && isUpdated) {
            state.ncAction.ncActionActions[actionIdx].calendriers[calendrierIdx].isUpdated = true
          }
          state.ncAction.ncActionActions[actionIdx].calendriers[calendrierIdx][field] = value
        }
      }
    },
    updateNCSignalementField(state, action) {
      state.nonConformite[action.payload.field] = action.payload.value;
    },
    setOpenEditDrawer(state, action) {
      state.openEditDrawer = action.payload;
    },
    setActiveEditSection(state, action) {
      state.activeEditSection = action.payload;
    },
    resetSignalement(state) {
      state.signalement = Signalement();
    },
    resetSignalementAcceptation(state) {
      state.signalementsAcceptation = {};
    },
    addOneNCCout(state, action) {
      const _ncCouts = state.ncCouts || []
      if (action && action.payload) {
        _ncCouts.push(action.payload)
      } else {
        _ncCouts.push({
          ...state.ncCout,
          coutNCId: 'new',
          isUpdated: true
        })
      }
      state.ncCouts = _ncCouts
    },
    deleteOneNCCout(state, action) {
      const _ncCouts = state.ncCouts || []
      state.ncCouts = _ncCouts.filter(item => item.coutNCId !== action.payload)
    },
    updateNCCoutProps(state, action) {
      const _cout = action.payload
      const _coutIdx = state.ncCouts?.findIndex(item => item.coutNCId === _cout.coutNCId)
      if (_coutIdx > -1) {
        state.ncCouts[_coutIdx] = { ..._cout }
      }
    },
    updateNCCoutField(state, action) {
      const { isUpdated = true, id, field, value } = action.payload
      const _coutIdx = state.ncCouts?.findIndex(item => item.coutNCId === id)
      if (_coutIdx > -1) {
        if (field !== 'isUpdated' && isUpdated) {
          state.ncCouts[_coutIdx].isUpdated = true
        }
        state.ncCouts[_coutIdx][field] = value
      }
    },
    updateIncidentsSoinsPremierSoinsField(state, action) {
      const { isUpdated = true, codeNonConform, field, value } = action.payload
      const premiersSoins = state.incidentsSoins.premiersSoins || {
        codeNonConform: codeNonConform,
        noRapPremierSoin: "",
        typePremierSoin: "",
        secouriste: "",
        intervenantExterne: "",
        premierSoinRemarque: "",
        transfertExterieur: "",
        dossierClosON: "",
        nbHyperliens: 0
      }
      if (field !== 'isUpdated' && isUpdated) {
        premiersSoins.isUpdated = true
      }
      state.incidentsSoins.premiersSoins = {
        ...premiersSoins,
        [field]: value
      }
    },
    updateIncidentsSoinsStatutField(state, action) {
      const { isUpdated = true, field, value } = action.payload
      const statut = state.incidentsSoins.statut || {}
      if (field !== 'isUpdated' && isUpdated) {
        statut.isUpdated = true
      }
      state.incidentsSoins.statut = {
        ...statut,
        [field]: value
      }
    },
    updateIncidentsSoinsField(state, action) {
      const { isUpdated = true, field, value } = action.payload
      if (field !== 'isUpdated' && isUpdated) {
        state.incidentsSoins.isUpdated = true
      }
      state.incidentsSoins[field] = value
    },
    addOnePartieCorpsTouche(state, action) {
      const { codeNonConform, touchePayload } = action.payload
      const _touches = state.incidentsSoins?.dommages?.partieCorpsTouches || []
      if (touchePayload) {
        _touches.push(touchePayload)
      } else {
        _touches.push({
          "partieCorpsToucheId": 'new',
          "codeNonConform": codeNonConform,
          "partieDuCorps": "",
          "typeBlessure": "",
          "remarque": "",
          "dossierClosON": "",
          "nbHyperliens": 0,
          isUpdated: true
        })
      }
      state.incidentsSoins.dommages.partieCorpsTouches = _touches
    },
    deleteOnePartieCorpsTouche(state, action) {
      const _touches = state.incidentsSoins?.dommages?.partieCorpsTouches || []
      state.incidentsSoins.dommages.partieCorpsTouches = _touches.filter(item => item.partieCorpsToucheId !== action.payload)
    },
    updatePartieCorpsToucheProps(state, action) {
      const _touche = action.payload
      const _toucheIdx = state.incidentsSoins?.dommages?.partieCorpsTouches.findIndex(item => item.partieCorpsToucheId === _touche.partieCorpsToucheId)
      if (_toucheIdx > -1) {
        state.incidentsSoins.dommages.partieCorpsTouches[_toucheIdx] = { ..._touche }
      }
    },
    updatePartieCorpsToucheField(state, action) {
      const { isUpdated = true, id, field, value } = action.payload
      const _toucheIdx = state.incidentsSoins?.dommages?.partieCorpsTouches?.findIndex(item => item.partieCorpsToucheId === id)
      if (_toucheIdx > -1) {
        if (field !== 'isUpdated' && isUpdated) {
          state.incidentsSoins.dommages.partieCorpsTouches[_toucheIdx].isUpdated = true
        }
        state.incidentsSoins.dommages.partieCorpsTouches[_toucheIdx][field] = value
      }
    },
    updateDommagesAutresField(state, action) {
      const { isUpdated = true, field, value } = action.payload
      if (field !== 'isUpdated' && isUpdated) {
        state.incidentsSoins.dommages.dommagesAutres.isUpdated = true
      }
      state.incidentsSoins.dommages.dommagesAutres[field] = value
    },
    addOneSuiviMedical(state, action) {
      const { codeNonConform, medicalPayload } = action.payload
      const _suiviMedicals = state.incidentsSoins?.suiviMedicals || []
      if (medicalPayload) {
        _suiviMedicals.push(medicalPayload)
      } else {
        _suiviMedicals.push({
          suiviMedicalId: 'new',
          codeNonConform: codeNonConform,
          suiviDate: "",
          suiviRemarque: "",
          dossierClosON: "",
          nbHyperliens: 0,
          isUpdated: true
        })
      }
      state.incidentsSoins.suiviMedicals = _suiviMedicals
    },
    deleteOneSuiviMedical(state, action) {
      const _mediclas = state.incidentsSoins?.suiviMedicals || []
      state.incidentsSoins.suiviMedicals = _mediclas.filter(item => item.suiviMedicalId !== action.payload)
    },
    updateSuiviMedicalProps(state, action) {
      const _medical = action.payload
      const _medicalIdx = state.incidentsSoins?.suiviMedicals?.findIndex(item => item.suiviMedicalId === _medical.suiviMedicalId)
      if (_medicalIdx > -1) {
        state.incidentsSoins.suiviMedicals[_medicalIdx] = { ..._medical }
      }
    },
    updateSuiviMedicalField(state, action) {
      const { isUpdated = true, id, field, value } = action.payload
      const _medicalIdx = state.incidentsSoins?.suiviMedicals?.findIndex(item => item.suiviMedicalId === id)
      if (_medicalIdx > -1) {
        if (field !== 'isUpdated' && isUpdated) {
          state.incidentsSoins.suiviMedicals[_medicalIdx].isUpdated = true
        }
        state.incidentsSoins.suiviMedicals[_medicalIdx][field] = value
      }
    },
    addOneArretTravail(state, action) {
      const { codeNonConform, arretPayload } = action.payload
      const _arretTravails = state.incidentsSoins?.arretTravails || []
      if (arretPayload) {
        _arretTravails.push(arretPayload)
      } else {
        _arretTravails.push({
          noAuto: 'new',
          codeNonConform: codeNonConform,
          codeAffectation: "",
          debutReel: null,
          retourPrevu: null,
          retourReel: null,
          prochVisitMedic: null,
          remarque: "",
          noAssignation: "",
          typeAssignation: "",
          dossierClosON: "",
          nbHyperliens: 0
        })
      }
      state.incidentsSoins.arretTravails = _arretTravails
    },
    deleteOneArretTravail(state, action) {
      const _arretTravails = state.incidentsSoins?.arretTravails || []
      state.incidentsSoins.arretTravails = _arretTravails.filter(item => item.noAuto !== action.payload)
    },
    updateArretTravailProps(state, action) {
      const _arretTravail = action.payload
      const _arretTravailIdx = state.incidentsSoins?.arretTravails?.findIndex(item => item.noAuto === _arretTravail.noAuto)
      if (_arretTravailIdx > -1) {
        state.incidentsSoins.arretTravails[_arretTravailIdx] = { ..._arretTravail }
      }
    },
    updateArretTravailField(state, action) {
      const { isUpdated = true, id, field, value } = action.payload
      const _arretIdx = state.incidentsSoins?.arretTravails?.findIndex(item => item.noAuto === id)
      if (_arretIdx > -1) {
        if (field !== 'isUpdated' && isUpdated) {
          state.incidentsSoins.arretTravails[_arretIdx].isUpdated = true
        }
        state.incidentsSoins.arretTravails[_arretIdx][field] = value
      }
    },
    addOneAssignation(state, action) {
      const { codeNonConform, assignationPayload } = action.payload
      const _assignationsTemp = state.incidentsSoins?.assignationsTemp || []
      if (assignationPayload) {
        _assignationsTemp.push(assignationPayload)
      } else {
        _assignationsTemp.push({
          noAuto: 'new',
          codeNonConform: codeNonConform,
          codeAffectation: null,
          debutReel: null,
          retourPrevu: null,
          retourReel: null,
          prochVisitMedic: null,
          remarque: null,
          noAssignation: null,
          typeAssignation: null,
          dossierClosON: null,
          nbHyperliens: 0,
          restrictions: []
        })
      }
      state.incidentsSoins.assignationsTemp = _assignationsTemp
    },
    deleteOneAssignation(state, action) {
      const _assignationsTemp = state.incidentsSoins?.assignationsTemp || []
      state.incidentsSoins.assignationsTemp = _assignationsTemp.filter(item => item.noAuto !== action.payload)
    },
    updateAssignationProps(state, action) {
      const _assignation = action.payload
      const _assignationIdx = state.incidentsSoins?.assignationsTemp?.findIndex(item => item.noAuto === _assignation.noAuto)
      if (_assignationIdx > -1) {
        state.incidentsSoins.assignationsTemp[_assignationIdx] = { ..._assignation }
      }
    },
    updateAssignationField(state, action) {
      const { isUpdated = true, id, field, value } = action.payload
      const _assignationIdx = state.incidentsSoins?.assignationsTemp?.findIndex(item => item.noAuto === id)
      if (_assignationIdx > -1) {
        if (field !== 'isUpdated' && isUpdated) {
          state.incidentsSoins.assignationsTemp[_assignationIdx].isUpdated = true
        }
        state.incidentsSoins.assignationsTemp[_assignationIdx][field] = value
      }
    },
    addOneAssgRestriction(state, action) {
      const { noAuto, restrictionPayload } = action.payload
      const _assignationIdx = state.incidentsSoins?.assignationsTemp?.findIndex(item => item.noAuto === noAuto)
      if (_assignationIdx > -1) {
        const _restrictions = state.incidentsSoins.assignationsTemp[_assignationIdx].restrictions || []
        _restrictions.push(restrictionPayload)
        state.incidentsSoins.assignationsTemp[_assignationIdx].restrictions = _restrictions
      }
    },
    deleteOneAssgRestriction(state, action) {
      const { noAuto, typeRestriction } = action.payload
      const _assignationIdx = state.incidentsSoins?.assignationsTemp?.findIndex(item => item.noAuto === noAuto)
      if (_assignationIdx > -1) {
        const _restrictions = state.incidentsSoins.assignationsTemp[_assignationIdx]?.restrictions.filter(item => item.typeRestriction !== typeRestriction)
        state.incidentsSoins.assignationsTemp[_assignationIdx].restrictions = _restrictions
      }
    },
    updateAssgRestrictionField(state, action) {
      const { isUpdated = true, noAuto, typeRestriction, field, value } = action.payload
      const _assignationIdx = state.incidentsSoins?.assignationsTemp?.findIndex(item => item.noAuto === noAuto)
      if (_assignationIdx > -1) {
        const _restrictionIdx = state.incidentsSoins.assignationsTemp[_assignationIdx]?.restrictions.findIndex(item => item.typeRestriction === typeRestriction)
        if (_restrictionIdx > -1) {
          if (field !== 'isUpdated' && isUpdated) {
            state.incidentsSoins.assignationsTemp[_assignationIdx].restrictions[_restrictionIdx].isUpdated = true
          }
          state.incidentsSoins.assignationsTemp[_assignationIdx].restrictions[_restrictionIdx][field] = value
        }
      }
    },
    addOneISCout(state, action) {
      const { codeNonConform, coutPayload } = action.payload
      const _couts = state.incidentsSoins?.couts || []
      if (coutPayload) {
        _couts.push(coutPayload)
      } else {
        _couts.push({
          coutNCId: 'new',
          codeNonConform: codeNonConform,
          categorieCout: null,
          dateCout: null,
          codeEtape: null,
          remarque: null,
          uniteMesure: null,
          codeActionCorr: null,
          coutPrevu: 0,
          coutReel: 0,
          quantite: 0,
          heure: 0,
          source: null,
          matMoAutre: null,
          nbHyperliens: 0
        })
      }
      state.incidentsSoins.couts = _couts
    },
    deleteOneISCout(state, action) {
      const _couts = state.incidentsSoins?.couts || []
      state.incidentsSoins.couts = _couts.filter(item => item.coutNCId !== action.payload)
    },
    updateISCoutProps(state, action) {
      const _cout = action.payload
      const _coutIdx = state.incidentsSoins?.couts?.findIndex(item => item.coutNCId === _cout.coutNCId)
      if (_coutIdx > -1) {
        state.incidentsSoins.couts[_coutIdx] = { ..._cout }
      }
    },
    updateISCoutField(state, action) {
      const { isUpdated = true, id, field, value } = action.payload
      const _coutIdx = state.incidentsSoins?.couts?.findIndex(item => item.coutNCId === id)
      if (_coutIdx > -1) {
        if (field !== 'isUpdated' && isUpdated) {
          state.incidentsSoins.couts[_coutIdx].isUpdated = true
        }
        state.incidentsSoins.couts[_coutIdx][field] = value
      }
    },
    addOneCommentaire(state, action) {
      const { codeNonConform, commentairePayload } = action.payload
      const _commentaires = state.incidentsSoins?.commentaires || []
      if (commentairePayload) {
        _commentaires.push(commentairePayload)
      } else {
        _commentaires.push({
          noAuto: 'new',
          codeNonConform: codeNonConform,
          dateCommentaire: null,
          commentaire: null,
          nbHyperliens: 0
        })
      }
      state.incidentsSoins.commentaires = _commentaires
    },
    deleteOneCommentaire(state, action) {
      const _commentaires = state.incidentsSoins?.commentaires || []
      state.incidentsSoins.commentaires = _commentaires.filter(item => item.noAuto !== action.payload)
    },
    updateCommentaireProps(state, action) {
      const _commentaire = action.payload
      const _commentaireIdx = state.incidentsSoins?.commentaires?.findIndex(item => item.noAuto === _commentaire.noAuto)
      if (_commentaireIdx > -1) {
        state.incidentsSoins.commentaires[_commentaireIdx] = { ..._commentaire }
      }
    },
    updateCommentaireField(state, action) {
      const { isUpdated = true, id, field, value } = action.payload
      const _commentaireIdx = state.incidentsSoins?.commentaires?.findIndex(item => item.noAuto === id)
      if (_commentaireIdx > -1) {
        if (field !== 'isUpdated' && isUpdated) {
          state.incidentsSoins.commentaires[_commentaireIdx].isUpdated = true
        }
        state.incidentsSoins.commentaires[_commentaireIdx][field] = value
      }
    },
    resetLigneProduit(state, action) {
      state.ligneProduit = [];
    },
    updateConfigSynchroDsnData(state, action) {
      const { isUpdated = true, field, value } = action.payload;
      if (state.configSynchro.dsn) {
        if (field !== 'isUpdated' && isUpdated) {
          state.configSynchro.dsn.isUpdated = true
        }
        state.configSynchro.dsn[field] = value
      }
    },
    updateConfigSynchroTypeNcData(state, action) {
      const { isUpdated = true, id, field, value } = action.payload;
      const _typeNcIdx = state.configSynchro?.typeNc?.findIndex(item => item.typeNonConformite === id)
      if (_typeNcIdx > -1) {
        if (field !== 'isUpdated' && isUpdated) {
          state.configSynchro.typeNc[_typeNcIdx].isUpdated = true
        }
        state.configSynchro.typeNc[_typeNcIdx][field] = value
      }
    },
    updateConfigSynchroDetailData(state, action) {
      const { isUpdated = true, id, field, value } = action.payload;
      const _idx = state.configSynchro?.details?.findIndex(item => item.champNcSynchroOdbcId === id)
      if (_idx > -1) {
        if (field !== 'isUpdated' && isUpdated) {
          state.configSynchro.details[_idx].isUpdated = true
        }
        state.configSynchro.details[_idx][field] = value
      }
    },
    updateConfigSynchroDetail2Data(state, action) {
      const { isUpdated = true, id, field, value } = action.payload;
      const _idx = state.configSynchro?.details2?.findIndex(item => item.champNcSynchroOdbcId === id)
      if (_idx > -1) {
        if (field !== 'isUpdated' && isUpdated) {
          state.configSynchro.details2[_idx].isUpdated = true
        }
        state.configSynchro.details2[_idx][field] = value
      }
    },
    updateNCConfigNCData(state, action) {
      const { isUpdated = true, field, value } = action.payload
      if (field !== 'isUpdated' && !state.ncConfigNC.isUpdated && isUpdated) {
        state.ncConfigNC.isUpdated = true
      }
      state.ncConfigNC[field] = value;
    },
    addOneAlerte(state, action) {
      const alertePayload = action.payload
      const _configAlertes = state?.configAlertes || []
      if (alertePayload) {
        _configAlertes.push(alertePayload)
      }
      state.configAlertes = _configAlertes
    },
    deleteOneAlerte(state, action) {
      const _configAlertes = state?.configAlertes || []
      state.configAlertes = _configAlertes.filter(item => item.noAuto !== action.payload)
    },
    updateAlerteProps(state, action) {
      const _configAlerte = action.payload
      const _configAlerteIdx = state?.configAlertes.findIndex(item => item.noAuto === _configAlerte.noAuto)
      if (_configAlerteIdx > -1) {
        state.configAlertes[_configAlerteIdx] = { ..._configAlerte }
      }
    },
    updateConfigHeuresTravailleesField(state, action) {
      const { isUpdated = true, field, value } = action.payload
      if (field !== 'isUpdated' && !state.configHeuresTravaillees.isUpdated && isUpdated) {
        state.configHeuresTravaillees.isUpdated = true
      }
      state.configHeuresTravaillees[field] = value;
    },
    updateConfigHeuresTravailleesTypePersonneField(state, action) {
      const { isUpdated = true, noTypePersonne, field, value } = action.payload
      const _idx = state.configHeuresTravaillees?.typePersonnes?.findIndex(item => item.noTypePersonne === noTypePersonne)
      if (_idx > -1) {
        if (field !== 'isUpdated' && isUpdated) {
          state.configHeuresTravaillees.isUpdated = true
        }
        state.configHeuresTravaillees.typePersonnes[_idx][field] = value
      }
    },
    unSelectAllConfigHeuresTravailleesTypePersonnes(state, action) {
      const _typePersonnes = state.configHeuresTravaillees?.typePersonnes?.map(item => {
        item.indSelect = 0
        return item
      })
      state.configHeuresTravaillees.isUpdated = true
      state.configHeuresTravaillees.typePersonnes = _typePersonnes
    },
  },
  extraReducers: {
    [nonConformiteEditLoad.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.nonConformite = action.payload;
    },
    [nonConformiteEditLoad.pending]: (state, action) => {
      state.status = "loading";
    },
    [nonConformiteEditLoad.rejected]: (state, action) => {
      state.status = "failed";
    },
    [nonConformiteEditDefaultLoad.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.nonConformite = action.payload;
    },
    [nonConformiteEditDefaultLoad.pending]: (state, action) => {
      state.status = "loading";
    },
    [nonConformiteEditDefaultLoad.rejected]: (state, action) => {
      state.status = "failed";
    },
    [UpdateAddNC.fulfilled]: (state, action) => {
      state.status = "succeeded";
      if (action.payload) {
        state.nonConformite = action.payload;
      }
    },
    [UpdateAddNC.pending]: (state, action) => {
      state.status = "loading";
    },
    [UpdateAddNC.rejected]: (state, action) => {
      state.status = "failed";
    },
    [nonConformiteAnalyseLoad.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.ncAnalyse = action.payload;
    },
    [nonConformiteAnalyseLoad.pending]: (state, action) => {
      state.status = "loading";
    },
    [nonConformiteAnalyseLoad.rejected]: (state, action) => {
      state.status = "failed";
    },
    [updateNonConformiteAnalyse.fulfilled]: (state, action) => {
      state.status = "succeeded";
      if (action.payload) {
        state.ncAnalyse = action.payload;
      }
      state.loadingCauseFondAnalyse = false
    },
    [updateNonConformiteAnalyse.pending]: (state, action) => {
      state.status = "loading";
      state.loadingCauseFondAnalyse = true
    },
    [updateNonConformiteAnalyse.rejected]: (state, action) => {
      state.status = "failed";
    },
    [updateAnalyseCauseImmNC.fulfilled]: (state, action) => {
      state.loadingCauseFondAnalyse = false
    },
    [updateAnalyseCauseImmNC.pending]: (state, action) => {
      state.loadingCauseFondAnalyse = true
    },
    [deleteAnalyseCauseImmNC.fulfilled]: (state, action) => {
      state.loadingCauseFondAnalyse = false
    },
    [deleteAnalyseCauseImmNC.pending]: (state, action) => {
      state.loadingCauseFondAnalyse = true
    },
    [updateAnalyseCauseFondNC.fulfilled]: (state, action) => {
      state.loadingCauseFondAnalyse = false
    },
    [updateAnalyseCauseFondNC.pending]: (state, action) => {
      state.loadingCauseFondAnalyse = true
    },
    [deleteAnalyseCauseFondNC.fulfilled]: (state, action) => {
      state.loadingCauseFondAnalyse = false
    },
    [deleteAnalyseCauseFondNC.pending]: (state, action) => {
      state.loadingCauseFondAnalyse = true
    },
    [updateAnalyseCauseFondAnalyse.fulfilled]: (state, action) => {
      state.loadingCauseFondAnalyse = false
    },
    [updateAnalyseCauseFondAnalyse.pending]: (state, action) => {
      state.loadingCauseFondAnalyse = true
    },
    [deleteAnalyseCauseFondAnalyse.fulfilled]: (state, action) => {
      state.loadingCauseFondAnalyse = false
    },
    [deleteAnalyseCauseFondAnalyse.pending]: (state, action) => {
      state.loadingCauseFondAnalyse = true
    },
    [loadNCNewActionResume.fulfilled]: (state, action) => {
      state.actionResume = action.payload
    },
    [loadNCNewActionAction.fulfilled]: (state, action) => {
      state.actionAction = action.payload
    },
    [loadNCNewActionSuivi.fulfilled]: (state, action) => {
      state.suiviAction = action.payload
    },
    [loadNCNewActionCout.fulfilled]: (state, action) => {
      state.actionCout = action.payload
    },
    [loadNCNewActionCalendrier.fulfilled]: (state, action) => {
      state.actionCalendrier = action.payload
    },
    [nonConformiteSignalementLoad.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.loadingSignalement = false;
      state.signalement = Signalement(action.payload);
    },
    [nonConformiteSignalementLoad.pending]: (state, action) => {
      state.status = "loading";
      state.loadingSignalement = true;
    },
    [nonConformiteSignalementLoad.rejected]: (state, action) => {
      state.status = "failed";
      state.loadingSignalement = false;
    },
    [fetchNCActions.fulfilled]: (state, action) => {
      state.ncAction = action.payload
    },
    [newSignalement.fulfilled]: (state, action) => {
      state.signalement = Signalement(action.payload);
      state.loadingSignalement = false;
    },
    [addSignalement.fulfilled]: (state, action) => {
      if (action.payload.messageId) {
        state.status = "failed";
      } else {
        state.status = "succeeded";
        state.signalement = Signalement(action.payload);
      }
      state.loadingSignalement = false;
      state.savingSignalement = false;
    },
    [addSignalement.pending]: (state, action) => {
      state.status = "loading";
      state.loadingSignalement = true;
      state.savingSignalement = true;
    },
    [addSignalement.rejected]: (state, action) => {
      state.status = "failed";
      state.loadingSignalement = false;
      state.savingSignalement = false;
    },
    [updateSignalement.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.signalement = Signalement(action.payload);
      state.loadingSignalement = false;
      state.savingSignalement = false;
    },
    [updateSignalement.pending]: (state, action) => {
      state.status = "loading";
      state.loadingSignalement = true;
      state.savingSignalement = true;
    },
    [updateSignalement.rejected]: (state, action) => {
      state.status = "failed";
      state.loadingSignalement = false;
      state.savingSignalement = false;
    },
    [nonConformiteSignalementAcceptationLoad.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.loadingSignalement = false;
      state.signalementsAcceptation = action.payload;
    },
    [nonConformiteSignalementAcceptationLoad.pending]: (state, action) => {
      state.status = "loading";
      state.loadingSignalement = true;
    },
    [nonConformiteSignalementAcceptationLoad.rejected]: (state, action) => {
      state.status = "failed";
      state.loadingSignalement = false;
    },
    [updateSignalementAcceptation.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.signalementsAcceptation = action.payload;
      state.loadingSignalement = false;
    },
    [updateSignalementAcceptation.pending]: (state, action) => {
      state.status = "loading";
      state.loadingSignalement = true;
    },
    [updateSignalementAcceptation.rejected]: (state, action) => {
      state.status = "failed";
      state.loadingSignalement = false;
    },
    [nonConformiteEditCheckAutreIdentifiant.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.loadingSignalement = false;
      state.loadingInProgress = false;
    },
    [nonConformiteEditCheckAutreIdentifiant.pending]: (state, action) => {
      state.status = "loading";
      state.loadingSignalement = true;
      state.loadingInProgress = true;
    },
    [nonConformiteEditCheckAutreIdentifiant.rejected]: (state, action) => {
      state.status = "failed";
      state.loadingSignalement = false;
      state.loadingInProgress = false;
    },
    [nonConformiteEditGetSignalementCourriel.fulfilled]: (state, action) => {
      state.loadingSignalementCourriel = false;
    },
    [nonConformiteEditGetSignalementCourriel.pending]: (state, action) => {
      state.loadingSignalementCourriel = true;
    },
    [nonConformiteEditGetSignalementCourriel.rejected]: (state, action) => {
      state.loadingSignalementCourriel = false;
    },
    [nonConformiteEditGetRenseignementCourriel.fulfilled]: (state, action) => {
      state.loadingRenseignementCourriel = false;
    },
    [nonConformiteEditGetRenseignementCourriel.pending]: (state, action) => {
      state.loadingRenseignementCourriel = true;
    },
    [nonConformiteEditGetRenseignementCourriel.rejected]: (state, action) => {
      state.loadingRenseignementCourriel = false;
    },
    [nonConformiteRenseignementLoad.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.loadingInProgress = false;
      state.rensiegnement = action.payload;
    },
    [nonConformiteRenseignementLoad.pending]: (state, action) => {
      state.status = "loading";
      state.loadingInProgress = true;
    },
    [nonConformiteRenseignementLoad.rejected]: (state, action) => {
      state.status = "failed";
      state.loadingInProgress = false;
    },
    [updateRenseignement.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.rensiegnement = action.payload;
      state.loadingRenseignement = false;
    },
    [updateRenseignement.pending]: (state, action) => {
      state.status = "loading";
      state.loadingRenseignement = true;
    },
    [updateRenseignement.rejected]: (state, action) => {
      state.status = "failed";
      state.loadingRenseignement = false;
    },
    [nonConformiteDerogationLoad.fulfilled]: (state, action) => {
      state.status = "loading";
      state.loadingInProgress = false;
      state.derogation = action.payload;
    },
    [nonConformiteDerogationLoad.pending]: (state, action) => {
      state.status = "loading";
      state.loadingInProgress = true;
    },
    [nonConformiteDerogationLoad.rejected]: (state, action) => {
      state.status = "failed";
      state.loadingInProgress = false;
    },
    [nonConformiteDerogationApprobationLoad.fulfilled]: (state, action) => {
      state.status = "loading";
      state.loadingInProgress = false;
    },
    [nonConformiteDerogationApprobationLoad.pending]: (state, action) => {
      state.status = "loading";
      state.loadingInProgress = true;
    },
    [nonConformiteDerogationApprobationLoad.rejected]: (state, action) => {
      state.status = "failed";
      state.loadingInProgress = false;
    },
    [updateDerogation.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.derogation = action.payload;
      state.loadingInProgress = false;
    },
    [updateDerogation.pending]: (state, action) => {
      state.status = "loading";
      state.loadingInProgress = true;
    },
    [updateDerogation.rejected]: (state, action) => {
      state.status = "failed";
      state.loadingInProgress = false;
    },
    [loadNCCouts.fulfilled]: (state, action) => {
      state.ncCouts = action.payload
    },
    [loadNCNewCout.fulfilled]: (state, action) => {
      state.ncCout = action.payload
    },
    [getHyperliensExist.fulfilled]: (state, action) => {
      state.duplicatingNC = false;
    },
    [getHyperliensExist.pending]: (state, action) => {
      state.duplicatingNC = true;
    },
    [getHyperliensExist.rejected]: (state, action) => {
      state.duplicatingNC = false;
    },
    [dupliquerNC.fulfilled]: (state, action) => {
      state.duplicatingNC = false;
    },
    [dupliquerNC.pending]: (state, action) => {
      state.duplicatingNC = true;
    },
    [dupliquerNC.rejected]: (state, action) => {
      state.duplicatingNC = false;
    },
    [loadIncidentsSoins.pending]: (state, action) => {
      state.loadingIncidentsSoins = true;
    },
    [loadIncidentsSoins.fulfilled]: (state, action) => {
      state.incidentsSoins = action.payload
      state.loadingIncidentsSoins = false;
    },
    [loadIncidentsSoins.rejected]: (state, action) => {
      state.loadingIncidentsSoins = false;
    },
    [nonConformiteEditLoadPerso.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.loadingLoadPerso = false;
      state.loadPerso = action.payload;
    },
    [nonConformiteEditLoadPerso.pending]: (state, action) => {
      state.status = "loading";
      state.loadingLoadPerso = true;
    },
    [nonConformiteEditLoadPerso.rejected]: (state, action) => {
      state.status = "failed";
      state.loadingLoadPerso = true;
    },
    [nonConformiteEditLoadEcranMenu.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.loadingEcranMenu = false;
      state.ecranMenu = action.payload;
    },
    [nonConformiteEditLoadEcranMenu.pending]: (state, action) => {
      state.status = "loading";
      state.loadingEcranMenu = true;
    },
    [nonConformiteEditLoadEcranMenu.rejected]: (state, action) => {
      state.status = "failed";
      state.loadingEcranMenu = true;
    },
    [nonConformiteEditLoadTypeNc.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.loadingTypeNc = false;
      state.typeNc = action.payload;
    },
    [nonConformiteEditLoadTypeNc.pending]: (state, action) => {
      state.status = "loading";
      state.loadingTypeNc = true;
    },
    [nonConformiteEditLoadTypeNc.rejected]: (state, action) => {
      state.status = "failed";
      state.loadingTypeNc = true;
    },
    [nonConformiteEditLoadLigneProduit.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.loadingLigneProduit = false;
      state.ligneProduit = action.payload;
    },
    [nonConformiteEditLoadLigneProduit.pending]: (state, action) => {
      state.status = "loading";
      state.loadingLigneProduit = true;
    },
    [nonConformiteEditLoadLigneProduit.rejected]: (state, action) => {
      state.status = "failed";
      state.loadingLigneProduit = true;
    },
    [nonConformiteEditLoadHeuresTravaillees.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.loadingHeuresTravaillees = false;
      state.heuresTravaillees = action.payload;
    },
    [nonConformiteEditLoadHeuresTravaillees.pending]: (state, action) => {
      state.status = "loading";
      state.loadingHeuresTravaillees = true;
    },
    [nonConformiteEditLoadHeuresTravaillees.rejected]: (state, action) => {
      state.status = "failed";
      state.loadingHeuresTravaillees = true;
    },
    [loadConfigSynchro.fulfilled]: (state, action) => {
      state.loadingConfigSynchro = false;
      state.configSynchro = action.payload;
    },
    [loadConfigSynchro.pending]: (state, action) => {
      state.loadingConfigSynchro = true;
    },
    [loadConfigSynchro.rejected]: (state, action) => {
      state.loadingConfigSynchro = true;
    },
    [updateConfigSynchroDsn.fulfilled]: (state, action) => {
      state.loadingConfigSynchro = false;
    },
    [updateConfigSynchroDsn.pending]: (state, action) => {
      state.loadingConfigSynchro = true;
    },
    [updateConfigSynchroDsn.rejected]: (state, action) => {
      state.loadingConfigSynchro = true;
    },
    [updateConfigSynchroTypeNc.fulfilled]: (state, action) => {
      state.loadingConfigSynchro = false;
    },
    [updateConfigSynchroTypeNc.pending]: (state, action) => {
      state.loadingConfigSynchro = true;
    },
    [updateConfigSynchroTypeNc.rejected]: (state, action) => {
      state.loadingConfigSynchro = true;
    },
    [updateConfigSynchroDetails.fulfilled]: (state, action) => {
      state.loadingConfigSynchro = false;
    },
    [updateConfigSynchroDetails.pending]: (state, action) => {
      state.loadingConfigSynchro = true;
    },
    [updateConfigSynchroDetails.rejected]: (state, action) => {
      state.loadingConfigSynchro = true;
    },
    [loadConfigNc.fulfilled]: (state, action) => {
      state.loadingConfigNC = false;
      if (action.payload) {
        state.ncConfigNC = action.payload;
      }
    },
    [loadConfigNc.pending]: (state, action) => {
      state.loadingConfigNC = true;
    },
    [loadConfigNc.rejected]: (state, action) => {
      state.loadingConfigNC = true;
    },
    [updateConfigNc.fulfilled]: (state, action) => {
      state.loadingConfigNC = false;
    },
    [updateConfigNc.pending]: (state, action) => {
      state.loadingConfigNC = true;
    },
    [updateConfigNc.rejected]: (state, action) => {
      state.loadingConfigNC = true;
    },
    [loadConfigAlerte.fulfilled]: (state, action) => {
      state.loadingConfigAlerte = false;
      if (action.payload) {
        state.configAlertes = action.payload;
      }
    },
    [loadConfigAlerte.pending]: (state, action) => {
      state.loadingConfigAlerte = true;
    },
    [loadConfigAlerte.rejected]: (state, action) => {
      state.loadingConfigAlerte = true;
    },
    [loadConfigHeuresTravaillees.fulfilled]: (state, action) => {
      state.loadingConfigHeuresTravaillees = false;
      if (action.payload) {
        state.configHeuresTravaillees = action.payload;
      }
    },
    [loadConfigHeuresTravaillees.pending]: (state, action) => {
      state.loadingConfigHeuresTravaillees = true;
    },
    [loadConfigHeuresTravaillees.rejected]: (state, action) => {
      state.loadingConfigHeuresTravaillees = false;
    },
    [updateConfigHeuresTravaillees.fulfilled]: (state, action) => {
      state.loadingConfigHeuresTravaillees = false;
    },
    [updateConfigHeuresTravaillees.pending]: (state, action) => {
      state.loadingConfigHeuresTravaillees = true;
    },
    [updateConfigHeuresTravaillees.rejected]: (state, action) => {
      state.loadingConfigHeuresTravaillees = false;
    },
  },
});

export const {
  updateNCField,
  updateNCDetailField,
  updateNCDetail2Field,
  updateNCAnalyseField,
  addOneNCAnalyseCauseImmNC,
  deleteOneNCAnalyseCauseImmNC,
  updateNCAnalyseCauseImmNCField,
  updateNCAnalyseCauseImmNCProps,
  addOneNCAnalyseCauseFondNC,
  deleteOneNCAnalyseCauseFondNC,
  updateNCAnalyseCauseFondNCField,
  updateNCAnalyseCauseFondNCProps,
  addOneNCAnalyseFondAnalyse,
  deleteOneNCAnalyseFondAnalyse,
  updateNCAnalyseFondAnalyseField,
  updateNCAnalyseFondAnalyseProps,
  updateNCActionField,
  addOneNCActionResume,
  deleteOneNCActionResume,
  updateNCActionResumeProps,
  updateNCActionResumeField,
  addOneNCActionAction,
  deleteOneNCActionAction,
  updateNCActionActionProps,
  updateNCActionActionField,
  updateNCSuiviActionField,
  addOneNCActionCout,
  deleteOneNCActionCout,
  updateNCActionCoutProps,
  updateNCActionCoutField,
  addOneNCActionCalendrier,
  deleteOneNCActionCalendrier,
  updateNCActionCalendrierProps,
  updateNCActionCalendrierField,
  updateNCSignalementField,
  setOpenEditDrawer,
  setActiveEditSection,
  resetSignalement,
  resetSignalementAcceptation,
  addOneNCCout,
  deleteOneNCCout,
  updateNCCoutProps,
  updateNCCoutField,
  updateIncidentsSoinsField,
  updateIncidentsSoinsStatutField,
  updateIncidentsSoinsPremierSoinsField,
  addOnePartieCorpsTouche,
  deleteOnePartieCorpsTouche,
  updatePartieCorpsToucheField,
  updatePartieCorpsToucheProps,
  updateDommagesAutresField,
  addOneSuiviMedical,
  deleteOneSuiviMedical,
  updateSuiviMedicalField,
  updateSuiviMedicalProps,
  addOneArretTravail,
  deleteOneArretTravail,
  updateArretTravailProps,
  updateArretTravailField,
  addOneAssignation,
  deleteOneAssignation,
  updateAssignationField,
  updateAssignationProps,
  addOneAssgRestriction,
  updateAssgRestrictionField,
  deleteOneAssgRestriction,
  addOneISCout,
  deleteOneISCout,
  updateISCoutField,
  updateISCoutProps,
  addOneCommentaire,
  deleteOneCommentaire,
  updateCommentaireField,
  updateCommentaireProps,
  addNCActionQuickEmps,
  resetLigneProduit,
  updateConfigSynchroDsnData,
  updateConfigSynchroTypeNcData,
  updateConfigSynchroDetailData,
  updateConfigSynchroDetail2Data,
  updateNCConfigNCData,
  addOneAlerte,
  updateAlerteProps,
  deleteOneAlerte,
  updateConfigHeuresTravailleesField,
  updateConfigHeuresTravailleesTypePersonneField,
  unSelectAllConfigHeuresTravailleesTypePersonnes

} = nonConformiteEditSlice.actions;

export default nonConformiteEditSlice.reducer;