import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "Services/AxiosService";
import { GET_CRITERES_MULTI_TYPE_PERF_REVIEW } from "store/UrlConstants";

export const loadCriteresMultiType = createAsyncThunk(
    "RevuePerformance/GetCriteresMultiType",
    async (params, thunkAPI) => {
        const response = await axios.get(GET_CRITERES_MULTI_TYPE_PERF_REVIEW);
        return response.data;
    }
);

const allCriteresMultiTypeAdapter = createEntityAdapter({
    selectId: (entity) => entity.ordre,
});

const initialState = {
    actionsObjets: allCriteresMultiTypeAdapter.getInitialState(),
    loadInProgress: false,
};

const getCritereMultiTypeSlice = createSlice({
    name: "perfReviewGetCriteresMultiType",
    initialState,
    reducers: {},
    extraReducers: {
        [loadCriteresMultiType.pending]: (state, action) => {
            state.loadInProgress = true;
        },
        [loadCriteresMultiType.fulfilled]: (state, action) => {
            allCriteresMultiTypeAdapter.setAll(
                state.actionsObjets,
                action.payload
            );
            state.loadInProgress = false;
        },
    },
});

export const { selectAll: selectAllCriteresMultiType } =
    allCriteresMultiTypeAdapter.getSelectors(
        (state) => state.perfReviewGetCriteresMultiType.actionsObjets
    );

export default getCritereMultiTypeSlice.reducer;
