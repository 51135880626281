import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import axios from 'Services/AxiosService';
import { listToQueryParam, paramsToQueryParam } from 'store/UrlUtils';
import {
  FETCH_OTHER_IDS,
  FETCH_PROCESSES,
  FETCH_UNITS,
  FETCH_COMPONENTS,
  FETCH_SAMPLES,
  FETCH_NC_CRITERES_MULTI_TYPES,
  FETCH_TYPES,
  FETCH_NC_SEARCH_VOLETS,
  FETCH_SEVERITIES,
  FETCH_REPORTED_BYS,
  FETCH_REPORTED_BYS_SIGNALEMENT,
  FETCH_CLASSES_NC_SIGNALEMENT,
  FETCH_ACCEPTED_DECLINED_BYS,
  FETCH_NC_EDIT_PROCESSES_BY_SITE,
  FETCH_FIELDS_OF_RENSEIGNEMENTS,
  FETCH_RENSEIGNEMENTS_NO_BON_COMMANDE,
  FETCH_RENSEIGNEMENTS_NO_BON_TRAVAIL,
  FETCH_RENSEIGNEMENTS_NO_DESSIN,
  FETCH_RENSEIGNEMENTS_NO_SERIE,
  FETCH_RENSEIGNEMENTS_NO_LOT,
  FETCH_FIELDS_OF_DEROGATION,
  FETCH_ACCEPTED_DECLINED_BYS_SIGNAL,
  FETCH_ETAPES_NC,
  FETCH_EMPLOYES_BLESSE,
  FETCH_EMPLOYES_BLESSE_SIGNALEMENT,
  FETCH_ORIGINES,
  FETCH_PRODUITS
} from 'store/UrlConstants';

const otherIdsAdapter = createEntityAdapter({});
const processesAdapter = createEntityAdapter({});
const siteProcessAdapter = createEntityAdapter({});
const unitsAdapter = createEntityAdapter({});
const componentsAdapter = createEntityAdapter({});
const samplesAdapter = createEntityAdapter({});
const criteresMultiTypesAdapter = createEntityAdapter({
  selectId: entity => entity.typeInfo,
});
const typesAdapter = createEntityAdapter({});
const voletsAdapter = createEntityAdapter({});
const severitiesAdapter = createEntityAdapter({});
const reportedBysAdapter = createEntityAdapter({});
const reportedBysSignalementAdapter = createEntityAdapter({});
const acceptedDeclinedBysAdapter = createEntityAdapter({});
const noBonCommandesAdapter = createEntityAdapter({});
const noSeriesAdapter = createEntityAdapter({});
const noBonTravailsAdapter = createEntityAdapter({});
const noDessinsAdapter = createEntityAdapter({});
const noLotsAdapter = createEntityAdapter({});
const derogationNoSeriesAdapter = createEntityAdapter({});
const derogationNoBonCommandesAdapter = createEntityAdapter({});
const derogationNoBonTravailsAdapter = createEntityAdapter({});
const derogationNoLotsAdapter = createEntityAdapter({});
const acceptedDeclinedBysSignalementAdapter = createEntityAdapter({});
const etapesAdapter = createEntityAdapter({});
const employesBlesseAdapter = createEntityAdapter({});
const employesBlesseSignalementAdapter = createEntityAdapter({});
const classesNCSignalementAdapter = createEntityAdapter({});
const produitsAdapter = createEntityAdapter({});
const originesAdapter = createEntityAdapter({});

export const fetchOtherIds = createAsyncThunk('NonConformites/Search/Criterias/OtherIds', async (params) => {
  const finalParams = params.typeNC === "SELECT-ALL" ? { sites: params.sites } : { sites: params.sites, typeNC: params.typeNC }
  const response = await axios.get(FETCH_OTHER_IDS + paramsToQueryParam(finalParams));
  return response.data;
});
export const {
  selectAll: selectAllOtherIds,
  selectById: selectOtherIdById,
} = otherIdsAdapter.getSelectors((state) => state.nonConformiteSearchCriteria.otherIds);

export const fetchProcesses = createAsyncThunk('NonConformites/Search/Criterias/Processes', async (sites) => {
  const response = await axios.get(FETCH_PROCESSES + listToQueryParam("sites", sites));
  return response.data;
});

export const {
  selectAll: selectAllProcesses,
  selectById: selectProcessById,
} = processesAdapter.getSelectors((state) => state.nonConformiteSearchCriteria.processes);

export const fetchUnits = createAsyncThunk('NonConformites/Search/Criterias/Units', async (processCode, thunkAPI) => {
  const response = await axios.get(FETCH_UNITS + paramsToQueryParam({ 'processCode': processCode }));
  return response.data;
});

export const fetchProcessesBySite = createAsyncThunk(FETCH_NC_EDIT_PROCESSES_BY_SITE, async (codeSite, thunkAPI) => {
  const response = await axios.get(FETCH_NC_EDIT_PROCESSES_BY_SITE + paramsToQueryParam({ codeSite: codeSite }));
  return response.data;
});
export const {
  selectAll: selectAllSiteProcesses,
  selectById: selectSiteProcessById,
} = siteProcessAdapter.getSelectors((state) => state.nonConformiteSearchCriteria.siteProcesses);

export const {
  selectAll: selectAllUnits,
  selectById: selectUnitById,
} = unitsAdapter.getSelectors((state) => state.nonConformiteSearchCriteria.units);

export const fetchComponents = createAsyncThunk('NonConformites/Search/Criterias/Components', async (unitCode, thunkAPI) => {
  const response = await axios.get(FETCH_COMPONENTS + paramsToQueryParam({ 'unitCode': unitCode }));
  return response.data;
});

export const {
  selectAll: selectAllComponents,
  selectById: selectComponentById,
} = componentsAdapter.getSelectors((state) => state.nonConformiteSearchCriteria.components);

export const fetchSamples = createAsyncThunk('NonConformites/Search/Criterias/Samples', async (componentCode, thunkAPI) => {
  const response = await axios.get(FETCH_SAMPLES + paramsToQueryParam({ 'componentCode': componentCode }));
  return response.data;
});

export const {
  selectAll: selectAllSamples,
  selectById: selectSampleById,
} = samplesAdapter.getSelectors((state) => state.nonConformiteSearchCriteria.samples);

export const fetchCriteresMultiTypes = createAsyncThunk('NonConformites/Search/Criterias/MultiTypes', async () => {
  const response = await axios.get(FETCH_NC_CRITERES_MULTI_TYPES);
  return response.data;
});

export const {
  selectAll: selectAllMultiTypes,
  selectById: selectMultiTypeById,
} = criteresMultiTypesAdapter.getSelectors((state) => state.nonConformiteSearchCriteria.criteresMultiTypes)

export const fetchTypes = createAsyncThunk('NonConformites/Search/Criterias/Types', async (thunkAPI) => {
  const response = await axios.get(FETCH_TYPES);
  return response.data;
});

export const {
  selectAll: selectAllTypes,
  selectById: selectTypeById,
} = typesAdapter.getSelectors((state) => state.nonConformiteSearchCriteria.types);

export const fetchVolets = createAsyncThunk('NonConformites/Search/Criterias/Volets', async (thunkAPI) => {
  const response = await axios.get(FETCH_NC_SEARCH_VOLETS);
  return response.data;
});

export const {
  selectAll: selectAllVolets,
  selectById: selectVoletById,
} = voletsAdapter.getSelectors((state) => state.nonConformiteSearchCriteria.volets);

export const fetchSeverities = createAsyncThunk('NonConformites/Search/Criterias/Severities', async (params, thunkAPI) => {
  const response = await axios.get(FETCH_SEVERITIES + paramsToQueryParam(params));
  return response.data;
});

export const {
  selectAll: selectAllSeverities,
  selectById: selectSeverityById,
} = severitiesAdapter.getSelectors((state) => state.nonConformiteSearchCriteria.severities);

export const fetchReportedBys = createAsyncThunk('NonConformites/Search/Criterias/ReportedBys', async (sites, thunkAPI) => {
  const response = await axios.get(FETCH_REPORTED_BYS + listToQueryParam("sites", sites));
  return response.data;
});
export const fetchReportedBysSignalement = createAsyncThunk('NonConformites/Search/Criterias/ReportedBysSignalement', async (sites, thunkAPI) => {
  const response = await axios.get(FETCH_REPORTED_BYS_SIGNALEMENT + listToQueryParam("sites", sites));
  return response.data;
});

export const {
  selectAll: selectAllReportedBys,
  selectById: selectReportedById,
} = reportedBysAdapter.getSelectors((state) => state.nonConformiteSearchCriteria.reportedBys);

export const {
  selectAll: selectAllReportedBysSignalement,
  selectById: selectReportedBysSignalement,
} = reportedBysSignalementAdapter.getSelectors((state) => state.nonConformiteSearchCriteria.reportedBysSignalement);

export const fetchAcceptedDeclinedBys = createAsyncThunk('NonConformites/Search/Criterias/AcceptedDeclinedBys', async (sites, thunkAPI) => {
  const response = await axios.get(FETCH_ACCEPTED_DECLINED_BYS + listToQueryParam("sites", sites));
  return response.data;
});

export const {
  selectAll: selectAllAcceptedDeclinedBys,
  selectById: selectAcceptedDeclinedById,
} = acceptedDeclinedBysAdapter.getSelectors((state) => state.nonConformiteSearchCriteria.acceptedDeclinedBys);

export const fetchAcceptedDeclinedBysSignalement = createAsyncThunk('NonConformites/Search/Criterias/AcceptedDeclinedBysSignalement', async (sites, thunkAPI) => {
  const response = await axios.get(FETCH_ACCEPTED_DECLINED_BYS_SIGNAL + listToQueryParam("sites", sites));
  return response.data;
});

export const {
  selectAll: selectAllAcceptedDeclinedBysSignalement,
  selectById: selectAcceptedDeclinedSignalementById,
} = acceptedDeclinedBysSignalementAdapter.getSelectors((state) => state.nonConformiteSearchCriteria.acceptedDeclinedBysSignalement);

export const fetchFieldsOfRenseignements = createAsyncThunk('NonConformites/Search/Criterias/Renseignements', async (payload, thunkAPI) => {
  const { fieldName, sites } = payload
  const { data } = await axios.get(FETCH_FIELDS_OF_RENSEIGNEMENTS + fieldName + listToQueryParam("sites", sites))
  return data
})

export const {
  selectAll: selectAllNoBonCommandes,
  selectById: selectNoBonCommandeById,
} = noBonCommandesAdapter.getSelectors((state) => state.nonConformiteSearchCriteria.noBonCommandes);

export const fetchRenseignementsNoBonCommandes = createAsyncThunk('NonConformites/Search/Criterias/RenseignementsNoBonCommande', async (payload, thunkAPI) => {
  const { sites } = payload
  const { data } = await axios.get(FETCH_RENSEIGNEMENTS_NO_BON_COMMANDE + listToQueryParam("sites", sites))
  return data
})

export const {
  selectAll: selectAllNoSeries,
  selectById: selectNoSerieById,
} = noSeriesAdapter.getSelectors((state) => state.nonConformiteSearchCriteria.noSeries);

export const fetchRenseignementsNoSeries = createAsyncThunk('NonConformites/Search/Criterias/RenseignementsNoSerie', async (payload, thunkAPI) => {
  const { sites } = payload
  const { data } = await axios.get(FETCH_RENSEIGNEMENTS_NO_SERIE + listToQueryParam("sites", sites))
  return data
})

export const {
  selectAll: selectAllNoBonTravails,
  selectById: selectNoBonTravailById,
} = noBonTravailsAdapter.getSelectors((state) => state.nonConformiteSearchCriteria.noBonTravails);

export const fetchRenseignementsNoBonTravails = createAsyncThunk('NonConformites/Search/Criterias/RenseignementsNoBonTravail', async (payload, thunkAPI) => {
  const { sites } = payload
  const { data } = await axios.get(FETCH_RENSEIGNEMENTS_NO_BON_TRAVAIL + listToQueryParam("sites", sites))
  return data
})

export const {
  selectAll: selectAllNoDessins,
  selectById: selectNoDessinById,
} = noDessinsAdapter.getSelectors((state) => state.nonConformiteSearchCriteria.noDessins);

export const fetchRenseignementsNoDessins = createAsyncThunk('NonConformites/Search/Criterias/RenseignementsNoDessin', async (payload, thunkAPI) => {
  const { sites } = payload
  const { data } = await axios.get(FETCH_RENSEIGNEMENTS_NO_DESSIN + listToQueryParam("sites", sites))
  return data
})

export const {
  selectAll: selectAllNoLots,
  selectById: selectNoLotById,
} = noLotsAdapter.getSelectors((state) => state.nonConformiteSearchCriteria.noLots);

export const fetchRenseignementsNoLots = createAsyncThunk('NonConformites/Search/Criterias/RenseignementsNoLot', async (payload, thunkAPI) => {
  const { sites } = payload
  const { data } = await axios.get(FETCH_RENSEIGNEMENTS_NO_LOT + listToQueryParam("sites", sites))
  return data
})

export const {
  selectAll: selectAllDerogationNoSeries,
  selectById: selectDerogationNoSerieById,
} = derogationNoSeriesAdapter.getSelectors((state) => state.nonConformiteSearchCriteria.derogationNoSeries);

export const {
  selectAll: selectAllDerogationNoBonCommandes,
  selectById: selectDerogationNoBonCommandeById,
} = derogationNoBonCommandesAdapter.getSelectors((state) => state.nonConformiteSearchCriteria.derogationNoBonCommandes);

export const {
  selectAll: selectAllDerogationNoBonTravails,
  selectById: selectDerogationNoBonTravailById,
} = derogationNoBonTravailsAdapter.getSelectors((state) => state.nonConformiteSearchCriteria.derogationNoBonTravails);

export const {
  selectAll: selectAllDerogationNoLots,
  selectById: selectDerogationNoLotById,
} = derogationNoLotsAdapter.getSelectors((state) => state.nonConformiteSearchCriteria.derogationNoLots);

export const fetchFieldsOfDerogation = createAsyncThunk('NonConformites/Search/Criterias/Derogation', async (payload, thunkAPI) => {
  const { fieldName, sites } = payload
  const { data } = await axios.get(FETCH_FIELDS_OF_DEROGATION + fieldName + listToQueryParam("sites", sites))
  return {
    fieldName,
    data
  }
})

export const fetchEtapes = createAsyncThunk('NonConformites/Search/Criterias/Etapes', async (payload, thunkAPI) => {
  const { data } = await axios.get(FETCH_ETAPES_NC + paramsToQueryParam(payload))
  return data
})
export const {
  selectAll: selectAllEtapes,
  selectById: selectEtapeById,
} = etapesAdapter.getSelectors((state) => state.nonConformiteSearchCriteria.etapesNC);

export const fetchEmployesBlesse = createAsyncThunk('NonConformites/Search/Criterias/EmployesBlesse', async (payload, thunkAPI) => {
  const { data } = await axios.get(FETCH_EMPLOYES_BLESSE + paramsToQueryParam(payload))
  return data
})
export const {
  selectAll: selectAllEmployesBlesse,
  selectById: selectEmployeBlesseById,
} = employesBlesseAdapter.getSelectors((state) => state.nonConformiteSearchCriteria.employesBlesse);

export const fetchEmployesBlesseSignalement = createAsyncThunk('NonConformites/Search/Criterias/EmployesBlesseSignalement', async (sites, thunkAPI) => {
  const response = await axios.get(FETCH_EMPLOYES_BLESSE_SIGNALEMENT + listToQueryParam("sites", sites));
  return response.data;
})
export const {
  selectAll: selectAllEmployesBlesseSignalement,
  selectById: selectEmployeBlesseSignalementById,
} = employesBlesseSignalementAdapter.getSelectors((state) => state.nonConformiteSearchCriteria.employesBlesseSignalement);

export const fetchClassesNCSignalement = createAsyncThunk('NonConformites/Search/Criterias/ClassesNCSignalement', async (payload, thunkAPI) => {
  const { data } = await axios.get(FETCH_CLASSES_NC_SIGNALEMENT + paramsToQueryParam(payload));
  return data;
})
export const {
  selectAll: selectAllClassesNCSignalement,
  selectById: selectClasseNCSignalementById,
} = classesNCSignalementAdapter.getSelectors((state) => state.nonConformiteSearchCriteria.classesNCSignalement);

export const fetchProduits = createAsyncThunk('NonConformites/Search/Criterias/Produits', async (payload, thunkAPI) => {
  const { data } = await axios.get(FETCH_PRODUITS + paramsToQueryParam(payload))
  return data
})
export const {
  selectAll: selectAllProduits,
  selectById: selectProduitById,
} = produitsAdapter.getSelectors((state) => state.nonConformiteSearchCriteria.produits);

export const fetchOrigines = createAsyncThunk('NonConformites/Search/Criterias/Origines', async (payload, thunkAPI) => {
  const { data } = await axios.get(FETCH_ORIGINES + paramsToQueryParam(payload))
  return data
})
export const {
  selectAll: selectAllOrigines,
  selectById: selectOrigineById,
} = originesAdapter.getSelectors((state) => state.nonConformiteSearchCriteria.origines);

const initialState =
{
  otherIds: otherIdsAdapter.getInitialState(),
  processes: processesAdapter.getInitialState(),
  siteProcesses: siteProcessAdapter.getInitialState(),
  units: unitsAdapter.getInitialState(),
  components: componentsAdapter.getInitialState(),
  samples: samplesAdapter.getInitialState(),
  criteresMultiTypes: criteresMultiTypesAdapter.getInitialState(),
  types: typesAdapter.getInitialState(),
  volets: voletsAdapter.getInitialState(),
  severities: severitiesAdapter.getInitialState(),
  reportedBys: reportedBysAdapter.getInitialState(),
  reportedBysSignalement: reportedBysSignalementAdapter.getInitialState(),
  acceptedDeclinedBys: acceptedDeclinedBysAdapter.getInitialState(),
  acceptedDeclinedBysSignalement: acceptedDeclinedBysSignalementAdapter.getInitialState(),
  loadingInProgress: false,
  loadingProcesses: false,
  loadingSiteProcesses: false,
  loadingUnits: false,
  loadingComponents: false,
  loadingSamples: false,
  noBonCommandes: noBonCommandesAdapter.getInitialState(),
  noSeries: noSeriesAdapter.getInitialState(),
  noBonTravails: noBonTravailsAdapter.getInitialState(),
  noDessins: noDessinsAdapter.getInitialState(),
  noLots: noLotsAdapter.getInitialState(),
  etapesNC: etapesAdapter.getInitialState(),
  employesBlesse: employesBlesseAdapter.getInitialState(),
  employesBlesseSignalement: employesBlesseSignalementAdapter.getInitialState(),
  classesNCSignalement: classesNCSignalementAdapter.getInitialState(),
  produits: produitsAdapter.getInitialState(),
  origines: originesAdapter.getInitialState(),

  derogationNoSeries: noLotsAdapter.getInitialState(),
  derogationNoBonCommandes: noLotsAdapter.getInitialState(),
  derogationNoBonTravails: noLotsAdapter.getInitialState(),
  derogationNoLots: noLotsAdapter.getInitialState()
}

const nonConformiteSearchCriteriaSlice = createSlice({
  name: 'nonConformiteSearchCriteria',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchOtherIds.fulfilled]: (state, action) => {
      otherIdsAdapter.setAll(state.otherIds, action.payload);
    },
    [fetchProcesses.pending]: (state, action) => {
      state.loadingProcesses = true;
    },
    [fetchProcesses.fulfilled]: (state, action) => {
      processesAdapter.setAll(state.processes, action.payload);
      state.loadingProcesses = false;
    },
    [fetchProcessesBySite.pending]: (state, action) => {
      state.loadingSiteProcesses = true;
    },
    [fetchProcessesBySite.fulfilled]: (state, action) => {
      processesAdapter.setAll(state.siteProcesses, action.payload);
      state.loadingSiteProcesses = false;
    },
    [fetchUnits.pending]: (state, action) => {
      state.loadingUnits = true
    },
    [fetchUnits.fulfilled]: (state, action) => {
      state.loadingUnits = false
      unitsAdapter.setAll(state.units, action.payload);
    },
    [fetchComponents.pending]: (state, action) => {
      state.loadingComponents = true
    },
    [fetchComponents.fulfilled]: (state, action) => {
      state.loadingComponents = false
      componentsAdapter.setAll(state.components, action.payload);
    },
    [fetchSamples.pending]: (state, action) => {
      state.loadingSamples = true
    },
    [fetchSamples.fulfilled]: (state, action) => {
      state.loadingSamples = false
      samplesAdapter.setAll(state.samples, action.payload);
    },
    [fetchSamples.rejected]: (state, action) => {
      state.loadingSamples = false
    },
    [fetchCriteresMultiTypes.pending]: (state) => {
      state.loadingInProgress = true
    },
    [fetchCriteresMultiTypes.rejected]: (state) => {
      state.loadingInProgress = false
    },
    [fetchCriteresMultiTypes.fulfilled]: (state, action) => {
      criteresMultiTypesAdapter.setAll(state.criteresMultiTypes, action.payload)
      state.loadingInProgress = false
    },
    [fetchTypes.fulfilled]: (state, action) => {
      typesAdapter.setAll(state.types, action.payload);
    },
    [fetchVolets.fulfilled]: (state, action) => {
      voletsAdapter.setAll(state.volets, action.payload);
    },
    [fetchSeverities.fulfilled]: (state, action) => {
      severitiesAdapter.setAll(state.severities, action.payload);
    },
    [fetchReportedBys.fulfilled]: (state, action) => {
      reportedBysAdapter.setAll(state.reportedBys, action.payload);
    },
    [fetchReportedBysSignalement.fulfilled]: (state, action) => {
      reportedBysSignalementAdapter.setAll(state.reportedBysSignalement, action.payload);
    },
    [fetchAcceptedDeclinedBys.fulfilled]: (state, action) => {
      acceptedDeclinedBysAdapter.setAll(state.acceptedDeclinedBys, action.payload);
    },
    [fetchAcceptedDeclinedBysSignalement.fulfilled]: (state, action) => {
      acceptedDeclinedBysSignalementAdapter.setAll(state.acceptedDeclinedBysSignalement, action.payload);
    },
    [fetchRenseignementsNoBonCommandes.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [fetchRenseignementsNoBonCommandes.fulfilled]: (state, action) => {
      noBonCommandesAdapter.setAll(state.noBonCommandes, action.payload);
      state.loadingInProgress = false
    },
    [fetchRenseignementsNoBonCommandes.rejected]: (state, action) => {
      state.loadingInProgress = false
    },
    [fetchRenseignementsNoSeries.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [fetchRenseignementsNoSeries.fulfilled]: (state, action) => {
      noSeriesAdapter.setAll(state.noSeries, action.payload);
      state.loadingInProgress = false
    },
    [fetchRenseignementsNoSeries.rejected]: (state, action) => {
      state.loadingInProgress = false
    },
    [fetchRenseignementsNoBonTravails.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [fetchRenseignementsNoBonTravails.fulfilled]: (state, action) => {
      noBonTravailsAdapter.setAll(state.noBonTravails, action.payload);
      state.loadingInProgress = false
    },
    [fetchRenseignementsNoBonTravails.rejected]: (state, action) => {
      state.loadingInProgress = false
    },
    [fetchRenseignementsNoDessins.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [fetchRenseignementsNoDessins.fulfilled]: (state, action) => {
      noDessinsAdapter.setAll(state.noDessins, action.payload);
      state.loadingInProgress = false
    },
    [fetchRenseignementsNoDessins.rejected]: (state, action) => {
      state.loadingInProgress = false
    },
    [fetchRenseignementsNoLots.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [fetchRenseignementsNoLots.fulfilled]: (state, action) => {
      noLotsAdapter.setAll(state.noLots, action.payload);
      state.loadingInProgress = false
    },
    [fetchRenseignementsNoLots.rejected]: (state, action) => {
      state.loadingInProgress = false
    },
    [fetchFieldsOfRenseignements.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [fetchFieldsOfRenseignements.fulfilled]: (state, action) => {
      state.loadingInProgress = false
    },
    [fetchFieldsOfRenseignements.rejected]: (state, action) => {
      state.loadingInProgress = false
    },
    [fetchFieldsOfDerogation.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [fetchFieldsOfDerogation.fulfilled]: (state, action) => {
      const { fieldName, data } = action.payload
      if (fieldName === 'NoSerie') {
        derogationNoSeriesAdapter.setAll(state.derogationNoSeries, data);
      } else if (fieldName === 'NoBonCommande') {
        derogationNoBonCommandesAdapter.setAll(state.derogationNoBonCommandes, data);
      } else if (fieldName === 'NoBonTravail') {
        derogationNoBonTravailsAdapter.setAll(state.derogationNoBonTravails, data);
      } else if (fieldName === 'NoLot') {
        derogationNoLotsAdapter.setAll(state.derogationNoLots, data);
      }
      state.loadingInProgress = false
    },
    [fetchFieldsOfDerogation.rejected]: (state, action) => {
      state.loadingInProgress = false
    },
    [fetchEtapes.fulfilled]: (state, action) => {
      etapesAdapter.setAll(state.etapesNC, action.payload);
    },
    [fetchEmployesBlesse.fulfilled]: (state, action) => {
      employesBlesseAdapter.setAll(state.employesBlesse, action.payload);
    },
    [fetchEmployesBlesseSignalement.fulfilled]: (state, action) => {
      employesBlesseSignalementAdapter.setAll(state.employesBlesseSignalement, action.payload);
    },
    [fetchClassesNCSignalement.fulfilled]: (state, action) => {
      classesNCSignalementAdapter.setAll(state.classesNCSignalement, action.payload);
    },
    [fetchProduits.fulfilled]: (state, action) => {
      produitsAdapter.setAll(state.produits, action.payload);
    },
    [fetchOrigines.fulfilled]: (state, action) => {
      originesAdapter.setAll(state.origines, action.payload);
    },
  }
});

export default nonConformiteSearchCriteriaSlice.reducer;