// ========================================================================================
// IMPORTS
// ========================================================================================
import { createSlice, createAsyncThunk, createEntityAdapter, createSelector } from '@reduxjs/toolkit'
import axios from 'Services/AxiosService';
import { COMPETENCE_SEARCH } from 'store/UrlConstants'


// ========================================================================================
// ADAPTERS / ASYNC ACTIONS / SELECTORS
// ========================================================================================
const competencesAdpater = createEntityAdapter({ selectId: entity => entity.codeCompetence });

export const fetchAllCompetences = createAsyncThunk('Competences/GetAll', async (searchParams) => {
    const response = await axios.post(COMPETENCE_SEARCH, searchParams);
    return response.data;
});

export const competencesSelector = (store) => store.competenceSearch.competences;

export const {
    selectAll: selectAllCompetences,
    selectById: selectCompetenceById,
} = competencesAdpater.getSelectors(competencesSelector);

export const selectLoadingCompetence = createSelector(
    competencesSelector,
    (state) => state.loading
);


// ========================================================================================
// MAIN SLICE
// ========================================================================================
const initialState = {
    competences: competencesAdpater.getInitialState({ loading: false })
}

const competenceSearchSlice = createSlice({
    name: "competenceSearchSlice",
    initialState,
    reducers: {
        setCompetencesList(state, action) {
            competencesAdpater.setAll(state.competences, action.payload)
        },
        upsertOneCompetence(state, action) {
            competencesAdpater.upsertOne(state.competences, action.payload)
        },
        removeOneCompetence(state, action) {
            competencesAdpater.removeOne(state.competences, action.payload.codeCompetence)
        }
    },
    extraReducers: {
        [fetchAllCompetences.pending]: (state, action) => {
            state.competences.loading = true;
        },
        [fetchAllCompetences.rejected]: (state, action) => {
            state.competences.loading = false;
        },
        [fetchAllCompetences.fulfilled]: (state, action) => {
            competencesAdpater.setAll(state.competences, action.payload);
            state.competences.loading = false;
        },
    },
})

export const {
    setCompetencesList, upsertOneCompetence, removeOneCompetence
} = competenceSearchSlice.actions;

export default competenceSearchSlice.reducer;