import LocalStorageService from "Services/LocalStorageService";
import {setLanguageState} from "../slices/AuthenticationSlice";
import {setLocale} from "../slices/PersoSlice";

const localStorageService = LocalStorageService.getService();

const setLocalStorage = (store, action) => {
  localStorageService.setLanguage(action.payload.language);
  store.dispatch(setLanguageState(action.payload.language));
  store.dispatch(setLocale(action.payload.language.id));
};

const localStorage = store => next => action => {
  switch (action.type) {
    case 'authentication/login/fulfilled':
    case 'authentication/loginAzureAD/fulfilled':
    case 'authentication/authentifyCodeMultifacteur/fulfilled':
      if(typeof action.payload === 'object' &&
          !Array.isArray(action.payload) &&
          action.payload !== null &&
          action.payload.success === true &&
          !action.payload.erreurMessage &&
          !action.payload.tempPassword) {
        setLocalStorage(store, action);
      }
      break;
    case 'authentication/setLanguage/fulfilled':
      setLocalStorage(store, action);
      break;
    // no default
  }

  return next(action);
}

export default localStorage;