import { createSlice, createSelector } from '@reduxjs/toolkit';

const initialState = {
    compteur:5,
}

const testBoicSelectorDemoSlice = createSlice({
  name: 'testBoicSelectorDemo',
  initialState,
  reducers: {
  },
  extraReducers: {
  },
});

export function compteurSelector(state) {
    return state.testBoicSelectorDemo.compteur
} 

export default testBoicSelectorDemoSlice.reducer;