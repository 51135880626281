// Prend un objet de clé:valeur et returne un array d'objet {id:clé, value:valeur}
// trié par Id. Il est possible de trié par value si le deuxième paramètre est passé

import {
    CO_W_DEPASSEMENT,
    CO_W_NON_CONFORMITE,
    CO_W_NON_CONFORMITE_AUTRES,
    CO_W_NON_CONFORMITE_INCIDENTS,
    CO_W_NON_CONFORMITE_PRODUIT,
    CO_W_SIGNALEMENT,
    CO_W_URGENCE,
    CO_W_DEROGATION,
    CO_W_RENSEIGNEMENTS,
    CO_W_NON_CONFORMITE_OPPORTUNITE,
} from "store/EcranConstants";
import {
    AJOUT,
    DISPONIBLE,
    DOSSIERS_DE_MES_EMPLOYES, ENREG_DE_MES_EMPLOYES, ENREG_SANS_RAPPORTE_PAR,
    LECTURE,
    MODIFICATION,
    SUPPRESSION, TOUS_LES_DOSSIERS, TOUS_LES_ENREG,
    VISIBLE,
} from "../store/DroitConstants"
import {showSnackbarMessage} from "../store/slices/MessagesSystemSlice"

// comme "value".
export function objectToArray(obj, orderByIdOrValue = "id") {
    const newArray = [];
    for (let [key, value] of Object.entries(obj)) {
        newArray.push({ id: key, value: value });
    }

    if (orderByIdOrValue === "value") {
        return newArray.sort((a, b) => a.value.localeCompare(b.value));
    } else {
        return newArray.sort((a, b) => a.id.localeCompare(b.id));
    }
}

// Prend un ensemble d'objets et retourne un nouveau ensemble filtre d'après les paramètres
// filter = key
// filterValue = value
export function filterObject(obj, filter, filterValue) {
    const result = Object.keys(obj).reduce((acc, val) => {
        return obj[val][filter] === filterValue
            ? {
                ...acc,
                [val]: obj[val],
            }
            : acc;
    }, {});
    return result;
}

export const getHeightAndWidthFromDataUrl = (dataURL) =>
    new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
            resolve({
                height: img.height,
                width: img.width,
            });
        };
        img.src = dataURL;
    });

export const fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export const fileToBinaryString = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export const removeObjectProperties = function (obj, props) {
    if (typeof obj === "undefined" || obj === null) return;

    for (let i = 0; i < props.length; i++) {
        if (obj.hasOwnProperty(props[i])) {
            delete obj[props[i]];
        }
    }
};

export const isPropExists = (perso, field) => {
    if (perso === null) return false;

    return perso.persoProprietes.hasOwnProperty(field);
};

export const isPropEquals = (perso, field, value) => {
    if (perso === null) return false;
    return (
        perso.persoProprietes.hasOwnProperty(field) &&
        perso.persoProprietes[field] === value
    );
};

export const propValue = (perso, field, defaultValue) => {
    if (perso === null || perso === undefined) return defaultValue;

    if (!perso.persoProprietes.hasOwnProperty(field)) return defaultValue;

    return perso.persoProprietes[field];
};

export const windowNameByNcType = (ncType) => {
    if (!ncType) return null;

    switch (ncType) {
        case "AUDIT":
        case "INSPECTION":
            return CO_W_NON_CONFORMITE;
        case "PRODUIT":
            return CO_W_NON_CONFORMITE_PRODUIT;
        case "ACCIDENT":
        case "INCIDENT":
            return CO_W_NON_CONFORMITE_INCIDENTS;
        case "URGENCE":
            return CO_W_URGENCE;
        case "SIGNALEMENT":
            return CO_W_SIGNALEMENT;
        case "VALEUR HORS LIMITE":
            return CO_W_DEPASSEMENT;
        case "DÉROGATION":
            return CO_W_DEROGATION;
        case "REQUÊTE":
            return CO_W_RENSEIGNEMENTS;
        case "OPPORTUNITÉ":
            return CO_W_NON_CONFORMITE_OPPORTUNITE;
        default:
            return CO_W_NON_CONFORMITE_AUTRES;
    }
};

export const sortArray = (data) => {
    if (data) {
        const sorted = data.sort((a, b) => a.value.localeCompare(b.value));
        const active_sorted = sorted.filter((item) => !item.inactive);
        const inactive_sorted = sorted.filter((item) => item.inactive === true);
        return [...active_sorted, ...inactive_sorted];
    }

    return [];
};

// Trie la liste d'objets en fonction du deuxième paramètre passé.
// Params obj = {}, propRefName = ""
export function sortArrObj(obj, propRefName) {
    obj.sort((a, b) => {
        if (a[propRefName] > b[propRefName]) return 1;
        if (a[propRefName] < b[propRefName]) return -1;
        return 0;
    });
    return obj;
}
// Trie la liste d'objets en fonction du deuxième paramètre passé.
// Params obj = {}, propRefName = ""
export function sortLocaleArrObj(obj, propRefName) {
    obj.sort((a, b) => {
        return a[propRefName].localeCompare(b[propRefName]);
    });
    return obj;
}

// Renvoie un nouvel objet sans les propriétés qui contiennent des fausses valeurs.
// Param obj = {}
// Fausses valeurs (falsy) dans le javascript = null, undefined, 0, "", []
export function deleteEmptyValues(obj) {
    let draftObj = obj;

    for (const prop in draftObj) {
        if (!draftObj[prop]) {
            delete draftObj[prop];
        } else if (Array.isArray(draftObj[prop])) {
            if (draftObj[prop].length === 0) {
                delete draftObj[prop];
            }
        }
    }

    return draftObj;
}

// Renvoie un nouvel objet sans les propriétés qui contiennent des valeurs == null.
// Param obj = {}
export function deleteNullValues(obj) {
    let draftObj = obj;

    for (const prop in draftObj) {
        if (draftObj[prop] === null) {
            delete draftObj[prop];
        }
    }

    return draftObj;
}

export function maybePluralize(count, noun, suffix = "s") {
    return `${count} ${noun}${count !== 1 ? suffix : ""}`;
}

/**
 * Vérifie s'il y a eu des changements dans les valeurs des 2 objets passés qui 
 * contiennent les mêmes propriétés, quel que soit l'ordre.
 * @param {Object} obj1 
 * @param {Object} obj2 
 */
export const isEquals = (obj1, obj2) => {
    const keys1 = Object.keys(obj1)
    const keys2 = Object.keys(obj2)
    return JSON.stringify(keys1) === JSON.stringify(keys2)
}

export const getCookieValue = (name) =>
    document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || "";

export const setCookie = (cname, cvalue, exmins) => {
    const d = new Date();
    d.setTime(d.getTime() + exmins * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;secure";
};

export const getHost = (location) => {
    return location.protocol + "//" + location.host;
};

const toString = Object.prototype.toString;
export function deepCopy(obj) {
    let rv;

    switch (typeof obj) {
        case "object":
            if (obj === null) {
                // null => null
                rv = null;
            } else {
                switch (toString.call(obj)) {
                    case "[object Array]":
                        // It's an array, create a new array with
                        // deep copies of the entries
                        rv = obj.map(deepCopy);
                        break;
                    case "[object Date]":
                        // Clone the date
                        rv = new Date(obj);
                        break;
                    case "[object RegExp]":
                        // Clone the RegExp
                        rv = new RegExp(obj);
                        break;
                    // ...probably a few others
                    default:
                        // Some other kind of object, deep-copy its
                        // properties into a new object
                        rv = Object.keys(obj).reduce(function (prev, key) {
                            prev[key] = deepCopy(obj[key]);
                            return prev;
                        }, {});
                        break;
                }
            }
            break;
        default:
            // It's a primitive, copy via assignment
            rv = obj;
            break;
    }
    return rv;
}

export function deepEqual(x, y) {
    const ok = Object.keys, tx = typeof x, ty = typeof y;
    return x && y && tx === 'object' && tx === ty ? (
        ok(x).length === ok(y).length &&
        ok(x).every(key => deepEqual(x[key], y[key]))
    ) : (x === y);
}

const permObject = {
    canAdd: false,
    canEdit: false,
    canRemove: false,
    canRead: false,
    isVisible: false,
    isDisponible: false,
    dossiersDeMesEmployes: false,
    enregDeMesEmployes: false,
    tousLesDossiers: false,
    tousLesEnreg: false,
    enregSansRapportePar: false,
};

export function checkPerms(droitsItem) {
    let droits = { ...permObject };

    for (let ds of droitsItem) {
        droits.canRead = ds.droits.some(d => d.code === LECTURE);
        droits.canEdit = ds.droits.some(d => d.code === MODIFICATION);
        droits.canRemove = ds.droits.some(d => d.code === SUPPRESSION);
        droits.canAdd = ds.droits.some(d => d.code === AJOUT);
        droits.isVisible = ds.droits.some(d => d.code === VISIBLE);
        droits.isDisponible = ds.droits.some(d => d.code === DISPONIBLE);
    }

    return droits;
}

export function formatPerms(_droits) {
    let droits = { ...permObject };

    droits.canRead = _droits.some(d => d.code === LECTURE);
    droits.canEdit = _droits.some(d => d.code === MODIFICATION);
    droits.canRemove = _droits.some(d => d.code === SUPPRESSION);
    droits.canAdd = _droits.some(d => d.code === AJOUT);

    droits.isVisible = _droits.some(d => d.code === VISIBLE);
    droits.isDisponible = _droits.some(d => d.code === DISPONIBLE);

    droits.dossiersDeMesEmployes = _droits.some(d => d.code === DOSSIERS_DE_MES_EMPLOYES);
    droits.enregDeMesEmployes = _droits.some(d => d.code === ENREG_DE_MES_EMPLOYES);

    droits.tousLesDossiers = _droits.some(d => d.code === TOUS_LES_DOSSIERS);
    droits.tousLesEnreg = _droits.some(d => d.code === TOUS_LES_ENREG);

    droits.enregSansRapportePar = _droits.some(d => d.code === ENREG_SANS_RAPPORTE_PAR);

    return droits;
}

export function stopPropagation(callback) {
    return (e) => {
        e.stopPropagation()
        callback(e)
    }
}

export function isObjEmpty(obj) {
    return Object.keys(obj).length === 0;
}

/**
 * Fait défiler une liste d'objets et convertit la valeur des propriétés qui sont nulles
 * en '', à l'exception des propriétés passées en second paramètre
 * @param {Object[]} objList
 * @param {String[]} propsToNotConvert
 * @return {Objet[]} 
 */
export function objListPropsNullToEmptyStr(objList, propsToNotConvert = []) {
    return objList.map(item => objPropsNullToEmptyStr(item, propsToNotConvert));
}

/**
 * Fait convertir la valeur des propriétés de l'objet qui sont nulles en "",
 * à l'exception des propriétés passées dans le second paramètre
 * @param {Object} objList
 * @param {String[]} propsToNotConvert
 * @return {Objet[]} 
 */
export function objPropsNullToEmptyStr(obj, propsToNotConvert = []) {
    return Object.fromEntries(
        Object.entries(obj).map(([prop, value]) => {
            if (!propsToNotConvert.includes(prop)) {
                if (value === null) {
                    return [prop, ''];
                } else if (typeof value === 'object' && !Array.isArray(value)) {
                    return [prop, objPropsNullToEmptyStr(value, propsToNotConvert)];
                } else if (Array.isArray(value)) {
                    return [prop, value.map(element => (typeof element === 'object' ? objPropsNullToEmptyStr(element, propsToNotConvert) : element))];
                }
            }
            return [prop, value];
        })
    );
}

/**
 * Fait convertir la valeur des propriétés de l'objet qui sont nulles en "",
 * uniquement pour les propriétés passées dans le second paramètre
 * @param {Object} obj
 * @param {String[]} propsToConvert
 * @return {Object}
 */
export function objPropsNullToEmptyStrForProps(obj, propsToConvert = []) {
    return Object.fromEntries(
        Object.entries(obj).map(([prop, value]) => {
            if (propsToConvert.includes(prop)) {
                if (value === null) {
                    return [prop, ''];
                } else if (typeof value === 'object' && !Array.isArray(value)) {
                    return [prop, objPropsNullToEmptyStrForProps(value, propsToConvert)];
                } else if (Array.isArray(value)) {
                    return [prop, value.map(element => (typeof element === 'object' ? objPropsNullToEmptyStrForProps(element, propsToConvert) : element))];
                }
            }
            return [prop, value];
        })
    );
}

/**
 * Fait défiler une liste d'objets et convertit la valeur des propriétés qui sont ""
 * en nulles, à l'exception des propriétés passées en second paramètre
 * @param {Object[]} objList
 * @param {String[]} propsToNotConvert
 * @return {Objet[]} 
 */
export function objListPropsEmptyStrToNull(objList, propsToNotConvert = []) {
    return objList.map(item => objPropsEmptyStrToNull(item, propsToNotConvert));
}

/**
 * Fait convertir la valeur des propriétés de l'objet qui sont "" en nulles,
 * à l'exception des propriétés passées dans le second paramètre
 * @param {Object} objList
 * @param {String[]} propsToNotConvert
 * @return {Objet[]} 
 */
export function objPropsEmptyStrToNull(obj, propsToNotConvert = []) {
    if (obj === null || obj === undefined) {
        return obj
    }
    
    return Object.fromEntries(
        Object.entries(obj).map(([prop, value]) => {
            if (!propsToNotConvert.includes(prop)) {
                if (value === '') {
                    return [prop, null];
                } else if (typeof value === 'object' && !Array.isArray(value)) {
                    return [prop, objPropsEmptyStrToNull(value, propsToNotConvert)];
                } else if (Array.isArray(value)) {
                    return [prop, value.map(element => (typeof element === 'object' ? objPropsEmptyStrToNull(element, propsToNotConvert) : element))];
                }
            }
            return [prop, value];
        })
    );
}

/**
 * Il est important que l'extension du fichier figure à côté du nom.
 * @param {String} fileName
 * @return {String} 
 */
export const getFileTypeByFileName = (fileName) => {
    if (fileName && fileName.includes('.')) {
        const [fileType, _fileName] = fileName.split('.').reverse();
        return `.${fileType?.toLowerCase()}`;
    }
    return null;
};

/**
 * Vérifie que la chaîne contient au moins un caractère avant et après le symbole '@',
 * suivi d'un point et d'autres caractères.
 * @param {String} email
 * @return {Boolean} 
 */
export function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

/**
 * Remplace les caractères %s du texte par les valeurs passées dans l'array replacementStrings
 * @param {String} text
 * @param {String[]} replacementStrings
 * @return {String}
 */
export function iwReplaceString(text, replacementStrings) {
    let index = 0;
    return text.replace(/%s/g, () => replacementStrings[index++]);
}

/**
 * Fonction qui prend un nom de champ windows et le met lower camel case
 * ex. classe_n_c => classeNC
 *     no_bon_travail => noBonTravail
 *     date_1 => date1
 * @param {String} str
 * @return {String}
 */
export function toLowerCamelCase(str) {
    return str.replace(/([-_][a-z0-9])/g, group => group.toUpperCase()
        .replace('-', '')
        .replace('_', ''))
        .replace(/(^[A-Z])/g, group => group.toLowerCase());
}

/**
 * Fonction qui prend un string et le met en Upper Camel Case
 * @param str
 * @returns {*}
 */
export function toUpperCamelCase(str = "") {
    return str.split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join('');
}

/**
 * Un wrapper qui recoit une promesse API (query rtk-query) et qui affiche les messages standard par défaut
 * @param {Dispatch} dispatch - The dispatch function from Redux.
 * @param {Promise} promise - The promise from an rtk-query mutation
 * @param {?object} successMsgOpts - Override d'options pour showSnackbarMessage en cas de succès
 * @param {?object} errorMsgOpts - Override d'options pour showSnackbarMessage en cas d'erreur
 */
export const majWrapper = async (dispatch, promise, successMsgOpts = null, errorMsgOpts = null) => {
    try {
        return await promise.unwrap().then((res) => {
            dispatch(
                showSnackbarMessage(successMsgOpts ? successMsgOpts : {
                    dba: "objetsLangues2",
                    messageId: "web_confirmation_enregistrement",
                    severity: "success",
                })
            );
            return res
        }).catch((e) => {
            const msg = e?.description || e?.data?.message || e?.data?.messageText || "Erreur inconnue"
            dispatch(showSnackbarMessage(errorMsgOpts ? errorMsgOpts : {
                message: msg,
                severity: "error",
                autoHideDuration: 10000,
            }));
            return false
        });
    } catch (e) {
        const msg = e?.description || e?.data?.message || e?.data?.messageText || "Erreur inconnue"
        dispatch(showSnackbarMessage(errorMsgOpts ? errorMsgOpts : {
            message: msg,
            severity: "error",
            autoHideDuration: 10000,
        }));
        return false
    }
};