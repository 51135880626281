import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import LocalStorageService from "Services/LocalStorageService";
import { REFRESH_TOKEN } from "../store/UrlConstants";

const localStorageService = LocalStorageService.getService();

const baseApi = axios.create();
const refreshApi = axios.create();

// Use interceptor to inject the token to requests
/*baseApi.interceptors.request.use(request => {
  request.headers['Authorization'] = `Bearer ${localStorageService.getToken()}`;
  return request;
});

refreshApi.interceptors.request.use(request => {
  request.headers['Authorization'] = `Bearer ${localStorageService.getRefreshToken()}`;
  return request;
});*/

const refreshAuthLogic = async failedRequest => {
  localStorageService.setRefreshingToken('refreshingInProgress');
  return refreshApi.get(REFRESH_TOKEN, { withCredentials: true }).then(tokenRefreshResponse => {
    return Promise.resolve().then(() => {
      localStorageService.setRefreshingToken('refreshDone');
    });
  }).catch(function () {
    return Promise.reject(new Error('failed_refresh_auth'));
  });
};

createAuthRefreshInterceptor(baseApi, refreshAuthLogic);

export default baseApi;