import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import moment from "moment";
import axios from "Services/AxiosService";
import GeneralInspectionSheet from "store/Models/AuditsInspections/GeneralInspectionSheet";
import InspectionSearchDetailCriteria from "store/Models/InspectionSearchDetail";
import InspectionSearchFichesCriteria from "store/Models/InspectionSearchFiches";
import { showSnackbarMessage } from "store/slices/MessagesSystemSlice";
import {
  FETCH_INSPECTION_DETAIL_GENERALE,
  FETCH_INSPECTION_DETAIL_GROUPS,
  FETCH_INSPECTION_DETAIL_GROUP,
  //FETCH_INSPECTION_DETAIL_ELEMENTS,
  FETCH_INSPECTION_DETAIL_GROUP_ELEMENTS,
  UPDATE_INSPECTION_DETAIL_GROUP_ELEMENT,
  UPDATE_INSPECTION_DETAIL_GROUP_ELEMENTS,
  SEND_INSPECTION_SEARCH_DETAIL,
  UPDATE_INSPECTION_DETAIL_GENERALE,
  UPDATE_INSPECTION_DETAIL_GROUP,
  INSPECTION_LOAD_CALENDRIER,
  INSPECTION_ADD_FICHE_RESULTAT,
  FETCH_AUTRE_IDENTIFIANT,
  FETCH_CRITERES_MULTI_TYPES,
  FETCH_CRITERES_MULTI_INFOS,
  UPDATE_INSPECTION_SEARCH_FICHES,
  FETCH_INSPECTION_FICHE_GENERALE,
  FETCH_INSPECTION_TYPE_INSPECTION,
  UPDATE_INSPECTION_FICHE_GENERALE,
  DELETE_INSPECTION_FICHE_GENERALE,
  FETCH_INSPECTION_FICHE_ELEMENTS,
  UPDATE_INSPECTION_FICHE_ELEMENTS,
  FETCH_INSPECTION_SELECTION_ELEMENT_STD,
  FETCH_INSPECTION_ELEMENT_STD,
  UPDATE_INSPECTION_ELEMENT_STD,
  DELETE_INSPECTION_ELEMENT_STD,
  FETCH_INSPECTION_FICHE_CALENDRIERS,
  FETCH_INSPECTION_NEW_FICHE_CALENDRIERS,
  ADD_INSPECTION_FICHE_CALENDRIERS,
  UPDATE_INSPECTION_FICHE_CALENDRIERS,
  DELETE_INSPECTION_FICHE_CALENDRIERS,
  FETCH_INSPECTION_FICHE_RESULTATS,
  DUPLICATE_FICHE_ELEMENT,
} from "store/UrlConstants";
import { paramsToQueryParam } from "store/UrlUtils";

// All Adapters
const detailGeneraleAdapter = createEntityAdapter({
  selectId: (detail) => detail.inspFicheResultatsId,
});
const detailGroupsAdapter = createEntityAdapter({
  selectId: (detail) => detail.inspFicheResGroupeId,
});
const detailGroupElementsAdapter = createEntityAdapter({
  selectId: (element) => element.inspFicheResElementsId,
});
const otherIdsAdapter = createEntityAdapter({});
const typeInspectionAdapter = createEntityAdapter({});
const ficheCalendriersAdapter = createEntityAdapter({
  selectId: entity => entity.codeFreqCalen
})

export const fetchInspectionGeneralInfo = createAsyncThunk(
  "inspection/load_general",
  async (inspFicheResultatsId) => {
    const response = await axios.get(
      FETCH_INSPECTION_DETAIL_GENERALE +
      "?inspFicheResultatsId=" +
      inspFicheResultatsId
    );
    return response.data;
  }
);

export const { selectAll: selectAllGenerale, selectById: selectGeneraleById } =
  detailGeneraleAdapter.getSelectors(
    (state) => state.inspection.detailGenerale
  );

export const updateInspectionDetailGenerale = createAsyncThunk(
  UPDATE_INSPECTION_DETAIL_GENERALE,
  async (inspectionDetailGenerale, thunkAPI) => {
    const response = await axios.put(
      UPDATE_INSPECTION_DETAIL_GENERALE,
      inspectionDetailGenerale
    );
    return response.data;
  }
);

export const fetchInspectionGroups = createAsyncThunk(
  "inspection/load_groups",
  async (inspFicheResultatsId, thunkAPI) => {
    const response = await axios.get(FETCH_INSPECTION_DETAIL_GROUPS, {
      params: { inspFicheResultatsId: inspFicheResultatsId },
    });
    return response.data;
  }
);

export const fetchInspectionGroup = createAsyncThunk(
  FETCH_INSPECTION_DETAIL_GROUP,
  async (inspFicheResGroupeId) => {
    const response = await axios.get(
      FETCH_INSPECTION_DETAIL_GROUP +
      "?inspFicheResGroupeId=" +
      inspFicheResGroupeId
    );
    return response.data;
  }
);

export const updateInspectionGroups = createAsyncThunk(UPDATE_INSPECTION_DETAIL_GROUP, async (detailGroups, thunkAPI) => {
  const response = axios.put(UPDATE_INSPECTION_DETAIL_GROUP, detailGroups);
  return response.data;
});

export const updateInspectionGroup = createAsyncThunk(
  UPDATE_INSPECTION_DETAIL_GROUP,
  async (detailGroup, thunkAPI) => {
    const response = await axios.put(
      UPDATE_INSPECTION_DETAIL_GROUP,
      detailGroup
    );
    return response.data;
  }
);

export const { selectAll: selectAllGroups, selectById: selectGroupById } =
  detailGroupsAdapter.getSelectors((state) => state.inspection.detailGroups);

export const fetchInspectionGroupElements = createAsyncThunk(
  "inspection/load_group_elements",
  async (inspFicheResultatsId) => {
    const response = await axios.get(
      FETCH_INSPECTION_DETAIL_GROUP_ELEMENTS +
      "?inspFicheResGroupeId=" +
      inspFicheResultatsId
    );
    return response.data;
  }
);

export const {
  selectAll: selectAllGroupElements,
  selectById: selectGroupElementById,
} = detailGroupElementsAdapter.getSelectors(
  (state) => state.inspection.detailGroupElements
);

export const updateInspectionDetailGroupElement = createAsyncThunk(
  UPDATE_INSPECTION_DETAIL_GROUP_ELEMENT,
  async (groupElement = {}, thunkAPI) => {
    const { data } = await axios.put(
      UPDATE_INSPECTION_DETAIL_GROUP_ELEMENT,
      groupElement
    );
    return data;
  }
);

export const updateInspectionDetailGroupElements = createAsyncThunk(
  UPDATE_INSPECTION_DETAIL_GROUP_ELEMENTS,
  async (groupElements = [], thunkAPI) => {
    const { data } = await axios.put(
      UPDATE_INSPECTION_DETAIL_GROUP_ELEMENTS,
      groupElements
    );
    return data;
  }
);

export const sendInspectionSearchDetail = createAsyncThunk(
  SEND_INSPECTION_SEARCH_DETAIL,
  async ({ requestParams, requestBody } = {}, thunkAPI) => {
    const response = await axios.post(
      SEND_INSPECTION_SEARCH_DETAIL + paramsToQueryParam(requestParams),
      requestBody
    );
    return response.data;
  }
);

export const loadCalendrierRecurrence = createAsyncThunk(
  INSPECTION_LOAD_CALENDRIER,
  async (params, thunkAPI) => {
    const response = await axios.get(
      INSPECTION_LOAD_CALENDRIER + paramsToQueryParam(params)
    );
    return response.data;
  }
);
const allRecurrenceAdapter = createEntityAdapter({
  selectId: (entity) => entity.calendrierId,
});

export const addFicheResultat = createAsyncThunk(
  INSPECTION_ADD_FICHE_RESULTAT,
  async (params, thunkAPI) => {
    const response = await axios.get(
      INSPECTION_ADD_FICHE_RESULTAT + paramsToQueryParam(params)
    );
    return response;
  }
);

// ---------------------------------------------------------------------------------
// Fiches d'Inspection
// ---------------------------------------------------------------------------------

// Recherche

export const fetchOtherIds = createAsyncThunk(
  "Inspection/GetAutreIdentifiant/Get",
  async (params) => {
    const response = await axios.get(
      FETCH_AUTRE_IDENTIFIANT + paramsToQueryParam(params)
    );
    return response.data;
  }
);

export const inspectionOtherIdsSelector = (store) => store.inspection.otherIds;

export const {
  selectAll: selectAllOtherIds,
  selectById: selectOtherIdById
} = otherIdsAdapter.getSelectors(inspectionOtherIdsSelector);

export const selectLoadingOtherIds = createSelector(
  inspectionOtherIdsSelector,
  (state) => state.loading
);

export const fetchCriteresMultiTypes = createAsyncThunk(
  "Inspection/GetCriteresMultiType/Get",
  async (params) => {
    const response = await axios.get(
      FETCH_CRITERES_MULTI_TYPES + paramsToQueryParam(params)
    );
    return response.data;
  }
);

export const fetchCriteresMultiInfos = createAsyncThunk(
  "Inspection/GetCriteresMultiInfo/Get",
  async (params) => {
    const response = await axios.get(
      FETCH_CRITERES_MULTI_INFOS + paramsToQueryParam(params)
    );
    return response.data;
  }
);

export const updateSearchFiches = createAsyncThunk("Inspection/SearchFiches/Post", async (searchFiches, thunkAPI) => {
  try {
    const response = await axios.post(UPDATE_INSPECTION_SEARCH_FICHES, searchFiches);
    return response.data;

  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        messageId: "iso_no_record_found",
        severity: "warning",
      })
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// Modifier - Onglet 1

export const fetchFicheGenerale = createAsyncThunk(
  "Inspection/GetFicheGenerale/Get",
  async (params) => {
    const response = await axios.get(
      FETCH_INSPECTION_FICHE_GENERALE + paramsToQueryParam(params)
    );
    return response.data;
  }
);

export const fetchTypeInspection = createAsyncThunk(
  "Inspection/GetTypeInspection/Get",
  async (params) => {
    const response = await axios.get(
      FETCH_INSPECTION_TYPE_INSPECTION + paramsToQueryParam(params)
    );
    return response.data;
  }
);

export const inspectionTypeInspectionSelector = (store) => store.inspection.typeInspection;
export const {
  selectAll: selectAllTypeInspection,
  selectById: selectTypeInspectionById
} = typeInspectionAdapter.getSelectors(inspectionTypeInspectionSelector);
export const selectLoadingTypeInspection = createSelector(
  inspectionTypeInspectionSelector,
  (state) => state.loading
);

export const updateFicheGenerale = createAsyncThunk(
  "Inspection/AddUpdateFicheGenerale/Put",
  async (params, thunkAPI) => {
    try {
      const response = await axios.put(UPDATE_INSPECTION_FICHE_GENERALE, params);
      return response.data;

    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      );
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteFicheGenerale = createAsyncThunk(
  "Inspection/DeleteFicheGenerale/Delete",
  async (params, thunkAPI) => {
    try {
      const resp = await axios.delete(
        DELETE_INSPECTION_FICHE_GENERALE,
        params
      );
      return resp.data;
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      );
      return thunkAPI.rejectWithValue(error.response.data);
    }
  });

// Modifier - Onglet 2

export const fetchFicheElements = createAsyncThunk(
  "Inspection/LoadFicheElements/Get",
  async (params) => {
    const response = await axios.get(
      FETCH_INSPECTION_FICHE_ELEMENTS + paramsToQueryParam(params)
    );
    return response.data;
  }
);

export const updateFicheElements = createAsyncThunk("Inspection/AddUpdateFicheElements/Put", async (params, thunkAPI) => {
  const { inspectionFicheElements, inspFicheInfoGenId } = params;
  try {
    const response = await axios.put(UPDATE_INSPECTION_FICHE_ELEMENTS, inspectionFicheElements, { params: { inspFicheInfoGenId } });

    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    )

    return response.data;

  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
}
);

export const duplicateFicheElement = createAsyncThunk(DUPLICATE_FICHE_ELEMENT, async (params, thunkAPI) => {
  try {
    const res = await axios.get(DUPLICATE_FICHE_ELEMENT + paramsToQueryParam(params))

    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    )

    return res.data

  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
})

export const fetchSelectionElementStd = createAsyncThunk(
  "Inspection/LoadSelectionElementStd/Get",
  async (params) => {
    const response = await axios.get(
      FETCH_INSPECTION_SELECTION_ELEMENT_STD + paramsToQueryParam(params)
    );
    return response.data;
  }
);

export const fetchElementStd = createAsyncThunk(
  "Inspection/LoadElementStd/Get",
  async () => {
    const response = await axios.get(FETCH_INSPECTION_ELEMENT_STD);
    return response.data;
  }
);

export const updateElementStd = createAsyncThunk(
  "Inspection/AddUpdateElementStd/Put",
  async (params) => {
    const response = await axios.put(
      UPDATE_INSPECTION_ELEMENT_STD,
      params
    );
    return response.data;
  }
);

export const deleteElementStd = createAsyncThunk(
  "Inspection/DeleteElementStd/Delete",
  async (params, thunkAPI) => {
    try {
      const resp = await axios.delete(
        DELETE_INSPECTION_ELEMENT_STD,
        params
      );
      return resp.data;
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      );
      return thunkAPI.rejectWithValue(error.response.data);
    }
  });

// Modifier - Onglet 3

export const fetchFicheCalendriers = createAsyncThunk("Inspection/LoadFicheCalendriers/Get", async (params) => {
  const response = await axios.get(FETCH_INSPECTION_FICHE_CALENDRIERS + paramsToQueryParam(params));
  return response.data;
});

export const ficheCalendriersSelector = (store) => store.inspection.ficheCalendriers

export const {
  selectAll: selectAllFichesCalendriers,
  selectById: selectFicheCalendrierById
} = ficheCalendriersAdapter.getSelectors(ficheCalendriersSelector)

export const selectLoadingFichesCalendriers = createSelector(
  ficheCalendriersSelector,
  (state) => state.loading
)

export const getNewFicheCalendrier = createAsyncThunk("Inspection/NewFicheCalendrier/Get", async (inspFicheInfoGenId) => {
  // const response = await axios.get(FETCH_INSPECTION_NEW_FICHE_CALENDRIERS + paramsToQueryParam(params));
  const response = await axios.get(FETCH_INSPECTION_NEW_FICHE_CALENDRIERS, { params: { inspFicheInfoGenId: inspFicheInfoGenId } });
  return response.data;
});

export const addFicheCalendrier = createAsyncThunk(
  "Inspection/AddFicheCalendrier/Post",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(ADD_INSPECTION_FICHE_CALENDRIERS, data);
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      );
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      );
    }
  }
);

export const updateFicheCalendrier = createAsyncThunk(
  "Inspection/UpdateFicheCalendrier/Post",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(UPDATE_INSPECTION_FICHE_CALENDRIERS, data);
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      );
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      );
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteOneFicheCalendrier = createAsyncThunk("Inspection/DeleteFicheCalendrier/Delete", async (codeFreqCalen, thunkAPI) => {
  try {
    const response = await axios.delete(DELETE_INSPECTION_FICHE_CALENDRIERS, { params: { codeFreqCalen: codeFreqCalen } });

    thunkAPI.dispatch(deleteOneFicheCalendrierStore(codeFreqCalen))

    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "information_supprimee",
        severity: "success",
      })
    );

    return response.data;

  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
}
);

// Modifier - Onglet 4

export const fetchFicheResultats = createAsyncThunk(
  "Inspection/LoadFicheResultats/Get",
  async (params) => {
    const response = await axios.get(
      FETCH_INSPECTION_FICHE_RESULTATS + paramsToQueryParam(params)
    );
    return response.data;
  }
);

// ---------------------------------------------------------------------------------
// FIN - Fiches d'Inspection
// ---------------------------------------------------------------------------------

const initialState = {
  recurrences: allRecurrenceAdapter.getInitialState(),
  detailGenerale: detailGeneraleAdapter.getInitialState(),
  detailGroups: detailGroupsAdapter.getInitialState({ loading: false }),
  detailGroupElements: detailGroupElementsAdapter.getInitialState(),
  criteresMultiTypes: [],
  criteresMultiInfos: [],
  inspectionResultats: [],
  loadCalendrier: [],
  loadInProgress: false,
  sendingSearchDetail: false,
  updatingSearchFiches: false,
  loadingGroupElements: false,
  detailGroup: null,
  searchDetailParams: null,
  searchDetail: new InspectionSearchDetailCriteria(),
  searchFiches: new InspectionSearchFichesCriteria(),
  criteres: {
    selectedCriteresType: null,
    selectedCriteresInfos: [],
  },
  showCalendar: false,
  calendarDefaultDate: moment().format("YYYY-MM-DDTHH:mm:ss"),
  newFicheInspection: null,
  otherIds: otherIdsAdapter.getInitialState({
    loading: false,
  }),
  ficheGenerale: new GeneralInspectionSheet(),
  loadingFicheGenerale: false,
  typeInspection: typeInspectionAdapter.getInitialState({
    loading: false,
  }),
  ficheElements: {},
  loadingFicheElements: false,
  selectionElementStd: {},
  loadingSelectionElementStd: false,
  elementStd: {},
  loadingElementStd: false,
  ficheCalendriers: ficheCalendriersAdapter.getInitialState({ loading: false }),
  newFicheCalendrier: {},
  loadingNewFicheCalendrier: false,
  ficheResultats: {},
  loadingFicheResultats: false,
};

const inspectionSlice = createSlice({
  name: "inspection",
  initialState,
  reducers: {
    setGeneralInfo(state, action) {
      state.detailGenerale = action.payload;
    },
    setDetailGroups(state, action) {
      state.detailGroups = action.payload;
    },
    setDetailGroupElements(state, action) {
      state.detailGroupElements = action.payload;
    },
    setSearchDetailParams(state, action) {
      state.searchDetailParams = action.payload;
    },
    setSearchDetail(state, action) {
      state.searchDetail = action.payload;
    },
    setSearchFiches(state, action) {
      state.searchFiches = action.payload;
    },
    setCriteres(state, action) {
      state.criteres = { ...action.payload };
    },
    resetSearchDetail(state) {
      state.searchDetail = new InspectionSearchDetailCriteria();
    },
    resetSearchFiches(state) {
      state.searchFiches = new InspectionSearchFichesCriteria();
    },
    resetInspResultats(state) {
      state.inspectionResultats = [];
    },
    setShowCalendar(state, action) {
      state.showCalendar = action.payload;
    },
    setCalendarDefaultDate(state, action) {
      state.calendarDefaultDate = action.payload;
    },
    resetNewFicheInspection(state, action) {
      state.newFicheInspection = null;
    },
    resetFicheGenerale(state) {
      state.ficheGenerale = new GeneralInspectionSheet()
    },

    deleteOneFicheCalendrierStore(state, action) {
      ficheCalendriersAdapter.removeOne(state.ficheCalendriers, action.payload)
    },
    resetFicheCalendriers(state) {
      ficheCalendriersAdapter.removeAll(state.ficheCalendriers)
    }
  },
  extraReducers: {
    [fetchOtherIds.pending]: (state, action) => {
      state.otherIds.loading = true;
    },
    [fetchOtherIds.rejected]: (state, action) => {
      state.otherIds.loading = false;
    },
    [fetchOtherIds.fulfilled]: (state, action) => {
      otherIdsAdapter.setAll(state.otherIds, action.payload);
      state.otherIds.loading = false;
    },
    [fetchCriteresMultiTypes.pending]: (state, action) => {
      state.loadInProgress = true;
    },
    [fetchCriteresMultiTypes.fulfilled]: (state, action) => {
      state.criteresMultiTypes = [...action.payload];
      state.loadInProgress = false;
    },
    [fetchCriteresMultiInfos.pending]: (state, action) => {
      state.loadInProgress = true;
    },
    [fetchCriteresMultiInfos.fulfilled]: (state, action) => {
      state.criteresMultiInfos = [...action.payload];
      state.loadInProgress = false;
    },
    [updateSearchFiches.pending]: (state, action) => {
      state.updatingSearchFiches = true;
    },
    [updateSearchFiches.rejected]: (state, action) => {
      state.updatingSearchFiches = false;
    },
    [updateSearchFiches.fulfilled]: (state, action) => {
      state.inspectionResultats = action.payload;
      state.updatingSearchFiches = false;
    },
    [fetchFicheGenerale.pending]: (state, action) => {
      state.loadingFicheGenerale = true;
    },
    [fetchFicheGenerale.rejected]: (state, action) => {
      state.loadingFicheGenerale = false;
    },
    [fetchFicheGenerale.fulfilled]: (state, action) => {
      state.ficheGenerale = new GeneralInspectionSheet(action.payload);
      state.loadingFicheGenerale = false;
    },
    [updateFicheGenerale.pending]: (state, action) => {
      state.loadingFicheGenerale = true;
    },
    [updateFicheGenerale.rejected]: (state, action) => {
      state.loadingFicheGenerale = false;
    },
    [updateFicheGenerale.fulfilled]: (state, action) => {
      state.loadingFicheGenerale = false;
      state.ficheGenerale = new GeneralInspectionSheet(action.payload);
    },
    [fetchTypeInspection.pending]: (state, action) => {
      state.typeInspection.loading = true;
    },
    [fetchTypeInspection.rejected]: (state, action) => {
      state.typeInspection.loading = false;
    },
    [fetchTypeInspection.fulfilled]: (state, action) => {
      typeInspectionAdapter.setAll(state.typeInspection, action.payload);
      state.typeInspection.loading = false;
    },
    [fetchFicheElements.pending]: (state, action) => {
      state.loadingFicheElements = true;
    },
    [fetchFicheElements.rejected]: (state, action) => {
      state.loadingFicheElements = false;
    },
    [fetchFicheElements.fulfilled]: (state, action) => {
      state.ficheElements = action.payload;
      state.loadingFicheElements = false;
    },
    [fetchSelectionElementStd.pending]: (state, action) => {
      state.loadingSelectionElementStd = true;
    },
    [fetchSelectionElementStd.rejected]: (state, action) => {
      state.loadingSelectionElementStd = false;
    },
    [fetchSelectionElementStd.fulfilled]: (state, action) => {
      state.selectionElementStd = action.payload;
      state.loadingSelectionElementStd = false;
    },
    [fetchElementStd.pending]: (state, action) => {
      state.loadingElementStd = true;
    },
    [fetchElementStd.rejected]: (state, action) => {
      state.loadingElementStd = false;
    },
    [fetchElementStd.fulfilled]: (state, action) => {
      state.elementStd = action.payload;
      state.loadingElementStd = false;
    },

    [fetchFicheCalendriers.pending]: (state, action) => {
      state.ficheCalendriers.loading = true
    },
    [fetchFicheCalendriers.rejected]: (state, action) => {
      state.ficheCalendriers.loading = false
    },
    [fetchFicheCalendriers.fulfilled]: (state, action) => {
      ficheCalendriersAdapter.setAll(state.ficheCalendriers, action.payload)
      state.ficheCalendriers.loading = false
    },
    [getNewFicheCalendrier.pending]: (state, action) => {
      state.loadingNewFicheCalendrier = true
    },
    [getNewFicheCalendrier.rejected]: (state, action) => {
      state.loadingNewFicheCalendrier = false
    },
    [getNewFicheCalendrier.fulfilled]: (state, action) => {
      state.newFicheCalendrier = action.payload;
      state.loadingNewFicheCalendrier = false
    },

    [fetchFicheResultats.pending]: (state, action) => {
      state.loadingFicheResultats = true
    },
    [fetchFicheResultats.rejected]: (state, action) => {
      state.loadingFicheResultats = false
    },
    [fetchFicheResultats.fulfilled]: (state, action) => {
      state.ficheResultats = action.payload;
      state.loadingFicheResultats = false
    },
    [fetchInspectionGeneralInfo.pending]: (state, action) => {
      state.loadInProgress = true;
    },
    [fetchInspectionGeneralInfo.fulfilled]: (state, action) => {
      detailGeneraleAdapter.setAll(state.detailGenerale, [
        action.payload,
      ]);
      state.loadInProgress = false;
    },
    [fetchInspectionGroups.pending]: (state, action) => {
      state.detailGroups.loading = true;
      state.loadInProgress = true;
    },
    [fetchInspectionGroups.rejected]: (state, action) => {
      state.detailGroups.loading = false;
      state.loadInProgress = false;
    },
    [fetchInspectionGroups.fulfilled]: (state, action) => {
      detailGroupsAdapter.setAll(state.detailGroups, action.payload);
      state.detailGroups.loading = false;
      state.loadInProgress = false;
    },
    [fetchInspectionGroup.pending]: (state, action) => {
      state.loadInProgress = true;
    },
    [fetchInspectionGroup.fulfilled]: (state, action) => {
      state.detailGroup = { ...action.payload };
      state.loadInProgress = false;
    },
    [fetchInspectionGroupElements.pending]: (state, action) => {
      state.loadingGroupElements = true;
    },
    [fetchInspectionGroupElements.fulfilled]: (state, action) => {
      detailGroupElementsAdapter.setAll(
        state.detailGroupElements,
        action.payload
      );
      state.loadingGroupElements = false;
    },
    [sendInspectionSearchDetail.pending]: (state, action) => {
      state.sendingSearchDetail = true;
    },
    [sendInspectionSearchDetail.rejected]: (state, action) => {
      state.sendingSearchDetail = false;
    },
    [sendInspectionSearchDetail.fulfilled]: (state, action) => {
      state.inspectionResultats = action.payload;
      state.sendingSearchDetail = false;
    },
    [updateInspectionGroup.pending]: (state, action) => {
      state.loadInProgress = true;
    },
    [updateInspectionGroup.fulfilled]: (state, action) => {
      state.loadInProgress = false;
    },
    [loadCalendrierRecurrence.pending]: (state, action) => {
      state.loadInProgress = true;
    },
    [loadCalendrierRecurrence.fulfilled]: (state, action) => {
      allRecurrenceAdapter.setAll(state.recurrences, action.payload);
      state.loadInProgress = false;
    },
    [addFicheResultat.pending]: (state, action) => {
      state.loadInProgress = true;
    },
    [addFicheResultat.fulfilled]: (state, action) => {
      state.newFicheInspection = action.payload;
      state.loadInProgress = false;
    },
  },
});

export const {
  setGeneralInfo,
  setSearchDetailParams,
  setSearchDetail,
  setSearchFiches,
  setCriteres,
  setDetailGroups,
  setDetailGroupElements,
  resetSearchDetail,
  resetSearchFiches,
  setShowCalendar,
  setCalendarDefaultDate,
  resetNewFicheInspection,
  resetInspResultats,
  resetFicheGenerale,
  deleteOneFicheCalendrierStore,
  resetFicheCalendriers,
} = inspectionSlice.actions;

export const { selectAll: selectAllRecurrence } =
  allRecurrenceAdapter.getSelectors((state) => state.inspection.recurrences);

export default inspectionSlice.reducer;
