/* 
=======================================================================
INSTRUCTIONS D'UTILISATION
=======================================================================

import {  showAlertDialog, resetAlertDialog, } from 'store/slices/AlertMessageSlice'

Le composant <AlertDialog/> a été appliqué au composant de niveau supérieur de l'application IsoVision (AppBootstrap)
et il n'est donc pas nécessaire de le rappeler dans les composants enfants.


- Paramètres obligatoires:
  {messageId: string || message: string, dialogAction: string}

    - messageId: Id du message à afficher selon la liste des messages du Store alertMessage.messagesList.
    - message: message à afficher.
    - dialogAction: Identification de l'AlertDialog à utiliser comme paramètre de fonctions après avoir cliqué sur un des boutons


- Paramètres optionnels:
  {dba: string, title: string, btnLabel:{cancel: string, confirm: string}, data: any, replacementStrings: [string, ...]}

    - dba: Base de données dans laquelle récupérer les messages:
           "objetsLangues2", "messagesLangues" (défaut: "messagesLangues")
    - type: Valeur définissant de titre de l'AlertDialog (confirmation" | "error" | "info" | "success" | "warning" | "")
    - btnLabel: {
        cancel: Label du bouton situé à gauche. L'autoFocus est ici.
        confirm: Label du bouton situé à droite.
      } (défaut: cancel: "Non", confirm: "Oui") - Multilangue
    - data: valeur attribuée pour être utilisée dans une fonction future. Il peut s'agir de n'importe quelle valeur de n'importe quel type.
    - replacementStrings: Array de strings qui remplaceront les caractères %s


- Structure:
    dispatch(showAlertDialog({
        dba: "",
        type: "",
        messageId: ou message: "",
        btnLabel: { cancel: "", confirm: "" },
        dialogAction: "",
        data: "",
        replacementStrings: [""]
    }))


- InitialState:
    alertDialog: {
      show: false,
      dba: "messagesLangues",
      type: "confirmation",
      message: "",
      btnLabel: { cancel: "Non", confirm: "Oui" },
      dialogAction: "",
      clickedButton: "",
      data: "",
    }


- Exemple simplifié:
    dispatch(showAlertDialog({
      type: "info",
      messageId: "iso_baselib_deleterow",
      dialogAction: "userDataDelete",
      data: { dataId: rowDataId, stateName: section.id },
    }));

    ou

    dispatch(showAlertDialog({
      message: "votre message",
      dialogAction: "userDataDelete",
      data: { dataId: rowDataId, stateName: section.id },
    }));


- Pour exécute les fonctions selon le choix d'AlertDialog
  useEffect(() => {
    const { dialogAction, clickedButton, data } = alertDialog;
    if (dialogAction === "userDataDelete" && clickedButton === "btnConfirm") {
      handleUserDataDelete(data.dataId, data.stateName);
    }
    dispatch(resetAlertDialog());
  }, [alertDialog.clickedButton]);


- Exemple complet:
    dispatch(showAlertDialog({
      dba: "objetsLangues2"
      type: "confirmation"
      messageId: "web_question_enregistrement",
      btnLabel: { cancel: "Non", confirm: "Oui" },
      dialogAction: "deleteUser",
      data: userId,
    }));

    ou

    dispatch(showAlertDialog({      
      message: "votre message",
      btnLabel: { cancel: "Non", confirm: "Oui" },
      dialogAction: "deleteUser",
      data: userId,
    }));
*/

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

import { hideAlertDialog } from "store/slices/MessagesSystemSlice";

export default function AlertDialog() {
  const dispatch = useDispatch();
  const { title, message, btnLabel, show } = useSelector(
    (state) => state.messagesSystem.alertDialog
  );

  const handleClose = (buttonId) => {
    dispatch(hideAlertDialog({ clickedButton: buttonId }));
  };

  return (
    <Box>
      <Dialog
        open={show}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {title && (
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              py: "5px",
              pr: "0px",
              mb: 2,
              color: "white",
              backgroundColor: "primary.main",
            }}
          >
            {title}
          </DialogTitle>
        )}
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{whiteSpace: 'pre-line'}}>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {btnLabel.cancel && (
              <Button
                  id="btnCancel"
                  autoFocus
                  size="small"
                  variant="contained"
                  color="inherit"
                  startIcon={<CloseIcon />}
                  onClick={() => handleClose("btnCancel")}
              >
                {btnLabel.cancel}
              </Button>
          )}
          <Button
            id="btnConfirm"
            size="small"
            variant="contained"
            color="primary"
            startIcon={<CheckIcon style={{ color: "white" }} />}
            onClick={() => handleClose("btnConfirm")}
          >
            {btnLabel.confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
