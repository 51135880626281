import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'Services/AxiosService';
import {
  FETCH_ALL_OBJETS_LANGUES_2_BY_LANGUE,
  FETCH_ALL_OBJETS_LANGUES_2_BY_LANGUE_LOGGED_OUT,
  FETCH_PERSO_ALL, FETCH_UNAUTH_PERSO_ALL
} from 'store/UrlConstants';
import {paramsToQueryParam} from "../UrlUtils";

export const fetchAllPersoObjets = createAsyncThunk('perso/all', async (params, thunkAPI) => {
  let response;
  if(params?.loggedOut) {
    response = await axios.get(FETCH_UNAUTH_PERSO_ALL + paramsToQueryParam({language: params.lang}));
  } else {
    response = await axios.get(FETCH_PERSO_ALL);
  }

  return response.data;
});

// All Perso 
const allPersoObjetsAdapter = createEntityAdapter();

const initialState = {
  persoObjets: allPersoObjetsAdapter.getInitialState(),
  loadInProgress: false,
  momentLocale: 'fr-ca',
  showHelpButtons: false,
  openHelpDialog: false,
  helpText:"",
  containerMaxWidth: "xl",
  openManualOpenFileDialog: false,
  urlManualOpenFileDialog: "",
  newTabManualOpenFileDialog: null,
};

const persoSlice = createSlice({
  name: 'perso',
  initialState,
  reducers: {
    setLocale(state, action) {
      let newLocale;
      switch (action.payload) {
        case 'FR':
          newLocale = "fr-ca";
          break;
        case 'AN':
          newLocale = "en-ca";
          break;
        case 'ES':
          newLocale = "es";
          break;
      }
      state.momentLocale = newLocale;
    },
    showHelpDialog(state, action) {
      state.openHelpDialog = true;
      state.helpTitle = action.payload.helpTitle;
      state.helpText = action.payload.helpText;
    },
    hideHelpDialog(state) {
      state.openHelpDialog = false;
      state.helpTitle = "";
      state.helpText = "";
    },
    setShowHelpButtons(state, action) {
      state.showHelpButtons = action.payload;
    },
    setContainerMaxWidth(state, action) {
      state.containerMaxWidth = action.payload;
    },
    showManualOpenFileDialog(state, action) {
      state.openManualOpenFileDialog = true;
      state.urlManualOpenFileDialog = action.payload.url;
      state.newTabManualOpenFileDialog = action.payload.newTab;
    },
    hideManualOpenFileDialog(state) {
      state.openManualOpenFileDialog = false;
      state.urlManualOpenFileDialog = "";
      state.newTabManualOpenFileDialog = null;
    },
  },
  extraReducers: {
    [fetchAllPersoObjets.pending]: (state, action) => {
      state.loadInProgress = true;
    },
    [fetchAllPersoObjets.fulfilled]: (state, action) => {
      allPersoObjetsAdapter.setAll(state.persoObjets, action.payload);
      state.loadInProgress = false;
    }
  },
});

export const { showHelpDialog, hideHelpDialog, setLocale, setShowHelpButtons, setContainerMaxWidth, showManualOpenFileDialog, hideManualOpenFileDialog } = persoSlice.actions;

export default persoSlice.reducer;