import {logout, setReloadLocale, setSessionExpired} from "store/slices/AuthenticationSlice";
import LocalStorageService from "../../Services/LocalStorageService";

const localStorageService = LocalStorageService.getService();

export default store => next => action => {
  if(action.type.endsWith('/rejected')) {
    if(action.error && action.error.message === 'failed_refresh_auth') {
      const state = store.getState();
      const refreshingTokenStatus = localStorageService.getRefreshingToken();
      if(state.authentication.expiredSession === false || refreshingTokenStatus === 'refreshingInProgress') {
        localStorageService.setRefreshingToken('idle');
        store.dispatch(setSessionExpired(true));
        store.dispatch(logout());
        store.dispatch(setReloadLocale(true));
      }
    }
  }

  return next(action);
}