import moment from "moment";

export default function InspectionSearchDetailCriteria(_requestParams, _requestBody, nullDates = false) {
    let searchCriteria = {
        requestParams: {},
        requestBody: {}
    };

    searchCriteria.requestParams.avecRecurrence = (_requestParams && _requestParams.avecRecurrence) ? _requestParams.avecRecurrence : false;
    searchCriteria.requestParams.dateDebut = (_requestParams && _requestParams.dateDebut) ? _requestParams.dateDebut : (!nullDates ? moment().subtract(1, 'months').format("YYYY-MM-DD") : "");
    searchCriteria.requestParams.dateFin = (_requestParams && _requestParams.dateFin) ? _requestParams.dateFin : (!nullDates ? moment().add(1, 'months').format("YYYY-MM-DD") : "");

    searchCriteria.requestBody.titre = (_requestBody && _requestBody.titre) ? _requestBody.titre : null;
    searchCriteria.requestBody.autreIdentifiant = (_requestBody && _requestBody.autreIdentifiant) ? _requestBody.autreIdentifiant : null;
    searchCriteria.requestBody.dateCreationDeb = (_requestBody && _requestBody.dateCreationDeb) ? _requestBody.dateCreationDeb : null;
    searchCriteria.requestBody.dateCreationFin = (_requestBody && _requestBody.dateCreationFin) ? _requestBody.dateCreationFin : null;
    searchCriteria.requestBody.datePrevuDeb = (_requestBody && _requestBody.datePrevuDeb) ? _requestBody.datePrevuDeb : null;
    searchCriteria.requestBody.datePrevuFin = (_requestBody && _requestBody.datePrevuFin) ? _requestBody.datePrevuFin : null;
    searchCriteria.requestBody.dateRealiseDeb = (_requestBody && _requestBody.dateRealiseDeb) ? _requestBody.dateRealiseDeb : null;
    searchCriteria.requestBody.dateRealiseFin = (_requestBody && _requestBody.dateRealiseFin) ? _requestBody.dateRealiseFin : null;
    searchCriteria.requestBody.globalSearchTerm = (_requestBody && _requestBody.globalSearchTerm) ? _requestBody.globalSearchTerm : "";
    searchCriteria.requestBody.indComplete = (_requestBody && _requestBody.indComplete) ? _requestBody.indComplete : "";
    searchCriteria.requestBody.commentaire = (_requestBody && _requestBody.commentaire) ? _requestBody.commentaire : null;
    searchCriteria.requestBody.codeProcede = (_requestBody && _requestBody.codeProcede) ? _requestBody.codeProcede : null;
    searchCriteria.requestBody.codeUnite = (_requestBody && _requestBody.codeUnite) ? _requestBody.codeUnite : null;
    searchCriteria.requestBody.codeComposante = (_requestBody && _requestBody.codeComposante) ? _requestBody.codeComposante : null;
    searchCriteria.requestBody.codePtEchantillon = (_requestBody && _requestBody.codePtEchantillon) ? _requestBody.codePtEchantillon : null;
    searchCriteria.requestBody.indSaisieMobile = (_requestBody && _requestBody.indSaisieMobile) ? _requestBody.indSaisieMobile : "";
    searchCriteria.requestBody.typeInspectionId = (_requestBody && _requestBody.typeInspectionId) ? _requestBody.typeInspectionId : [];
    searchCriteria.requestBody.voletId = (_requestBody && _requestBody.voletId) ? _requestBody.voletId : [];
    searchCriteria.requestBody.personneRessource = (_requestBody && _requestBody.personneRessource) ? _requestBody.personneRessource : [];
    searchCriteria.requestBody.statut = (_requestBody && _requestBody.statut) ? _requestBody.statut : [];
    searchCriteria.requestBody.inspectePar = (_requestBody && _requestBody.inspectePar) ? _requestBody.inspectePar : [];
    searchCriteria.requestBody.personneRencontreId = (_requestBody && _requestBody.personneRencontreId) ? _requestBody.personneRencontreId : [];
    searchCriteria.requestBody.sites = (_requestBody && _requestBody.sites) ? _requestBody.sites : [];

    return searchCriteria;
}