import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'Services/AxiosService';
import {
  GET_HYPERLIEN_DOCUMENT_INFO,
  GET_HYPERLIEN_DOCUMENT_TYPELIEN,
  GET_HYPERLIEN_DOCUMENT_NEW,
  LOAD_HYPERLIEN_DOCUMENTS,
  ADD_HYPERLIEN_DOCUMENT,
  UPDATE_HYPERLIEN_DOCUMENT,
  DELETE_HYPERLIEN_DOCUMENT,
  LOAD_UNAUTHENTICATED_HYPERLIEN_DOCUMENTS
} from 'store/UrlConstants';
import { paramsToQueryParam } from "store/UrlUtils";
import { showSnackbarMessage } from "store/slices/MessagesSystemSlice";
import { removeObjectProperties } from 'Utils/CommonFunctions';

export const getHyperlienDocumentInfo = createAsyncThunk('Hyperlien/DocumentInfo', async (noHyperlien) => {
  const response = await axios.get(`${GET_HYPERLIEN_DOCUMENT_INFO}/${noHyperlien}`);
  return response.data;
});

export const getHyperlienTypeLien = createAsyncThunk('HyperlienDocument/GetTypeLien', async () => {
  const response = await axios.get(GET_HYPERLIEN_DOCUMENT_TYPELIEN);
  return response.data;
});

export const getNewHyperlienDocument = createAsyncThunk('HyperlienDocument/NewHyperlienDocument', async (params) => {
  const response = await axios.get(GET_HYPERLIEN_DOCUMENT_NEW + paramsToQueryParam(params));
  return response.data;
});

export const fetchHyperlienDocuments = createAsyncThunk('HyperlienDocument/LoadHyperlienDocuments', async (params) => {
  let url = LOAD_HYPERLIEN_DOCUMENTS
  if(params.indLoggedOut){
    url = LOAD_UNAUTHENTICATED_HYPERLIEN_DOCUMENTS
  }
  removeObjectProperties(params, ['indLoggedOut'])
  const response = await axios.get(url + paramsToQueryParam(params));
  return response.data;
});

export const addHyperlienDocument = createAsyncThunk("HyperlienDocument/AddHyperlienDocument", async (data, thunkAPI) => {
  try{
    let formData = new FormData();
    for (let key in data) {
      if(key === 'enregId' && Array.isArray(data.enregId)){
        data.enregId?.map(item => {
          formData.append(`${key}[]`, item !== null ? item : '');
        })
      }else if(key === 'nomColonne' && Array.isArray(data.nomColonne)){
        data.nomColonne?.map(item => {
          formData.append(`${key}[]`, item !== null ? item : '');
        })
      }else{
        formData.append(key, data[key] !== null ? data[key] : '');
      }
    }
    const response = await axios.put(ADD_HYPERLIEN_DOCUMENT, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
    thunkAPI.dispatch(
      showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
      })
  );
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
      })
    );
  }
});
export const updateHyperlienDocument = createAsyncThunk('HyperlienDocument/UpdateHyperlienDocument', async (params, thunkAPI) => {
  const { requestBody, showMessage } = params
  try {
    let formData = new FormData();
    for (let key in requestBody) {
      formData.append(key, requestBody[key] !== null ? requestBody[key] : '');
    }
    const response = await axios.put(UPDATE_HYPERLIEN_DOCUMENT, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
    if (showMessage) {
      thunkAPI.dispatch(
          showSnackbarMessage({
              dba: "objetsLangues2",
              messageId: "web_confirmation_enregistrement",
              severity: "success",
          })
      );
    }
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
      })
    );

    return thunkAPI.rejectWithValue(error.response.data);
  }
});
export const deleteHyperlienDocument = createAsyncThunk('formations/DeleteHyperlienDocument', async (params, thunkAPI) => {
  try{
    const { data } = await axios.delete(DELETE_HYPERLIEN_DOCUMENT + paramsToQueryParam(params));
    thunkAPI.dispatch(
      showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "information_supprimee",
          severity: "success",
      })
  );
    return data;
  } catch(error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
      })
    );
  }
});
  
const initialState = {
  metadata: {
    key: "",
    name: "",
    mimeType: "",
    warning: "",
    wait: "",
    size: 0,
    noDocument: 0
  },
  loadingInProgress: false
};

const hyperlienSlice = createSlice({
  name: 'hyperlien',
  initialState,
  reducers: {
    resetMetadata(state, action) {
      state.metadata = {...state.metadata, key: ""};
    },
  },
  extraReducers: {
    [getHyperlienDocumentInfo.fulfilled]: (state, action) => {
      state.metadata = action.payload
    },
    [getHyperlienTypeLien.rejected]: (state, action) => {
      state.loadingInProgress = false
    },
    [getHyperlienTypeLien.fulfilled]: (state, action) => {
      state.loadingInProgress = false
    },
    [getHyperlienTypeLien.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [getNewHyperlienDocument.rejected]: (state, action) => {
      state.loadingInProgress = false
    },
    [getNewHyperlienDocument.fulfilled]: (state, action) => {
      state.loadingInProgress = false
    },
    [getNewHyperlienDocument.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [fetchHyperlienDocuments.rejected]: (state, action) => {
      state.loadingInProgress = false
    },
    [fetchHyperlienDocuments.fulfilled]: (state, action) => {
      state.loadingInProgress = false
    },
    [fetchHyperlienDocuments.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [addHyperlienDocument.rejected]: (state, action) => {
      state.loadingInProgress = false
    },
    [addHyperlienDocument.fulfilled]: (state, action) => {
      state.loadingInProgress = false
    },
    [addHyperlienDocument.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [deleteHyperlienDocument.rejected]: (state, action) => {
      state.loadingInProgress = false
    },
    [deleteHyperlienDocument.fulfilled]: (state, action) => {
      state.loadingInProgress = false
    },
    [deleteHyperlienDocument.pending]: (state, action) => {
      state.loadingInProgress = true
    },
  },
});

export const { resetMetadata } = hyperlienSlice.actions;

export default hyperlienSlice.reducer;