import { createSlice, createAsyncThunk, createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import axios from 'Services/AxiosService';
import {
  ADD_ONE_SEC_USAGER,
  ADD_SEC_GROUPES_USAGER, 
  CHANGE_SEC_USAGER_PASSWORD,
  CHANGE_SEC_USAGER_PASSWORD_LOGGED_IN,
  DELETE_ONE_SEC_USAGER,
  DELETE_SEC_GROUPES_USAGER,
  GET_ALL_CARTE_ACCESS_BY_USAGER_ID,
  GET_ONE_CARTE_ACCESS_BY_USAGER_ID,
  GET_SEC_GROUPES_ASSOCIE_USAGER,
  GET_SEC_GROUPES_DISP_BY_USAGER,
  GET_SEC_USAGER_VM_FC,
  MENU_DATA,
  REQUEST_CHANGE_SEC_USAGER_PASSWORD,
  RESET_SEC_USAGER_PASSWORD,
  UPDATE_ONE_SEC_USAGER,
  ENVOIS_CURRIEL_SEC_USAGER,
} from 'store/UrlConstants';
import { showSnackbarMessage } from './MessagesSystemSlice';
import {paramsToQueryParam} from "../UrlUtils";

export const fetchMenuData = createAsyncThunk('security/MenuData', async (thunkAPI) => {
  const response = await axios.get(MENU_DATA);

  return response.data;
});


// ----------------------------------------------------------------------------------------
// CarteAcces
// ----------------------------------------------------------------------------------------
const cartesAccesByEmployeAdapter = createEntityAdapter();

export const fetchCartesAccesByEmploye = createAsyncThunk("Security/CarteAcess/GetAllByEmploye", async (secUsagerId) => {
  const resp = await axios.get(GET_ALL_CARTE_ACCESS_BY_USAGER_ID, { params: { secUsagerId: secUsagerId } });
  return resp.data;
});

export const fetchCarteAccesByEmploye = createAsyncThunk("Security/CarteAcess/GetOneByEmploye", async (secUsagerId) => {
  const resp = await axios.get(GET_ONE_CARTE_ACCESS_BY_USAGER_ID, { params: { secUsagerId: secUsagerId } });
  return resp.data;
});

export const cartesAccesByEmployeSelector = (store) => store.security.cartesAccesByEmploye;

export const {
  selectAll: selectAllCartesAccesByEmploye
} = cartesAccesByEmployeAdapter.getSelectors(cartesAccesByEmployeSelector);

export const selectCartesAccesByEmployeLoading = createSelector(
  cartesAccesByEmployeSelector,
  (state) => state.loading
);


// ----------------------------------------------------------------------------------------
// SecGroupes
// ----------------------------------------------------------------------------------------
const secGroupesDispByEmployeAdapter = createEntityAdapter();

export const fetchSecGroupesDispByEmploye = createAsyncThunk("Security/SecGoupes/GetDisponiblesByEmploye", async (secUsagerId) => {
  const resp = await axios.get(GET_SEC_GROUPES_DISP_BY_USAGER, { params: { secUsagerId: secUsagerId } });
  return resp.data;
});

export const secGroupesDispByEmployeSelector = (store) => store.security.secGroupesDispByEmploye;

export const {
  selectAll: selectAllSecGroupesDispByEmploye
} = secGroupesDispByEmployeAdapter.getSelectors(secGroupesDispByEmployeSelector);

export const selectSecGroupesDispByEmployeLoading = createSelector(
  secGroupesDispByEmployeSelector,
  (state) => state.loading
);


const secGroupesAssocByEmployerAdapter = createEntityAdapter();

export const fetchSecGroupesAssocByEmploye = createAsyncThunk("Security/SecGroupes/GetSelectionnesByEmploye", async (secUsagerId) => {
  const resp = await axios.get(GET_SEC_GROUPES_ASSOCIE_USAGER, { params: { secUsagerId: secUsagerId } });
  return resp.data;
});

export const secGroupesAssocByEmployerSelector = (store) => store.security.secGroupesAssocByEmploye;

export const {
  selectAll: selectAllSecGroupesAssocByEmploye
} = secGroupesAssocByEmployerAdapter.getSelectors(secGroupesAssocByEmployerSelector);

export const selectSecGroupesAssocByEmployeLoading = createSelector(
  secGroupesAssocByEmployerSelector,
  (state) => state.loading
);

export const addSecGroupesUsager = createAsyncThunk("Security/SecGroupes/AddMany", async (secGroupesUsager, thunkAPI) => {
  try {
    const resp = await axios.post(ADD_SEC_GROUPES_USAGER, secGroupesUsager);

    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );

    return resp.data;

  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const deleteSecGroupesUsager = createAsyncThunk("Security/SecGroupes/DeleteMany", async (secGroupesUsager, thunkAPI) => {
  try {
    const resp = await axios.delete(DELETE_SEC_GROUPES_USAGER, { data: secGroupesUsager });

    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );

    return resp.data;

  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});


// ----------------------------------------------------------------------------------------
// SecUsager
// ----------------------------------------------------------------------------------------
export const fetchSecUsagerVmFc = createAsyncThunk("Security/SecUsager/GetOne", async (noEmploye, thunkAPI) => {
  const resp = await axios.get(GET_SEC_USAGER_VM_FC, { params: { noEmploye: noEmploye } })
  return resp.data;
});

export const addOneSecUsager = createAsyncThunk("Security/SecUsager/AddOne", async (secUsager, thunkAPI) => {
  try {
    const resp = await axios.post(ADD_ONE_SEC_USAGER, secUsager);

    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return resp.data;

  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateOneSecUsager = createAsyncThunk("Security/SecUsager/UpdateOne", async (secUsager, thunkAPI) => {
  try {
    const resp = await axios.put(UPDATE_ONE_SEC_USAGER, secUsager);

    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return resp.data;

  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const resetSecUsagerPassword = createAsyncThunk("Security/SecUsager/ResetPassword", async (secUsager, thunkAPI) => {
  try {
    const resp = await axios.put(RESET_SEC_USAGER_PASSWORD, secUsager);
    return resp.data;

  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const deleteOneSecUsager = createAsyncThunk("Security/SecUsager/DeleteOne", async (secUsager, thunkAPI) => {
  try {
    const resp = await axios.delete(DELETE_ONE_SEC_USAGER, { data: secUsager });

    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "information_supprimee",
        severity: "success",
      })
    );
    return resp.data;

  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const requestChangeSecUsagerPassword = createAsyncThunk("Security/SecUsager/RequestChangePassword", async (noEmploye, thunkAPI) => {
  try {
    const resp = await axios.get(REQUEST_CHANGE_SEC_USAGER_PASSWORD + paramsToQueryParam({noEmploye: noEmploye}));
    return resp.data;

  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const changeSecUsagerPassword = createAsyncThunk("Security/SecUsager/ChangePassword", async (params, thunkAPI) => {
  try {
    const resp = await axios.post(CHANGE_SEC_USAGER_PASSWORD, params);
    return resp.data;

  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const changeSecUsagerPasswordLoggedIn = createAsyncThunk("Security/SecUsager/ChangePasswordLoggedIn", async (params, thunkAPI) => {
  const {password, newPassword} = params
  try {
    const resp = await axios.get(CHANGE_SEC_USAGER_PASSWORD_LOGGED_IN, {
      params: {
        password: password,
        newPassword: newPassword,
      },
    });
    return resp.data;

  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const secUsagerEnvoisCourriel = createAsyncThunk("Usager/SecUsagerEnvoisCourriel", async (params) => {
  const resp = await axios.get(ENVOIS_CURRIEL_SEC_USAGER, { params: { noEmploye: params.noEmploye, envoyeA: params.envoyeA } });
  return resp.data;
});

const initialState = {
  menuData: {},
  loadInProgress: false,
  cartesAccesByEmploye: cartesAccesByEmployeAdapter.getInitialState({ loading: false }),
  secGroupesDispByEmploye: secGroupesDispByEmployeAdapter.getInitialState({ loading: false }),
  secGroupesAssocByEmploye: secGroupesAssocByEmployerAdapter.getInitialState({ loading: false }),
  loadingSecUsager: false,
  loadingCarteAccesEmploye: false,
};

const securitySlice = createSlice({
  name: 'security',
  initialState,
  reducers: {
    setMenuData(state, action) {
      state.menuData = action.payload;
    }
  },
  extraReducers: {
    [fetchMenuData.pending]: (state, action) => {
      state.loadInProgress = true;
    },
    [fetchMenuData.fulfilled]: (state, action) => {
      state.menuData = action.payload;
      state.loadInProgress = false;
    },
    [fetchSecUsagerVmFc.pending]: (state) => {
      state.loadingSecUsager = true;
    },
    [fetchSecUsagerVmFc.rejected]: (state,) => {
      state.loadingSecUsager = false;
    },
    [fetchSecUsagerVmFc.fulfilled]: (state,) => {
      state.loadingSecUsager = false;
    },
    [fetchCartesAccesByEmploye.pending]: (state) => {
      state.cartesAccesByEmploye.loading = true;
    },
    [fetchCartesAccesByEmploye.rejected]: (state) => {
      state.cartesAccesByEmploye.loading = false;
    },
    [fetchCartesAccesByEmploye.fulfilled]: (state, action) => {
      cartesAccesByEmployeAdapter.setAll(state.cartesAccesByEmploye, action.payload);
      state.cartesAccesByEmploye.loading = false;
    },
    [fetchCarteAccesByEmploye.pending]: (state) => {
      state.loadingCarteAccesEmploye = true;
    },
    [fetchCarteAccesByEmploye.rejected]: (state,) => {
      state.loadingCarteAccesEmploye = false;
    },
    [fetchCarteAccesByEmploye.fulfilled]: (state,) => {
      state.loadingCarteAccesEmploye = false;
    },
    [fetchSecGroupesDispByEmploye.pending]: (state) => {
      state.secGroupesDispByEmploye.loading = true;
    },
    [fetchSecGroupesDispByEmploye.rejected]: (state,) => {
      state.secGroupesDispByEmploye.loading = false;
    },
    [fetchSecGroupesDispByEmploye.fulfilled]: (state, action) => {
      secGroupesDispByEmployeAdapter.setAll(state.secGroupesDispByEmploye, action.payload);
      state.secGroupesDispByEmploye.loading = false;
    },
    [fetchSecGroupesAssocByEmploye.pending]: (state) => {
      state.secGroupesAssocByEmploye.loading = true;
    },
    [fetchSecGroupesAssocByEmploye.rejected]: (state,) => {
      state.secGroupesAssocByEmploye.loading = false;
    },
    [fetchSecGroupesAssocByEmploye.fulfilled]: (state, action) => {
      secGroupesAssocByEmployerAdapter.setAll(state.secGroupesAssocByEmploye, action.payload);
      state.secGroupesAssocByEmploye.loading = false;
    },
  },
});

export const { setMenuData } = securitySlice.actions;

export default securitySlice.reducer;