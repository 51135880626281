import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'Services/AxiosService';
import { paramsToQueryParam } from "store/UrlUtils";
import {
  HISTORIQUE_LOAD_EVENEMENTS
} from 'store/UrlConstants';

import { showSnackbarMessage } from "store/slices/MessagesSystemSlice";

const initialState = {
  loadingInProgress: false,
  evenements: [],
};

export const loadEvenements = createAsyncThunk('historique/loadEvenements', async (params, thunkAPI) => {
  try{
    const { data } = await axios.get(HISTORIQUE_LOAD_EVENEMENTS + paramsToQueryParam(params));
    return data;
  } catch(e){
    console.log(e, 'E')
  }
});


const HistoriqueSlice = createSlice({
  name: 'historique',
  initialState,
  reducers: {},
  extraReducers: {
    [loadEvenements.fulfilled]: (state, action) => {
      state.loadingInProgress = false
      if(Array.isArray(action.payload)){
        state.evenements = [...action.payload];
      }
    },
    [loadEvenements.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [loadEvenements.rejected]: (state, action) => {
      state.loadingInProgress = false
    },
  },
});

export const prepareSelector = state => state.historique;

export default HistoriqueSlice.reducer;
