import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'Services/AxiosService';
import { listToQueryParam, paramsToQueryParam } from "store/UrlUtils";
import {
  STIMULSOFT_GET_FILE_RAPPORT
} from 'store/UrlConstants';

import { showSnackbarMessage } from "store/slices/MessagesSystemSlice";

const initialState = {
  loadingInProgress: false,
  procedesliste: [],
  initialRappelActivitesReport: {},
  rapportOptionPostes: []
};

export const getFileRapport = createAsyncThunk('stimulsoft/geTfileRapport', async (params, thunkAPI) => {
  try{
    const { data } = await axios.get(STIMULSOFT_GET_FILE_RAPPORT + paramsToQueryParam(params));
    return data;
  } catch(e){
    console.log(e, 'E')
  }
});

const StimulsoftSlice = createSlice({
  name: 'stimulsoft',
  initialState,
  reducers: {},
  extraReducers: {
  },
});

export default StimulsoftSlice.reducer;
