import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit';
import axios from 'Services/AxiosService';
import {
  FETCH_DECISIONS,
  FETCH_VOLETS,
  FETCH_DECISIONS_OF_CODE,
  FETCH_PROCEDES,
  FETCH_UNITES,
  FETCH_COMPOSANTS,
  FETCH_PT_ECHANTILLONS,
  LOAD_ARTICLE_ISO_ACTIF,
  GET_OPTION_REGROUPEMENTS,
  FETCH_POSTES,
  FETCH_TYPE_PREMIER_SOIN,
  FETCH_INTERVENANT_EXTERNE,
  FETCH_PARTIE_DU_CORPS,
  FETCH_TYPE_BLESSURE,
  FETCH_TYPE_EVENEMENT,
  FETCH_TYPE_ACTIVITE,
  FETCH_TYPE_ASSIGNATION,
  FETCH_TYPE_RESTRICTION,
  FETCH_CATEGORIE_COUT_AU,
  FETCH_PRODUIT_LIGNE_FAMILLE,
  FETCH_FAMILLE_PRODUIT,
  FETCH_LIGNE_PRODUIT,
  FETCH_CRITERIA_AUDIT,
  FETCH_TYPE_GRAVITE,
  FETCH_EQUIPEMENT_PROTECTION,
} from 'store/UrlConstants';
import { paramsToQueryParam } from 'store/UrlUtils';

const decisionsAdapter = createEntityAdapter({});
const voletsAdapter = createEntityAdapter({
  selectId: (volet) => volet.voletId,
});
const siteProcessAdapter = createEntityAdapter({});
const unitsAdapter = createEntityAdapter({});
const componentsAdapter = createEntityAdapter({});
const optionRegroupementsAdapter = createEntityAdapter({});
const ptEchantillonsAdapter = createEntityAdapter({});
const postesAdapter = createEntityAdapter({})
const typePremierSoinAdapter = createEntityAdapter({});
const intervenantExterneAdapter = createEntityAdapter({});
const partieDuCorpsAdapter = createEntityAdapter({});
const typeBlessuresAdapter = createEntityAdapter({});
const typeEvenementsAdapter = createEntityAdapter({});
const typeActivitesAdapter = createEntityAdapter({});
const typeAssignationsAdapter = createEntityAdapter({});
const typeRestrictionsAdapter = createEntityAdapter({});
const categorieCoutAusAdapter = createEntityAdapter({});
const produitLigneFamilleAdapter = createEntityAdapter({});
const familleProduitsAdapter = createEntityAdapter({});
const ligneProduitsAdapter = createEntityAdapter({});
const auditsAdapter = createEntityAdapter({});
const typeGravitesAdapter = createEntityAdapter({});
const equipementProtectionAdapter = createEntityAdapter({});

const initialState = {
  decisions: decisionsAdapter.getInitialState(),
  volets: voletsAdapter.getInitialState(),
  siteProcesses: siteProcessAdapter.getInitialState(),
  loadingSiteProcesses: false,
  units: unitsAdapter.getInitialState(),
  loadingUnits: false,
  components: componentsAdapter.getInitialState(),
  loadingComponents: false,
  optionRegroupements: optionRegroupementsAdapter.getInitialState(),
  loadinOptionRegroupements: false,
  ptEchantillons: ptEchantillonsAdapter.getInitialState(),
  loadingPtEchantillons: false,
  articleIsoActifs: [],
  loadingArticleIsoActifs: false,
  postes: postesAdapter.getInitialState(),
  typePremierSoins: typePremierSoinAdapter.getInitialState(),
  intervenantExternes: intervenantExterneAdapter.getInitialState(),
  partieDuCorps: partieDuCorpsAdapter.getInitialState(),
  typeBlessures: typeBlessuresAdapter.getInitialState(),
  typeEvenements: typeEvenementsAdapter.getInitialState(),
  typeActivites: typeActivitesAdapter.getInitialState(),
  typeAssignations: typeAssignationsAdapter.getInitialState(),
  typeRestrictions: typeRestrictionsAdapter.getInitialState(),
  categorieCoutAus: categorieCoutAusAdapter.getInitialState(),
  familleProduits: familleProduitsAdapter.getInitialState(),
  ligneProduits: ligneProduitsAdapter.getInitialState(),
  audits: auditsAdapter.getInitialState(),
  loadingPostes: false,
  produitLigneFamille: produitLigneFamilleAdapter.getInitialState({
    loading: false,
  }),
  typeGravites: typeGravitesAdapter.getInitialState(),
  equipementProtection: equipementProtectionAdapter.getInitialState(),
};

export const fetchDecisions = createAsyncThunk('Criteria/Decisions', async () => {
  const response = await axios.get(FETCH_DECISIONS);
  return response.data;
});

export const fetchVolets = createAsyncThunk('Criteria/Volets', async () => {
  const response = await axios.get(FETCH_VOLETS);
  return response.data;
});

export const fetchProcessesBySite = createAsyncThunk(FETCH_PROCEDES, async (codeSites, thunkAPI) => {
  const response = await axios.get(FETCH_PROCEDES + paramsToQueryParam({ codeSites: codeSites }));
  return response.data;
});
export const {
  selectAll: selectAllSiteProcesses,
  selectById: selectSiteProcessById,
} = siteProcessAdapter.getSelectors((state) => state.criteria.siteProcesses);

export const fetchUnits = createAsyncThunk('Criteria/Unites', async (procede, thunkAPI) => {
  const response = await axios.get(FETCH_UNITES + paramsToQueryParam({procede: procede}));
  return response.data;
});
export const {
  selectAll: selectAllUnits,
  selectById: selectUnitById,
} = unitsAdapter.getSelectors((state) => state.criteria.units);

export const fetchComponents = createAsyncThunk('Criteria/Composants', async (unite, thunkAPI) => {
  const response = await axios.get(FETCH_COMPOSANTS + paramsToQueryParam({unite: unite}));
  return response.data;
});
export const {
  selectAll: selectAllComponents,
  selectById: selectComponentById,
} = componentsAdapter.getSelectors((state) => state.criteria.components);

export const fetchPtEchantillons = createAsyncThunk('Criteria/PtEchantillons', async (composant, thunkAPI) => {
  const response = await axios.get(FETCH_PT_ECHANTILLONS + paramsToQueryParam({composant: composant}));
  return response.data;
});
export const {
  selectAll: selectAllPtEchantillons,
  selectById: selectPtEchantillonById,
} = ptEchantillonsAdapter.getSelectors((state) => state.criteria.ptEchantillons);

export const fetchArticleIsoActif = createAsyncThunk('Criteria/ArticleIsoActif', async (composant, thunkAPI) => {
  const response = await axios.get(LOAD_ARTICLE_ISO_ACTIF);
  return response.data;
});

export const getOptionRegroupements = createAsyncThunk('Criteria/GetOptionRegroupements', async (nomRapport, thunkAPI) => {
  const response = await axios.get(GET_OPTION_REGROUPEMENTS + paramsToQueryParam({nomRapport: nomRapport}));
  return response.data;
});
export const {
  selectAll: selectAllOptionRegroupements,
  selectById: selectOptionRegroupementById,
} = optionRegroupementsAdapter.getSelectors((state) => state.criteria.optionRegroupements);

export const fetchPostes = createAsyncThunk(FETCH_POSTES, async (thunkAPI) => {
  const response = await axios.get(FETCH_POSTES);
  return response.data;
});
export const {
  selectAll: selectAllPostes,
  selectById: selectPostById,
} = postesAdapter.getSelectors((state) => state.criteria.postes);

export const {
  selectAll: selectAllTypePremierSoins,
  selectById: selectTypePremierSoinById,
} = typePremierSoinAdapter.getSelectors((state) => state.criteria.typePremierSoins);
export const getTypePremierSoins = createAsyncThunk(FETCH_TYPE_PREMIER_SOIN, async (params, thunkAPI) => {
  const response = await axios.get(FETCH_TYPE_PREMIER_SOIN + paramsToQueryParam(params));
  return response.data;
});

export const {
  selectAll: selectAllIntervenantExternes,
  selectById: selectIntervenantExterneById,
} = typePremierSoinAdapter.getSelectors((state) => state.criteria.intervenantExternes);
export const getIntervenantExternes = createAsyncThunk(FETCH_INTERVENANT_EXTERNE, async (params, thunkAPI) => {
  const response = await axios.get(FETCH_INTERVENANT_EXTERNE + paramsToQueryParam(params));
  return response.data;
});

export const {
  selectAll: selectAllPartieDuCorps,
  selectById: selectPartieDuCorpById,
} = partieDuCorpsAdapter.getSelectors((state) => state.criteria.partieDuCorps);
export const getPartieDuCorps = createAsyncThunk(FETCH_PARTIE_DU_CORPS, async (params, thunkAPI) => {
  const response = await axios.get(FETCH_PARTIE_DU_CORPS + paramsToQueryParam(params));
  return response.data;
});

export const {
  selectAll: selectAllTypeBlessures,
  selectById: selectTypeBlessureById,
} = typeBlessuresAdapter.getSelectors((state) => state.criteria.typeBlessures);
export const getTypeBlessures = createAsyncThunk(FETCH_TYPE_BLESSURE, async (params, thunkAPI) => {
  const response = await axios.get(FETCH_TYPE_BLESSURE + paramsToQueryParam(params));
  return response.data;
});

export const {
  selectAll: selectAllTypeEvenements,
  selectById: selectTypeEvenementById,
} = typeEvenementsAdapter.getSelectors((state) => state.criteria.typeEvenements);
export const getTypeEvenements = createAsyncThunk(FETCH_TYPE_EVENEMENT, async (params, thunkAPI) => {
  const response = await axios.get(FETCH_TYPE_EVENEMENT + paramsToQueryParam(params));
  return response.data;
});

export const {
  selectAll: selectAllTypeActivites,
  selectById: selectTypeActiviteById,
} = typeActivitesAdapter.getSelectors((state) => state.criteria.typeActivites);
export const getTypeActivites = createAsyncThunk(FETCH_TYPE_ACTIVITE, async (params, thunkAPI) => {
  const response = await axios.get(FETCH_TYPE_ACTIVITE + paramsToQueryParam(params));
  return response.data;
});

export const {
  selectAll: selectAllTypeAssignations,
  selectById: selectTypeAssignationById,
} = typeAssignationsAdapter.getSelectors((state) => state.criteria.typeAssignations);
export const getTypeAssignations = createAsyncThunk(FETCH_TYPE_ASSIGNATION, async (params, thunkAPI) => {
  const response = await axios.get(FETCH_TYPE_ASSIGNATION + paramsToQueryParam(params));
  return response.data;
});

export const {
  selectAll: selectAllTypeRestrictions,
  selectById: selectTypeRestrictionById,
} = typeRestrictionsAdapter.getSelectors((state) => state.criteria.typeRestrictions);
export const getTypeRestrictions = createAsyncThunk(FETCH_TYPE_RESTRICTION, async (params, thunkAPI) => {
  const response = await axios.get(FETCH_TYPE_RESTRICTION + paramsToQueryParam(params));
  return response.data;
});

export const {
  selectAll: selectAllCategorieCoutAus,
  selectById: selectCategorieCoutAuById,
} = categorieCoutAusAdapter.getSelectors((state) => state.criteria.categorieCoutAus);
export const getCategorieCoutAus = createAsyncThunk(FETCH_CATEGORIE_COUT_AU, async (params, thunkAPI) => {
  const response = await axios.get(FETCH_CATEGORIE_COUT_AU + paramsToQueryParam(params));
  return response.data;
});


export const fetchProduitLigneFamille = createAsyncThunk(
  "Criteria/ProduitLigneFamille/Get",
  async () => {
    const response = await axios.get(FETCH_PRODUIT_LIGNE_FAMILLE);
    return response.data;
  }
);

export const produitLigneFamilleSelector = (store) =>
store.criteria.produitLigneFamille;

export const {
  selectAll: selectAllProduitLigneFamilles,
  selectById: selectProduitLigneFamilleById,
} = produitLigneFamilleAdapter.getSelectors(produitLigneFamilleSelector);

export const selectLoadingProduitLigneFamille = createSelector(
  produitLigneFamilleSelector,
  (state) => state.loading
);

export const {
  selectAll: selectAllFamilleProduits,
  selectById: selectFamilleProduitById,
} = familleProduitsAdapter.getSelectors((state) => state.criteria.familleProduits);
export const getFamilleProduits = createAsyncThunk(FETCH_FAMILLE_PRODUIT, async () => {
  const response = await axios.get(FETCH_FAMILLE_PRODUIT);
  return response.data;
});

export const {
  selectAll: selectAllLigneProduits,
  selectById: selectLigneProduitById,
} = ligneProduitsAdapter.getSelectors((state) => state.criteria.ligneProduits);
export const getLigneProduits = createAsyncThunk(FETCH_LIGNE_PRODUIT, async (params, thunkAPI) => {
  const response = await axios.get(FETCH_LIGNE_PRODUIT + paramsToQueryParam(params));
  return response.data;
});

export const {
  selectAll: selectAllAudits,
  selectById: selectAuditById,
} = auditsAdapter.getSelectors((state) => state.criteria.audits);
export const getAudits = createAsyncThunk(FETCH_CRITERIA_AUDIT, async (codeSite, thunkAPI) => {
  const response = await axios.get(FETCH_CRITERIA_AUDIT + paramsToQueryParam({ codeSite }));
  return response.data;
});

export const {
  selectAll: selectAllTypeGravites,
  selectById: selectTypeGraviteById,
} = typeGravitesAdapter.getSelectors((state) => state.criteria.typeGravites);
export const getTypeGravites = createAsyncThunk(FETCH_TYPE_GRAVITE, async (codeSite, thunkAPI) => {
  const response = await axios.get(FETCH_TYPE_GRAVITE  + paramsToQueryParam({ codeSite: codeSite }));
  return response.data;
});

export const equipementProtectionSelector = (store) =>
  store.criteria.equipementProtection;
export const {
  selectAll: selectAllEquipementProtection,
  selectById: selectEquipementProtectionById,
} = equipementProtectionAdapter.getSelectors(equipementProtectionSelector);
export const selectLoadingEquipementProtection = createSelector(
  equipementProtectionSelector,
  (state) => state.loading
);
export const fetchEquipementProtection = createAsyncThunk(
  "Criteria/EquipementProtection/Get", 
  async (codeSite) => {
    const response = await axios.get(FETCH_EQUIPEMENT_PROTECTION  + paramsToQueryParam({ codeSite: codeSite }));
    return response.data;
  }
);

const criteriaSlice = createSlice({
  name: 'criteria',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchDecisions.fulfilled]: (state, action) => {
      decisionsAdapter.setAll(state.decisions, action.payload);
    },
    [fetchVolets.fulfilled]: (state, action) => {
      voletsAdapter.setAll(state.volets, action.payload);
    },
    [fetchProcessesBySite.pending]: (state, action) => {
      state.loadingSiteProcesses = true;
    },
    [fetchProcessesBySite.fulfilled]: (state, action) => {
      siteProcessAdapter.setAll(state.siteProcesses, action.payload);
      state.loadingSiteProcesses = false;
    },
    [fetchUnits.pending]: (state, action) => {
      state.loadingUnits = true
    },
    [fetchUnits.fulfilled]: (state, action) => {
      state.loadingUnits = false
      unitsAdapter.setAll(state.units, action.payload);
    },
    [fetchComponents.pending]:(state, action) => {
      state.loadingComponents = true
    },
    [fetchComponents.fulfilled]:(state, action) => {
      state.loadingComponents = false
      componentsAdapter.setAll(state.components, action.payload);
    },
    [fetchPtEchantillons.pending]:(state, action) => {
      state.loadingPtEchantillons = true
    },
    [fetchPtEchantillons.fulfilled]:(state, action) => {
      state.loadingPtEchantillons = false
      ptEchantillonsAdapter.setAll(state.ptEchantillons, action.payload);
    },
    [fetchPostes.pending]:(state, action) => {
      state.loadingPostes = true
    },
    [fetchPostes.fulfilled]:(state, action) => {
      state.loadingPostes = false
      postesAdapter.setAll(state.postes, action.payload);
    },
    [fetchArticleIsoActif.pending]:(state, action) => {
      state.loadingArticleIsoActifs = true
    },
    [fetchArticleIsoActif.fulfilled]:(state, action) => {
      state.loadingArticleIsoActifs = false
      state.articleIsoActifs = action.payload
    },
    [getOptionRegroupements.pending]:(state, action) => {
      state.loadinOptionRegroupements = true
    },
    [getOptionRegroupements.fulfilled]:(state, action) => {
      state.loadinOptionRegroupements = false
      optionRegroupementsAdapter.setAll(state.optionRegroupements, action.payload);
    },
    [getTypePremierSoins.fulfilled]: (state, action) => {
      typePremierSoinAdapter.setAll(state.typePremierSoins, action.payload);
    },
    [getIntervenantExternes.fulfilled]: (state, action) => {
      intervenantExterneAdapter.setAll(state.intervenantExternes, action.payload);
    },
    [getPartieDuCorps.fulfilled]: (state, action) => {
      partieDuCorpsAdapter.setAll(state.partieDuCorps, action.payload);
    },
    [getTypeBlessures.fulfilled]: (state, action) => {
      typeBlessuresAdapter.setAll(state.typeBlessures, action.payload);
    },
    [getTypeEvenements.fulfilled]: (state, action) => {
      typeEvenementsAdapter.setAll(state.typeEvenements, action.payload);
    },
    [getTypeActivites.fulfilled]: (state, action) => {
      typeActivitesAdapter.setAll(state.typeActivites, action.payload);
    },
    [getTypeAssignations.fulfilled]: (state, action) => {
      typeAssignationsAdapter.setAll(state.typeAssignations, action.payload);
    },
    [getTypeRestrictions.fulfilled]: (state, action) => {
      typeRestrictionsAdapter.setAll(state.typeRestrictions, action.payload);
    },
    [getCategorieCoutAus.fulfilled]: (state, action) => {
      categorieCoutAusAdapter.setAll(state.categorieCoutAus, action.payload);
    },
    [fetchProduitLigneFamille.pending]: (state, action) => {
      state.produitLigneFamille.loading = true;
    },
    [fetchProduitLigneFamille.rejected]: (state, action) => {
      state.produitLigneFamille.loading = false;
    },
    [fetchProduitLigneFamille.fulfilled]: (state, action) => {
      produitLigneFamilleAdapter.setAll(
        state.produitLigneFamille,
        action.payload
      );
      state.produitLigneFamille.loading = false;
    },
    [getFamilleProduits.fulfilled]: (state, action) => {
      familleProduitsAdapter.setAll(state.familleProduits, action.payload);
    },
    [getLigneProduits.fulfilled]: (state, action) => {
      ligneProduitsAdapter.setAll(state.ligneProduits, action.payload);
    },
    [getAudits.fulfilled]: (state, action) => {
      auditsAdapter.setAll(state.audits, action.payload);
    },
    [getTypeGravites.fulfilled]: (state, action) => {
      typeGravitesAdapter.setAll(
        state.typeGravites,
        action.payload
      );
    },
    [fetchEquipementProtection.pending]: (state, action) => {
      state.equipementProtection.loading = true;
    },
    [fetchEquipementProtection.rejected]: (state, action) => {
      state.equipementProtection.loading = false;
    },
    [fetchEquipementProtection.fulfilled]: (state, action) => {
      equipementProtectionAdapter.setAll(
        state.equipementProtection,
        action.payload
      );
      state.equipementProtection.loading = false;
    },
  }
});

export const {
  selectAll: selectAllDecisions,
  selectById: selectDecisionsById,
} = decisionsAdapter.getSelectors((state) => state.criteria.decisions);

export const {
  selectAll: selectAllVolets,
  selectById: selectVoletById,
} = voletsAdapter.getSelectors((state) => state.criteria.volets);

export default criteriaSlice.reducer;