import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "Services/AxiosService";
import { GET_CRITERES_MULTI_INFO_PERF_REVIEW } from "store/UrlConstants";
import { paramsToQueryParam } from "store/UrlUtils";

export const loadCriteresMultiInfo = createAsyncThunk(
    "RevuePerformance/GetCriteresMultiInfo",
    async (params, thunkAPI) => {
        //console.log(params);
        const response = await axios.get(
            GET_CRITERES_MULTI_INFO_PERF_REVIEW + paramsToQueryParam(params)
        );
        //console.log(response.data);
        return response.data;
    }
);

const allCriteresMultiInfoAdapter = createEntityAdapter({
    selectId: (entity) => entity.dataValue,
});

const allVoletsAdapater = createEntityAdapter({
    selectId: (entity) => entity.dataValue,
});
const allResponsablesAdapater = createEntityAdapter({
    selectId: (entity) => entity.dataValue,
});
const allStatutsAdapater = createEntityAdapter({
    selectId: (entity) => entity.dataValue,
});
const allReunionsAdapater = createEntityAdapter({
    selectId: (entity) => entity.dataValue,
});
const allComitesAdapater = createEntityAdapter({
    selectId: (entity) => entity.dataValue,
});
const allOriginesAdapater = createEntityAdapter({
    selectId: (entity) => entity.dataValue,
});
const allDefaultsAdapter = createEntityAdapter({
    selectId: (entity) => entity.dataValue,
});

const initialState = {
    actionsObjets: allCriteresMultiInfoAdapter.getInitialState(),
    volets: allVoletsAdapater.getInitialState(),
    responsables: allResponsablesAdapater.getInitialState(),
    statuts: allStatutsAdapater.getInitialState(),
    reunions: allReunionsAdapater.getInitialState(),
    comites: allComitesAdapater.getInitialState(),
    origines: allOriginesAdapater.getInitialState(),
    defaults: allDefaultsAdapter.getInitialState(),
};

const getCritereMultiInfoSlice = createSlice({
    name: "perfReviewGetCriteresMultiInfo",
    initialState,
    reducers: {},
    extraReducers: {
        [loadCriteresMultiInfo.pending]: (state, action) => {
            state.loadInProgress = true;
        },
        [loadCriteresMultiInfo.fulfilled]: (state, action) => {
            allCriteresMultiInfoAdapter.setAll(
                state.actionsObjets,
                action.payload
            );
            //console.log(action);
            let vole = [];
            let resp = [];
            let stat = [];
            let reun = [];
            let comi = [];
            let orig = [];
            let defa = [];

            let actionsPayload = action.payload;
            //console.log(actionsPayload);

            actionsPayload.map((action) => {
                switch (action.typeInfo) {
                    case "VOLE":
                        vole.push({ ...action, default: false });
                        break;
                    case "VOLE_DEF":
                        vole.push({ ...action, default: true });
                        defa.push({ ...action, default: true });
                        break;
                    case "RESP":
                        resp.push({ ...action, default: false });
                        break;
                    case "RESP_DEF":
                        resp.push({ ...action, default: true });
                        defa.push({ ...action, default: true });
                        break;
                    case "STAT":
                        stat.push({ ...action, default: false });
                        break;
                    case "STAT_DEF":
                        stat.push({ ...action, default: true });
                        defa.push({ ...action, default: true });
                        break;
                    case "REUN":
                        reun.push({ ...action, default: false });
                        break;
                    case "REUN_DEF":
                        reun.push({ ...action, default: true });
                        defa.push({ ...action, default: true });
                        break;
                    case "COMI":
                        comi.push({ ...action, default: false });
                        break;
                    case "COMI_DEF":
                        comi.push({ ...action, default: true });
                        defa.push({ ...action, default: true });
                        break;
                    case "ORIG":
                        orig.push({ ...action, default: false });
                        break;
                    case "ORIG_DEF":
                        orig.push({ ...action, default: true });
                        defa.push({ ...action, default: true });
                        break;
                    default:
                        break;
                }
            });

            // console.log(vole);
            // console.log(resp);
            // console.log(stat);
            // console.log(reun);
            // console.log(comi);
            // console.log(orig);
            // console.log(defa);

            allVoletsAdapater.setAll(state.volets, vole);
            allResponsablesAdapater.setAll(state.responsables, resp);
            allStatutsAdapater.setAll(state.statuts, stat);
            allReunionsAdapater.setAll(state.reunions, reun);
            allComitesAdapater.setAll(state.comites, comi);
            allOriginesAdapater.setAll(state.origines, orig);
            allDefaultsAdapter.setAll(state.defaults, defa);
        },
    },
});

/*** Retourne tous les critères de toutes les catégories --- N'EST PAS UTILISÉ ACTUELLEMENT ***/
export const { selectAll: selectAllCriteresMultiInfo } =
    allCriteresMultiInfoAdapter.getSelectors(
        (state) => state.perfReviewGetCriteresMultiInfo.actionsObjets
    );
/**********************************************************************************************/

export const { selectAll: selectAllVolets } = allVoletsAdapater.getSelectors(
    (state) => state.perfReviewGetCriteresMultiInfo.volets
);
export const { selectAll: selectAllResponsables } =
    allResponsablesAdapater.getSelectors(
        (state) => state.perfReviewGetCriteresMultiInfo.responsables
    );
export const { selectAll: selectAllStatuts } = allStatutsAdapater.getSelectors(
    (state) => state.perfReviewGetCriteresMultiInfo.statuts
);
export const { selectAll: selectAllReunions } =
    allReunionsAdapater.getSelectors(
        (state) => state.perfReviewGetCriteresMultiInfo.reunions
    );
export const { selectAll: selectAllComites } = allComitesAdapater.getSelectors(
    (state) => state.perfReviewGetCriteresMultiInfo.comites
);
export const { selectAll: selectAllOrigines } =
    allOriginesAdapater.getSelectors(
        (state) => state.perfReviewGetCriteresMultiInfo.origines
    );
export const { selectAll: selectAllDefaults } = allDefaultsAdapter.getSelectors(
    (state) => state.perfReviewGetCriteresMultiInfo.defaults
);

export default getCritereMultiInfoSlice.reducer;
