import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'Services/AxiosService';
import { paramsToQueryParam } from 'store/UrlUtils';
import {
  GET_NOTIFICATIONS, SET_NOTIFICATION_LU, SET_NOTIFICATIONS_VUES, DELETE_NOTIFICATION
} from 'store/UrlConstants';
import { showSnackbarMessage } from "./MessagesSystemSlice";

const notificationsAdapter = createEntityAdapter({
  selectId: (entity) => entity.notificationId,
});

export const fetchNotifications = createAsyncThunk("notifications/fetchNotifications", async (notificationId, thunkAPI) => {
  const response = await axios.get(`${GET_NOTIFICATIONS}${notificationId ? paramsToQueryParam({ notificationId: notificationId }) : ''}`);
  const notifications = response.data;

  const state = thunkAPI.getState();

  const notifsNonLues = notifications.filter(notif => notif.dteVu === null);
  if (notifsNonLues.length) {
    let message;
    if (notifsNonLues.length === 1) {
      message = notifsNonLues[0].titre;
    } else {
      message = state.messagesSystem.objetsLangues2ByLangue.entities['IW_MSG_NOUV_NOTIFS']?.valeurAffichee.replace('%s', notifsNonLues.length) || '';
    }
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: message,
        severity: 'info',
      })
    );
  }

  return notifications;
});

export const setVus = createAsyncThunk("notifications/setVues", async (notificationIds) => {
  const response = await axios.get(SET_NOTIFICATIONS_VUES + paramsToQueryParam({ notificationIds: notificationIds }));
  return response.data;
});

export const setLu = createAsyncThunk("notifications/setLu", async (notificationId) => {
  const response = await axios.get(SET_NOTIFICATION_LU + paramsToQueryParam({ notificationId: notificationId }));
  return response.data;
});

export const deleteOneNotification = createAsyncThunk("notifications/deleteOne", async (notificationId) => {
  const response = await axios.delete(DELETE_NOTIFICATION, { params: { notificationId: notificationId } })
  return response.data;
})

export const {
  selectAll: selectAllNotifications,
  selectById: selectNotificationById,
} = notificationsAdapter.getSelectors((state) => state.notifications.notifications);

const initialState = {
  notifications: notificationsAdapter.getInitialState(),
  selectedNotification: null
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    clearNotifications(state) {
      state.notifications = notificationsAdapter.getInitialState();
    },
    deleteOneNotificationStore(state, action) {
      notificationsAdapter.removeOne(state.notifications, action.payload)
    },
    upsertOneNotificationStore(state, action) {
      notificationsAdapter.upsertOne(state.notifications, action.payload)
    },
    setSelectedNotification(state, action) {
      state.selectedNotification = action.payload;
    }
  },
  extraReducers: {
    [fetchNotifications.fulfilled]: (state, action) => {
      notificationsAdapter.upsertMany(state.notifications, action.payload);
    },
    [setVus.fulfilled]: (state, { payload }) => {
      const newObjects = payload.map((notif) => {
        const id = notif.notificationId;
        let origEntity = JSON.parse(JSON.stringify(state.notifications.entities[id]));
        origEntity.dteVu = notif.dteVu;
        return origEntity;
      });

      notificationsAdapter.upsertMany(state.notifications, newObjects);
    },
    [setLu.fulfilled]: (state, { payload }) => {
      let origEntity = JSON.parse(JSON.stringify(state.notifications.entities[payload.notificationId]));
      origEntity.dteLu = payload.dteLu;
      notificationsAdapter.upsertOne(state.notifications, origEntity);
    }
  },
});

export const { clearNotifications, upsertOneNotificationStore, deleteOneNotificationStore, setSelectedNotification } = notificationsSlice.actions;

export default notificationsSlice.reducer;