export default {
  0:[
    {id:1, nom:"Site de Chicoutimi"},
    {id:2, nom:"Site de Laterrie`re"},
    {id:3, nom:"Site d'Alma"},
    {id:4, nom:"Site de Jonquière"},
    {id:5, nom:"Site de La Baie"},
    {id:6, nom:"Site de Dolbeau"},
    {id:7, nom:"Site de Mistassinni"},
  ],
  1:[
    {id:8, nom:"Canard"},
    {id:9, nom:"Chien"},
    {id:10, nom:"Poisson"},
    {id:11, nom:"Vache"},
    {id:12, nom:"Poussin"},
    {id:13, nom:"Biche"},
  ],
  2:[
    {id:14, nom:"Harry"},
    {id:15, nom:"Ron"},
    {id:16, nom:"Hermione"},
  ],
  3:[
    {id:17, nom:"Chêne"},
    {id:18, nom:"Bouleau"},
  ],
  4:[
    {id:19, nom:"Poulet"},
    {id:20, nom:"Boeuf"},
  ],
  5:[
    {id:21, nom:"Fondue"},
    {id:22, nom:"Fromage"},
  ],
  6:[
    {id:23, nom:"Google"},
    {id:24, nom:"Facebook"},
  ],
  7:[
    {id:25, nom:"Mathématiques"},
    {id:26, nom:"Français"},
  ],
  10:[
    {id:29, nom:"Saumon"},
    {id:30, nom:"Thon"},
  ],
  13:[
    {id:27, nom:"Bichon"},
    {id:28, nom:"Bichette"},
  ],
  29:[
    {id:31, nom:"Altantique"},
    {id:32, nom:"Pacifique"},
  ],
}