// ISOVISION DROITS ------------------------------------------------------------------- //

export const ACCES_ARCHIVE = "ACCES ARCHIVE";
export const ACCES_CREATION = "ACCES CREATION";
export const ACCES_DETRUIT = "ACCES DETRUIT";
export const ACTIONS_DE_MES_EMPLOYES = "ACTIONS_DE_MES_EMPLOYES";
export const AFFICHER_DEMANDES_AUTRE = "AFFICHER DEMANDES AUTRE";
export const AFFICHER_DOCUMENTS_AUTRES = "AFFICHER_DOCUMENTS_AUTRES";
export const AJOUT = "AJOUT";
export const AJOUT_DESTINATAIRE = "AJOUT_DESTINATAIRE";
export const AVISER_LES_GENS_À_AUDITER = "AVISER LES GENS À AUDITER";
export const AVISER_LES_PARTICIPANTS = "AVISER LES PARTICIPANTS";
export const CONSULTER_DOCUMENT_DETRUIT = "CONSULTER DOCUMENT DETRUIT";
export const CONSULTER_DOSSIER_DETRUIT = "CONSULTER DOSSIER DETRUIT";
export const COPIER_LE_GRAPHIQUE = "COPIER LE GRAPHIQUE";
export const COPIER_URL = "COPIER_URL";
export const CREER_UN_RACCOURCI_BUREAU = "CREER UN RACCOURCI BUREAU";
export const DEPLACER = "DEPLACER";
export const DESACTIVATION = "DESACTIVATION";
export const DISPONIBLE = "DISPONIBLE";
export const DOC_HORODATAGE = "DOC_HORODATAGE";
export const DOSSIER_COMPLETE = "DOSSIER_COMPLETE";
export const DOSSIERS_DE_MES_EMPLOYES = "DOSSIERS DE MES EMPLOYES";
export const EDITER_LE_DOCUMENT = "EDITER LE DOCUMENT";
export const EMETTRE_LE_DOCUMENT = "EMETTRE LE DOCUMENT";
export const ENREG_DE_MES_EMPLOYES = "ENREG DE MES EMPLOYES";
export const ENREG_SANS_RAPPORTE_PAR = "ENREG SANS RAPPORTE PAR";
export const ENREG_SANS_RESPONSABLE = "ENREG SANS RESPONSABLE";
export const ENREGISTRER_SOUS = "ENREGISTRER SOUS";
export const ENVOYER_PAR_COURRIEL = "ENVOYER PAR COURRIEL";
export const ETAPES_DE_MES_EMPLOYES = "ETAPES_DE_MES_EMPLOYES";
export const EXEMPLAIRES_SECONDAIRES = "EXEMPLAIRES SECONDAIRES";
export const FAIRE_UNE_DEMANDE = "FAIRE UNE DEMANDE";
export const HISTORIQUE = "HISTORIQUE";
export const IMPRIMER = "IMPRIMER";
export const IMPRIMER_ÉTIQUETTES = "IMPRIMER ÉTIQUETTES";
export const LECTURE = "LECTURE";
export const LISTE_DE_CONTROLE = "LISTE DE CONTROLE";
export const MODIFICATION = "MODIFICATION";
export const MODIFIER_CHOIX_ACTIONS = "MODIFIER CHOIX ACTIONS";
export const PARTAGER_LE_GRAPHIQUE = "PARTAGER LE GRAPHIQUE";
export const PUBLIER_WEB = "PUBLIER WEB";
export const REACTIVATION = "REACTIVATION";
export const REGISTRE_DE_PRET = "REGISTRE DE PRET";
export const SUPPRESSION = "SUPPRESSION";
export const SUPPRIMER_DOCUMENT_ACTIF = "SUPPRIMER DOCUMENT ACTIF";
export const TOUS_LES_DOSSIERS = "TOUS LES DOSSIERS";
export const TOUS_LES_ENREG = "TOUS LES ENREG";
export const TOUS_LES_STATUTS = "TOUS_LES_STATUTS";
export const TOUTES_LES_ACTIONS = "TOUTES_LES_ACTIONS";
export const TOUTES_LES_ETAPES = "TOUTES_LES_ETAPES";
export const VISIBLE = "VISIBLE";