import axios from 'Services/AxiosService';
import {
  createAsyncThunk,
  createSlice
} from '@reduxjs/toolkit';
import { ADD_UPDATE_ALERTE, DELETE_ALERTE, LOAD_ALERTE } from 'store/UrlConstants';
import { paramsToQueryParam } from 'store/UrlUtils';
import { showSnackbarMessage } from './MessagesSystemSlice';
import { removeObjectProperties } from 'Utils/CommonFunctions';

//ACTIONS 
export const loadAlerte = createAsyncThunk('Alerte/LoadAlerte', async (noAlerte) => { 
  const response = await axios.get(LOAD_ALERTE + paramsToQueryParam({
    noAlerte
  }));
  return response.data;
});
export const addUpdateAlerte = createAsyncThunk("Alerte/AddUpdateAlerte", async (data, thunkAPI) => {
  try {
    const postData = {...data}
    removeObjectProperties(postData, ['isUpdated'])
    const response = await axios.post(ADD_UPDATE_ALERTE, postData);
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});
export const deleteAlerte = createAsyncThunk("Alerte/DeleteAlerte", async (noAlerte, thunkAPI) => {
  try {
    const { data } = await axios.delete(DELETE_ALERTE + paramsToQueryParam({
      noAlerte: noAlerte
    }));
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "information_supprimee",
        severity: "success",
      })
    );
    return data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});


const initialState = {
  loadingAlerte: false,
  deletingAlerte: false
}
//REDUCER
const AlerteSlice = createSlice({
  name: 'alerte',
  initialState,
  reducers: {},
  extraReducers: {
    [loadAlerte.fulfilled]: (state, action) => {
      state.loadingAlerte = false;
    },
    [loadAlerte.pending]: (state, action) => {
      state.loadingAlerte = true;
    },
    [loadAlerte.rejected]: (state, action) => {
      state.loadingAlerte = false;
    },
    [addUpdateAlerte.fulfilled]: (state, action) => {
      state.loadingAlerte = false;
    },
    [addUpdateAlerte.pending]: (state, action) => {
      state.loadingAlerte = true;
    },
    [addUpdateAlerte.rejected]: (state, action) => {
      state.loadingAlerte = false;
    },
    [deleteAlerte.fulfilled]: (state, action) => {
      state.deletingAlerte = false;
    },
    [deleteAlerte.pending]: (state, action) => {
      state.deletingAlerte = true;
    },
    [deleteAlerte.rejected]: (state, action) => {
      state.deletingAlerte = false;
    },
  }
});

//SELECTORS/ STORE STATES

export const AlerteSelector = state => state.alerte

export default AlerteSlice.reducer;