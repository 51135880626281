import React from 'react';
import AppBootstrap from './AppBootstrap'
import store from "./store/store";
import {Provider} from "react-redux";

function App() {
  return (
    <Provider store={store}>
      <AppBootstrap />
    </Provider>
  );
}

export default App;