import { createSlice, createAsyncThunk, createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import axios from 'Services/AxiosService';
import { paramsToQueryParam } from "store/UrlUtils";
import {
  FORMATION_NEW, FORMATION_ADD, FOR_FECH_CODE_FORMATION, FETCH_VOLETS, FETCH_MOYENS_SQDM_SITES, FORMATION_ONE_LOGGED, FORMATION_UPDATE,
  FORMATION_LOAD_COMPETENCES, FORMATION_ADD_COMPETENCE, COMPETENCE_SEARCH, FORMATION_DELETE_COMPETENCE,
  FORMATION_LOAD_INSCRIPTIONS, FORMATION_UPDATE_INSCRIPTION, FORMATION_DELETE_INSCRIPTION, FORMATION_ADD_INSCRIPTION,
  FORMATION_LOAD_PRESENCES, FORMATION_ADD_PRESENCE, FORMATION_DELETE_PRESENCE, FORMATION_UPDATE_PRESENCE, FORMATION_ADD_TEMPS_FORMATEUR,
  FORMATION_LOAD_TEMPS_FORMATEUR, FORMATION_DELETE_TEMPS_FORMATEUR, FORMATION_UPDATE_TEMPS_FORMATEUR, FORMATION_LOAD_OPTIONS,
  FORMATION_UPDATE_OPTIONS, FORMATION_LOAD_DOCUMENTS, FORMATION_UPDATE_DOCUMENTS, FORMATION_ADD_DOCUMENT,
  FORMATION_DELETE_DOCUMENT,
  FORMATION_LOAD_EXAMEN_QUESTIONS,
  FORMATION_LOAD_EXAMEN_INFO,
  FORMATION_UPDATE_EXAMEN_QUESTIONS,
  FORMATION_DELETE_EXAMEN_QUESTION,
  FORMATION_UPDATE_EXAMEN_INFO,
  FORMATION_ADD_EXAMEN_REPONSE,
  FORMATION_DELETE_EXAMEN_REPONSE,
  FORMATION_ADD_EXAMEN_QUESTION,
  FORMATION_IMPORT_EXAMEN_QUESTIONS,
  FORMATION_LOAD_PARTIES,
  FORMATION_UPDATE_PARTIE_QUESTIONS,
  FORMATION_UPDATE_PARTIE_DOCUMENTS,
  FORMATION_UPDATE_PARTIE_SECTIONS,
  COMPETENCE_LOAD_COMPETENCES_FROM_INSCRIPTION,
  COMPETENCE_UPDATE_COMPETENCES_EMPLOYE,
  COMPETENCE_DELETE_COMPETENCES_EMPLOYE
} from 'store/UrlConstants';

import { showSnackbarMessage } from "store/slices/MessagesSystemSlice";
import { removeObjectProperties } from 'Utils/CommonFunctions';
const codeFormationAdapter = createEntityAdapter();
const competencesAdapter = createEntityAdapter({ selectId: (entity) => entity.competenceId });
const voletAdapter = createEntityAdapter({ selectId: (entity) => entity.voletId });
const moyenSQDMSAdapter = createEntityAdapter();
const competencesFormationAdapter = createEntityAdapter({ selectId: (entity) => entity.formationCompetenceId });
const inscriptionAdapter = createEntityAdapter({ selectId: (entity) => entity.formationRecueId });
const presencesAdapter = createEntityAdapter({ selectId: (entity) => entity.clePresences });
const tempsFormAdapter = createEntityAdapter({ selectId: (entity) => entity.formationTempsFormId });

export const {
  selectAll: selectAllCodeFormObj,
} = codeFormationAdapter.getSelectors((state) => state.felPrepare.codeFormations);

export const {
  selectAll: selectAllVolets,
} = voletAdapter.getSelectors((state) => state.felPrepare.volets);

export const {
  selectAll: selectAllMoyensSqdmS,
} = voletAdapter.getSelectors((state) => state.felPrepare.moyensSqdmS);

export const {
  selectAll: selectAllCompetences,
} = competencesAdapter.getSelectors((state) => state.felPrepare.competences);

export const {
  selectAll: selectAllInscription,
} = inscriptionAdapter.getSelectors((state) => state.felPrepare.inscriptionsFormation);

export const {
  selectAll: selectAllPresences,
} = presencesAdapter.getSelectors((state) => state.felPrepare.presencesFormation);

export const {
  selectAll: selectAllCompetencesFormation,
} = competencesFormationAdapter.getSelectors((state) => state.felPrepare.competencesFormation);

export const tempsFormSelector = (store) =>
  store.felPrepare.tempsFormateurs;
export const {
  selectAll: selectAllTempsForm,
} = tempsFormAdapter.getSelectors(tempsFormSelector);
export const selectLoadingTempsForm = createSelector(
  tempsFormSelector,
  (state) => state.loading
);


const initialState = {
  loadingInProgress: false,
  processing: false,
  codeFormations: codeFormationAdapter.getInitialState(),
  formationModel: {},
  competencesFormation: competencesFormationAdapter.getInitialState(),
  inscriptionsFormation: inscriptionAdapter.getInitialState(),
  presencesFormation: presencesAdapter.getInitialState(),
  volets: voletAdapter.getInitialState(),
  moyensSqdmS: moyenSQDMSAdapter.getInitialState(),
  status: "idle",
  competences: codeFormationAdapter.getInitialState(),
  tempsFormateurs: tempsFormAdapter.getInitialState({
    loading: false,
  }),
  option: {},
  documents: [],
  examenQuestions: [],
  examenInfo: {},
  partiesData: {},
  isPartiesQuestionUpdated: false,
  isPartiesDocumentUpdated: false,
  addingExamenQuestion: false,
  addingExamenReponse: false
};

export const fetchCodeFormations = createAsyncThunk('formations/CodeFormation/GET', async () => {
  const response = await axios.get(FOR_FECH_CODE_FORMATION);
  return response.data;
});

export const fetchMoyensSqdmS = createAsyncThunk('formations/MoyensSqdmS/GET', async (site) => {
  const response = await axios.get(FETCH_MOYENS_SQDM_SITES + "?sites=" + site);
  return response.data;
});

export const fetchVolets = createAsyncThunk('formations/Volets/GET', async () => {
  const response = await axios.get(FETCH_VOLETS);
  return response.data;
});

export const fetchFormationModel = createAsyncThunk('formations/New', async () => {
  const response = await axios.get(FORMATION_NEW);
  return response.data;
});

export const fetchCompetencesFormation = createAsyncThunk('formations/LoadCompetences', async (id) => {
  const response = await axios.get(FORMATION_LOAD_COMPETENCES + "?idFormation=" + id);
  return response.data;
});

export const fetchInscriptionsFormation = createAsyncThunk('formations/LoadInscriptions', async (id) => {
  const response = await axios.get(FORMATION_LOAD_INSCRIPTIONS + "?formationId=" + id);
  return response.data;
});

export const fetchCompetences = createAsyncThunk('competences/Search', async (searchParams) => {
  const response = await axios.post(COMPETENCE_SEARCH, searchParams);
  return response.data;
});

export const fetchOneFormationModel = createAsyncThunk('formations/OneLogged', async (id) => {
  const response = await axios.get(FORMATION_ONE_LOGGED + "?idFormation=" + id);
  return response.data;
});

export const fetchPresencesFormation = createAsyncThunk('formations/LoadPresences', async (id) => {
  const response = await axios.get(FORMATION_LOAD_PRESENCES + "?formationId=" + id);
  return response.data;
});

export const fetchTempsFormateurs = createAsyncThunk('formations/LoadTempsForms', async (id) => {
  const response = await axios.get(FORMATION_LOAD_TEMPS_FORMATEUR + "?formationId=" + id);
  return response.data;
});

export const saveFormation = createAsyncThunk("formations/save", async (formationModel, { rejectWithValue }) => {
  try {
    const response = await axios.post(FORMATION_ADD, formationModel);
    return response.data;

  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const updateFormation = createAsyncThunk("formations/update", async (formationModel, { rejectWithValue }) => {
  try {
    const response = await axios.put(FORMATION_UPDATE, formationModel);
    return response.data;

  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const addCompetence = createAsyncThunk("formations/AddCompetence", async (competence, thunkAPI) => {
  try {
    const response = await axios.post(FORMATION_ADD_COMPETENCE, competence);
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return response.data;
  }
  catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const deleteCompetence = createAsyncThunk("formations/DeleteCompetence", async (formationCompetenceId, thunkAPI) => {
  const param = { formationCompetenceId: formationCompetenceId };
  try {
    const response = await axios.delete(FORMATION_DELETE_COMPETENCE, { params: param });
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "information_supprimee",
        severity: "success",
      })
    );
    return { deleted: response.data, id: formationCompetenceId };
  }
  catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateInscription = createAsyncThunk("formations/UpdateInscription", async (formationInscription, thunkAPI) => {
  try {
    const response = await axios.put(FORMATION_UPDATE_INSCRIPTION, formationInscription);

    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );

    return response.data;

  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const addInscription = createAsyncThunk("formations/AddInscription", async (formationInscription, thunkAPI) => {
  try {
    const response = await axios.post(FORMATION_ADD_INSCRIPTION, formationInscription);

    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );

    return response.data;
  }
  catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const deleteInscription = createAsyncThunk("formations/DeleteInscription", async (formationRecueId, thunkAPI) => {
  const param = { formationRecueId: formationRecueId };
  try {
    const response = await axios.delete(FORMATION_DELETE_INSCRIPTION, { params: param });

    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "information_supprimee",
        severity: "success",
      })
    );

    return { deleted: response.data, id: formationRecueId };
  }
  catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const addPresence = createAsyncThunk("formations/AddPresence", async (presence, thunkAPI) => {
  try {
    const response = await axios.post(FORMATION_ADD_PRESENCE, presence);
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );

    return response.data;
  }
  catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updatePresence = createAsyncThunk("formations/UpdatePresence", async (presence, thunkAPI) => {
  try {
    const response = await axios.put(FORMATION_UPDATE_PRESENCE, presence);
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return { updated: response.data, presence: presence };
  }
  catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const deletePresence = createAsyncThunk("formations/DeletePresence", async (clePresences, thunkAPI) => {
  const param = { clePresences: clePresences };
  try {
    const response = await axios.delete(FORMATION_DELETE_PRESENCE, { params: param });
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "information_supprimee",
        severity: "success",
      })
    );
    return { deleted: response.data, id: clePresences };
  }
  catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const addTempsForm = createAsyncThunk("formations/AddTempsForm", async (tempsFormateur, thunkAPI) => {
  try {
    const response = await axios.post(FORMATION_ADD_TEMPS_FORMATEUR, tempsFormateur);
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return response.data;
  }
  catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateTempsForm = createAsyncThunk("formations/UpdateTempsForm", async (tempsFormateur, thunkAPI) => {
  try {
    const response = await axios.put(FORMATION_UPDATE_TEMPS_FORMATEUR, tempsFormateur);
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return { updated: response.data, tempsFormateur: tempsFormateur };
  }
  catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const deleteTempsForm = createAsyncThunk("formations/DeleteTempsForm", async (cleTempsForm, thunkAPI) => {
  const param = { cleTempsForm: cleTempsForm };
  try {
    const response = await axios.delete(FORMATION_DELETE_TEMPS_FORMATEUR, { params: param });
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "information_supprimee",
        severity: "success",
      })
    );
    return { deleted: response.data, id: cleTempsForm };
  }
  catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const fetchFormationOptions = createAsyncThunk('formations/GetOptions', async (params, thunkAPI) => {
  const { data } = await axios.get(FORMATION_LOAD_OPTIONS + paramsToQueryParam(params));
  return data;
});

export const updateFormationOptions = createAsyncThunk('formations/UpdateOptions', async (params, thunkAPI) => {
  const payload = {...params}
  if(payload.isUpdated){
    removeObjectProperties(payload, ['isUpdated'])
  }
  const response = await axios.put(FORMATION_UPDATE_OPTIONS, payload);
  return response.data;
});

export const fetchFormationDocuments = createAsyncThunk('formations/LoadDocuments', async (params, thunkAPI) => {
  const { data } = await axios.get(FORMATION_LOAD_DOCUMENTS + paramsToQueryParam(params));
  return data;
});
export const updateFormationDocuments = createAsyncThunk('formations/UpdateDocuments', async (params, thunkAPI) => {
  const { requestBody, showMessage } = params
  try {
    const response = await axios.put(FORMATION_UPDATE_DOCUMENTS, requestBody);
    if (showMessage) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      );
    }
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );

    return thunkAPI.rejectWithValue(error.response.data);
  }
});
export const addFormationDocument = createAsyncThunk("formations/AddDocument", async (data, thunkAPI) => {
  try {
    let formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key] !== null ? data[key] : '');
    }
    const response = await axios.put(FORMATION_ADD_DOCUMENT, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const deleteFormationDocument = createAsyncThunk('formations/DeleteDocument', async (params, thunkAPI) => {
  try {
    const { data } = await axios.delete(FORMATION_DELETE_DOCUMENT + paramsToQueryParam(params));
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "information_supprimee",
        severity: "success",
      })
    );
    return data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const fetchExamenQuestions = createAsyncThunk('formations/LoadExamenQuestions', async (params, thunkAPI) => {
  const { data } = await axios.get(FORMATION_LOAD_EXAMEN_QUESTIONS + paramsToQueryParam(params));
  return data;
});

export const addExamenQuestion = createAsyncThunk("formations/addExamenQuestion", async (data, thunkAPI) => {
  try {
    const response = await axios.put(FORMATION_ADD_EXAMEN_QUESTION, data);
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const updateExamenQuestions = createAsyncThunk('formations/UpdateExamenQuestions', async (params, thunkAPI) => {
  const { requestBody, showMessage } = params
  try {
    const response = await axios.put(FORMATION_UPDATE_EXAMEN_QUESTIONS, requestBody);
    if (showMessage) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      );
    }
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );

    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const deleteExamenQuestion = createAsyncThunk('formations/DeleteExamenQuestion', async (params, thunkAPI) => {
  try {
    const { data } = await axios.delete(FORMATION_DELETE_EXAMEN_QUESTION + paramsToQueryParam(params));
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "information_supprimee",
        severity: "success",
      })
    );
    return data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const importExamenQuestion = createAsyncThunk('formations/ImportExamenQuestion', async (params, thunkAPI) => {
  try {
    const { data } = await axios.get(FORMATION_IMPORT_EXAMEN_QUESTIONS + paramsToQueryParam(params));
    return data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const addExamenReponse = createAsyncThunk("formations/AddExamenReponse", async (data, thunkAPI) => {
  try {
    const response = await axios.put(FORMATION_ADD_EXAMEN_REPONSE, data);
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const deleteExamenReponse = createAsyncThunk('formations/DeleteExamenReponse', async (params, thunkAPI) => {
  try {
    const { data } = await axios.delete(FORMATION_DELETE_EXAMEN_REPONSE + paramsToQueryParam(params));
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "information_supprimee",
        severity: "success",
      })
    );
    return data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const fetchExamenInfo = createAsyncThunk('formations/LoadExamenInfo', async (params, thunkAPI) => {
  const { data } = await axios.get(FORMATION_LOAD_EXAMEN_INFO + paramsToQueryParam(params));
  return data;
});

export const updateExamenInfo = createAsyncThunk('formations/UpdateExamenInfo', async (params, thunkAPI) => {
  const { requestBody, showMessage } = params
  try {
    const response = await axios.put(FORMATION_UPDATE_EXAMEN_INFO, requestBody);
    if (showMessage) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      );
    }
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );

    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const fetchParties = createAsyncThunk('formations/LoadParties', async (params, thunkAPI) => {
  const { data } = await axios.get(FORMATION_LOAD_PARTIES + paramsToQueryParam(params));
  return data;
});

export const updatePartieQuestions = createAsyncThunk('formations/UpdatePartieQuestions', async (params, thunkAPI) => {
  const { requestBody, showMessage } = params
  try {
    const response = await axios.put(FORMATION_UPDATE_PARTIE_QUESTIONS, requestBody);
    if (showMessage) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      );
    }
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );

    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updatePartieDocuments = createAsyncThunk('formations/UpdatePartieDocuments', async (params, thunkAPI) => {
  const { requestBody, showMessage } = params
  try {
    const response = await axios.put(FORMATION_UPDATE_PARTIE_DOCUMENTS, requestBody);
    if (showMessage) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      );
    }
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );

    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updatePartieSections = createAsyncThunk('formations/UpdatePartieSections', async (params, thunkAPI) => {
  const { requestBody, showMessage } = params
  try {
    const response = await axios.put(FORMATION_UPDATE_PARTIE_SECTIONS, requestBody);
    if (showMessage) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      );
    }
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );

    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const LoadCompetencesFromInscription = createAsyncThunk('competence/LoadCompetencesFromInscription', async (params, thunkAPI) => {
  const { data } = await axios.get(COMPETENCE_LOAD_COMPETENCES_FROM_INSCRIPTION + paramsToQueryParam(params));
  return data;
});


export const updateCompetencesEmploye = createAsyncThunk("competence/updateCompetencesEmploye", async (postBody, { rejectWithValue }) => {
  try {
      const response = await axios.put(COMPETENCE_UPDATE_COMPETENCES_EMPLOYE, postBody);
      return response.data;
  }
  catch (error) {
    return rejectWithValue(error.response.data);
  }
});


export const deleteCompetencesEmploye = createAsyncThunk("competence/DeleteCompetencesEmploye", async (params, { rejectWithValue }) => {
  try {
    const response = await axios.delete(COMPETENCE_DELETE_COMPETENCES_EMPLOYE, { data: params });
    return response.data;
  }
  catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const formationPrepareSlice = createSlice({
  name: 'felPrepare',
  initialState,
  reducers: {
    updateFormationData(state, action){
      const { field, value } = action.payload
      state[field] = value
    },
    updatePartiePayload(state, action) {
      const { isUpdated = true, flag, requestBody = [] } = action.payload
      if(flag === 'question'){
        state.isPartiesQuestionUpdated = isUpdated
        state.partiesData.questions = requestBody;
      }else if(flag === 'document'){
        state.isPartiesDocumentUpdated = isUpdated
        state.partiesData.documents = requestBody;
      }else if(flag === 'section'){
        state.partiesData.sections = [...requestBody]
      }
    },
    updateOptionDataField(state, action) {
      const { isUpdated = true, field, value } = action.payload
      if(field !== 'isUpdated' && !state.option.isUpdated && isUpdated){
        state.option.isUpdated = true
      }
      state.option[field] = value;
    },
    updateOptionData(state, action) {
      state.option = action.payload
    }
  },
  extraReducers: {
    [fetchCodeFormations.fulfilled]: (state, action) => {
      codeFormationAdapter.setAll(state.codeFormations, action.payload);
    },
    [fetchMoyensSqdmS.fulfilled]: (state, action) => {
      moyenSQDMSAdapter.setAll(state.moyensSqdmS, action.payload);
    },
    [fetchVolets.fulfilled]: (state, action) => {
      voletAdapter.setAll(state.volets, action.payload);
    },
    [fetchFormationModel.fulfilled]: (state, action) => {
      state.formationModel = action.payload;
    },
    [fetchCompetencesFormation.fulfilled]: (state, action) => {
      competencesFormationAdapter.setAll(state.competencesFormation, action.payload);
    },
    [fetchInscriptionsFormation.fulfilled]: (state, action) => {
      inscriptionAdapter.setAll(state.inscriptionsFormation, action.payload);
    },
    [fetchCompetences.fulfilled]: (state, action) => {
      competencesAdapter.setAll(state.competences, action.payload);
    },
    [fetchOneFormationModel.fulfilled]: (state, action) => {
      state.formationModel = action.payload;
    },
    [fetchPresencesFormation.fulfilled]: (state, action) => {
      presencesAdapter.setAll(state.presencesFormation, action.payload);
    },
    [fetchTempsFormateurs.pending]: (state, action) => {
      state.tempsFormateurs.loading = true;
    },
    [fetchTempsFormateurs.rejected]: (state, action) => {
      state.tempsFormateurs.loading = false;
    },
    [fetchTempsFormateurs.fulfilled]: (state, action) => {
      tempsFormAdapter.setAll(state.tempsFormateurs, action.payload);
      state.tempsFormateurs.loading = false;
    },
    [saveFormation.fulfilled]: (state, action) => {
      state.status = "succeded";
      state.formationModel = action.payload;
    },
    [saveFormation.pending]: (state, action) => {
      state.status = "pending";
    },
    [saveFormation.rejected]: (state, action) => {
      state.status = 'failed';
    },
    [updateFormation.fulfilled]: (state, action) => {
      state.status = "succeded";
      if (action.payload) {
        state.formationModel = action.payload;
      }
    },
    [updateFormation.pending]: (state, action) => {
      state.status = "pending";
    },
    [updateFormation.rejected]: (state, action) => {
      state.status = 'failed';
    },
    [addCompetence.fulfilled]: (state, action) => {
      competencesFormationAdapter.upsertOne(state.competencesFormation, action.payload);
    },
    [deleteCompetence.fulfilled]: (state, action) => {
      competencesFormationAdapter.removeOne(state.competencesFormation, action.payload.id);
    },
    [addInscription.fulfilled]: (state, action) => {
      inscriptionAdapter.upsertOne(state.inscriptionsFormation, action.payload);
    },
    [updateInscription.fulfilled]: (state, action) => {
      inscriptionAdapter.upsertOne(state.inscriptionsFormation, action.payload);
    },
    [deleteInscription.fulfilled]: (state, action) => {
      inscriptionAdapter.removeOne(state.inscriptionsFormation, action.payload.id);
    },
    [addPresence.fulfilled]: (state, action) => {
      presencesAdapter.upsertOne(state.presencesFormation, action.payload);
    },
    [updatePresence.fulfilled]: (state, action) => {
      presencesAdapter.upsertOne(state.presencesFormation, action.payload.presence);
    },
    [deletePresence.fulfilled]: (state, action) => {
      presencesAdapter.removeOne(state.presencesFormation, action.payload.id);
    },
    [addTempsForm.fulfilled]: (state, action) => {
      tempsFormAdapter.upsertOne(state.tempsFormateurs, action.payload);
    },
    [updateTempsForm.fulfilled]: (state, action) => {
      tempsFormAdapter.upsertOne(state.tempsFormateurs, action.payload.tempsFormateur);
    },
    [deleteTempsForm.fulfilled]: (state, action) => {
      tempsFormAdapter.removeOne(state.tempsFormateurs, action.payload.id);
    },
    [fetchFormationOptions.fulfilled]: (state, action) => {
      state.loadingInProgress = false
      state.option = { ...action.payload };
    },
    [fetchFormationOptions.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [fetchFormationOptions.rejected]: (state, action) => {
      state.loadingInProgress = false
    },
    [updateFormationOptions.fulfilled]: (state, action) => {
      state.loadingInProgress = false
    },
    [updateFormationOptions.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [updateFormationOptions.rejected]: (state, action) => {
      state.loadingInProgress = false
    },
    [fetchFormationDocuments.fulfilled]: (state, action) => {
      state.loadingInProgress = false
      state.documents = [...action.payload];
    },
    [fetchFormationDocuments.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [fetchFormationDocuments.rejected]: (state, action) => {
      state.loadingInProgress = false
    },
    [fetchExamenQuestions.fulfilled]: (state, action) => {
      state.loadingInProgress = false
      if (action.payload) {
        state.examenQuestions = [...action.payload];
      }
    },
    [fetchExamenQuestions.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [fetchExamenQuestions.rejected]: (state, action) => {
      state.loadingInProgress = false
    },
    [deleteExamenQuestion.fulfilled]: (state, action) => {
      // state.loadingInProgress = false
      if (action.payload) {
        state.examenQuestions = [...action.payload];
      }
    },
    [fetchExamenInfo.fulfilled]: (state, action) => {
      // state.loadingInProgress = false
      state.examenInfo = { ...action.payload };
    },
    [fetchExamenInfo.pending]: (state, action) => {
      // state.loadingInProgress = true
    },
    [fetchExamenInfo.rejected]: (state, action) => {
      // state.loadingInProgress = false
    },
    [updateExamenInfo.fulfilled]: (state, action) => {
      // if(action.payload){
      //   state.examenQuestions = [...action.payload];
      // }
    },
    [updateFormationDocuments.fulfilled]: (state, action) => {
      state.processing = false
    },
    [updateFormationDocuments.pending]: (state, action) => {
      state.processing = true
    },
    [updateFormationDocuments.rejected]: (state, action) => {
      state.processing = false
    },
    [addFormationDocument.fulfilled]: (state, action) => {
      state.processing = false
    },
    [addFormationDocument.pending]: (state, action) => {
      state.processing = true
    },
    [addFormationDocument.rejected]: (state, action) => {
      state.processing = false
    },
    [addExamenQuestion.fulfilled]: (state, action) => {
      state.addingExamenQuestion = false
    },
    [addExamenQuestion.pending]: (state, action) => {
      state.addingExamenQuestion = true
    },
    [addExamenQuestion.rejected]: (state, action) => {
      state.addingExamenQuestion = false
    },
    [addExamenReponse.fulfilled]: (state, action) => {
      state.addingExamenReponse = false
    },
    [addExamenReponse.pending]: (state, action) => {
      state.addingExamenReponse = true
    },
    [addExamenReponse.rejected]: (state, action) => {
      state.addingExamenReponse = false
    },
    [deleteExamenReponse.fulfilled]: (state, action) => {
      state.processing = false
    },
    [deleteExamenReponse.pending]: (state, action) => {
      state.processing = true
    },
    [deleteExamenReponse.rejected]: (state, action) => {
      state.processing = false
    },
    [deleteFormationDocument.fulfilled]: (state, action) => {
      state.processing = false
    },
    [deleteFormationDocument.pending]: (state, action) => {
      state.processing = true
    },
    [deleteFormationDocument.rejected]: (state, action) => {
      state.processing = false
    },
    [fetchParties.fulfilled]: (state, action) => {
      state.loadingInProgress = false
      if(action.payload){
        state.partiesData = {...action.payload}
      }
      // state.examenInfo = {...action.payload};
    },
    [fetchParties.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [fetchParties.rejected]: (state, action) => {
      state.loadingInProgress = false
    },
    [LoadCompetencesFromInscription.fulfilled]: (state, action) => {
      state.loadingInProgress = false
      // state.examenInfo = {...action.payload};
    },
    [LoadCompetencesFromInscription.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [LoadCompetencesFromInscription.rejected]: (state, action) => {
      state.loadingInProgress = false
    },
    [updateCompetencesEmploye.fulfilled]: (state, action) => {
      state.loadingInProgress = false
      // state.examenInfo = {...action.payload};
    },
    [updateCompetencesEmploye.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [updateCompetencesEmploye.rejected]: (state, action) => {
      state.loadingInProgress = false
    },
  },
});

export const prepareSelector = state => state.felPrepare;
export const {
  updatePartiePayload,
  updateFormationData,
  updateOptionData,
  updateOptionDataField
} = formationPrepareSlice.actions;
export default formationPrepareSlice.reducer;
