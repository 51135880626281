import { configureStore } from "@reduxjs/toolkit";

import localStorage from "./middlewares/LocalStorage";
import rejectionHandler from "./middlewares/RejectionHandler";

import { emptySplitApi } from "./slices/EmptySplitApi";

import messagesSystemReducer from "./slices/MessagesSystemSlice";
import appMessageReducer from "./slices/AppMessageSlice";
import languagesReducer from "./slices/LanguagesSlice";
import sitesReducer from "./slices/SitesSlice";
import authenticationReducer from "./slices/AuthenticationSlice";
import securityReducer from "./slices/SecuritySlice";
import persoReducer from "./slices/PersoSlice";
import criteriaReducer from "./slices/CriteriaSlice";
import employeReducer from "./slices/EmployeSlice";
import inspectionReducer from "./slices/InspectionSlice";
import AuditsInpectionReducer from "./slices/AuditsInpectionSlice";
import CalendrierFrequenceReducer from "./slices/CalendrierFrequenceSlice";
import fournisseurReducer from "./slices/FournisseurSlice";
import fichierDocumentReducer from "./slices/FichierDocumentSlice";
import courrielReducer from "./slices/CourrielSlice";
import nightWorkerReducer from "./slices/NightWorkerSlice";
import globalSearchReducer from "./slices/GlobalSearchSlice";

import competenceSearchReducer from "./slices/Competences/CompetenceSearchSlice";
import competenceCriteriaReducer from "./slices/CompetenceCriteriaSlice";
import competenceEmployeReducer from "./slices/CompetenceEmployeSlice";
import companyContactDetailsReducer from "./slices/CompanyContactDetailsSlice";

import planificationReducer from "./slices/Planification/PlanificationSlice";

import formationCriteriaReducer from "./slices/Formations/FormationCriteriaSlice";
import formationSearchReducer from "./slices/Formations/FormationSearchSlice";

import formationCompetencesReducer from "./slices/FormationCompetences/FormationCompetencesSlice";
import formationCompetencesRapportsReducer from "./slices/FormationCompetencesRapportsSlice";

import actionsVisibles from "./slices/PerformanceReview/ActionsVisibles";

import felFormationsReducer from "./slices/FormationEnLigne/FormationSlice";
import felFormationsMultiReducer from "./slices/FormationEnLigne/FormationMultiSlice";
import felCriteriaReducer from "./slices/FormationEnLigne/CriteriaSlice";
import felEmployeReducer from "./slices/FormationEnLigne/EmployeSlice";
import felPrepareReducer from "./slices/Formations/FormationSlice";

import analyseDeRisquesReducer from "./slices/AnalyseDeRisquesSlice";
import riskAnalysisGetCriteresMultiTypeReducer from "./slices/AnalyseRisques/CriteresMultiTypeSlice"
import riskAnalysisGetCriteresMultiInfoReducer from "./slices/AnalyseRisques/CriteresMultiInfoSlice"
import historiqueReducer from "./slices/HistoriqueSlice";

import maintenanceGeneralReducer from "./slices/Maintenance/MaintenanceGeneralSlice";

import nonConformiteSearchReducer from "./slices/NonConformites/SearchSlice";
import nonConformiteSearchCriteriaReducer from "./slices/NonConformites/SearchCriteriaSlice";
import nonConformiteEditReducer from "./slices/NonConformites/EditSlice";
import nonConformiteEditCriteriaReducer from "./slices/NonConformites/EditCriteriaSlice";

import homeReducer from "./slices/HomeSlice";

import universeReducer from "./slices/UniverseSlice";
import kmGeneralReducer from "./slices/KM/GeneralSlice";
import kmObjectReducer from "./slices/KM/KnowledgeObjectSlice";
import kmObjectLinkReducer from "./slices/KM/KnowledgeObjectLinkSlice";
import knowledgeBaliseReducer from "./slices/KM/KnowledgeBaliseSlice";
import kmUniverseSearchReducer from "./slices/KM/KnowledgeUniverseSearchSlice";
import kmObjectSearchReducer from "./slices/KM/KnowledgeObjectSearchSlice";
import KmUniversCriteriaReducer from "./slices/KM/KnowledgeUniversCriteriaSlice";
import KnowledgeObjectCriteria from "./slices/KM/KnowledgeObjectCriteriaSlice";
import kmRequestReducer from "./slices/KM/RequestSlice";

import documentReducer from "./slices/GestionDocumentaire/GestDocumentaireSlice";
import gestDocumentaireReducer from "./slices/GestionDocumentaire/GestDocumentaireSlice";
import unauthenticatedGestDocumentaireReducer from "./slices/UnauthenticatedGestDocumentaire/UnauthenticatedGestDocumentaireSlice"

import postesReducer from "./slices/PostesSlice";

import produitReducer from "./slices/ProduitSlice";

import alerteReducer from "./slices/AlerteSlice";

import hyperlienReducer from "./slices/HyperlienSlice";

import testBoicTreeReducer from "./slices/Test/Boic/TestBoicTreeSlice";
import testBoicSelectorDemoReducer from "./slices/Test/Boic/TestBoicSelectorDemoSlice";

import perfReviewSearchReducer from "./slices/PerformanceReview/SearchSlice";
import perfReviewListEmployesReducer from "./slices/PerformanceReview/ListEmployesSlice";
import perfReviewGetCriteresMultiTypeReducer from "./slices/PerformanceReview/CriteresMultiTypeSlice";
import perfReviewGetCriteresMultiInfoReducer from "./slices/PerformanceReview/CriteresMultiInfoSlice";

import notificationsReducer from "./slices/NotificationSlice";

import versionReducer from "./slices/VersionSlice";
import RapportReducer from "./slices/RapportSlice";
import stimulsoftReducer from "./slices/StimulsoftSlice";

const ignoredPaths = ["employe", "home", "languages", "perso", "security", "universe"];
const ignoredActionPaths = [
    "meta.arg.employe.imageFichier",
    "meta.arg.file",
    "meta.arg.fichiers",
    "meta.arg.getPhotoSrc",
    "meta.arg.onUploadProgress",
    "meta.arg.universe.selectedFile.0",
    "meta.arg.fichierJoint.0",
    "meta.arg.originalArgs.0.fichierModifie",
    "meta.arg.objetConnaissance.fichierJoint.0",
    "meta.arg.objetConnaissance.miniatures.0",
    "meta.arg.originalArgs.filesToAttach.0.file",
    "payload.file",
    "payload.miniature",
];

export default configureStore({
    reducer: {
        [emptySplitApi.reducerPath]: emptySplitApi.reducer,
        messagesSystem: messagesSystemReducer,
        appMessage: appMessageReducer,
        languages: languagesReducer,
        sites: sitesReducer,
        authentication: authenticationReducer,
        security: securityReducer,
        perso: persoReducer,
        criteria: criteriaReducer,
        employe: employeReducer,
        fournisseur: fournisseurReducer,
        fichierDocument: fichierDocumentReducer,
        courriel: courrielReducer,
        nightWorker: nightWorkerReducer,
        postes: postesReducer,
        produit: produitReducer,
        alerte: alerteReducer,
        globalSearch: globalSearchReducer,
        historique: historiqueReducer,

        competenceSearch: competenceSearchReducer,
        competenceCriteria: competenceCriteriaReducer,
        competenceEmploye: competenceEmployeReducer,
        companyContactDetails: companyContactDetailsReducer,

        analyseDeRisques: analyseDeRisquesReducer,
        riskAnalysisGetCriteresMultiType: riskAnalysisGetCriteresMultiTypeReducer,
        riskAnalysisGetCriteresMultiInfo: riskAnalysisGetCriteresMultiInfoReducer,

        planification: planificationReducer,

        formationCriteria: formationCriteriaReducer,
        formationSearch: formationSearchReducer,

        formationCompetences: formationCompetencesReducer,
        formationCompetencesRapports: formationCompetencesRapportsReducer,

        felFormations: felFormationsReducer,
        felFormationsMulti: felFormationsMultiReducer,
        felCriteria: felCriteriaReducer,
        felEmploye: felEmployeReducer,
        felPrepare: felPrepareReducer,

        actionVisibles: actionsVisibles,

        maintenanceGeneral: maintenanceGeneralReducer,

        nonConformiteSearch: nonConformiteSearchReducer,
        nonConformiteSearchCriteria: nonConformiteSearchCriteriaReducer,
        nonConformiteEdit: nonConformiteEditReducer,
        nonConformiteEditCriteria: nonConformiteEditCriteriaReducer,

        rapport: RapportReducer,

        home: homeReducer,

        kmGeneral: kmGeneralReducer,
        universe: universeReducer,
        kmUniverseSearch: kmUniverseSearchReducer,
        KmUniversCriteria: KmUniversCriteriaReducer,
        kmObject: kmObjectReducer,
        kmObjectSearch: kmObjectSearchReducer,
        kmObjectCriteria: KnowledgeObjectCriteria,
        kmObjectLink: kmObjectLinkReducer,
        balises: knowledgeBaliseReducer,
        request: kmRequestReducer,
        document: documentReducer,
        gestDocumentaire: gestDocumentaireReducer,
        unauthenticatedGestDocumentaire: unauthenticatedGestDocumentaireReducer,

        inspection: inspectionReducer,
        auditsInpection: AuditsInpectionReducer,
        calendrierFrequence: CalendrierFrequenceReducer,

        hyperlien: hyperlienReducer,

        testBoicTree: testBoicTreeReducer,
        testBoicSelectorDemo: testBoicSelectorDemoReducer,

        perfReviewSearch: perfReviewSearchReducer,
        perfReviewListEmployes: perfReviewListEmployesReducer,
        perfReviewGetCriteresMultiType: perfReviewGetCriteresMultiTypeReducer,
        perfReviewGetCriteresMultiInfo: perfReviewGetCriteresMultiInfoReducer,

        notifications: notificationsReducer,

        version: versionReducer,
        stimulsoftReport: stimulsoftReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredPaths: ignoredPaths,
                ignoredActionPaths: ignoredActionPaths,
            },
            immutableCheck: { ignoredPaths: ignoredPaths },
        }).concat(emptySplitApi.middleware).concat(localStorage, rejectionHandler),
});
