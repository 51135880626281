import { createSlice, createAsyncThunk, createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import axios from 'Services/AxiosService';
import { paramsToQueryParam } from "../../UrlUtils";
import { GET_ALL_BALISES_BY_DOSSIER, GET_ALL_BALISES_DISTINCT, ADD_ONE_BALISE, DELETE_ONE_BALISE } from 'store/UrlConstants';

// ----------------------------------------------------------------------------------------
// BalisesByDossier
// ----------------------------------------------------------------------------------------
const balisesByDossierAdapter = createEntityAdapter();

export const addManyBalise = createAsyncThunk("Balises/AddMany", async (balisesList, { rejectWithValue }) => {
    try {
        const response = await axios.post(ADD_ONE_BALISE, balisesList);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const getAllBalisesByDossier = createAsyncThunk("Balises/GetAllByDossier", async (idDossier) => {
    const params = { idDossier: idDossier };
    const response = await axios.get(GET_ALL_BALISES_BY_DOSSIER, { params: params });
    return response.data;
});

export const deleteOneBalise = createAsyncThunk("Balises/DeleteOne", async (idBalise, { rejectWithValue }) => {
    const params = { idBalise: idBalise }
    try {
        const response = await axios.delete(DELETE_ONE_BALISE, { params: params });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const {
    selectAll: selectAllBalisesByDossier,
} = balisesByDossierAdapter.getSelectors((store) => store.balises.balisesByDossier);


// ----------------------------------------------------------------------------------------
// BalisesDistinct
// ----------------------------------------------------------------------------------------
const balisesDistinctAdapter = createEntityAdapter();

export const getAllBalisesDistinct = createAsyncThunk("Balises/GetAllDistinct", async () => {
    const response = await axios.get(GET_ALL_BALISES_DISTINCT);
    return response.data;
});

export const balisesDistinctSelector = (store) => store.balises.balisesDistinct;

export const {
    selectAll: selectAllBalisesDistinct,
} = balisesDistinctAdapter.getSelectors(balisesDistinctSelector);

export const loadingBalisesDistinct = createSelector(
    balisesDistinctSelector,
    (state) => state.loading
);


// ========================================================================================
// MAIN SLICE
// ========================================================================================
const initialState = {
    balisesByDossier: balisesByDossierAdapter.getInitialState(),
    balisesDistinct: balisesDistinctAdapter.getInitialState({ loading: false }),
}

const knowledgeBaliseSlice = createSlice({
    name: "knowledgeBalise",
    initialState,
    reducers: {},
    extraReducers: {
        [getAllBalisesByDossier.fulfilled]: (state, action) => {
            balisesByDossierAdapter.setAll(state.balisesByDossier, action.payload)
        },

        [getAllBalisesDistinct.pending]: (state) => {
            state.balisesDistinct.loading = true;
        },
        [getAllBalisesDistinct.rejected]: (state) => {
            state.balisesDistinct.loading = false;
        },
        [getAllBalisesDistinct.fulfilled]: (state, action) => {
            balisesDistinctAdapter.setAll(state.balisesDistinct, action.payload);
            state.balisesDistinct.loading = false;
        },
    },
});

export const { } = knowledgeBaliseSlice.actions;

export default knowledgeBaliseSlice.reducer;