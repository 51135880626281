import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'Services/AxiosService';
import { listToQueryParam, paramsToQueryParam } from 'store/UrlUtils';
import { FETCH_LIEU_FORMATION, FETCH_MOYENS_SQDM_SITES, FETCH_FORMATEUR, FETCH_COMPETENCE, FETCH_EMPLOYES_INSCRITS } from 'store/UrlConstants';


/* ======================================================================= */
// Competence
/* ======================================================================= */
const competenceAdapter = createEntityAdapter();

export const fetchCompetences = createAsyncThunk("FormationCriteria/Competence/Get", async (codeSiteListe) => {
    const response = await axios.get(FETCH_COMPETENCE + listToQueryParam("sites", codeSiteListe));
    return response.data;
});

export const {
    selectAll: selectAllCompetences,
} = competenceAdapter.getSelectors((state) => state.formationCriteria.competence);



/* ======================================================================= */
// Employés Inscrits
/* ======================================================================= */
const EmployesInscritsAdapter = createEntityAdapter();

export const fetchEmployesInscrits = createAsyncThunk("FormationCriteria/EmployesInscrits/Get", async (formationId) => {
    const response = await axios.get(FETCH_EMPLOYES_INSCRITS +"?formationId="+formationId);
    return response.data;
});

export const {
    selectAll: selectAllEmployesInscrits,
} = EmployesInscritsAdapter.getSelectors((state) => state.formationCriteria.employesInscrits);



/* ======================================================================= */
// Formateur
/* ======================================================================= */
const formateurAdapter = createEntityAdapter();

export const fetchFormateurs = createAsyncThunk("FormationCriteria/Formateur/Get", async (codeSiteListe) => {
    const response = await axios.get(FETCH_FORMATEUR + listToQueryParam("sites", codeSiteListe));
    return response.data;
});

export const {
    selectAll: selectAllFormateurs,
} = formateurAdapter.getSelectors((state) => state.formationCriteria.formateur);


/* ======================================================================= */
// LieuFormation
/* ======================================================================= */
const lieuFormationAdapter = createEntityAdapter();

export const fetchLieuxFormation = createAsyncThunk("FormationCriteria/LieuFormation/Get", async (codeSiteListe) => {
    const response = await axios.get(FETCH_LIEU_FORMATION + listToQueryParam("sites", codeSiteListe));
    return response.data;
});

export const {
    selectAll: selectAllLieuxFormation,
} = lieuFormationAdapter.getSelectors((state) => state.formationCriteria.lieuFormation);


/* ======================================================================= */
// MoyensSqdm
/* ======================================================================= */
const moyenSqdmAdapter = createEntityAdapter();

export const fetchMoyensSqdmSites = createAsyncThunk('FormationCriteria/MoyensSqdmSites/Get', async (codeSiteListe) => {
    const response = await axios.get(FETCH_MOYENS_SQDM_SITES + listToQueryParam("sites", codeSiteListe));
    return response.data;
});

export const {
    selectAll: selectAllMoyensSqdm,
} = moyenSqdmAdapter.getSelectors((state) => state.formationCriteria.moyensSqdm);


/* ======================================================================= */
// Main Slice
/* ======================================================================= */
const initialState = {
    competence: competenceAdapter.getInitialState(),
    employesInscrits: EmployesInscritsAdapter.getInitialState(),
    formateur: formateurAdapter.getInitialState(),
    lieuFormation: lieuFormationAdapter.getInitialState(),
    moyensSqdm: moyenSqdmAdapter.getInitialState(),
}

const formationCriteriaSlice = createSlice({
    name: "FormationCriteria",
    initialState,
    reducers: {},
    extraReducers: {
        [fetchCompetences.fulfilled]: (state, action) => {
            competenceAdapter.setAll(state.competence, action.payload);
        },
        [fetchEmployesInscrits.fulfilled]: (state, action) => {
            EmployesInscritsAdapter.setAll(state.employesInscrits, action.payload);
        },
        [fetchFormateurs.fulfilled]: (state, action) => {
            formateurAdapter.setAll(state.formateur, action.payload);
        },
        [fetchLieuxFormation.fulfilled]: (state, action) => {
            lieuFormationAdapter.setAll(state.lieuFormation, action.payload);
        },
        [fetchMoyensSqdmSites.fulfilled]: (state, action) => {
            moyenSqdmAdapter.setAll(state.moyensSqdm, action.payload);
        },
    },
});

export const { } = formationCriteriaSlice.actions;

export default formationCriteriaSlice.reducer;