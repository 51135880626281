import { createSlice, createAsyncThunk, createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import axios from 'Services/AxiosService';
import { paramsToQueryParam } from 'store/UrlUtils';
import {
    GET_ONE_FOURNISSEUR_DOCUMENTS_REMPLIR,
    LOAD_FOURNISSEUR_DOCUMENTS_REMPLIR,
    UPDATE_REQUETE_FOURN_DOCUMENT_REMPLIR_DOCUMENT
} from 'store/UrlConstants';

const fournisseurDocumentAdapter = createEntityAdapter();

export const getOneSupplierRequestForQualification = createAsyncThunk("Fournisseur/RequestForQualification/GetOne", async (requeteFournDocumentId) => {
    const res = await axios.get(GET_ONE_FOURNISSEUR_DOCUMENTS_REMPLIR, { params: { requetefourndocumentId: requeteFournDocumentId } });
    return res.data;
})

export const fetchFournisseurDocumentsRemplir = createAsyncThunk('Fournisseur/LoadFournisseurDocumentRemplir', async (params) => {
    const result = await axios.get(LOAD_FOURNISSEUR_DOCUMENTS_REMPLIR + paramsToQueryParam({ ...params }));
    return result.data;
});

export const updateRequeteFournDocumentRemplirDocument = createAsyncThunk('Fournisseur/UpdateRequeteFournDocumentRemplirDocument', async (fournDocument) => {
    const { requetefourndocumentId, fileData } = fournDocument
    const formData = new FormData()
    formData.append('file', fileData)
    const result = await axios.post(
        UPDATE_REQUETE_FOURN_DOCUMENT_REMPLIR_DOCUMENT + '?requetefourndocumentId=' + requetefourndocumentId,
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
    );
    return result.data;
});

export const {
    selectAll: selectAllFournisseurDocuments,
    selectById: selectFournisseurDocumentById,
} = fournisseurDocumentAdapter.getSelectors((state) => state.fournisseur.documents)

const initialState = {
    documents: fournisseurDocumentAdapter.getInitialState(),
    status: false,
    loadingInProgress: false
};

const fournisseurSlice = createSlice({
    name: 'fournisseur',
    initialState,
    reducers: {
        resetStatus(state, action) {
            state.status = initialState.status;
        },
    },
    extraReducers: {
        [fetchFournisseurDocumentsRemplir.pending]: (state, action) => {
            state.loadingInProgress = true
        },
        [fetchFournisseurDocumentsRemplir.fulfilled]: (state, action) => {
            state.loadingInProgress = false
            fournisseurDocumentAdapter.setAll(state.documents, action.payload);
        },
    },
});
export const fournisseurSelector = state => state.fournisseur

export const { resetStatus } = fournisseurSlice.actions;

export default fournisseurSlice.reducer;