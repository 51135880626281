import { createSlice, createAsyncThunk, createEntityAdapter } from "@reduxjs/toolkit";
import axios from "Services/AxiosService";
import { listToQueryParam, paramsToQueryParam } from "store/UrlUtils";
import { FEL_SELECTION_DE_PERSONNE } from "store/UrlConstants";
import { keys } from '@mui/material/styles';


// Person Select Adapter
const personneToReceiveTrainingAdapter = createEntityAdapter({
    selectId: entity => entity.noEmploye,
});

export const fetchPersonneToReceiveTraining = createAsyncThunk("FormationEnLigne/PersonneToReceiveTraining", async (params) => {
    let filtredParams = {};

    for (var [key, value] of Object.entries(params)) {
        if (value) {
            filtredParams = ({ ...filtredParams, [key]: value })
        }
    }
    const response = await axios.get(FEL_SELECTION_DE_PERSONNE + paramsToQueryParam(filtredParams));
    return response.data;
});

export const {
    selectAll: selectAllPersonneToReceiveTraining,
    selectById: selectPersonneToReceiveTrainingById,
} = personneToReceiveTrainingAdapter.getSelectors((state) => state.felEmploye.personneToReceiveTraining);

const initialState =
{
    personneToReceiveTraining: personneToReceiveTrainingAdapter.getInitialState(),
}

const felEmployeSlice = createSlice({
    name: "felEmploye",
    initialState,
    reducers: {},
    extraReducers: {
        [fetchPersonneToReceiveTraining.fulfilled]: (state, action) => {
            personneToReceiveTrainingAdapter.setAll(state.personneToReceiveTraining, action.payload);
        },
    }
});

export default felEmployeSlice.reducer;