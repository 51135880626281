import React, {useEffect, useState} from 'react';
import MainRoutes from "./Routes/MainRoutes";
import { HashRouter as Router } from "react-router-dom";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from "moment";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import "moment/locale/fr-ca";
import "moment/locale/en-ca";
import "moment/locale/es";
import {
  bootstrapLogin,
  getAzureADConfig,
  setLanguageState
} from "./store/slices/AuthenticationSlice";
import LocalStorageService from "./Services/LocalStorageService";
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from "./themeConfig";
import Snackbar from "./Components/Common/CustomSnackbar";
import AlertDialog from "./Components/Common/CustomAlertDialog";
import ScrollToTop from "./Routes/ScrollToTop";
import {setLocale} from "./store/slices/PersoSlice";
import {useDispatch, useSelector} from "react-redux";
import {getCookieValue, setCookie} from "./Utils/CommonFunctions";
import {fetchLanguages} from "./store/slices/LanguagesSlice";

moment.locale("fr-ca"); // it is required to select default locale manually

export default function AppBootstrap() {
  const dispatch = useDispatch();
  const localStorageService = LocalStorageService.getService();
  const locale = useSelector((state) => state.perso.momentLocale);
  const [initialized, setInitialized] = useState(false);

  const selectLocale = React.useCallback(locale => {
    let newLocale;
    switch (locale) {
      case 'FR':
        newLocale = "fr-ca";
        break;
      case 'AN':
        newLocale = "en-ca";
        break;
      case 'ES':
        newLocale = "es";
        break;
    }
    dispatch(setLocale(locale));
    moment.locale(newLocale);
  }, []);

  useEffect(() => {
    localStorageService.setRefreshingToken('idle');
    const loggedIn = localStorageService.getLoggedIn();
    const lang = localStorageService.getLanguage();
    if (loggedIn === "true" && lang) {
      selectLocale(lang.id);
      dispatch(setLanguageState(lang));
      dispatch(bootstrapLogin());
    }
    // Render MainRoutes seulement après avoir déterminer le statut de la config par rapport à Azure AD et langues
    let promises = [
      dispatch(getAzureADConfig()),
      dispatch(fetchLanguages()),
    ];
    Promise.all(promises).then().then((r) => {
      setInitialized(true);
    });
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Snackbar />
        <AlertDialog />
        <LocalizationProvider dateLibInstance={moment} dateAdapter={AdapterMoment} adapterLocale={locale}>
          {initialized ? <MainRoutes selectLocale={selectLocale} /> : <div />}
        </LocalizationProvider>
      </ThemeProvider>
    </Router>
  )
}