// ========================================================================================
// IMPORTS
// ========================================================================================
import { createSlice, createAsyncThunk, createEntityAdapter, createSelector } from '@reduxjs/toolkit'
import axios from 'Services/AxiosService';
import { listToQueryParam } from 'store/UrlUtils';
import { GET_ALL_NIVEAU_MAITRISE, EMP_SKILL_FILTER_LIST } from 'store/UrlConstants'
import { showSnackbarMessage } from "store/slices/MessagesSystemSlice";

// ========================================================================================
// ADAPTERS / ASYNC ACTIONS / SELECTORS
// ========================================================================================
const niveauMaitriseAdapter = createEntityAdapter();

export const fetchNiveauxMaitrise = createAsyncThunk("CompetenceCriteria/NiveauMaitrise/GetAll", async (sites) => {
    const response = await axios.get(GET_ALL_NIVEAU_MAITRISE + listToQueryParam("sites", sites));
    return response.data;
});

export const niveauxMaitriseSelector = (store) => store.competenceCriteria.niveauxMaitrise;

export const {
    selectAll: selectAllNiveauxMaitrise,
} = niveauMaitriseAdapter.getSelectors(niveauxMaitriseSelector);

export const selectAllNiveauxMaitriseActifs = createSelector(
    selectAllNiveauxMaitrise,
    (niveauMaitrise) => niveauMaitrise.filter((maitrise) => maitrise.inactive === false)
);

export const selectLoadingNiveauMaitrise = createSelector(
    niveauxMaitriseSelector,
    (state) => state.loading
);

const competenceFilterListAdapter = createEntityAdapter();

export const fetchCompetenceFilterList = createAsyncThunk("CompetenceCriteria/CompetenceFilter/GetAll", async (noEmploye) => {
    const response = await axios.get(EMP_SKILL_FILTER_LIST, { params: { noEmploye: noEmploye } });
    return response.data;
});

export const competenceFilterListSelector = (store) => store.competenceCriteria.competenceFilterList;

export const {
    selectAll: selectAllCompetenceFilter
} = competenceFilterListAdapter.getSelectors(competenceFilterListSelector);

export const selectLoadingCompetenceFilter = createSelector(
    competenceFilterListSelector,
    (state) => state.loading
)

// ========================================================================================
// MAIN SLICE
// ========================================================================================
const initialState = {
    niveauxMaitrise: niveauMaitriseAdapter.getInitialState({ loading: false }),
    competenceFilterList: competenceFilterListAdapter.getInitialState({ loading: false }),
};

const competenceCriteriaSlice = createSlice({
    name: "competenceCriteria",
    initialState,
    reducers: {},
    extraReducers: {
        [fetchNiveauxMaitrise.pending]: (state, action) => {
            state.niveauxMaitrise.loading = true;
        },
        [fetchNiveauxMaitrise.rejected]: (state, action) => {
            state.niveauxMaitrise.loading = false;
        },
        [fetchNiveauxMaitrise.fulfilled]: (state, action) => {
            niveauMaitriseAdapter.setAll(state.niveauxMaitrise, action.payload)
            state.niveauxMaitrise.loading = false;
        },
        [fetchCompetenceFilterList.pending]: (state, action) => {
            state.competenceFilterList.loading = true;
        },
        [fetchCompetenceFilterList.rejected]: (state, action) => {
            state.competenceFilterList.loading = false;
        },
        [fetchCompetenceFilterList.fulfilled]: (state, action) => {
            niveauMaitriseAdapter.setAll(state.competenceFilterList, action.payload)
            state.competenceFilterList.loading = false;
        }
    },
})

// ========================================================================================

export const { } = competenceCriteriaSlice.actions;

export default competenceCriteriaSlice.reducer;