import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import axios from 'Services/AxiosService';
import { GET_VERSION_BD, GET_VERSION_API, GET_VERSION_UI } from 'store/UrlConstants';


const initialState = {
  versionUi: '',
  versionApi: '',
  versionBd: '',
};

export const fetchVersionBd = createAsyncThunk('version/fetchVersionBd', async () => {
  const response = await axios.get(GET_VERSION_BD);
  return response.data;
});

export const fetchVersionApi = createAsyncThunk('version/fetchVersionApi', async () => {
  const response = await axios.get(GET_VERSION_API);
  return response.data;
});

export const fetchVersionUi = createAsyncThunk('version/fetchVersionUi', async () => {
  const response = await axios.get(GET_VERSION_UI);
  return response.data;
});

const versionSlice = createSlice({
  name: 'version',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchVersionBd.fulfilled]: (state, action) => {
      state.versionBd = action.payload;
    },
    [fetchVersionApi.fulfilled]: (state, action) => {
      state.versionApi = action.payload;
    },
    [fetchVersionUi.fulfilled]: (state, action) => {
      state.versionUi = action.payload;
    },
    [fetchVersionApi.rejected]: (state) => {
      state.versionApi = 'dev';
    },
    [fetchVersionUi.rejected]: (state) => {
      state.versionUi = 'dev';
    },
  }
});

export default versionSlice.reducer;