// ========================================================================================
// IMPORTS
// ========================================================================================
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'Services/AxiosService';
import { paramsToQueryParam } from "../../UrlUtils";
import { GET_ALL_POSTES_RESTRIENT, ADD_REQUEST_BY_CODE_SYSTEME } from 'store/UrlConstants';
import { showSnackbarMessage } from "store/slices/MessagesSystemSlice";
import { updateOneObjectList } from "store/slices/KM/KnowledgeObjectSearchSlice";
import { setKmObject } from './KnowledgeObjectSlice';


// ========================================================================================
// ADAPTERS
// ========================================================================================
const allPosteRestrientAdapter = createEntityAdapter();

export const getAllPosteRestrient = createAsyncThunk("Request/PostesRestrient/Get", async () => {
    const response = await axios.get(GET_ALL_POSTES_RESTRIENT);
    return response.data;
})

export const {
    selectAll: selectAllPostesRestrient,
    selectById: selectPosteRestrientById,
} = allPosteRestrientAdapter.getSelectors((state) => state.request.allPosteRestrient);

export const addRequestByCodeSysteme = createAsyncThunk("Request/AddByCodeSysteme", async (request, thunkAPI) => {
    try {
        const response = await axios.post(ADD_REQUEST_BY_CODE_SYSTEME, request);

        const kmObjectSearchListScreen = thunkAPI.getState().kmObjectSearch.objectList.entities[request.idDocument];
        const kmObjectDetailsScreen = thunkAPI.getState().kmObject.object;

        const oldOC = kmObjectSearchListScreen || kmObjectDetailsScreen
        const newOC = { ...oldOC, requestExist: true, requestCount: oldOC.requestCount + 1 };

        if(kmObjectSearchListScreen) {
            thunkAPI.dispatch(updateOneObjectList(newOC));
        } else {
            thunkAPI.dispatch(setKmObject(newOC));
        }

        thunkAPI.dispatch(
            showSnackbarMessage({
                dba: "objetsLangues2",
                messageId: "web_confirmation_enregistrement",
                severity: "success",
            })
        );

        return response.data;

    } catch (error) {
        thunkAPI.dispatch(
            showSnackbarMessage({
                message: error.response.data.messageText,
                severity: "error",
                autoHideDuration: 10000,
            })
        );
        return thunkAPI.rejectWithValue(error.response.data);
    }
});


// ========================================================================================
// SLICE / ACTIONS / MIDLEWARES
// ========================================================================================
const initialState = {
    allPosteRestrient: allPosteRestrientAdapter.getInitialState({ loading: 'idle' }),
}

const requestSlice = createSlice({
    name: 'request',
    initialState: initialState,
    reducers: {
        
    },
    extraReducers: {
        [getAllPosteRestrient.pending]: (state, action) => {
            state.allPosteRestrient.loading = "pending"
        },
        [getAllPosteRestrient.fulfilled]: (state, action) => {
            allPosteRestrientAdapter.setAll(state.allPosteRestrient, action.payload)
            state.allPosteRestrient.loading = "fulfilled"
        }
    }
})

export const { } = requestSlice.actions;

export default requestSlice.reducer;