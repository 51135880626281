import axios from "Services/AxiosService";
import { createAsyncThunk, createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { POSTES_EXT_SITES, POSTES_GET_CRITERES, POSTES_LOAD_RESULTAT } from "store/UrlConstants";
import { paramsToQueryParam } from "store/UrlUtils";
import { showSnackbarMessage } from "store/slices/MessagesSystemSlice";

const externesSitesAdapter = createEntityAdapter();
export const getExternesSites = createAsyncThunk("Poste/PostesExternesSites/GET", async (sites) => {
  const response = await axios.get(POSTES_EXT_SITES + paramsToQueryParam({ sites: sites }));
  return response.data;
});

export const getPosteCriteres = createAsyncThunk("Poste/GetPosteCriteres/GET", async (params) => {
  const response = await axios.get(POSTES_GET_CRITERES + paramsToQueryParam(params));
  return response.data;
});

const posteResultatAdapter = createEntityAdapter({
  selectId: (entity) => entity.posteId,
});
export const loadPosteResultat = createAsyncThunk("Poste/LoadPosteResultat/PUT", async ({params = {}, showMsg = true} = {}, thunkAPI) => {
  try {
    const { data } = await axios.put(POSTES_LOAD_RESULTAT, params);
    if (showMsg) {
      thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    )}
    return data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const initialState = {
  externesSites: externesSitesAdapter.getInitialState(),
  loadingExternesSites: false,
  posteResultat: posteResultatAdapter.getInitialState(),
  loadingPosteResultat: false,
};

const PosteSlice = createSlice({
  name: "poste",
  initialState,
  reducers: {},
  extraReducers: {
    [getExternesSites.fulfilled]: (state, action) => {
      externesSitesAdapter.setAll(state.externesSites, action.payload);
      state.loadingExternesSites = false;
    },
    [getExternesSites.pending]: (state, action) => {
      state.loadingExternesSites = true;
    },
    [loadPosteResultat.pending]: (state, action) => {
      state.loadingPosteResultat = true;
    },
    [loadPosteResultat.rejected]: (state, action) => {
      state.loadingPosteResultat = false;
    },
    [loadPosteResultat.fulfilled]: (state, action) => {
      posteResultatAdapter.setAll(state.posteResultat, action.payload);
      state.loadingPosteResultat = false;
    },
  },
});

export const { selectAll: selectAllExternesSites, selectById: selectExternesSitesById } =
  externesSitesAdapter.getSelectors((state) => state.postes.externesSites);

export const { selectAll: selectAllPostes } = posteResultatAdapter.getSelectors((state) => state.postes.posteResultat);

export const PosteSelector = (state) => state.postes;

export default PosteSlice.reducer;
