import {emptySplitApi} from './EmptySplitApi'
import {
    CHANGE_SEC_USAGER_PASSWORD,
    CHANGE_SEC_USAGER_PASSWORD_LOGGED_IN,
    LOAD_POLPWPOL_BY_USERNAME,
    MENU_DATA,
    GET_ALL_CARTE_ACCESS_BY_USAGER_ID,
    GET_ONE_CARTE_ACCESS_BY_USAGER_ID,
    GET_SEC_USAGER_VM_FC,
    ADD_ONE_SEC_USAGER,
    UPDATE_ONE_SEC_USAGER,
    RESET_SEC_USAGER_PASSWORD,
    DELETE_ONE_SEC_USAGER,
    GET_SEC_GROUPES_DISP_BY_USAGER,
    GET_SEC_GROUPES_ASSOCIE_USAGER,
    ADD_SEC_GROUPES_USAGER,
    DELETE_SEC_GROUPES_USAGER
} from "../UrlConstants"

const enhancedApi = emptySplitApi.enhanceEndpoints({
    addTagTypes: ["Usager.groupes"]
})

const extendedApi = enhancedApi.injectEndpoints({
    endpoints: (build) => ({
        fetchMenuData: build.query({
            query: () => {
                return ({
                    url: MENU_DATA,
                    method: 'GET'
                });
            },
        }),
        changeSecUsagerPasswordLoggedIn: build.mutation({
            query: (params) => {
                return ({
                    url: CHANGE_SEC_USAGER_PASSWORD_LOGGED_IN,
                    method: 'POST',
                    data: params
                });
            },
        }),
        changeSecUsagerPassword: build.mutation({
            query: (params) => {
                return ({
                    url: CHANGE_SEC_USAGER_PASSWORD,
                    method: 'POST',
                    data: params
                });
            },
        }),
        loadPolPwPolByUsername: build.query({
            query: (username) => {
                return ({
                    url: LOAD_POLPWPOL_BY_USERNAME,
                    method: 'GET',
                    params: {username: username}
                });
            },
        }),
        getAllCartesAccessByUsagerId: build.query({
            query: (secUsagerId) => {
                return ({
                    url: GET_ALL_CARTE_ACCESS_BY_USAGER_ID,
                    method: 'GET',
                    params: { secUsagerId }
                });
            },
        }),
        getOneCarteAccessByUsagerId: build.query({
            query: (secUsagerId) => {
                return ({
                    url: GET_ONE_CARTE_ACCESS_BY_USAGER_ID,
                    method: 'GET',
                    params: { secUsagerId }
                });
            }
        }),
        getSecUsagerVmFc: build.query({
            query: (noEmploye) => {
                return ({
                    url: GET_SEC_USAGER_VM_FC,
                    method: 'GET',
                    params: { noEmploye }
                });
            },
            providesTags: result => [{ type: 'SecUsager', id: result.id }],
        }),
        addOneSecUsager: build.mutation({
            query: (secUsager) => {
                return ({
                    url: ADD_ONE_SEC_USAGER,
                    method: 'POST',
                    data: secUsager
                });
            },
            invalidatesTags: (result, error, secUsager) => [{ type: 'SecUsager', id: secUsager.id }],
        }),
        updateOneSecUsager: build.mutation({
            query: (secUsager) => {
                return ({
                    url: UPDATE_ONE_SEC_USAGER,
                    method: 'PUT',
                    data: secUsager
                });
            },
            invalidatesTags: (result, error, secUsager) => [{ type: 'SecUsager', id: secUsager.id }],
        }),
        resetSecUsagerPassword: build.mutation({
            query: (secUsager) => {
                return ({
                    url: RESET_SEC_USAGER_PASSWORD,
                    method: 'PUT',
                    data: secUsager
                });
            },
            invalidatesTags: (result, error, secUsager) => [{ type: 'SecUsager', id: secUsager.id }],
        }),
        deleteOneSecUsager: build.mutation({
            query: (secUsager) => {
                return ({
                    url: DELETE_ONE_SEC_USAGER,
                    method: 'DELETE',
                    data: secUsager 
                });
            },
            invalidatesTags: (result, error, secUsager) => [{ type: 'SecUsager', id: secUsager.id }],
        }),        
        getSecGroupesDispByEmploye: build.query({
            query: (secUsagerId) => {
                return ({
                    url: GET_SEC_GROUPES_DISP_BY_USAGER,
                    method: 'GET',
                    params: { secUsagerId}
                });
            },
            providesTags: (result) => 
                result
                    ? [...result.map(g => ({ type: 'Usager.groupes', id: g.id })), { type: 'Usager.groupes', id: 'LIST_1' }]
                    : [{ type: 'Usager.groupes', id: 'LIST_1' }]        
        }),
        getSecGroupesAssocByEmploye: build.query({
            query: (secUsagerId) => {
                return ({
                    url: GET_SEC_GROUPES_ASSOCIE_USAGER,
                    method: 'GET',
                    params: { secUsagerId}
                });
            },
            providesTags: (result) => 
                result
                    ? [...result.map(g => ({ type: 'Usager.groupes', id: g.id })), { type: 'Usager.groupes', id: 'LIST_2' }]
                    : [{ type: 'Usager.groupes', id: 'LIST_2' }]        
        }),
        addSecGroupesUsager: build.mutation({
            query: (secGroupesUsager) => {
                return ({
                    url: ADD_SEC_GROUPES_USAGER,
                    method: 'POST',
                    data: secGroupesUsager
                });
            },
            invalidatesTags: (result, error, secGroupesUsager) =>
                secGroupesUsager
                  ? [
                      ...secGroupesUsager.map((g) => ({
                        type: 'Usager.groupes',
                        id: g.idSecGroupe,
                      })),
                      { type: 'Usager.groupes', id: 'LIST_2' },
                    ]
                  : [{ type: 'Usager.groupes', id: 'LIST_1' }, { type: 'Usager.groupes', id: 'LIST_2'}],
        }),
        deleteSecGroupesUsager: build.mutation({
            query: (secGroupesUsager) => {
                return ({
                    url: DELETE_SEC_GROUPES_USAGER,
                    method: 'DELETE',
                    data: secGroupesUsager
                });
            },
            invalidatesTags: (result, error, secGroupesUsager) =>
                secGroupesUsager
                  ? [
                      ...secGroupesUsager.map((g) => ({
                        type: 'Usager.groupes',
                        id: g.idSecGroupe,
                      })),
                        { type: 'Usager.groupes', id: 'LIST_1' },
                    ]
                  : [{ type: 'Usager.groupes', id: 'LIST_1' }, { type: 'Usager.groupes', id: 'LIST_2'}],
        }),
    })
})

export const {
    useFetchMenuDataQuery,
    useLazyFetchMenuDataQuery,
    useChangeSecUsagerPasswordLoggedInMutation,
    useChangeSecUsagerPasswordMutation,
    useLoadPolPwPolByUsernameQuery,
    useGetAllCartesAccessByUsagerIdQuery,
    useGetOneCarteAccessByUsagerIdQuery,
    useGetSecUsagerVmFcQuery,
    useAddOneSecUsagerMutation,
    useUpdateOneSecUsagerMutation,
    useResetSecUsagerPasswordMutation,
    useDeleteOneSecUsagerMutation,
    useGetSecGroupesDispByEmployeQuery,
    useGetSecGroupesAssocByEmployeQuery,
    useAddSecGroupesUsagerMutation,
    useDeleteSecGroupesUsagerMutation
} = extendedApi