// ========================================================================================
// IMPORTS
// ========================================================================================
import {createAsyncThunk, createEntityAdapter, createSelector, createSlice,} from "@reduxjs/toolkit";
import axios from "Services/AxiosService";
import _ from "lodash";
import {
    MAJ_MEMBRES_GROUPES,
    ADD_MEMBRES_GROUPES,
    DELETE_MEMBRES_GROUPES, EMP_SKILL_ADD,
    GET_ALL_GED_BY_CLASSIFICATION,
    GET_ALL_GED_CRITERIAS_AUTEUR,
    GET_ALL_GED_CRITERIAS_EMPLACEMENT,
    GET_ALL_GED_CRITERIAS_LOCALISATION,
    GET_ALL_GED_CRITERIAS_RESPONSABLE,
    GET_ALL_GED_CRITERIAS_TYPE_DOCUMENT,
    GET_ALL_GED_CRITERIAS_TYPE_SUPPORT,
    GET_ALL_GED_DOCUMENT_CRITERIAS,
    GET_ALL_GED_DOCUMENT_SEARCH,
    GET_ALL_GED_DOSSIER_CRITERIAS,
    GET_ALL_GED_DOSSIER_SEARCH,
    GET_ALL_GED_GROUPES,
    GET_ALL_GED_GROUPES_BY_EMPLOYE,
    GET_ALL_LOAD_DOC_CHANGEMENT,
    UPDATE_DOC_CHANGEMENT,
    GET_ALL_GED_LOAD_DOS_COTES, GET_CODE_AUTO_PATH, LOAD_DOS_COTE_PATH, ADD_GED_HISTORIQUE,
} from "store/UrlConstants";
import {listToQueryParam, paramsToQueryParam} from "../../UrlUtils";
import {showSnackbarMessage} from "store/slices/MessagesSystemSlice";
import moment from "moment";

// ========================================================================================
// ADAPTERS / ASYNC ACTIONS
// ========================================================================================
// ----------------------------------------------------------------------------------------
// Criterias
// ----------------------------------------------------------------------------------------
const gedCriteriaEmplacementAdapter = createEntityAdapter();

export const fetchAllGedEmplacement = createAsyncThunk(
  "Ged/Criterias/Emplacement/Get",
  async (codeSites) => {
    const response = await axios.get(
      GET_ALL_GED_CRITERIAS_EMPLACEMENT + listToQueryParam("sites", codeSites)
    );
    return response.data;
  }
);

export const gedEmplacementSelector = (store) =>
  store.gestDocumentaire.allGedEmplacement;

export const {
  selectAll: selectAllGedEmplacements,
  selectById: selectGedEmplacementById,
} = gedCriteriaEmplacementAdapter.getSelectors(gedEmplacementSelector);

export const selectLoadingGedEmplacement = createSelector(
  gedEmplacementSelector,
  (state) => state.loading
);

const gedCriteriaResponsableAdapter = createEntityAdapter();

export const fetchAllGedResponsable = createAsyncThunk(
  "Ged/Criterias/Responsable/Get",
  async (codeSites) => {
    const response = await axios.get(
      GET_ALL_GED_CRITERIAS_RESPONSABLE + listToQueryParam("sites", codeSites)
    );
    return response.data;
  }
);

export const gedResponsableSelector = (store) =>
  store.gestDocumentaire.allGedResponsable;

export const {
  selectAll: selectAllGedResponsables,
  selectById: selectGedResponsableById,
} = gedCriteriaResponsableAdapter.getSelectors(gedResponsableSelector);

export const selectLoadingGedResponsable = createSelector(
  gedResponsableSelector,
  (state) => state.loading
);

const gedCriteriaAuteurAdapter = createEntityAdapter();

export const fetchAllGedAuteur = createAsyncThunk(
  "Ged/Criterias/Auteur/Get",
  async (codeSites) => {
    const response = await axios.get(
      GET_ALL_GED_CRITERIAS_AUTEUR + listToQueryParam("sites", codeSites)
    );
    return response.data;
  }
);

export const gedAuteurSelector = (store) => store.gestDocumentaire.allGedAuteur;

export const {
  selectAll: selectAllGedAuteurs,
  selectById: selectGedAuteurById,
} = gedCriteriaAuteurAdapter.getSelectors(gedAuteurSelector);

export const selectLoadingGedAuteur = createSelector(
  gedAuteurSelector,
  (state) => state.loading
);

const gedCriteriaTypeDocumentAdapter = createEntityAdapter();

export const fetchAllGedTypeDocument = createAsyncThunk(
  "Ged/Criterias/TypeDocument/Get",
  async () => {
    const response = await axios.get(GET_ALL_GED_CRITERIAS_TYPE_DOCUMENT);
    return response.data;
  }
);

export const gedTypeDocumentSelector = (store) =>
  store.gestDocumentaire.allGedTypeDocument;

export const {
  selectAll: selectAllGedTypeDocuments,
  selectById: selectGedTypeDocumentById,
} = gedCriteriaTypeDocumentAdapter.getSelectors(gedTypeDocumentSelector);

export const selectLoadingGedTypeDocument = createSelector(
  gedTypeDocumentSelector,
  (state) => state.loading
);

const gedCriteriaTypeSupportAdapter = createEntityAdapter();

export const fetchAllGedTypeSupport = createAsyncThunk(
  "Ged/Criterias/TypeSupport/Get",
  async (codeSites) => {
    const response = await axios.get(
      GET_ALL_GED_CRITERIAS_TYPE_SUPPORT + listToQueryParam("sites", codeSites)
    );
    return response.data;
  }
);

export const gedTypeSupportSelector = (store) =>
  store.gestDocumentaire.allGedTypeSupport;

export const {
  selectAll: selectAllGedTypeSupports,
  selectById: selectGedTypeSupportById,
} = gedCriteriaTypeSupportAdapter.getSelectors(gedTypeSupportSelector);

export const selectLoadingGedTypeSupport = createSelector(
  gedTypeSupportSelector,
  (state) => state.loading
);

const gedCriteriaLocalisationAdapter = createEntityAdapter();

export const fetchAllGedLocalisation = createAsyncThunk(
  "Ged/Criterias/Localisation/Get",
  async () => {
    const response = await axios.get(GET_ALL_GED_CRITERIAS_LOCALISATION);
    return response.data;
  }
);

export const gedLocalisationSelector = (store) =>
  store.gestDocumentaire.allGedLocalisation;

export const {
  selectAll: selectAllGedLocalisations,
  selectById: selectGedLocalisationById,
} = gedCriteriaLocalisationAdapter.getSelectors(gedLocalisationSelector);

export const selectLoadingGedLocalisation = createSelector(
  gedLocalisationSelector,
  (state) => state.loading
);

// ----------------------------------------------------------------------------------------
//
// ----------------------------------------------------------------------------------------
const allByClassificationAdapter = createEntityAdapter({
  selectId: (entity) => entity.cote,
});

export const fetchDosCotesPath = createAsyncThunk(
  "Ged/LoadDosCotesPath/Get",
  async (codeAuto) => {
    const response = await axios.get(LOAD_DOS_COTE_PATH, {
      params: { codeAuto: codeAuto },
    });
    return response.data;
  }
);

export const fetchAllByClassification = createAsyncThunk(
  "Ged/AllByClassification/Get",
  async (codeAuto) => {
    const response = await axios.get(GET_ALL_GED_BY_CLASSIFICATION, {
      params: { codeAuto: codeAuto },
    });
    return response.data;
  }
);

export const fetchAllLoadCotes = createAsyncThunk(
  "Ged/LoadDosCotes/Get",
  async (params) => {
    const response = await axios.get(
      GET_ALL_GED_LOAD_DOS_COTES + paramsToQueryParam(params)
    );

    //retour premier rendu
    if (params.loadSites) {
      const arrayChildrenRoot = response.data.map((item) => item.dosCote);
      return {
        dosCote: 0,
        dossierFils: arrayChildrenRoot,
        responseData: response.data,
        loadSites: true
      };
    } else {
      return response.data;
    }
  }
);

export const allByClassificationSelector = (store) =>
  store.document.allByClassification;

export const {
  selectAll: selectAllByClassification,
  selectById: selectAllByClassificationById,
} = allByClassificationAdapter.getSelectors(
  (state) => state.document.allByClassification
);

export const selectLoadingAllByClassification = createSelector(
  allByClassificationSelector,
  (state) => state.loading
);

/**
 * @param {Object} {idDossier: int, lnk_zero: bool, messageId: string, remarque: string, indEmission: int, dateEvenement?: date}
 * @returns {Boolean} true || false
 */
export const addGedHistorique = createAsyncThunk("Ged/Documents/AddHistorique", async(params) => {
  const res = await axios.post(ADD_GED_HISTORIQUE + paramsToQueryParam(params));
  return res.data;  
})

// ----------------------------------------------------------------------------------------
// GedGroupes
// ----------------------------------------------------------------------------------------
const gedGroupeAdapter = createEntityAdapter();

export const fetchAllGedGroupes = createAsyncThunk(
  "Ged/Groupes/Get",
  async () => {
    const response = await axios.get(GET_ALL_GED_GROUPES);
    return response.data;
  }
);

export const gedGroupesSelector = (state) =>
  state.gestDocumentaire.allGedGroupes;

export const {
  selectAll: selectAllGedGroupes,
  selectById: selectGedGroupeById,
} = gedGroupeAdapter.getSelectors(gedGroupesSelector);

export const selectAllGedGroupesNotSysteme = createSelector(
  selectAllGedGroupes,
  (groupes) => groupes.filter((groupe) => groupe.indSysteme === 0)
);

export const selectLoadingGedGroupes = createSelector(
  gedGroupesSelector,
  (state) => state.loading
);

const gedGroupeByEmployeAdapter = createEntityAdapter();

export const fetchGedGroupeByEmploye = createAsyncThunk(
  "Ged/Groupes/GetByEmploye",
  async (noEmploye) => {
    const response = await axios.get(GET_ALL_GED_GROUPES_BY_EMPLOYE, {
      params: { noEmploye: noEmploye },
    });
    return response.data;
  }
);

export const gedGroupeByEmployeSelector = (state) =>
  state.gestDocumentaire.allGedGroupesByEmploye;

export const {
  selectAll: selectAllGedGroupesByEmploye,
  selectById: selectGedGroupeByEmployeById,
} = gedGroupeByEmployeAdapter.getSelectors(gedGroupeByEmployeSelector);

export const selectLoadingGedGroupesByEmpoloye = createSelector(
  gedGroupeByEmployeSelector,
  (state) => state.loading
);

// ----------------------------------------------------------------------------------------
// MembresGroupes
// ----------------------------------------------------------------------------------------
export const addMajMembresGroupes = createAsyncThunk(
  "Ged/MajMembresGroupes/Get",
  async (params) => {
    const response = await axios.get(
      MAJ_MEMBRES_GROUPES + paramsToQueryParam(params)
    );
    return response.data;
  }
);


export const addMembresGroupes = createAsyncThunk(
  "Ged/MembresGroupes/Add",
  async (membresGroupes, thunkAPI) => {
    try {
      const response = await axios.post(ADD_MEMBRES_GROUPES, membresGroupes);

      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      );

      return response.data;
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      );
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteMembresGroupes = createAsyncThunk(
  "Ged/MembresGroupes/Delete",
  async (membresGroupes, thunkAPI) => {
    try {
      const response = await axios.delete(DELETE_MEMBRES_GROUPES, {
        data: membresGroupes,
      });

      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      );

      return response.data;
    } catch (error) {
      thunkAPI.dispatch(
        showSnackbarMessage({
          message: error.response.data.messageText,
          severity: "error",
          autoHideDuration: 10000,
        })
      );
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// ----------------------------------------------------------------------------------------
// Recherche par document - critères de recherche
// ----------------------------------------------------------------------------------------
export const fetchDocumentCriterias = createAsyncThunk(
  "Ged/FetchDocumentCriterias/Get",
  async () => {
    const response = await axios.get(GET_ALL_GED_DOCUMENT_CRITERIAS);
    return response.data;
  }
);

const gedSearchDocumentsAdapter = createEntityAdapter({
  selectId: (documentList) => documentList.codeAutoincrement,
});

export const fetchAllSearchDocuments = createAsyncThunk(
    "Ged/Criterias/SearchDocuments/Get",
    async (docParams, {rejectWithValue, dispatch}) => {
        try {
            const response = await axios.post(GET_ALL_GED_DOCUMENT_SEARCH, docParams);
            return response.data;
        } catch (error) {
            dispatch(
                showSnackbarMessage({
                    message: error.response.data.messageText,
                    severity: "error",
                })
            );
            return rejectWithValue(error.response.data);
        }
    }
);

export const gedSearchDocumentsSelector = (store) =>
  store.gestDocumentaire.allGedSearchDocuments;

export const {
  selectAll: selectAllSearchDocuments,
  selectById: selectSearchDocumentById,
} = gedSearchDocumentsAdapter.getSelectors(gedSearchDocumentsSelector);

export const selectLoadingSearchDocuments = createSelector(
  gedSearchDocumentsSelector,
  (state) => state.loading
);

// ----------------------------------------------------------------------------------------
// Recherche par dossier - critères de recherche
// ----------------------------------------------------------------------------------------
export const fetchDossierCriterias = createAsyncThunk(
  "Ged/FetchDossierCriterias/Get",
  async () => {
    const response = await axios.get(GET_ALL_GED_DOSSIER_CRITERIAS);
    return response.data;
  }
);

const gedSearchDossiersAdapter = createEntityAdapter({
  selectId: (documentList) => documentList.codeAutoincrement,
});

export const fetchAllSearchDossiers = createAsyncThunk(
  "Ged/Criterias/SearchDossiers/Get",
  async (docParams, thunkAPI) => {
    try {
      const response = await axios.post(GET_ALL_GED_DOSSIER_SEARCH, docParams);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.messageId);
    }
  }
);

export const gedSearchDossiersSelector = (store) =>
  store.gestDocumentaire.allGedSearchDossier;

export const {
  selectAll: selectAllSearchDossiers,
  selectById: selectSearchDossierById,
} = gedSearchDossiersAdapter.getSelectors(gedSearchDossiersSelector);

export const selectLoadingSearchDossiers = createSelector(
  gedSearchDossiersSelector,
  (state) => state.loading
);

// ----------------------------------------------------------------------------------------
// Traitement 
// ----------------------------------------------------------------------------------------
export const fetchLoadDocChangement = createAsyncThunk(
  "Ged/FetchLoadDocChangement/Get",
  async (params) => {
    let id = 0
    const response = await axios.get(GET_ALL_LOAD_DOC_CHANGEMENT+ params);
    const addId = response.data.map((element)=> {
      id++
      return {...element, id: id.toString()}
    })
    return addId;
  }
);

const gedLoadDocChangementAdapter = createEntityAdapter({
  selectId: (documentList) => documentList.id,
});

export const gedLoadDocChangementSelector = (store) =>
  store.gestDocumentaire.allGedLoadDocChangement;

export const {
  selectAll: selectAllLoadDocChangement,
  selectById: selectLoadDocChangement,
} = gedLoadDocChangementAdapter.getSelectors(gedLoadDocChangementSelector);

export const selectLoadingLoadDocChangement = createSelector(
  gedLoadDocChangementSelector,
  (state) => state.loading
);

export const gedUpdateDocChangement = createAsyncThunk(
  "Ged/Traitement/UpdateDocChangement",
  async (params) => {
    const { champ, destination, gedDocChangements } = params;
    let response
    try {
      if(destination != undefined){
        response = await axios.post(UPDATE_DOC_CHANGEMENT + paramsToQueryParam({ champ, destination }), gedDocChangements);
      }else{
        response = await axios.post(UPDATE_DOC_CHANGEMENT + paramsToQueryParam({ champ}), gedDocChangements);
      }
      return response.data;
    } catch (error) {
      alert("Une erreur inattendue est survenue lors de l'opération <Changement de type de document>. Veuiller communiquer avec l'administrateur d'IsoVision. Merci de votre collaboration." );    }
  }
);


// ========================================================================================
// MAIN SLICE / ACTIONS / MIDLEWARES
// ========================================================================================
const initialState = {
  allCotes: {},
  allByClassification: allByClassificationAdapter.getInitialState({
    loading: false,
  }),
  allGedGroupes: gedGroupeAdapter.getInitialState({ loading: false }),
  allGedGroupesByEmploye: gedGroupeByEmployeAdapter.getInitialState({
    loading: false,
  }),
  allGedEmplacement: gedCriteriaEmplacementAdapter.getInitialState({
    loading: false,
  }),
  allGedResponsable: gedCriteriaResponsableAdapter.getInitialState({
    loading: false,
  }),
  allGedAuteur: gedCriteriaAuteurAdapter.getInitialState({ loading: false }),
  allGedTypeDocument: gedCriteriaTypeDocumentAdapter.getInitialState({
    loading: false,
  }),
  allGedTypeSupport: gedCriteriaTypeSupportAdapter.getInitialState({
    loading: false,
  }),
  allGedLocalisation: gedCriteriaLocalisationAdapter.getInitialState({
    loading: false,
  }),
  allGedSearchDocuments: gedSearchDocumentsAdapter.getInitialState({
    loading: false,
  }),
  allGedSearchDossier: gedSearchDossiersAdapter.getInitialState({
    loading: false,
  }),
  //
  //
  //
  //
  //
  allGedLoadDocChangement: gedLoadDocChangementAdapter.getInitialState({
    loading: false,
  })
  //
  //
  //
  //
  //
};

const gestDocumentaireSlice = createSlice({
  name: "gestDocumentaire",
  initialState,
  reducers: {
    upsertOneAllByClassification(state, action) {
      allByClassificationAdapter.upsertOne(
        state.allByClassification,
        action.payload
      );
    },
    resetSearchDocuments(state, action) {
      state.allGedSearchDocuments = gedSearchDocumentsAdapter.getInitialState({
        loading: false,
      })
    },
    resetSearchDossier(state, action) {
      state.allGedSearchDossier = gedSearchDossiersAdapter.getInitialState({
        loading: false,
      })
    },
  },
  extraReducers: {
    // ---------------------------------------------------------------------------------
    // Criterias
    // ---------------------------------------------------------------------------------
    [fetchAllGedEmplacement.pending]: (state, action) => {
      state.allGedEmplacement.loading = true;
    },
    [fetchAllGedEmplacement.rejected]: (state, action) => {
      state.allGedEmplacement.loading = false;
    },
    [fetchAllGedEmplacement.fulfilled]: (state, action) => {
      gedCriteriaEmplacementAdapter.setAll(
        state.allGedEmplacement,
        action.payload
      );
      state.allGedEmplacement.loading = false;
    },
    [fetchAllGedResponsable.pending]: (state, action) => {
      state.allGedResponsable.loading = true;
    },
    [fetchAllGedResponsable.rejected]: (state, action) => {
      state.allGedResponsable.loading = false;
    },
    [fetchAllGedResponsable.fulfilled]: (state, action) => {
      gedCriteriaResponsableAdapter.setAll(
        state.allGedResponsable,
        action.payload
      );
      state.allGedResponsable.loading = false;
    },
    [fetchAllGedAuteur.pending]: (state, action) => {
      state.allGedAuteur.loading = true;
    },
    [fetchAllGedAuteur.rejected]: (state, action) => {
      state.allGedAuteur.loading = false;
    },
    [fetchAllGedAuteur.fulfilled]: (state, action) => {
      gedCriteriaAuteurAdapter.setAll(state.allGedAuteur, action.payload);
      state.allGedAuteur.loading = false;
    },
    [fetchAllGedTypeDocument.pending]: (state, action) => {
      state.allGedTypeDocument.loading = true;
    },
    [fetchAllGedTypeDocument.rejected]: (state, action) => {
      state.allGedTypeDocument.loading = false;
    },
    [fetchAllGedTypeDocument.fulfilled]: (state, action) => {
      gedCriteriaTypeDocumentAdapter.setAll(
        state.allGedTypeDocument,
        action.payload
      );
      state.allGedTypeDocument.loading = false;
    },
    [fetchAllGedTypeSupport.pending]: (state, action) => {
      state.allGedTypeSupport.loading = true;
    },
    [fetchAllGedTypeSupport.rejected]: (state, action) => {
      state.allGedTypeSupport.loading = false;
    },
    [fetchAllGedTypeSupport.fulfilled]: (state, action) => {
      gedCriteriaTypeSupportAdapter.setAll(
        state.allGedTypeSupport,
        action.payload
      );
      state.allGedTypeSupport.loading = false;
    },
    [fetchAllGedLocalisation.pending]: (state, action) => {
      state.allGedLocalisation.loading = true;
    },
    [fetchAllGedLocalisation.rejected]: (state, action) => {
      state.allGedLocalisation.loading = false;
    },
    [fetchAllGedLocalisation.fulfilled]: (state, action) => {
      gedCriteriaTypeDocumentAdapter.setAll(
        state.allGedLocalisation,
        action.payload
      );
      state.allGedLocalisation.loading = false;
    },
    [fetchAllSearchDocuments.pending]: (state, action) => {
      state.allGedSearchDocuments.loading = true;
    },
    [fetchAllSearchDocuments.rejected]: (state, action) => {
      state.allGedSearchDocuments.loading = false;
    },
    [fetchAllSearchDocuments.fulfilled]: (state, action) => {
      gedSearchDocumentsAdapter.setAll(
        state.allGedSearchDocuments,
        action.payload
      );
      state.allGedSearchDocuments.loading = false;
    },
    [fetchAllSearchDossiers.pending]: (state, action) => {
      state.allGedSearchDossier.loading = true;
    },
    [fetchAllSearchDossiers.rejected]: (state, action) => {
      state.allGedSearchDossier.loading = false;
    },
    [fetchAllSearchDossiers.fulfilled]: (state, action) => {
      gedSearchDossiersAdapter.setAll(
        state.allGedSearchDossier,
        action.payload
      );
      state.allGedSearchDossier.loading = false;
    },

    [fetchLoadDocChangement.pending]: (state, action) => {
      state.allGedLoadDocChangement.loading = true;
    },
    [fetchLoadDocChangement.rejected]: (state, action) => {
      state.allGedLoadDocChangement.loading = false;
    },
    [fetchLoadDocChangement.fulfilled]: (state, action) => {
      gedLoadDocChangementAdapter.setAll(
        state.allGedLoadDocChangement,
        action.payload
      );
      state.allGedLoadDocChangement.loading = false;
    },

    // ---------------------------------------------------------------------------------
    //
    // ---------------------------------------------------------------------------------
    [fetchAllLoadCotes.fulfilled]: (state, action) => {
      if (action.payload.loadSites) {
        state.allCotes[action.payload.dosCote] = {};
        state.allCotes[action.payload.dosCote].dossierFils =
          action.payload.dossierFils.map((i) => i);
        state.allCotes[action.payload.dosCote].dosCote = action.payload.dosCote;
        action.payload.responseData.map((dossier) => {
          state.allCotes[dossier.dosCote] = {...dossier};
          state.allCotes[dossier.dosCote].dossierFils = dossier.dossierFils.map((i) => i.dosCote);
          dossier.dossierFils.map(
            (item) => (state.allCotes[item.dosCote] = item)
          );
        });
      } else {
        state.allCotes[action.payload[0].dosCote].dossierFils =
          action.payload[0].dossierFils.map((i) => i.dosCote);
        action.payload[0].dossierFils.map(
          (item) => (state.allCotes[item.dosCote] = item)
        );
      }
    },
    [fetchDosCotesPath.fulfilled]: (state, action) => {
      action.payload.forEach(dossier => {
        state.allCotes[dossier.dosCote] = {...dossier};
        state.allCotes[dossier.dosCote].dossierFils = dossier.dossierFils.map((i) => i.dosCote);
        dossier.dossierFils.map(
          (item) => (state.allCotes[item.dosCote] = item)
        );
      });
    },
    [fetchAllByClassification.pending]: (state, action) => {
      state.allByClassification.loading = true;
    },
    [fetchAllByClassification.rejected]: (state, action) => {
      state.allByClassification.loading = false;
    },
    [fetchAllByClassification.fulfilled]: (state, action) => {
      allByClassificationAdapter.setAll(
        state.allByClassification,
        action.payload
      );
      state.allByClassification.loading = false;
    },

    // ---------------------------------------------------------------------------------
    // GedGroupes
    // ---------------------------------------------------------------------------------
    [fetchAllGedGroupes.pending]: (state, action) => {
      state.allGedGroupes.loading = true;
    },
    [fetchAllGedGroupes.rejected]: (state, action) => {
      state.allGedGroupes.loading = false;
    },
    [fetchAllGedGroupes.fulfilled]: (state, action) => {
      gedGroupeAdapter.setAll(state.allGedGroupes, action.payload);
      state.allGedGroupes.loading = false;
    },
    [fetchGedGroupeByEmploye.pending]: (state, action) => {
      state.allGedGroupesByEmploye.loading = true;
    },
    [fetchGedGroupeByEmploye.rejected]: (state, action) => {
      state.allGedGroupesByEmploye.loading = false;
    },
    [fetchGedGroupeByEmploye.fulfilled]: (state, action) => {
      gedGroupeByEmployeAdapter.setAll(
        state.allGedGroupesByEmploye,
        action.payload
      );
      state.allGedGroupesByEmploye.loading = false;
    },
  },
});

export const { upsertOneAllByClassification, resetSearchDocuments, resetSearchDossier } = gestDocumentaireSlice.actions;

export default gestDocumentaireSlice.reducer;

export const treeSelector = (state) => state.document.allCotes;
export const nodeSelector = (nodeId) =>
  createSelector(treeSelector, (data) => data[nodeId]);
