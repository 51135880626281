import {
  createSlice,
  createAsyncThunk,
  createSelector,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import axios from 'Services/AxiosService';
import {
  POST_OBJECT_APPROVE, POST_OBJECT_UNAPPROVE, KNOWLEDGE_OBJECT_EVALUATE,
  GET_FILTERS_ALL_LOGGED,
  GET_OBJECT_TYPES,
  GET_OBJECT_RECENTS,
  GET_OBJECT_REQUEST,
  POST_LINK_REPORT,
  //POST_KNOWLEDGE_UPDATE_FAV,
  POST_KNOWLEDGE_FAVORITE,
  POST_KNOWLEDGE_UNFAVORITE,
  GET_AUTHORS,
  POST_FILTER_ADD,
  GET_KNOWLEDGE_OBJECT_NOTIFY,
  POST_KNOWLEDGE_COMMENT_ALL,
  POST_KNOWLEDGE_COMMENT_ADD,
  POST_UNIVERS_ADD,
  POST_UNIVERS_UPDATE,
  GET_UNIVERS_FILTER_ALL,
  POST_KNOWLEDGE_OBJECT_ADD,
  POST_OBJECT_ADDLINK,
  GET_GENERAL_ENDORSEMENT_TYPES,
  GET_UNIVERS_ONE,
  GET_OBJECT_SEARCH_COUNT, POST_KNOWLEDGE_UPDATE_IMAGE,
  GET_UC_OPTION_LIST,
  GET_UNIVERS_ONE_LOGGED,
} from 'store/UrlConstants';
import { paramsToQueryParam } from "../UrlUtils";
import Univers from "store/Models/Univers";
// import { getHeightAndWidthFromDataUrl } from "../../Utils/CommonFunctions";
import { showSnackbarMessage } from './MessagesSystemSlice';


/* ======================================================================= */
// Endorsement
/* ======================================================================= */
const endorsementTypesAdapter = createEntityAdapter();

export const {
  selectAll: selectAllEndorsementTypes,
  selectById: selectEndorsementTypeById,
} = endorsementTypesAdapter.getSelectors((state) => state.universe.knowledgeObjectEndorsementTypes);


/* ======================================================================= */
// ObjetConnaissance
/* ======================================================================= */
const knowledgeObjectCriteriaAuthorsAdapter = createEntityAdapter({ selectId: (element) => element.noEmploye });

export const {
  selectAll: selectAllKnowledgeObjectCriteriaAuthors,
  selectById: selectKnowledgeObjectCriteriaAuthorsById,
} = knowledgeObjectCriteriaAuthorsAdapter.getSelectors((state) => state.universe.knowledgeObjectCriteriaAuthors);

/* ======================================================================= */
// Universe
/* ======================================================================= */
export const fetchOneUniverse = createAsyncThunk('Universe/GetOne', async (params, { rejectWithValue }) => {
  try {
    const response = await axios.get(GET_UNIVERS_ONE, { params: params });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const getOneLoggedUnivers = createAsyncThunk('Universe/GetOneLogged', async ({ idDossier, maintenance = false }) => {
  const resp = await axios.get(GET_UNIVERS_ONE_LOGGED, { params: { idDossier: idDossier, maintenance: maintenance } });
  return resp.data;
})

export const addUpdateKmUniverse = createAsyncThunk('KnowledgeUniverse/AddUpdate', async ({ universe, mode }, thunkAPI) => {
  let idUniverse, kmUnivese;

  // Ajout / Edit de l'UC
  try {
    const url = mode === 'add' ? POST_UNIVERS_ADD : POST_UNIVERS_UPDATE;
    const response = await axios.post(url, universe);

    kmUnivese = response.data;
    idUniverse = kmUnivese.id;

    thunkAPI.dispatch(setKmUniverse(kmUnivese));

  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }

  const state = thunkAPI.getState();
  let messages = [];
  messages.push(state.messagesSystem.objetsLangues2ByLangue.entities['IW_MSG_UC_ENREGISTRE']?.valeurAffichee || '');
  let promises = [];

  // Si la miniature est présente, mettre en file l'appel API pour ça
  if (universe.selectedFile) {
    messages.push(state.messagesSystem.objetsLangues2ByLangue.entities['IW_MSG_UC_UPLOAD_MINIATURE']?.valeurAffichee || '');
    promises.push(uploadFile(thunkAPI, universe.selectedFile[0], `${POST_KNOWLEDGE_UPDATE_IMAGE}?idDossier=${idUniverse}`, 'addImage'));
  }

  thunkAPI.dispatch(showSnackbarMessage({
    message: messages.join("\n"),
    severity: "info",
  }));

  // Execution de l'ajout de fichier et de miniature
  await Promise.allSettled(promises).then(results => {
    let messages = [];
    let severity = 'success';
    results.forEach(result => {
      if (result.value.promise === 'addImage' && result.status === 'fulfilled') {
        messages.push(state.messagesSystem.objetsLangues2ByLangue.entities['IW_MSG_UC_UPLOAD_MINIATURE_DONE']?.valeurAffichee || '');
        thunkAPI.dispatch(setKmUniverse({ ...kmUnivese, hasThumbnail: true }));
      } else if (result.value.promise === 'addImage' && result.status === 'rejected') {
        severity = 'error';
        messages.push(state.messagesSystem.objetsLangues2ByLangue.entities['IW_MSG_UC_UPLOAD_MINIATURE_ERROR']?.valeurAffichee || '');
      }
    });

    if (messages.length) {
      thunkAPI.dispatch(showSnackbarMessage({
        message: messages.join('\n'),
        severity: severity,
      }));
    }
  });
});

function uploadFile(thunkAPI, file, url, promiseName) {
  let formData = new FormData();
  formData.append('file', file);

  try {
    return axios.post(url,
      formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
    }
    ).then(res => ({ res: res, promise: promiseName }));

  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
}

export const deleteKnowledgeImage = createAsyncThunk("Univers/Miniature/Delete", async (idDossier, thunkAPI) => {
  try {
    const response = await axios.post(POST_KNOWLEDGE_UPDATE_IMAGE, null, {
      params: { idDossier: idDossier },
      headers: { 'Content-Type': 'multipart/form-data' }
    })

    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "information_supprimee",
        severity: "success",
      })
    );

    return response.data;

  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getOptionsList = createAsyncThunk("Univers/OptionList/Get", async (idDossier, thunkAPI) => {
  const response = await axios.get(GET_UC_OPTION_LIST, { params: { idDossier: idDossier } });
  return response.data;
});

/* ------------------------------- Satnam -------------------------------- */
// Actions
export const postKnowledgeObjectAdd = createAsyncThunk('universe/KnowledgeObjectAdd', async (item) => {
  const response = await axios.post(POST_KNOWLEDGE_OBJECT_ADD, item);
  return response.data;
});

export const getAllKnowledgeObjFilters = createAsyncThunk('universe/KnowledgeObjectFiltersALL', async () => {
  const response = await axios.get(GET_FILTERS_ALL_LOGGED);
  return response.data;
});

export const getKnowledgeUniversFilterAll = createAsyncThunk('universe/KnowledgeUniversFilterAll', async () => {
  const response = await axios.get(GET_UNIVERS_FILTER_ALL);
  return response.data;
});

export const getKnowledgeObjectNotify = createAsyncThunk('universe/KnowledgeObjectNotify', async () => {
  const response = await axios.get(GET_KNOWLEDGE_OBJECT_NOTIFY);
  return response.data;
});

export const getKnowledgeObjectCriteriaAuthors = createAsyncThunk('universe/KnowledgeObjectCriteriaAuthors', async () => {
  const response = await axios.get(GET_AUTHORS);
  return response.data;
});

export const getKnowledgeObjectCriteriaTypes = createAsyncThunk('universe/KnowledgeObjectCriteriaTypes', async () => {
  const response = await axios.get(GET_OBJECT_TYPES);
  return response.data;
});

export const postKnowledgeCommentAll = createAsyncThunk('universe/KnowledgeCommentAll', async () => {
  const response = await axios.post(POST_KNOWLEDGE_COMMENT_ALL);
  return response.data;
});

export const postKnowledgeCommentAdd = createAsyncThunk('universe/KnowledgeCommentAdd', async () => {
  const response = await axios.post(POST_KNOWLEDGE_COMMENT_ADD);
  return response.data;
});

export const getKnowledgeObjectEndorsementTypes = createAsyncThunk('universe/KnowledgeObjectEndorsersTypes', async () => {
  const response = await axios.get(GET_GENERAL_ENDORSEMENT_TYPES);
  return response.data;
});

export const getKnowledgeObjectRecents = createAsyncThunk('universe/KnowledgeObjectRecents', async () => {
  const response = await axios.get(GET_OBJECT_RECENTS);
  return response.data;
});

export const getKnowledgeObjectSearchCount = createAsyncThunk('universe/KnowledgeObjectSearchCount', async (params) => {
  const response = await axios.get(GET_OBJECT_SEARCH_COUNT + paramsToQueryParam(params));
  return response.data;
});

export const postKnowledgeObjectApprove = createAsyncThunk('universe/KnowledgeObjectApprove', async params => {
  const response = await axios.post(POST_OBJECT_APPROVE, params);
  return response.data;
});

export const postKnowledgeObjectUnapprove = createAsyncThunk('universe/KnowledgeObjectUnapprove', async params => {
  const response = await axios.post(POST_OBJECT_UNAPPROVE, params);
  return response.data;
});

export const getKnowledgeObjectRequest = createAsyncThunk('universe/KnowledgeObjectRequest', async () => {
  const response = await axios.get(GET_OBJECT_REQUEST);
  return response.data;
});

export const postKnowledgeObjFilterAdd = createAsyncThunk('universe/KnowledgeObjectFilterAdd', async () => {
  const response = await axios.post(POST_FILTER_ADD);
  return response.data;
});

export const postKnowledgeObjectEvaluation = createAsyncThunk('universe/KnowledgeObjectEvaluation', async params => {
  const response = await axios.post(KNOWLEDGE_OBJECT_EVALUATE, params);
  return response.data;
});

export const postKnowledgeLinkAdd = createAsyncThunk('universe/KnowledgeLinkAdd', async params => {
  const response = await axios.post(POST_OBJECT_ADDLINK, params);
  return response.data;
});

export const postKnowledgeLinkReport = createAsyncThunk('universe/KnowledgeLinkReport', async params => {
  const response = await axios.post(POST_LINK_REPORT, params);
  return response.data;
});

export const postKnowledgeUpdateFav = createAsyncThunk('universe/KnowledgeUpdateFav', async (item) => {
  const response = await axios.post(item.favorite ? POST_KNOWLEDGE_FAVORITE : POST_KNOWLEDGE_UNFAVORITE, { paramInt: item.id });
  return { updated: response.data, itemUpdated: item };
});


/* ======================================================================= */
// Main Slice
/* ======================================================================= */
const initialStates = {
  knowledgeObjectSearchCount: 0,
  knowledgeObjectEndorsementTypes: endorsementTypesAdapter.getInitialState(),
  universe: Univers(),
  knowledgeObjectCriteriaAuthors: knowledgeObjectCriteriaAuthorsAdapter.getInitialState(),

  /* ------------------------------- Satnam -------------------------------- */
  knowledgeObjectUpdate: [],
  knowledgeObjectAdd: [],
  knowledgeLinkALl: [],
  knowledgeLinkAdd: [],
  knowledgeUniversDelete: [],
  knowledgeUniversFilterAll: [],
  knowledgeObjFiltersAll: [],
  knowledgeObjCriteriaTypes: [],
  knowledgeObjectRecents: [],
  knowledgeObjectApprove: {},
  knowledgeObjectUnapprove: null,
  knowledgeObjectRequest: null,
  knowledgeObjectValidation: null,
  knowledgeObjectConfirmation: null,
  knowledgeObjectEvaluation: {},
  knowledgeObjectDelete: null,
  knowledgeLinkReport: null,
  knowledgeObjCriteriaPublicationStatuses: [],
  knowledgeUpdateFav: [],
  authors: [],
  filterAdd: [],
  knowledgeObjectNotify: [],
  knowledgeCommentAll: [],
  knowledgeCommentAdd: [],
  universAdd: [],
  universUpdate: [],

}

const UniverseSlice = createSlice({
  name: 'universe',
  initialState: initialStates,
  reducers: {
    setKmUniverse(state, action) {
      state.universe = action.payload;
    },
    updateUCField(state, action) {
      state.universe[action.payload.field] = action.payload.value;
    },
    resetUniverse(state, action) {
      state.universe = Univers();
    },
  },
  extraReducers: {
    /* ----------------------------------------------------------------------- */
    // ObjetConnaissance
    /* ----------------------------------------------------------------------- */
    [getKnowledgeObjectEndorsementTypes.fulfilled]: (state, action) => {
      endorsementTypesAdapter.setAll(state.knowledgeObjectEndorsementTypes, action.payload);
    },

    /* ----------------------------------------------------------------------- */
    // Universe
    /* ----------------------------------------------------------------------- */
    [fetchOneUniverse.fulfilled]: (state, action) => {
      state.universe = action.payload;
    },
    [getOneLoggedUnivers.fulfilled]: (state, action) => {
      state.universe = action.payload;
    },

    /* ------------------------------- Satnam -------------------------------- */
    [getKnowledgeObjectCriteriaAuthors.fulfilled]: (state, action) => {
      knowledgeObjectCriteriaAuthorsAdapter.setAll(state.knowledgeObjectCriteriaAuthors, action.payload);
    },
    [postKnowledgeObjectAdd.fulfilled]: (state, action) => {
      state.knowledgeObjectAdd = action.payload;
    },
    [getKnowledgeUniversFilterAll.fulfilled]: (state, action) => {
      state.knowledgeUniversFilterAll = action.payload;
    },
    [postKnowledgeCommentAll.fulfilled]: (state, action) => {
      state.knowledgeCommentAll = action.payload;
    },
    [postKnowledgeCommentAdd.fulfilled]: (state, action) => {
      state.knowledgeCommentAdd = action.payload;
    },
    [getAllKnowledgeObjFilters.fulfilled]: (state, action) => {
      state.knowledgeObjFiltersAll = action.payload;
    },
    [getKnowledgeObjectNotify.fulfilled]: (state, action) => {
      state.knowledgeObjectNotify = action.payload;
    },
    [getKnowledgeObjectCriteriaTypes.fulfilled]: (state, action) => {
      state.knowledgeObjCriteriaTypes = action.payload;
    },
    [getKnowledgeObjectRecents.fulfilled]: (state, action) => {
      state.knowledgeObjectRecents = action.payload;
    },
    [getKnowledgeObjectSearchCount.fulfilled]: (state, action) => {
      state.knowledgeObjectSearchCount = action.payload;
    },
    [postKnowledgeObjectApprove.fulfilled]: (state, action) => {
      state.knowledgeObjectApprove = action.payload;
    },
    [postKnowledgeObjectUnapprove.fulfilled]: (state, action) => {
      state.knowledgeObjectUnapprove = action.payload;
    },
    [getKnowledgeObjectRequest.fulfilled]: (state, action) => {
      state.knowledgeObjectRequest = action.payload;
    },
    [postKnowledgeObjectEvaluation.fulfilled]: (state, action) => {
      state.knowledgeObjectEvaluation = action.payload;
    },
    [postKnowledgeLinkAdd.fulfilled]: (state, action) => {
      state.knowledgeLinkAdd = action.payload;
    },
    [postKnowledgeLinkReport.fulfilled]: (state, action) => {
      state.knowledgeLinkReport = action.payload;
    },
    [postKnowledgeObjFilterAdd.fulfilled]: (state, action) => {
      state.knowledgeObjFilterAdd = action.payload;
    }
  }
});


// Selectors/Store states
export const universeSelector = state => state.universe

export const selectUniverse = createSelector(universeSelector, slice => slice.universe)

export const selectKnowledgeObjFiltersAll = createSelector(universeSelector, items => items.knowledgeObjFiltersAll)

export const selectKnowledgeObjectAdd = createSelector(universeSelector, items => items.knowledgeObjectAdd)

export const selectKnowledgeObjectUpdate = createSelector(universeSelector, items => items.knowledgeObjectUpdate)

export const selectKnowledgeLinkAll = createSelector(universeSelector, items => items.knowledgeLinkALl)

export const selectKnowledgeUniversDelete = createSelector(universeSelector, items => items.knowledgeUniversDelete)

export const selectKnowledgeUniversFilterAll = createSelector(universeSelector, items => items.knowledgeUniversFilterAll)

export const selectKnowledgeObjCriteriaTypes = createSelector(universeSelector, types => types.knowledgeObjCriteriaTypes)

export const selectKnowledgeCommentAll = createSelector(universeSelector, types => types.knowledgeCommentAll)

export const selectKnowledgeCommentAdd = createSelector(universeSelector, types => types.knowledgeCommentAdd)

export const selectKnowledgeObjectRecents = createSelector(universeSelector, recents => recents.knowledgeObjectRecents)

export const selectKnowledgeObjectSearch = createSelector(universeSelector, results => results.knowledgeObjectSearch)

export const selectKnowledgeObjectApprove = createSelector(universeSelector, results => results.knowledgeObjectApprove)

export const selectKnowledgeObjectUnapprove = createSelector(universeSelector, results => results.knowledgeObjectUnapprove)

export const selectKnowledgeObjectNotify = createSelector(universeSelector, results => results.knowledgeObjectNotify)

export const selectKnowledgeObjectRequest = createSelector(universeSelector, results => results.knowledgeObjectRequest)

export const selectKnowledgeObjectSubscribe = createSelector(universeSelector, results => results.knowledgeObjectSubscribe)

export const selectKnowledgeObjectUnsubscribe = createSelector(universeSelector, results => results.knowledgeObjectUnsubscribe)

export const selectKnowledgeObjectConfirmation = createSelector(universeSelector, confirmation => confirmation.knowledgeObjectConfirmation)

export const selectKnowledgeObjectEvaluation = createSelector(universeSelector, evaluation => evaluation.knowledgeObjectEvaluation)

export const selectKnowledgeObjectDelete = createSelector(universeSelector, result => result.knowledgeObjectDelete)

export const selectKnowledgeLinkAdd = createSelector(universeSelector, result => result.knowledgeLinkAdd)

export const selectKnowledgeLinkReport = createSelector(universeSelector, result => result.knowledgeLinkReport)

export const selectKnowledgeObjCriteriaPublicationStatuses = createSelector(universeSelector, statuses => statuses.knowledgeObjCriteriaPublicationStatuses)

export const selectKnowledgeUpdateFav = createSelector(universeSelector, result => result.knowledgeUpdateFav)

export const selectKnowledgeObjFilterAdd = createSelector(universeSelector, result => result.knowledgeObjFilterAdd)

export const selectKnowledgeObjectSearchCount = createSelector(universeSelector, result => result.knowledgeObjectSearchCount)

export const { setKmUniverse, updateUCField, resetUniverse } = UniverseSlice.actions;

export default UniverseSlice.reducer;