import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import axios from 'Services/AxiosService';
import { paramsToQueryParam } from 'store/UrlUtils';
import { NIGHTWORKER, COURRIEL } from 'store/UrlConstants';

export const loadEmailMessage = createAsyncThunk('loadEmailMessage', async (params) => {
  if (params["EmailVar"]) {
    params["EmailVar"] = JSON.stringify(params["EmailVar"])
  }
  const response = await axios.get(COURRIEL + "Message/" + paramsToQueryParam(params));
  return response.data;
});

export const sendEmail = createAsyncThunk("sendEmail", async (courriel = {}, thunkAPI) => {
  if (Object.keys(courriel).length){
    thunkAPI.dispatch(addEmail(courriel))
  }  
  const courriels = thunkAPI.getState().courriel.courriels
  thunkAPI.dispatch(resetEmails())
//  const response = await axios.post(NIGHTWORKER + 'addEmailTasks', emails );
  const response = await axios.post(COURRIEL + 'addTasks', courriels );
  return response.data;
})
  
const initialState = {
  courriels: [],
  message: {},
  courrielProp: {
    subject: '',
    from: '',
    to: [],
    cc: [],
    ccc: [],
    msgBrut: '',
    msgHtml: '', 
    subjectAppName: false
  } 
};

function getEmail(courriel = {}) {
  return  {...initialState.courrielProp, ...courriel }
}

const courrielSlice = createSlice({
  name: 'courriel',
  initialState,
  reducers: {
    addEmail(state, action) {
      state.courriels.push(getEmail(action.payload))
    },
    resetEmails(state) {
      state.courriels = []
    },
  },
  extraReducers: {
    [loadEmailMessage.fulfilled]: (state, action) => {
      state.message = action.payload
    },
  },
});

export const { addEmail, resetEmails } = courrielSlice.actions;

export default courrielSlice.reducer;