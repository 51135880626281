export default function InspectionSearchCriteria(_searchCriteria) {
    let searchCriteria = {};

    searchCriteria.globalSearchTerm = (_searchCriteria && _searchCriteria.globalSearchTerm) ? _searchCriteria.globalSearchTerm : "";
    searchCriteria.titre = (_searchCriteria && _searchCriteria.titre) ? _searchCriteria.titre : null;
    searchCriteria.autreIdentifiant = (_searchCriteria && _searchCriteria.autreIdentifiant) ? _searchCriteria.autreIdentifiant : null;
    searchCriteria.codeProcede = (_searchCriteria && _searchCriteria.codeProcede) ? _searchCriteria.codeProcede : null;
    searchCriteria.codeUnite = (_searchCriteria && _searchCriteria.codeUnite) ? _searchCriteria.codeUnite : null;
    searchCriteria.codeComposante = (_searchCriteria && _searchCriteria.codeComposante) ? _searchCriteria.codeComposante : null;
    searchCriteria.codePtEchantillon = (_searchCriteria && _searchCriteria.codePtEchantillon) ? _searchCriteria.codePtEchantillon : null;
    searchCriteria.sites = (_searchCriteria && _searchCriteria.sites) ? _searchCriteria.sites : [];
    searchCriteria.typeInspectionId = (_searchCriteria && _searchCriteria.typeInspectionId) ? _searchCriteria.typeInspectionId : [];
    searchCriteria.voletId = (_searchCriteria && _searchCriteria.voletId) ? _searchCriteria.voletId : [];
    searchCriteria.personneRessource = (_searchCriteria && _searchCriteria.personneRessource) ? _searchCriteria.personneRessource : [];

    return searchCriteria;
}