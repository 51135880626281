/**
 * @param {object} univers
 * @returns 
 */
export default function Univers(univers) {
  return {
    abonne: univers ? univers.abonne : false,
    abonneCount: univers ? univers.abonneCount : 0,
    consultationCount: univers ? univers.consultationCount : 0,
    cote: univers ? univers.cote : null,
    dateConsultation: univers ? univers.dateConsultation : null,
    dateCreation: univers ? univers.dateCreation : null,
    dateMaj: univers ? univers.dateMaj : null,
    description: univers ? univers.description : '',
    dosDtEntreeEnVigueur: univers ? univers.dosDtEntreeEnVigueur : null,
    favorite: univers ? univers.favorite : false,
    gecDemandeQte: univers ? univers.gecDemandeQte : 1,
    gecDemandeType: univers ? univers.gecDemandeType : "",
    hasThumbnail: univers ? univers.hasThumbnail : false,
    id: univers ? univers.id : null,
    idEmploye: univers ? univers.idEmploye : null,
    idPere: univers ? univers.idPere : null,
    idPoste: univers ? univers.idPoste : null,
    localisation: univers ? univers.localisation : null,
    qteDocument: univers ? univers.qteDocument : 0,
    qteDocumentEndosse: univers ? univers.qteDocumentEndosse : 0,
    qteDocumentExpirer: univers ? univers.qteDocumentExpirer : 0,
    qteDocumentNonEndosse: univers ? univers.qteDocumentNonEndosse : 0,
    qteDossierAssocier: univers ? univers.qteDossierAssocier : 0,
    secondaryCopyExist: univers ? univers.secondaryCopyExist : false,
    shareCount: univers ? univers.shareCount : 0,
    site: univers ? univers.site : '',
    title: univers ? univers.title : '',
    typeDoc: univers ? univers.typeDoc : null,

    evaluationPersonnel: univers ? univers.evaluationPersonnel : null,
    evaluationMoyenne: univers ? univers.evaluationMoyenne : null,
    dateEvenement: univers ? univers.dateEvenement : null,
  }
}