import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import axios from "Services/AxiosService";
import { ANALYSE_DERISQUES_GET_CRITERES_MULTI_INFO } from "store/UrlConstants";
import { paramsToQueryParam } from "store/UrlUtils";

export const getCriteresMultiInfo = createAsyncThunk(
    "AnalyseDeRisques/GetActionCriteresMultiInfo",
    async (params, thunkAPI) => {
        const response = await axios.get(
            ANALYSE_DERISQUES_GET_CRITERES_MULTI_INFO + paramsToQueryParam(params)
        );
        return response.data;
    }
);

const allCriteresMultiInfoAdapter = createEntityAdapter({
    selectId: (entity) => entity.dataValue,
});

const allVoletsAdapater = createEntityAdapter({
    selectId: (entity) => entity.dataValue,
});
const allResponsablesAdapater = createEntityAdapter({
    selectId: (entity) => entity.dataValue,
});
const allStatutsAdapater = createEntityAdapter({
    selectId: (entity) => entity.dataValue,
});
const allReunionsAdapater = createEntityAdapter({
    selectId: (entity) => entity.dataValue,
});
const allComitesAdapater = createEntityAdapter({
    selectId: (entity) => entity.dataValue,
});
const allOriginesAdapater = createEntityAdapter({
    selectId: (entity) => entity.dataValue,
});
const allDefaultsAdapter = createEntityAdapter({
    selectId: (entity) => entity.dataValue,
});

const initialState = {
    actionsObjets: allCriteresMultiInfoAdapter.getInitialState(),
    volets: allVoletsAdapater.getInitialState(),
    responsables: allResponsablesAdapater.getInitialState(),
    statuts: allStatutsAdapater.getInitialState(),
    reunions: allReunionsAdapater.getInitialState(),
    comites: allComitesAdapater.getInitialState(),
    origines: allOriginesAdapater.getInitialState(),
    defaults: allDefaultsAdapter.getInitialState(),
};

const getCritereMultiInfoSlice = createSlice({
    name: "riskAnalysisGetCriteresMultiInfo",
    initialState,
    reducers: {},
    extraReducers: {
        [getCriteresMultiInfo.pending]: (state, action) => {
            state.loading = true;
        },
        [getCriteresMultiInfo.fulfilled]: (state, action) => {
            allCriteresMultiInfoAdapter.setAll(
                state.actionsObjets,
                action.payload
            );
            let vole = [];
            let resp = [];
            let stat = [];
            let reun = [];
            let comi = [];
            let orig = [];
            let defa = [];

            let actionsPayload = action.payload;

            actionsPayload.map((action) => {
                switch (action.typeInfo) {
                    case "VOLE":
                        vole.push({ ...action, default: false });
                        break;
                    case "VOLE_DEF":
                        vole.push({ ...action, default: true });
                        defa.push({ ...action, default: true });
                        break;
                    case "RESP":
                        resp.push({ ...action, default: false });
                        break;
                    case "RESP_DEF":
                        resp.push({ ...action, default: true });
                        defa.push({ ...action, default: true });
                        break;
                    case "STAT":
                        stat.push({ ...action, default: false });
                        break;
                    case "STAT_DEF":
                        stat.push({ ...action, default: true });
                        defa.push({ ...action, default: true });
                        break;
                    case "REUN":
                        reun.push({ ...action, default: false });
                        break;
                    case "REUN_DEF":
                        reun.push({ ...action, default: true });
                        defa.push({ ...action, default: true });
                        break;
                    case "COMI":
                        comi.push({ ...action, default: false });
                        break;
                    case "COMI_DEF":
                        comi.push({ ...action, default: true });
                        defa.push({ ...action, default: true });
                        break;
                    case "ORIG":
                        orig.push({ ...action, default: false });
                        break;
                    case "ORIG_DEF":
                        orig.push({ ...action, default: true });
                        defa.push({ ...action, default: true });
                        break;
                    default:
                        break;
                }
            });
            allVoletsAdapater.setAll(state.volets, vole);
            allResponsablesAdapater.setAll(state.responsables, resp);
            allStatutsAdapater.setAll(state.statuts, stat);
            allReunionsAdapater.setAll(state.reunions, reun);
            allComitesAdapater.setAll(state.comites, comi);
            allOriginesAdapater.setAll(state.origines, orig);
            allDefaultsAdapter.setAll(state.defaults, defa);
        },
    }
});

export const { selectAll: selectAllVolets } = allVoletsAdapater.getSelectors(
    (state) => state.riskAnalysisGetCriteresMultiInfo.volets
);
export const { selectAll: selectAllResponsables } = allResponsablesAdapater.getSelectors(
    (state) => state.riskAnalysisGetCriteresMultiInfo.responsables
);
export const { selectAll: selectAllStatuts } = allStatutsAdapater.getSelectors(
    (state) => state.riskAnalysisGetCriteresMultiInfo.statuts
);
export const { selectAll: selectAllReunions } =
    allReunionsAdapater.getSelectors(
        (state) => state.riskAnalysisGetCriteresMultiInfo.reunions
    );
export const { selectAll: selectAllComites } = allComitesAdapater.getSelectors(
    (state) => state.riskAnalysisGetCriteresMultiInfo.comites
);
export const { selectAll: selectAllOrigines } =
    allOriginesAdapater.getSelectors(
        (state) => state.riskAnalysisGetCriteresMultiInfo.origines
    );
export const { selectAll: selectAllARDefaults } = allDefaultsAdapter.getSelectors(
    (state) => state.riskAnalysisGetCriteresMultiInfo.defaults
);

export default getCritereMultiInfoSlice.reducer;