import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
} from "@reduxjs/toolkit";
import axios from "Services/AxiosService";
import { DELETE_ACTION_PERF_REVIEW, UPDATE_ACTION_PERF_REVIEW, ADD_ACTION_PERF_REVIEW } from "store/UrlConstants";

export const deleteAction = createAsyncThunk(
    "RevuePerformance/DeleteAction",
    async (params,thunkAPI) => {
        try{
        const response = await axios.get(
            DELETE_ACTION_PERF_REVIEW + "?codeAutoAction=" + params
        );
        return response.data}
        catch(error){
            return error
        }
    }
)

export const addAction = createAsyncThunk(
    "RevuePerformance/AddAction",
    async(newAction={},thunkAPI)=>{
        const response = await axios.post(ADD_ACTION_PERF_REVIEW,newAction)
        return response.data
    }
)

export const updateAction = createAsyncThunk(
    "RevuePerformance/UpdateAction",
    async(updatedAction={},thunkAPI)=>{
        const response = await axios.post(UPDATE_ACTION_PERF_REVIEW,updatedAction)
        return response.data
    }
)

export const ActionsVisibleSlice=createSlice({
    name:"visible",
    initialState:[],
    reducers:{
        handleAdd:(state,id)=>{
            state=state.push(Math.max(...state)+1)
            
            
        },
        handleDelete:(state,e)=>{
            const temp=state.filter(action=>
                action!=e.payload
            )
            state=temp
            return(state)
        },
        handleLoad:(state,e)=>{
            state=state.push(parseInt(e.payload))
        }

    }
});
export const { handleAdd, handleDelete, handleLoad } = ActionsVisibleSlice.actions;
export default ActionsVisibleSlice.reducer