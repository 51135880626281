import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import axios from "Services/AxiosService";
import {
  GET_GENERAL_CONSULTATION_UNITS,
  GET_GENERAL_DISPLAYS,
  GET_GENERAL_OBJECT_SORTS,
  GET_GENERAL_UNIVERS_SORTS,
  POST_KNOWLEDGE_SHARE,
  REQUEST_LIST,
  GET_CONFIG_GEC,
  UPDATE_CONFIG_GEC
} from "store/UrlConstants";
import { updateUCListItemField } from '../KM/KnowledgeUniverseSearchSlice';
import { updateOCListItemField } from '../KM/KnowledgeObjectSearchSlice';
import { showSnackbarMessage } from '../MessagesSystemSlice';

const consultationUnitsAdapter = createEntityAdapter();
const displayAdapter = createEntityAdapter();
const objectSortAdapter = createEntityAdapter();
const universSortAdapter = createEntityAdapter();

export const getGeneralUniversSorts = createAsyncThunk('kmGeneral/UniversSorts', async () => {
  const response = await axios.get(GET_GENERAL_UNIVERS_SORTS);
  return response.data;
});

export const getGeneralObjectSorts = createAsyncThunk('kmGeneral/ObjectSorts', async () => {
  const response = await axios.get(GET_GENERAL_OBJECT_SORTS);
  return response.data;
});

export const getGeneralDisplays = createAsyncThunk('kmGeneral/Displays', async () => {
  const response = await axios.get(GET_GENERAL_DISPLAYS);
  return response.data;
});

export const getGeneralRequestList = createAsyncThunk("KmGeneral/RequestList", async (idDocument) => {
  const response = await axios.get(REQUEST_LIST, { params: { idDocument: idDocument } });
  return response.data;
});

export const shareKnowledge = createAsyncThunk('kmGeneral/Share', async (params, thunkAPI) => {
  try {
    const response = await axios.post(POST_KNOWLEDGE_SHARE, params);
    const isUniverse = thunkAPI.getState().kmUniverseSearch.universeList.ids.includes(response.data.idDossier);
    const isObject = thunkAPI.getState().kmObjectSearch.objectList.ids.includes(response.data.idDossier);

    if (isUniverse) {
      const oldShareCount = thunkAPI.getState().kmUniverseSearch.universeList.entities[response.data.idDossier].shareCount;
      const newShareCount = oldShareCount + response.data.dossierPartageEmployes.length;

      thunkAPI.dispatch(updateUCListItemField({ id: response.data.idDossier, field: "shareCount", value: newShareCount }))
    } else if (isObject) {
      const oldShareCount = thunkAPI.getState().kmObjectSearch.objectList.entities[response.data.idDossier].shareCount;
      const newShareCount = oldShareCount + response.data.dossierPartageEmployes.length;

      thunkAPI.dispatch(updateOCListItemField({ id: response.data.idDossier, field: "shareCount", value: newShareCount }))
    }

    thunkAPI.dispatch(
      showSnackbarMessage({
        messageId: "connaissance_partagee_succes",
        severity: "success",
      })
    );

    return response.data;

  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const getGeneralConsultationUnits = createAsyncThunk('kmGeneral/GeneralConsultationUnits', async () => {
  const response = await axios.get(GET_GENERAL_CONSULTATION_UNITS);
  return response.data;
});

export const getGecConfig = createAsyncThunk("kmGeneral/Config/Get", async () => {
  const response = await axios.get(GET_CONFIG_GEC);
  return response.data;
});

export const updateGecConfig = createAsyncThunk("kmGeneral/Config/Update", async (configs, thunkAPI) => {
  try {
    const response = await axios.post(UPDATE_CONFIG_GEC, configs);

    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );

    return response.data;

  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const {
  selectAll: selectAllUniversSorts,
  selectById: selectUniversSortById,
} = universSortAdapter.getSelectors((state) => state.kmGeneral.universSorts);

export const {
  selectAll: selectAllObjectSorts,
  selectById: selectObjectSortById,
} = objectSortAdapter.getSelectors((state) => state.kmGeneral.objectSorts);

export const {
  selectAll: selectAllDisplays,
  selectById: selectDisplayById,
} = displayAdapter.getSelectors((state) => state.kmGeneral.displays);

export const {
  selectAll: selectAllConsultationUnits,
  selectById: selectConsultationUnitById,
} = consultationUnitsAdapter.getSelectors((state) => state.kmGeneral.consultationUnits);

const initialStates = {
  universSorts: universSortAdapter.getInitialState(),
  objectSorts: objectSortAdapter.getInitialState(),
  displays: displayAdapter.getInitialState(),
  consultationUnits: consultationUnitsAdapter.getInitialState(),
  filtersAutoHide: true,
  showKmUniversFilters: false,
  showKmObjectFilters: false,
  filterMyFavorites: false,
  moduleConfigs: {
    indAfficherGecDansGed: false,
    indAfficherKmObjNonEndosses: true,
    indAfficherKmObjExpires: true,
  }
};

const KmGeneralSlice = createSlice({
  name: 'kmGeneral',
  initialState: initialStates,
  reducers: {
    setFilterAutoHide(state, action) {
      state.filtersAutoHide = action.payload;
    },
    setShowKmUniversFilters(state, action) {
      state.showKmUniversFilters = action.payload;
    },
    setShowKmObjectFilters(state, action) {
      state.showKmObjectFilters = action.payload;
    },
    setFilterMyFavorites(state, action) {
      state.filterMyFavorites = action.payload;
    }
  },
  extraReducers: {
    [getGeneralUniversSorts.fulfilled]: (state, action) => {
      universSortAdapter.setAll(state.universSorts, action.payload);
    },
    [getGeneralObjectSorts.fulfilled]: (state, action) => {
      objectSortAdapter.setAll(state.objectSorts, action.payload);
    },
    [getGeneralDisplays.fulfilled]: (state, action) => {
      displayAdapter.setAll(state.displays, action.payload)
    },
    [getGeneralConsultationUnits.fulfilled]: (state, action) => {
      consultationUnitsAdapter.setAll(state.consultationUnits, action.payload)
    },
    [getGecConfig.fulfilled]: (state, action) => {
      state.moduleConfigs = action.payload
    },
    [updateGecConfig.fulfilled]: (state, action) => {
      state.moduleConfigs = action.payload
    },
  }
});

export const { setFilterAutoHide, setShowKmUniversFilters, setShowKmObjectFilters, setFilterMyFavorites } = KmGeneralSlice.actions;

export default KmGeneralSlice.reducer;