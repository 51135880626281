import {
  createSlice,
  createAsyncThunk,
  createSelector,
  createEntityAdapter
} from '@reduxjs/toolkit';
import axios from 'Services/AxiosService';
import { FETCH_KM_TODO_ALL, FETCH_KM_NEWS_ALL, FETCH_ALL_SLIDER_ITEM, FETCH_REQUEST_SETCHOICE, FETCH_KM_REQUESTS_ALL, FETCH_KM_REQUESTS_ALL_CODE } from 'store/UrlConstants';
import { paramsToQueryParam } from "../UrlUtils";

const todoAllAdapter = createEntityAdapter({
  selectId: (entity) => entity.title,
});
const newsAllAdapter = createEntityAdapter({});
const requestAllAdapter = createEntityAdapter({});
const requestAllCodeAdapter = createEntityAdapter({ selectId: (entity) => entity.systemCode });


export const {
  selectAll: selectAllTodos,
  selectById: selectTodoById,
} = todoAllAdapter.getSelectors((state) => state.home.todos);

export const fetchTodoAll = createAsyncThunk('home/todoAll', async () => {
  let response = await axios.get(FETCH_KM_TODO_ALL);
  return response.data;
});


export const {
  selectAll: selectAllNews,
  selectById: selectNewsById,
} = newsAllAdapter.getSelectors((state) => state.home.news);


export const fetchNewsAll = createAsyncThunk('home/newsAll', async () => {
  let response = await axios.get(FETCH_KM_NEWS_ALL);
  return response.data;
});


export const {
  selectAll: selectAllRequests,
  selectById: selectRequestById,
} = requestAllAdapter.getSelectors((state) => state.home.requests);


export const {
  selectAll: selectAllRequestsCode,
  selectById: selectRequestCodeById,
} = requestAllCodeAdapter.getSelectors((state) => state.home.requestsCode);


export const fetchRequestAll = createAsyncThunk('home/requestAll', async () => {
  let response = await axios.get(FETCH_KM_REQUESTS_ALL);
  return response.data;
});


export const fetchRequestAllCode = createAsyncThunk('home/requestAllCode', async () => {
  let response = await axios.get(FETCH_KM_REQUESTS_ALL_CODE);
  return response.data;
});


export const fetchAllSliderItem = createAsyncThunk('home/SliderItem', async (params) => {
  let response = await axios.get(FETCH_ALL_SLIDER_ITEM + paramsToQueryParam(params));
  return response.data;
});

export const fetchRequestSetChoice = createAsyncThunk('home/SetChoice', async (choiceDecision, thunkAPI) => {
  try {
    let response = await axios.post(FETCH_REQUEST_SETCHOICE, choiceDecision);
    
    const requestSystemCode = thunkAPI.getState().home.requests.entities[choiceDecision.requestId].requestSystemCode;
    const requestCode = thunkAPI.getState().home.requestsCode.entities[requestSystemCode];

    thunkAPI.dispatch(deleteOneRequest(choiceDecision.requestId));
    thunkAPI.dispatch(upsertOneRequestCode({...requestCode, count: requestCode.count - 1}));
    
    return response.data;
    
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});


const HomeSlice = createSlice({
  name: 'home',
  initialState: {
    todos: todoAllAdapter.getInitialState(),
    news: newsAllAdapter.getInitialState(),
    requests: requestAllAdapter.getInitialState(),
    requestsCode: requestAllCodeAdapter.getInitialState(),
    sliderItem: {},
    RequestSetChoice: {}
  },
  reducers: {
    deleteOneRequest(state, action) {
      requestAllAdapter.removeOne(state.requests, action.payload);
    },
    resetRequestAskingReason(state, action) {
      state.requests.entities[action.payload.id].choices.forEach(c => c.askingReason = false);
    },
    upsertOneRequestCode(state, action) {
      requestAllCodeAdapter.upsertOne(state.requestsCode, action.payload);
    },
    setChoiceAskingReason(state, action) {
      state.requests.entities[action.payload.idRequest].choices.forEach(c => c.askingReason = false);
      state.requests.entities[action.payload.idRequest].choices.find(c => c.id === action.payload.idChoice).askingReason = action.payload.value;
    },
    setRequestReason(state, action) {
      state.requests.entities[action.payload.id].raisonStatut = action.payload.value;
    },
    updateRequestField(state, action) {
      state.requests.entities[action.payload.idRequest][action.payload.field] = action.payload.value;
    }
  },
  extraReducers: {
    [fetchTodoAll.fulfilled]: (state, action) => {
      todoAllAdapter.setAll(state.todos, action.payload);
    },
    [fetchNewsAll.fulfilled]: (state, action) => {
      newsAllAdapter.setAll(state.news, action.payload);
    },
    [fetchRequestAll.fulfilled]: (state, action) => {
      requestAllAdapter.setAll(state.requests, action.payload);
    },
    [fetchRequestAllCode.fulfilled]: (state, action) => {
      requestAllCodeAdapter.setAll(state.requestsCode, action.payload);
    },
    [fetchAllSliderItem.fulfilled]: (state, action) => {
      state.sliderItem = action.payload;
    },
    [fetchRequestSetChoice.fulfilled]: (state, action) => {
      state.RequestSetChoice = action.payload;
    }
  }

});


export const { deleteOneRequest, resetRequestAskingReason, setRequestReason, setChoiceAskingReason, upsertOneRequestCode, updateRequestField } = HomeSlice.actions

export const homeSelector = state => state.home

export const selectSliderItems = createSelector(homeSelector, newsItem => newsItem.sliderItem)

export const selectRequestSetChoice = createSelector(homeSelector, newsItem => newsItem.RequestSetChoice)

export default HomeSlice.reducer;