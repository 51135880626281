import axios from 'Services/AxiosService';
import {
  createAsyncThunk,
  createSlice
} from '@reduxjs/toolkit';
import { PRODUIT_SEARCH } from 'store/UrlConstants';
import { paramsToQueryParam } from 'store/UrlUtils';

//ACTIONS 
export const searchProduit = createAsyncThunk('Produit/Search', async (params) => { 
  const response = await axios.get(PRODUIT_SEARCH + paramsToQueryParam(params));
  return response.data;
});


const initialState = {
}
//REDUCER
const ProduitSlice = createSlice({
  name: 'produit',
  initialState,
  reducers: {},
  extraReducers: {
  }
});

//SELECTORS/ STORE STATES

export const ProduitSelector = state => state.produit

export default ProduitSlice.reducer;
