import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter
} from '@reduxjs/toolkit';
import axios from 'Services/AxiosService';
import {
    FETCH_ALL_OBJETS_LANGUES_2_BY_LANGUE, UPDATE_OBJETS_LANGUES_2,
    FETCH_ALL_MESSAGES_LANGUES_BY_LANGUE, FETCH_ALL_OBJETS_LANGUES_2_BY_LANGUE_LOGGED_OUT,
} from 'store/UrlConstants';
import { paramsToQueryParam } from "../UrlUtils";


/* ======================================================================= */
// Messages_Langues dba
/* ======================================================================= */
const messagesLanguesAdapter = createEntityAdapter({
    selectId: (entity) => entity.messageId,
});

export const fetchAllMessagesLanguesByLangue = createAsyncThunk('MessagesSysteme/MessagesLangues/GetAllByLangue', async () => {
    const response = await axios.get(FETCH_ALL_MESSAGES_LANGUES_BY_LANGUE);
    return response.data;
})

export const {
    selectAll: selectAllMessagesLanguesByLangue,
    selectById: selectMessagesLanguesById,
} = messagesLanguesAdapter.getSelectors((state) => state.messagesSysteme.messagesLanguesByLangue);


/* ======================================================================= */
// ObjetsLangues2 dba
/* ======================================================================= */
const objetsLangues2Adapter = createEntityAdapter({
    selectId: (entity) => entity.code,
});

export const fetchAllObjetsLangues2ByLangue = createAsyncThunk("MessagesSysteme/ObjetsLangues2/GetAllByLangue", async (params) => {
    let response;
    if (params?.loggedOut) {
        response = await axios.get(FETCH_ALL_OBJETS_LANGUES_2_BY_LANGUE_LOGGED_OUT + paramsToQueryParam({ language: params.lang }));
    } else {
        response = await axios.get(FETCH_ALL_OBJETS_LANGUES_2_BY_LANGUE);
    }

    return response.data;
});

export const updateObjetsLangues2 = createAsyncThunk("MessagesSysteme/ObjetsLangues2/Update", async (objetsLangues2, { rejectWithValue }) => {
    try {
        const response = await axios.put(UPDATE_OBJETS_LANGUES_2, objetsLangues2)
        return { updated: response.data, objetsLangues2Updated: objetsLangues2 };

    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

export const {
    selectAll: selectAllObjetsLangues2ByLangue,
    selectById: selectObjetsLangues2ByLangueById,
} = objetsLangues2Adapter.getSelectors((state) => state.messagesSysteme.objetsLangues2ByLangue);


/* ======================================================================= */
// Main Slice
/* ======================================================================= */
const initialState = {
    messagesLanguesByLangue: messagesLanguesAdapter.getInitialState(),
    objetsLangues2ByLangue: objetsLangues2Adapter.getInitialState(),
    snackbar: {
        show: false,
        dba: "messagesLangues",
        message: "",
        severity: "info",
        autoHideDuration: 6000,
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
    },
    alertDialog: {
        show: false,
        dba: "messagesLangues",
        title: "",
        message: "",
        btnLabel: { cancel: "Non", confirm: "Oui" },
        dialogAction: "",
        clickedButton: "",
        data: "",
    },
    loadingMessagesLangues: false,
    loadingObjetsLangues: false
};

const messagesSystemSlice = createSlice({
    name: "alertMessages",
    initialState,
    reducers: {
        showSnackbarMessage(state, action) {
            const { dba, messageId, message, severity, autoHideDuration, anchorOrigin, replacementStrings } = action.payload;

            if (dba === "objetsLangues2") {
                state.snackbar.message = messageId ? state.objetsLangues2ByLangue.entities[messageId.toUpperCase()]?.valeurAffichee : message;
            } else {
                let msg = messageId ? state.messagesLanguesByLangue.entities[messageId.toUpperCase()]?.text : message;

                if (replacementStrings) {
                    replacementStrings.map((string) => {
                        msg = msg?.replace('%s', string);
                    });
                }

                state.snackbar.message = msg
            }

            state.snackbar.severity = severity;
            state.snackbar.autoHideDuration = autoHideDuration ? autoHideDuration : initialState.snackbar.autoHideDuration;
            state.snackbar.anchorOrigin = anchorOrigin ? anchorOrigin : initialState.snackbar.anchorOrigin;
            state.snackbar.show = true;
        },
        hideSnackbarMessage(state) {
            state.snackbar = initialState.snackbar;
        },
        showAlertDialog(state, action) {
            const { dba, type, messageId, message, btnLabel, dialogAction, data, replacementStrings } = action.payload;

            const alertType = {
                confirmation: "iw_dlog_alert_title_confirmation",
                error: "iw_dlog_alert_title_error",
                info: "iw_dlog_alert_title_info",
                success: "iw_dlog_alert_title_success",
                warning: "iw_dlog_alert_title_warning"                
            }

            state.alertDialog.title = type ? state.objetsLangues2ByLangue.entities[alertType[type]?.toUpperCase()]?.valeurAffichee : "";

            let msg;
            if (dba === "objetsLangues2") {
                msg = messageId ? state.objetsLangues2ByLangue.entities[messageId.toUpperCase()]?.valeurAffichee : message;
            } else {
                msg = messageId ? state.messagesLanguesByLangue.entities[messageId.toUpperCase()]?.text : message;
            }

            if (replacementStrings) {
                replacementStrings.map((string) => {
                    msg = msg.replace('%s', string);
                });
            }

            state.alertDialog.message = msg;

            const btnConfirm = state.objetsLangues2ByLangue.entities.CODE_OUI?.valeurAffichee;
            const btnCancel = state.objetsLangues2ByLangue.entities.CODE_NON?.valeurAffichee

            state.alertDialog.btnLabel = btnLabel ? btnLabel : { cancel: btnCancel || "Non", confirm: btnConfirm || "Oui" };
            state.alertDialog.dialogAction = dialogAction ? dialogAction : initialState.alertDialog.dialogAction;
            state.alertDialog.data = data ? data : initialState.alertDialog.data;
            state.alertDialog.show = true;
        },
        hideAlertDialog(state, action) {
            state.alertDialog.show = false;
            const { clickedButton } = action.payload;
            if (typeof clickedButton === 'string') {
                state.alertDialog.clickedButton = clickedButton;
            }
        },
        resetAlertDialog(state) {
            state.alertDialog = initialState.alertDialog;
        }
    },
    extraReducers: {
        /* ----------------------------------------------------------------------- */
        // MessagesLangues dba
        /* ----------------------------------------------------------------------- */
        [fetchAllMessagesLanguesByLangue.fulfilled]: (state, action) => {
            messagesLanguesAdapter.setAll(state.messagesLanguesByLangue, action.payload);
            state.loadingMessagesLangues = false;
        },
        [fetchAllMessagesLanguesByLangue.pending]: (state, action) => {
            state.loadingMessagesLangues = true;
        },

        /* ----------------------------------------------------------------------- */
        // ObjetsLangues2 dba
        /* ----------------------------------------------------------------------- */
        [fetchAllObjetsLangues2ByLangue.fulfilled]: (state, action) => {
            objetsLangues2Adapter.upsertMany(state.objetsLangues2ByLangue, action.payload);
            state.loadingObjetsLangues = false;
        },
        [fetchAllObjetsLangues2ByLangue.pending]: (state, action) => {
            state.loadingObjetsLangues = true;
        },
    }
});

export const { showSnackbarMessage, hideSnackbarMessage, showAlertDialog, hideAlertDialog, resetAlertDialog } = messagesSystemSlice.actions;

export default messagesSystemSlice.reducer;