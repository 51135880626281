/**
 * @param {object} kmObj
 * @returns 
 */
export default function KmObject (kmObj) {
  return {
    abonne: kmObj ? kmObj.abonne : false,
    abonneCount: kmObj ? kmObj.abonneCount : 0,
    approuved: kmObj ? kmObj.approuved : false,
    approuvedCount: kmObj ? kmObj.approuvedCount : 0,
    commentCount: kmObj ? kmObj.commentCount : 0,
    commentExist: kmObj ? kmObj.commentExist : false,
    confirmationEnabled: kmObj ? kmObj.confirmationEnabled : false,
    confirmationReasonRequired: kmObj ? kmObj.confirmationReasonRequired : false,
    consultationCount: kmObj ? kmObj.consultationCount : 0,
    cote: kmObj ? kmObj.cote : "",
    dateConfirmation: kmObj ? kmObj.dateConfirmation : "",
    dateConfirmationClose: kmObj ? kmObj.dateConfirmationClose : "",
    dateConfirmationOpen: kmObj ? kmObj.dateConfirmationOpen : "",
    dateConsultation: kmObj ? kmObj.dateConsultation : "",
    dateMaj: kmObj ? kmObj.dateMaj : "",
    dateRevision: kmObj ? kmObj.dateRevision : "",
    description: kmObj ? kmObj.description : "",
    evaluationCount: kmObj ? kmObj.evaluationCount : 0,
    evaluationMoyenne: kmObj ? kmObj.evaluationMoyenne : null,
    evaluationPersonnel: kmObj ? kmObj.evaluationPersonnel : null,
    favorite: kmObj ? kmObj.favorite : false,
    gecDemandeQte: kmObj ? kmObj.gecDemandeQte : null,
    gecDemandeType: kmObj ? kmObj.gecDemandeType : null,
    hasThumbnail: kmObj ? kmObj.hasThumbnail : false,
    id: kmObj ? kmObj.id : null,
    idAuteur: kmObj ? kmObj.idAuteur : null,
    idEmploye: kmObj ? kmObj.idEmploye : null,
    idPere: kmObj ? kmObj.idPere : null,
    idResponsable: kmObj ? kmObj.idResponsable : null,
    indGec: kmObj ? kmObj.indGec : 0,
    indTypeEndossement: kmObj ? kmObj.indTypeEndossement : null,
    keyDoc: kmObj ? kmObj.keyDoc : "",
    lnkId: kmObj ? kmObj.lnkId : null,
    localisation: kmObj ? kmObj.localisation : "",
    natureChangement: kmObj ? kmObj.natureChangement ?? "" : "",
    requestCount: kmObj ? kmObj.requestCount : 0,
    requestExist: kmObj ? kmObj.requestExist : false,
    revalidateEnabled: kmObj ? kmObj.revalidateEnabled : false,
    secondaryCopyExist: kmObj ? kmObj.secondaryCopyExist : false,
    shareCount: kmObj ? kmObj.shareCount : 0,
    site: kmObj ? kmObj.site : "",
    staDesc: kmObj ? kmObj.staDesc : "",
    title: kmObj ? kmObj.title : "",
    titlePere: kmObj ? kmObj.titlePere : "",
    typeDoc: kmObj ? kmObj.typeDoc : "",
    version: kmObj ? kmObj.version : "",
  }
}