import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'Services/AxiosService';
import { paramsToQueryParam } from "store/UrlUtils";
import {
  ANALYSE_DERISQUES_LOAD_PROCEDELISTE,
  ANALYSE_DERISQUES_GET_PROCEDE,
  ANALYSE_DERISQUES_GET_ARBORESCENSE_INFO,
  ANALYSE_DERISQUES_ADD_PROCEDE,
  ANALYSE_DERISQUES_UPDATE_PROCEDE,
  ANALYSE_DERISQUES_DELETE_PROCEDE,
  ANALYSE_DERISQUES_PROCEDE_CHANGE_ACTIVE,
  ANALYSE_DERISQUES_LOAD_ARBORESCENSE,
  ANALYSE_DERISQUES_LOAD_UNITES_LISTE,
  ANALYSE_DERISQUES_GET_UNITE,
  ANALYSE_DERISQUES_LOAD_UNITES,
  ANALYSE_DERISQUES_ADD_UNITE,
  ANALYSE_DERISQUES_UPDATE_UNITE,
  ANALYSE_DERISQUES_DELETE_UNITE,
  ANALYSE_DERISQUES_UNITE_CHANGE_ACTIVE,
  ANALYSE_DERISQUES_LOAD_COMPOSANTES_LISTE,
  ANALYSE_DERISQUES_LOAD_COMPOSANTES,
  ANALYSE_DERISQUES_GET_COMPOSANTE,
  ANALYSE_DERISQUES_ADD_COMPOSANTE,
  ANALYSE_DERISQUES_UPDATE_COMPOSANTE,
  ANALYSE_DERISQUES_DELETE_COMPOSANTE,
  ANALYSE_DERISQUES_COMPOSANTE_CHANGE_ACTIVE,
  ANALYSE_DERISQUES_LOAD_TYPE_COMPOSANTES_LISTE,
  ANALYSE_DERISQUES_LOAD_PT_ECHANTILLONS,
  ANALYSE_DERISQUES_GET_PT_ECHANTILLON,
  ANALYSE_DERISQUES_ADD_PT_ECHANTILLON,
  ANALYSE_DERISQUES_UPDATE_PT_ECHANTILLON,
  ANALYSE_DERISQUES_DELETE_PT_ECHANTILLON,
  ANALYSE_DERISQUES_PT_ECHANTILLON_CHANGE_ACTIVE,
  ANALYSE_DERISQUES_LOAD_PT_ECHANTILLON_LISTE
} from 'store/UrlConstants';

import { showSnackbarMessage } from "store/slices/MessagesSystemSlice";

const initialState = {
  loadingInProgress: false,
  procedesliste: [],
  unitesListe: [],
  composantesListe: [],
  typeComposantesListe: [],
  ptEchantillonListe: [],
};

export const getArborescenseInfo = createAsyncThunk('analyseDeRisques/getArborescenseInfo', async (params, thunkAPI) => {
  try{
    const { data } = await axios.get(ANALYSE_DERISQUES_GET_ARBORESCENSE_INFO + paramsToQueryParam(params));
    return data;
  } catch(e){
    console.log(e, 'E')
  }
});

export const loadArborescense = createAsyncThunk('analyseDeRisques/LoadArborescense', async (params, thunkAPI) => {
  try{
    const { data } = await axios.get(ANALYSE_DERISQUES_LOAD_ARBORESCENSE + paramsToQueryParam(params));
    return data;
  } catch(e){
    console.log(e, 'E')
  }
});

export const loadProcedesListe = createAsyncThunk('analyseDeRisques/LoadProcedesListe', async (params, thunkAPI) => {
  try{
    const { data } = await axios.get(ANALYSE_DERISQUES_LOAD_PROCEDELISTE);
    return data;
  } catch(e){
    console.log(e, 'E')
  }
});

export const getProcede = createAsyncThunk('analyseDeRisques/getProcede', async (params, thunkAPI) => {
  try{
    const { data } = await axios.get(ANALYSE_DERISQUES_GET_PROCEDE + paramsToQueryParam(params));
    return data;
  } catch(e){
    console.log(e, 'E')
  }
});

export const addProcede = createAsyncThunk("formations/AddProcede", async (data, thunkAPI) => {
  try {
    const response = await axios.post(ANALYSE_DERISQUES_ADD_PROCEDE, data);
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const updateProcede = createAsyncThunk("competence/updateProcede", async (postBody, thunkAPI) => {
  try {
      const response = await axios.put(ANALYSE_DERISQUES_UPDATE_PROCEDE, postBody);
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      );
      return response.data;
  }
  catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    // return rejectWithValue(error.response.data);
  }
});

export const deleteProcede = createAsyncThunk("competence/DeleteProcede", async (params, { rejectWithValue }) => {
  try {
    const response = await axios.delete(ANALYSE_DERISQUES_DELETE_PROCEDE + paramsToQueryParam(params));
    return response.data;
  }
  catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const procedeChangeActive = createAsyncThunk('analyseDeRisques/ProcedeChangeActive', async (params, thunkAPI) => {
  try{
    const { data } = await axios.get(ANALYSE_DERISQUES_PROCEDE_CHANGE_ACTIVE + paramsToQueryParam(params));
    return data;
  } catch(e){
    console.log(e, 'E')
  }
});


export const loadUnitesListe = createAsyncThunk('analyseDeRisques/LoadUnitesListe', async (params, thunkAPI) => {
  try{
    const { data } = await axios.get(ANALYSE_DERISQUES_LOAD_UNITES_LISTE);
    return data;
  } catch(e){
    console.log(e, 'E')
  }
});

export const loadUnites = createAsyncThunk('analyseDeRisques/LoadUnites', async (params, thunkAPI) => {
  try{
    const { data } = await axios.get(ANALYSE_DERISQUES_LOAD_UNITES + paramsToQueryParam(params));
    return data;
  } catch(e){
    console.log(e, 'E')
  }
});

export const getUnite = createAsyncThunk('analyseDeRisques/getUnite', async (params, thunkAPI) => {
  try{
    const { data } = await axios.get(ANALYSE_DERISQUES_GET_UNITE + paramsToQueryParam(params));
    return data;
  } catch(e){
    console.log(e, 'E')
  }
});

export const addUnite = createAsyncThunk("formations/AddUnite", async (data, thunkAPI) => {
  try {
    const response = await axios.post(ANALYSE_DERISQUES_ADD_UNITE, data);
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const updateUnite = createAsyncThunk("competence/updateUnite", async (postBody, thunkAPI) => {
  try {
      const response = await axios.put(ANALYSE_DERISQUES_UPDATE_UNITE, postBody);
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      );
      return response.data;
  }
  catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    // return rejectWithValue(error.response.data);
  }
});

export const deleteUnite = createAsyncThunk("competence/DeleteUnite", async (params, { rejectWithValue }) => {
  try {
    const response = await axios.delete(ANALYSE_DERISQUES_DELETE_UNITE + paramsToQueryParam(params));
    return response.data;
  }
  catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const uniteChangeActive = createAsyncThunk('analyseDeRisques/uniteChangeActive', async (params, thunkAPI) => {
  try{
    const { data } = await axios.get(ANALYSE_DERISQUES_UNITE_CHANGE_ACTIVE + paramsToQueryParam(params));
    return data;
  } catch(e){
    console.log(e, 'E')
  }
});

export const loadComposantes = createAsyncThunk('analyseDeRisques/LoadComposantes', async (params, thunkAPI) => {
  try{
    const { data } = await axios.get(ANALYSE_DERISQUES_LOAD_COMPOSANTES + paramsToQueryParam(params));
    return data;
  } catch(e){
    console.log(e, 'E')
  }
});

export const getComposante = createAsyncThunk('analyseDeRisques/GetComposante', async (params, thunkAPI) => {
  try{
    const { data } = await axios.get(ANALYSE_DERISQUES_GET_COMPOSANTE + paramsToQueryParam(params));
    return data;
  } catch(e){
    console.log(e, 'E')
  }
});

export const addComposante = createAsyncThunk("formations/AddComposante", async (data, thunkAPI) => {
  try {
    const response = await axios.post(ANALYSE_DERISQUES_ADD_COMPOSANTE, data);
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const updateComposante = createAsyncThunk("competence/updateComposante", async (postBody, thunkAPI) => {
  try {
      const response = await axios.put(ANALYSE_DERISQUES_UPDATE_COMPOSANTE, postBody);
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      );
      return response.data;
  }
  catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    // return rejectWithValue(error.response.data);
  }
});

export const deleteComposante = createAsyncThunk("competence/DeleteComposante", async (params, { rejectWithValue }) => {
  try {
    const response = await axios.delete(ANALYSE_DERISQUES_DELETE_COMPOSANTE + paramsToQueryParam(params));
    return response.data;
  }
  catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const composanteChangeActive = createAsyncThunk('analyseDeRisques/ComposanteChangeActive', async (params, thunkAPI) => {
  try{
    const { data } = await axios.get(ANALYSE_DERISQUES_COMPOSANTE_CHANGE_ACTIVE + paramsToQueryParam(params));
    return data;
  } catch(e){
    console.log(e, 'E')
  }
});

export const loadComposantesListe = createAsyncThunk('analyseDeRisques/LoadComposantesListe', async () => {
  try{
    const { data } = await axios.get(ANALYSE_DERISQUES_LOAD_COMPOSANTES_LISTE);
    return data;
  } catch(e){
    console.log(e, 'E')
  }
});

export const loadTypeComposantesListe = createAsyncThunk('analyseDeRisques/loadTypeComposantesListe', async (params, thunkAPI) => {
  try{
    const { data } = await axios.get(ANALYSE_DERISQUES_LOAD_TYPE_COMPOSANTES_LISTE + paramsToQueryParam(params));
    return data;
  } catch(e){
    console.log(e, 'E')
  }
});

export const loadPtEchantillons = createAsyncThunk('analyseDeRisques/LoadPtEchantillons', async (params, thunkAPI) => {
  try{
    const { data } = await axios.get(ANALYSE_DERISQUES_LOAD_PT_ECHANTILLONS + paramsToQueryParam(params));
    return data;
  } catch(e){
    console.log(e, 'E')
  }
});

export const getPtEchantillon = createAsyncThunk('analyseDeRisques/GetPtEchantillon', async (params, thunkAPI) => {
  try{
    const { data } = await axios.get(ANALYSE_DERISQUES_GET_PT_ECHANTILLON + paramsToQueryParam(params));
    return data;
  } catch(e){
    console.log(e, 'E')
  }
});

export const addPtEchantillon = createAsyncThunk("formations/AddPtEchantillon", async (data, thunkAPI) => {
  try {
    const response = await axios.post(ANALYSE_DERISQUES_ADD_PT_ECHANTILLON, data);
    thunkAPI.dispatch(
      showSnackbarMessage({
        dba: "objetsLangues2",
        messageId: "web_confirmation_enregistrement",
        severity: "success",
      })
    );
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
  }
});

export const updatePtEchantillon = createAsyncThunk("competence/UpdatePtEchantillon", async (postBody, thunkAPI) => {
  try {
      const response = await axios.put(ANALYSE_DERISQUES_UPDATE_PT_ECHANTILLON, postBody);
      thunkAPI.dispatch(
        showSnackbarMessage({
          dba: "objetsLangues2",
          messageId: "web_confirmation_enregistrement",
          severity: "success",
        })
      );
      return response.data;
  }
  catch (error) {
    thunkAPI.dispatch(
      showSnackbarMessage({
        message: error.response.data.messageText,
        severity: "error",
        autoHideDuration: 10000,
      })
    );
    // return rejectWithValue(error.response.data);
  }
});

export const deletePtEchantillon = createAsyncThunk("competence/DeletePtEchantillon", async (params, { rejectWithValue }) => {
  try {
    const response = await axios.delete(ANALYSE_DERISQUES_DELETE_PT_ECHANTILLON + paramsToQueryParam(params));
    return response.data;
  }
  catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const ptEchantillonChangeActive = createAsyncThunk('analyseDeRisques/PtEchantillonChangeActive', async (params, thunkAPI) => {
  try{
    const { data } = await axios.get(ANALYSE_DERISQUES_PT_ECHANTILLON_CHANGE_ACTIVE + paramsToQueryParam(params));
    return data;
  } catch(e){
    console.log(e, 'E')
  }
});

export const loadPtEchantillonListe = createAsyncThunk('analyseDeRisques/LoadPtEchantillonListe', async () => {
  try{
    const { data } = await axios.get(ANALYSE_DERISQUES_LOAD_PT_ECHANTILLON_LISTE);
    return data;
  } catch(e){
    console.log(e, 'E')
  }
});

const AnalyseDeRisquesSlice = createSlice({
  name: 'analyseDeRisques',
  initialState,
  reducers: {},
  extraReducers: {
    [loadProcedesListe.fulfilled]: (state, action) => {
      state.loadingInProgress = false
      if(Array.isArray(action.payload)){
        state.procedesliste = [...action.payload];
      }
    },
    [loadProcedesListe.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [loadProcedesListe.rejected]: (state, action) => {
      state.loadingInProgress = false
    },
    [addProcede.fulfilled]: (state, action) => {
      state.loadingInProgress = false
    },
    [addProcede.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [addProcede.rejected]: (state, action) => {
      state.loadingInProgress = false
    },
    [updateProcede.fulfilled]: (state, action) => {
      state.loadingInProgress = false
      // state.examenInfo = {...action.payload};
    },
    [updateProcede.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [updateProcede.rejected]: (state, action) => {
      state.loadingInProgress = false
    },
    [loadUnitesListe.fulfilled]: (state, action) => {
      state.loadingInProgress = false
      if(Array.isArray(action.payload)){
        state.unitesListe = [...action.payload];
      }
    },
    [loadUnitesListe.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [loadUnitesListe.rejected]: (state, action) => {
      state.loadingInProgress = false
    },
    [loadComposantesListe.fulfilled]: (state, action) => {
      state.loadingInProgress = false
      if(Array.isArray(action.payload)){
        state.composantesListe = [...action.payload];
      }
    },
    [loadComposantesListe.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [loadComposantesListe.rejected]: (state, action) => {
      state.loadingInProgress = false
    },
    [loadTypeComposantesListe.fulfilled]: (state, action) => {
      state.loadingInProgress = false
      if(Array.isArray(action.payload)){
        state.typeComposantesListe = [...action.payload];
      }
    },
    [loadTypeComposantesListe.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [loadTypeComposantesListe.rejected]: (state, action) => {
      state.loadingInProgress = false
    },
    [loadPtEchantillonListe.fulfilled]: (state, action) => {
      state.loadingInProgress = false
      if(Array.isArray(action.payload)){
        state.ptEchantillonListe = [...action.payload];
      }
    },
    [loadPtEchantillonListe.pending]: (state, action) => {
      state.loadingInProgress = true
    },
    [loadPtEchantillonListe.rejected]: (state, action) => {
      state.loadingInProgress = false
    },
  },
});

export const prepareSelector = state => state.analyseDeRisques;

export default AnalyseDeRisquesSlice.reducer;
