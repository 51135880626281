const LocalStorageService = (function () {
  let _service;

  function _getService() {
    if (!_service) {
      _service = this;
      return _service
    }
    return _service
  }

  const setItem = (key, value) => localStorage.setItem(key, JSON.stringify(value))
  const getItem = key => JSON.parse(localStorage.getItem(key))
  const removeItem = key => localStorage.removeItem(key)

  function _setLanguage(language) {
    localStorage.setItem('language', JSON.stringify(language));
  }

  function _getLanguage() {
    return JSON.parse(localStorage.getItem('language'));
  }

  function _clearLanguage() {
    localStorage.removeItem('language');
  }

  function _setLanguageUnlogged(language) {
    localStorage.setItem('languageUnlogged', JSON.stringify(language));
  }

  function _getLanguageUnlogged() {
    return JSON.parse(localStorage.getItem('languageUnlogged'));
  }

  function _clearLanguageUnlogged() {
    localStorage.removeItem('languageUnlogged');
  }

  function _setRefreshingToken(refreshingToken) {
    localStorage.setItem('refreshingToken', refreshingToken);
  }

  function _getRefreshingToken() {
    return localStorage.getItem('refreshingToken') ?? 'idle';
  }

  function _setLoggedIn(loggedIn) {
    localStorage.setItem('loggedIn', loggedIn)
  }

  function _getLoggedIn() {
    return localStorage.getItem('loggedIn')
  }

  function _clearLoggedIn() {
    localStorage.removeItem('loggedIn')
  }

  function _setLastEmpContExtReAuth(reAuthDate) {
    localStorage.setItem('lastEmpContExtReAuth', reAuthDate);
  }

  function _getLastEmpContExtReAuth() {
    return localStorage.getItem('lastEmpContExtReAuth');
  }

  function _clearLastEmpContExtReAuth() {
    localStorage.removeItem('lastEmpContExtReAuth');
  }

  return {
    getService: _getService,
    setItem: setItem,
    getItem: getItem,
    removeItem: removeItem,
    setLanguage: _setLanguage,
    getLanguage: _getLanguage,
    clearLanguage: _clearLanguage,
    setLanguageUnlogged: _setLanguageUnlogged,
    getLanguageUnlogged: _getLanguageUnlogged,
    clearLanguageUnlogged: _clearLanguageUnlogged,
    setRefreshingToken: _setRefreshingToken,
    getRefreshingToken: _getRefreshingToken,
    getLoggedIn: _getLoggedIn,
    setLoggedIn: _setLoggedIn,
    clearLoggedIn: _clearLoggedIn,
    setLastEmpContExtReAuth: _setLastEmpContExtReAuth,
    getLastEmpContExtReAuth: _getLastEmpContExtReAuth,
    clearLastEmpContExtReAuth: _clearLastEmpContExtReAuth,
  }
})();

export default LocalStorageService;